import { isEmpty, isObject, objectFromEntries } from 'utils'

import { Dictionary } from 'types'

export const encodeUrlValue = (val) => (isEmpty(val) ? '' : encodeURIComponent(val))

export const encodeUrlParams = (params: Dictionary<any>, keyPrefix = undefined): string => {
  return Object.keys(params)
    .map((key) => {
      const prefix = keyPrefix ? keyPrefix + '.' : ''
      return isObject(params[key])
        ? encodeUrlParams(params[key], prefix + key)
        : `${prefix}${encodeURIComponent(key)}=${encodeUrlValue(params[key])}`
    })
    .join('&')
}

export const isValidUrl = (url: string) => {
  try {
    new URL(url)
  } catch (e) {
    return false
  }

  return true
}

export const addOrUpdateUrlParam = (name: string, value: string) => {
  const url = window.location.href.split('?')[0]
  const params = objectFromEntries(new URLSearchParams(window.location.search))

  params[name] = value

  const newUrl = `${url}?${new URLSearchParams(params).toString()}`
  window.history.replaceState({ prevUrl: window.location.href }, null, newUrl)
}
