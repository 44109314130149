import { CreateDocumentBody, UpdateDocumentBody } from 'api'
import { addPetDocument, deletePetDocument, updatePetDocument } from 'services/documentServices'
import { call, put, takeLatest } from 'redux-saga/effects'

import { fetchPetDetailAction } from './petDetails'
import { storeError } from './errors'

//
// ADD PET DOCUMENT
//
const ADD_PET_DOCUMENT_REQUEST = 'ADD_PET_DOCUMENT_REQUEST'
const ADD_PET_DOCUMENT_SUCCEED = 'ADD_PET_DOCUMENT_SUCCEED'
const ADD_PET_DOCUMENT_FAILED = 'ADD_PET_DOCUMENT_FAILED'

interface AddPetDocumentRequestType {
  type: typeof ADD_PET_DOCUMENT_REQUEST
  payload: { petId: string; body: CreateDocumentBody }
}

export const addPetDocumentAction = (payload: {
  petId: string
  body: CreateDocumentBody
}): AddPetDocumentRequestType => {
  return {
    type: ADD_PET_DOCUMENT_REQUEST,
    payload,
  }
}

interface AddPetDocumentSucceedType {
  type: typeof ADD_PET_DOCUMENT_SUCCEED
}

export const addPetDocumentSucceed = (): AddPetDocumentSucceedType => ({
  type: ADD_PET_DOCUMENT_SUCCEED,
})

interface addPetDocumentFailedType {
  type: typeof ADD_PET_DOCUMENT_FAILED
  payload: any
}

export const addPetDocumentFailed = (payload): addPetDocumentFailedType => ({
  type: ADD_PET_DOCUMENT_FAILED,
  payload,
})

/* saga */
function* addPetDocumentSaga(action): Generator {
  try {
    const { petId, body } = action.payload

    if (petId) {
      yield call(addPetDocument, { petId, body })
      yield put(addPetDocumentSucceed())
      yield put(fetchPetDetailAction({ petId: petId as string }))
    }
  } catch (e) {
    yield put(addPetDocumentFailed(e))
    yield put(storeError({ error: e, origin: 'addPetDocumentSaga' }))
    console.error(e)
  }
}

//
// UPDATE PET DOCUMENT
//
const UPDATE_PET_DOCUMENT_REQUEST = 'UPDATE_PET_DOCUMENT_REQUEST'
const UPDATE_PET_DOCUMENT_SUCCEED = 'UPDATE_PET_DOCUMENT_SUCCEED'
const UPDATE_PET_DOCUMENT_FAILED = 'UPDATE_PET_DOCUMENT_FAILED'

interface UpdatePetDocumentRequestType {
  type: typeof UPDATE_PET_DOCUMENT_REQUEST
  payload: { documentId: string; body: UpdateDocumentBody }
}

export const updatePetDocumentAction = (payload: {
  documentId: string
  body: UpdateDocumentBody
}): UpdatePetDocumentRequestType => {
  return {
    type: UPDATE_PET_DOCUMENT_REQUEST,
    payload,
  }
}

interface UpdatePetDocumentSucceedType {
  type: typeof UPDATE_PET_DOCUMENT_SUCCEED
}

export const updatePetDocumentSucceed = (): UpdatePetDocumentSucceedType => ({
  type: UPDATE_PET_DOCUMENT_SUCCEED,
})

interface updatePetDocumentFailedType {
  type: typeof UPDATE_PET_DOCUMENT_FAILED
  payload: any
}

export const updatePetDocumentFailed = (payload): updatePetDocumentFailedType => ({
  type: UPDATE_PET_DOCUMENT_FAILED,
  payload,
})

/* saga */
function* updatePetDocumentSaga(action): Generator {
  try {
    const { documentId, body } = action.payload

    if (documentId) {
      yield call(updatePetDocument, { documentId, body })
      yield put(updatePetDocumentSucceed())
    }
  } catch (e) {
    yield put(updatePetDocumentFailed(e))
    yield put(storeError({ error: e, origin: 'updatePetDocumentSaga' }))
    console.error(e)
  }
}

//
// DELETE PET DOCUMENT
//
const DELETE_PET_DOCUMENT_REQUEST = 'DELETE_PET_DOCUMENT_REQUEST'
const DELETE_PET_DOCUMENT_SUCCEED = 'DELETE_PET_DOCUMENT_SUCCEED'
const DELETE_PET_DOCUMENT_FAILED = 'DELETE_PET_DOCUMENT_FAILED'

interface DeletePetDocumentRequestType {
  type: typeof DELETE_PET_DOCUMENT_REQUEST
  payload: { documentId: string }
}

export const deletePetDocumentAction = (payload: {
  documentId: string
}): DeletePetDocumentRequestType => {
  return {
    type: DELETE_PET_DOCUMENT_REQUEST,
    payload,
  }
}

interface DeletePetDocumentSucceedType {
  type: typeof DELETE_PET_DOCUMENT_SUCCEED
}

export const deletePetDocumentSucceed = (): DeletePetDocumentSucceedType => ({
  type: DELETE_PET_DOCUMENT_SUCCEED,
})

interface deletePetDocumentFailedType {
  type: typeof DELETE_PET_DOCUMENT_FAILED
  payload: any
}

export const deletePetDocumentFailed = (payload): deletePetDocumentFailedType => ({
  type: DELETE_PET_DOCUMENT_FAILED,
  payload,
})

/* saga */
function* deletePetDocumentSaga(action): Generator {
  try {
    const { documentId } = action.payload

    if (documentId) {
      yield call(deletePetDocument, { documentId })
      yield put(deletePetDocumentSucceed())
    }
  } catch (e) {
    yield put(deletePetDocumentFailed(e))
    yield put(storeError({ error: e, origin: 'deletePetDocumentSaga' }))
    console.error(e)
  }
}

//
// SAGA
//
export function* petDocumentsSaga() {
  yield takeLatest(ADD_PET_DOCUMENT_REQUEST, addPetDocumentSaga)
  yield takeLatest(UPDATE_PET_DOCUMENT_REQUEST, updatePetDocumentSaga)
  yield takeLatest(DELETE_PET_DOCUMENT_REQUEST, deletePetDocumentSaga)
}
