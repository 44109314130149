import { Pet, PetState } from 'apiLegacy'

import { PetEntity } from 'api'
import RoundAvatar from 'components/roundAvatar'
import { mainTheme } from 'styles/themes'
import { useRouter } from 'next/router'

const RoundAvatarPet = (props: {
  size?: number | string
  onClick?: (e) => void
  style?: any
  iconOverlay?: Element
  pet: Pet | PetEntity
  useBirthdayIcon?: boolean
  noRedirect?: boolean
}) => {
  const router = useRouter()
  const image = props.pet?.images?.thumbnail
  const isInactive = [PetState.Archived, PetState.Inactive].includes(props.pet?.state as any)

  const getAvatarText = () => {
    if (!image) {
      return props.pet.name ? props.pet.name[0].toUpperCase() : null
    }
  }

  const handleRedirect = () => {
    if (props.pet?.id) {
      router.push(`/pet/${props.pet?.id}`)
    }
  }

  return (
    <RoundAvatar
      size={props.size}
      onClick={props.onClick ? props.onClick : !props.noRedirect ? handleRedirect : undefined}
      style={props.style}
      image={image}
      birthdayDate={(props.pet as any)?.birthday}
      useBirthdayIcon={props.useBirthdayIcon}
      isInactive={isInactive}
      iconOverlay={props.iconOverlay}
      color={mainTheme.colors.primary}
    >
      {getAvatarText()}
    </RoundAvatar>
  )
}

export default RoundAvatarPet
