import SvgIcon, { IconName } from 'components/svgIcon'
import styled, { css } from 'styled-components'
import { submitButtonBoxCss, submitButtonCircularCss } from 'styles/css'

import CheckIcon from 'components/svg/check.svg'
import CloseIcon from 'components/svg/no.svg'
import ModalBase from 'components/modals/ModalBase'
import TextInput from 'components/inputs/input'
import { capitalizeFirstWord } from 'styles/themes'
import { useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

const PopUpContainer = styled.div<{ index?: number }>`
  --padding: 17px;
  ${(props) => props.theme.breakpoints.XXS} {
    --padding: 14px;
  }

  position: relative;
  transition: 0.3s ease all;

  ${(props) =>
    props.index >= 0 &&
    css`
      transform: translateY(${props.index * 15}px);
      opacity: ${100 - (props.index > 0 ? 50 : 0) - props.index * 10}%;
    `};
`

const PopUpWindow = styled.div<{
  submitButtonVisible?: boolean
  height?: number | string
  maxHeight?: number | string
}>`
  --maxHeight: 90vh;
  transition: 0.2s ease all;
  width: 100%;
  max-height: var(--maxHeight);
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 26px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.submitButtonVisible ? 24 : 0)}px;
  box-shadow: 2px 5px 10px #0003;

  ${(props) =>
    props.height &&
    css`
      height: ${typeof props.height === 'number' ? `${props.height}px}` : props.height};
    `}

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: min(
        var(--maxHeight),
        ${typeof props.maxHeight === 'number' ? `${props.maxHeight}px}` : props.maxHeight}
      );
    `}
`

const HeaderWrapper = styled.div`
  padding: var(--padding);
  padding-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
`

const CloseTitle = styled.div`
  ${(props) => props.theme.breakpoints.XXS} {
    display: none;
  }
`

export const PopUpDivider = styled.div`
  position: relative;
  left: calc(-1 * var(--padding));
  width: calc(100% + 2 * var(--padding));
  height: 1px;
  min-height: 1px;
  background: ${(props) => props.theme.colors.black};

  margin: 20px 0;
  ${(props) => props.theme.breakpoints.XXS} {
    margin: 10px 0;
  }
`

const Spacer = styled.div`
  flex: 1;
`

const TitleWrapper = styled.div<{ iconColor?: string }>`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${(props) => props.theme.colors.black};
  font-size: 22px;
  line-height: 26px;

  ${(props) => props.theme.breakpoints.XXS} {
    gap: 12px;
    font-size: 18px;
  }

  svg {
    fill: ${(props) => props.iconColor || props.theme.colors.primary};
    color: ${(props) => props.iconColor || props.theme.colors.primary};

    --size: 26px;
    height: var(--size);
    min-height: var(--size);

    ${(props) => props.theme.breakpoints.XXS} {
      --size: 20px;
    }
  }
`

const Title = styled.span`
  ${capitalizeFirstWord};
`

const CloseWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
  color: ${(props) => props.theme.colors.orange};
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  svg {
    width: 16px;
  }
`

const Content = styled.div<{
  forceHeight?: number
  paddingBottom?: number
  noPadding?: boolean
}>`
  ${(props) =>
    !props.noPadding &&
    css`
      padding: var(--padding);
      padding-top: 0px;
    `}
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${(props) => props.theme.colors.black};
  overflow-x: hidden;
  overflow-y: auto;

  font-size: 16px;
  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 14px;
  }

  ${(props) =>
    props.forceHeight &&
    css`
      height: ${props.forceHeight}px;
      min-height: ${props.forceHeight}px;
      max-height: ${props.forceHeight}px;
    `}

  ${(props) =>
    props.paddingBottom !== undefined &&
    css`
      padding-bottom: ${props.paddingBottom}px;
    `}
`

const SearchBox = styled.div`
  margin-top: calc(-1 * var(--padding));
  padding: 20px 20px;
  padding-top: 10px;
`

const SubmitIconSpacer = styled.div`
  height: 50px;
`

const SubmitButtonBox = styled.div`
  ${submitButtonBoxCss}
  bottom: -30px;
  right: 30px;
  ${(props) => props.theme.breakpoints.XXS} {
    --size: 66px;
  }
`

const SubmitButton = styled.button`
  ${submitButtonCircularCss}
`

export type PopUpSearchBoxType = {
  value: string
  onChange: (searchString) => void
  disabled?: boolean
  isOnTop?: boolean
  isInFocus?: boolean
}

const PopUp = (props: {
  children?: any
  isOpen: boolean
  onOpen?: () => void
  close: () => void
  header?: {
    icon?: any
    iconName?: IconName
    iconColor?: string
    title?: string
    closeIcon?: any
    closeTitle?: string
  }
  content?: {
    paddingBottom?: number
    noPadding?: boolean
  }
  submitButton?: {
    icon?: any
    onClick: () => void
    formId?: string
  }
  searchBox?: PopUpSearchBoxType
  closeOnOverlayClick?: boolean
  noOverlay?: boolean
  index?: number
  height?: number | string
  maxHeight?: number | string
  innerRef?: any
}) => {
  const { t } = useTranslation('common')

  const inputId = `${Math.round(Math.random() * 1000)}${Math.round(Math.random() * 1000)}`
  /* const [contentHeight, setContentHeight] = useState(0) */

  useEffect(() => {
    if (!props.isOpen && props.searchBox) {
      props.searchBox.onChange('')
    }
    const timer = setTimeout(() => {
      if (props.isOpen) {
        const contentElem = document.getElementById(inputId)
        if (contentElem) {
          /* setContentHeight(contentElem.offsetHeight) */
        }
      }
    }, 100)
    return () => {
      clearTimeout(timer)
    }
  }, [props.isOpen])

  //
  // RENDER
  //

  const renderSearchBox = () => {
    return (
      <SearchBox>
        <TextInput
          value={props.searchBox.value}
          onChange={(e) => props.searchBox.onChange((e?.currentTarget as any)?.value)}
          placeholder={t('SEARCH')}
          iconCode={props.searchBox.value?.length > 0 ? 'icon-no' : 'icon-search'}
          onIconClick={
            props.searchBox.value?.length > 0 ? () => props.searchBox.onChange('') : undefined
          }
          disabled={props.searchBox.disabled}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.searchBox.isInFocus}
        />
      </SearchBox>
    )
  }

  return (
    <ModalBase
      isOpen={props.isOpen}
      onOpen={props.onOpen}
      close={props.close}
      closeOnOverlayClick={props.closeOnOverlayClick}
      noOverlay={props.noOverlay}
      index={props.index}
    >
      <PopUpContainer index={props.index}>
        <PopUpWindow
          submitButtonVisible={props.submitButton ? true : false}
          height={props.height}
          maxHeight={props.maxHeight}
          ref={props.innerRef}
        >
          {props.header && (
            <>
              <HeaderWrapper>
                <TitleWrapper iconColor={props.header?.iconColor}>
                  {props.header?.icon
                    ? props.header.icon
                    : props.header?.iconName && <SvgIcon code={props.header.iconName} />}
                  {props.header?.title && <Title>{props.header?.title}</Title>}
                </TitleWrapper>

                <Spacer />

                <CloseWrapper onClick={props.close}>
                  {props.header?.closeIcon ? props.header?.closeIcon : <CloseIcon />}
                  {props.header?.closeTitle && <CloseTitle>{props.header?.closeTitle}</CloseTitle>}
                </CloseWrapper>
              </HeaderWrapper>

              <PopUpDivider />
            </>
          )}

          {props.searchBox && props.searchBox.isOnTop && renderSearchBox()}

          <Content
            id={inputId}
            /* forceHeight={contentHeight} - MOBILE KEYBOARD NEEDS TO SHRINK THE HEIGHT... */
            paddingBottom={props.content?.paddingBottom}
            noPadding={props.content?.noPadding}
          >
            {props.children}
          </Content>

          {props.searchBox && !props.searchBox.isOnTop && renderSearchBox()}

          {props.submitButton && <SubmitIconSpacer />}
        </PopUpWindow>

        {props.submitButton && (
          <SubmitButtonBox>
            <SubmitButton
              onClick={(e) => {
                e.preventDefault()
                props.submitButton?.onClick && props.submitButton?.onClick()
              }}
              type={props.submitButton?.formId ? 'submit' : undefined}
              form={props.submitButton?.formId}
            >
              {props.submitButton.icon || <CheckIcon />}
            </SubmitButton>
          </SubmitButtonBox>
        )}
      </PopUpContainer>
    </ModalBase>
  )
}

export default PopUp
