import styled, { keyframes } from 'styled-components'

import ReactModal from 'react-modal'
import { mainTheme } from 'styles/themes'
import { useEffect } from 'react'

const openAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: none;
  }
`

const Window = styled.div`
  animation-name: ${openAnimation};
  animation-duration: 250ms;
  pointer-events: auto;
`

const ModalBase = (props: {
  isOpen: boolean
  onOpen?: () => void
  close: () => void
  children: any
  closeOnOverlayClick?: boolean
  noOverlay?: boolean
  index?: number
}) => {
  const closeOnOverlayClickDefault = true
  const closeOnOverlayClick =
    props.closeOnOverlayClick === undefined
      ? closeOnOverlayClickDefault
      : props.closeOnOverlayClick
      ? true
      : false

  useEffect(() => {
    ReactModal.setAppElement('body')
  }, [])

  return (
    <ReactModal
      isOpen={props.isOpen}
      closeTimeoutMS={150}
      onAfterOpen={props.onOpen}
      onRequestClose={closeOnOverlayClick ? props.close : undefined}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: props.noOverlay ? '#0000' : `${mainTheme.colors.black}aa`,
          zIndex: mainTheme.layout.layers.modal - (props.index || 0),
        },
        content: {
          left: '0',
          right: '0',
          maxWidth: '500px',
          margin: 'auto',
          padding: '12px',
          border: 'none',
          outline: 'none',
          borderRadius: '0',
          background: 'none',
          inset: '0px',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pointerEvents: 'none',
        },
      }}
      ariaHideApp={false}
    >
      <Window>{props.children}</Window>
    </ReactModal>
  )
}

export default ModalBase
