import { TUTORIAL_IDS, addTutorialToShow } from 'entity/tutorials'

import { addRouteToHistory } from 'entity/navigation'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

// Skip these routes from history (will be handled manually)
const SKIP_ROUTES = ['/event/[eventId]', '/event/new/[code]', '/graphs', '/calendar']

const RouteChangeListener = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    if (router.pathname === '/') {
      dispatch(addTutorialToShow(TUTORIAL_IDS.HEADER_INFO))
    }

    if (!SKIP_ROUTES.includes(router.pathname)) {
      dispatch(addRouteToHistory({ route: router.pathname }))
    }
  }, [router.pathname])

  return null
}

export default RouteChangeListener
