import { clickable, styled } from 'styles/themes'

import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  min-height: 200px;
  display: grid;
  place-items: center;
  margin: 50px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${(props) => props.theme?.colors?.white || 'white'};
  border: ${(props) => props.theme?.colors.darkGrey || 'darkgray'} 1px solid;
  box-shadow: 0px 0px 5px #0000000f;
  ${clickable};
`

const NoPetWarning = () => {
  const { t } = useTranslation('common')

  return (
    <Link href="/pets" prefetch={false}>
      <Wrapper dangerouslySetInnerHTML={{ __html: t('NO_PET_WARNING') }}></Wrapper>
    </Link>
  )
}
export default NoPetWarning
