import styled, { css } from 'styled-components'

import SvgIcon from 'components/svgIcon'

const Wrapper = styled.div<{ onClick?: any }>`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 54px;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  ${(props) =>
    props.onClick &&
    css`
      cursor: Pointer;
    `}
`

const Background = styled.div`
  position: absolute;
  z-index: -1;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 10px ${(props) => props.theme.colors.black};
  top: 0;
  left: 28px;
  right: 0;
  bottom: 0;
  border-radius: 6px;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

const CloseButton = styled.button<{ color: string }>`
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  padding: 16px;

  svg {
    --size: 20px;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);

    ${(props) => props.theme.breakpoints.XXS} {
      --size: 16px;
    }
  }
`

const NotificationBox = (props: {
  children: any
  closeOnClick?: boolean
  onClose?: () => void
  color?: string
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper onClick={props.closeOnClick && !!props.onClose ? props.onClose : undefined}>
      <Background />
      <Content>{props.children}</Content>

      {!!props.onClose && (
        <CloseButton onClick={props.onClose} color={props.color}>
          <SvgIcon code="icon-no" />
        </CloseButton>
      )}
    </Wrapper>
  )
}

export default NotificationBox
