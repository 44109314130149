import { CommonActionType, RESET_STATE } from 'store/actions'
import {
  EventsDefinitionStoreType,
  SAVE_EVENTS_DEFINITION,
  SAVE_EVENT_LISTS,
  SaveEventListsActionType,
  SaveEventsDefinitionActionType,
  eventDefinitionStoreDefault,
  saveEventListsReducer,
  saveEventsDefinitionReducer,
} from 'entity/eventDefinition'
import {
  EventsStoreType,
  FetchEventsSucceedType,
  SAVE_EVENT,
  SAVE_EVENTS,
  SaveEventActionType,
  SaveEventsActionType,
  eventsStoreDefault,
  saveEventReducer,
  saveEventsReducer,
} from 'entity/event'
import {
  SAVE_ASSIGNABLE_USERS,
  SaveAssignableUsersActionType,
  assignableUsersStoreDefault,
  assignableUsersStoreType,
  saveAssignableUsersReducer,
} from 'entity/eventAssign'
import {
  STORE_OVERDUE_COUNT,
  overdueCountStoreDefault,
  overdureCountReducer,
  storeOverdueActionType,
} from 'entity/eventOverdue'

import { HYDRATE } from 'next-redux-wrapper'

type initialStateType = assignableUsersStoreType & EventsStoreType & EventsDefinitionStoreType

export const initialState: initialStateType = {
  ...eventsStoreDefault,
  ...eventDefinitionStoreDefault,
  ...assignableUsersStoreDefault,
  ...overdueCountStoreDefault,
}

export default function eventReducer(
  state = initialState,
  action:
    | SaveEventActionType
    | SaveEventsActionType
    | SaveEventsDefinitionActionType
    | CommonActionType
    | SaveAssignableUsersActionType
    | SaveEventListsActionType
    | FetchEventsSucceedType
    | storeOverdueActionType
) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.items }
    case RESET_STATE:
      return initialState
    case SAVE_EVENT:
      return saveEventReducer(state, action)
    case SAVE_EVENTS:
      return saveEventsReducer(state, action)
    case SAVE_EVENTS_DEFINITION:
      return saveEventsDefinitionReducer(state, action)
    case SAVE_EVENT_LISTS:
      return saveEventListsReducer(state, action)
    case SAVE_ASSIGNABLE_USERS:
      return saveAssignableUsersReducer(state, action)
    case STORE_OVERDUE_COUNT:
      return overdureCountReducer(state, action)
    default:
      return state
  }
}
