import {
  AppNotificationEventType,
  AppNotificationTextType,
  AppNotificationType,
  Event,
} from 'types'
import {
  getEventDate,
  getEventIcon,
  getEventName,
  getEventType,
  getFormattedDate,
  getFormattedTime,
} from 'utils'
import { refreshApp, restartApp } from 'utils/app/utils'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import { IconName } from 'components/svgIcon'
import NotificationBox from '../Components/NotificationBox'
import NotificationIcon from '../Components/NotificationIcon'
import { getEventDefinition } from 'entity/eventDefinition'
import { mainTheme } from 'styles/themes'
import { removeAppNotification } from 'entity/appNotifications'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

const ANIM_LENGTH_MS = 150
const DEFAULT_TIMEOUT_MS = 4000

const Wrapper = styled.div`
  position: relative;
  & * {
    -webkit-tap-highlight-color: transparent;
  }
`

const Spacer = styled.div<{ isVisible: boolean; height: number }>`
  transition: all ${ANIM_LENGTH_MS}ms ease;
  ${(props) =>
    props.isVisible
      ? css`
          height: ${props.height}px;
        `
      : css`
          height: 0px;
        `}
`

const Absolute = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;

  transition: all ${ANIM_LENGTH_MS}ms ease;
  ${(props) =>
    props.isVisible
      ? css`
          opacity: 1;
          transform: none;
        `
      : css`
          opacity: 0;
          transform: translateY(50px);
        `}
`

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 0;
`

const Title = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 14px;
  }
`

const Text = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 18px;

  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 14px;
    line-height: 14px;
  }
`

const Button = styled.button<{ color: string; useGap?: boolean }>`
  outline: none;
  border-radius: 6px;
  border: 1px solid ${(props) => props.color};
  background: ${(props) => props.color}11;
  color: ${(props) => props.color};
  width: 100%;
  padding: 10px;
  margin-top: ${(props) => (props.useGap ? '10px' : '0')};

  font-size: 16px;
  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 14px;
  }
`

const ReportErrorButtons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
`

const ErrorButton = styled.button`
  outline: none;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.error};
  background: ${(props) => props.theme.colors.error}11;
  color: ${(props) => props.theme.colors.error};
  width: 100%;
  padding: 10px;

  font-size: 16px;
  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 14px;
  }
`

const Time = styled.div`
  font-size: 12px;
  line-height: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-right: 10px;

  & > *:first-child {
    font-weight: bold;
  }
`

const Notification = (props: { notification: AppNotificationType; isVisible: boolean }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const timerRef = useRef<any>()
  const gap = 12 //px

  const timeout_ms =
    props.notification.timeout_ms || props.notification.timeout_ms === 0
      ? props.notification.timeout_ms
      : DEFAULT_TIMEOUT_MS

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const eventDefinition = useSelector(
    getEventDefinition((props.notification as AppNotificationEventType).event?.code)
  )

  const heightRef = useRef()
  const [height, setHeight] = useState<number>()

  const closeNotification = () => {
    clearTimeout(timerRef.current)
    setIsVisible(false)
    setTimeout(() => dispatch(removeAppNotification(props.notification)), ANIM_LENGTH_MS)
  }

  useEffect(() => {
    if (props.isVisible) {
      setIsVisible(true)
      /* AUTO-CLOSE AFTER TIMEOUT */
      if (timeout_ms > 0) {
        timerRef.current = setTimeout(closeNotification, timeout_ms)
      }
      return () => {
        clearTimeout(timerRef.current)
      }
    }
  }, [props.isVisible])

  useEffect(() => {
    let timer
    if (heightRef.current) {
      const height = (heightRef.current as any)?.clientHeight
      timer = setTimeout(() => setHeight(height + gap), 40)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [heightRef?.current])

  //
  // NOTIFICATION - TEXT
  //
  const renderNotificationText = () => {
    const notification = props.notification as AppNotificationTextType
    let color, icon

    switch (notification.actionType) {
      case 'success':
        color = mainTheme.colors.green
        icon = 'icon-check'
        break
      case 'warning':
        color = mainTheme.colors.orange
        icon = 'icon-warning'
        break
      case 'error':
        color = mainTheme.colors.error
        icon = 'icon-warning'
        break
      default:
        color = mainTheme.colors.lighterGrey
        icon = 'icon-info'
    }

    return (
      <NotificationBox onClose={closeNotification} closeOnClick color={color}>
        <NotificationIcon code={icon} color={color} timeout_ms={timeout_ms} isVisible={isVisible} />
        <BoxContent>
          {notification.title && (
            <Title>{notification.noTranslation ? notification.title : t(notification.title)}</Title>
          )}
          {notification.text && (
            <Text>{notification.noTranslation ? notification.text : t(notification.text)}</Text>
          )}
          {notification.button && (
            <Button
              color={notification.button.color || mainTheme.colors.black}
              onClick={notification.button.onClick}
              useGap={!!notification.text || !!notification.title}
            >
              {notification.noTranslation ? notification.button.text : t(notification.button.text)}
            </Button>
          )}
        </BoxContent>
      </NotificationBox>
    )
  }

  //
  // NOTIFICATION - EVENT
  //
  const renderNotificationEvent = () => {
    const notification = props.notification as AppNotificationEventType
    const event = notification?.event
    const eventType = getEventType(event as Event, { isCompleted: notification.isCompleted })
    const eventDate = getEventDate(event)
    const color = mainTheme.events?.[eventType]?.color || mainTheme.colors.lighterGrey

    const eventName = getEventName(event as Event, eventDefinition)
    const eventIcon = getEventIcon(event as Event, eventDefinition)

    return (
      <>
        {eventDate && (
          <Time>
            <div>{getFormattedDate(eventDate, { today: t('TODAY') })}</div>
            <div>{getFormattedTime(eventDate)}</div>
          </Time>
        )}

        <NotificationBox onClose={closeNotification} closeOnClick color={color}>
          <NotificationIcon
            code={(eventIcon || 'icon-info') as IconName}
            color={color}
            timeout_ms={timeout_ms}
            isVisible={isVisible}
          />
          <BoxContent>
            {notification.title && (
              <Title>
                {notification.noTranslation ? notification.title : t(notification.title)}
              </Title>
            )}
            <Text>{eventName}</Text>
          </BoxContent>
        </NotificationBox>
      </>
    )
  }

  //
  // NOTIFICATION - ERROR FEEDBACK
  //
  const renderNotificationErrorFeedback = () => {
    const color = mainTheme.colors.error

    const onErrorReport = () => {
      closeNotification()
      router.push('/feedback')
    }

    const onRestart = () => {
      restartApp(dispatch)
    }

    return (
      <NotificationBox onClose={closeNotification} color={color}>
        <NotificationIcon
          code="icon-warning"
          color={color}
          timeout_ms={timeout_ms}
          isVisible={isVisible}
        />
        <ReportErrorButtons>
          <ErrorButton onClick={onErrorReport}>{t('notifications.error.REPORT_ERROR')}</ErrorButton>
          <ErrorButton onClick={onRestart}>{t('RESTART_APP')}</ErrorButton>
        </ReportErrorButtons>
      </NotificationBox>
    )
  }

  //
  // NOTIFICATION - NETWORK ERROR
  //
  const renderNotificationNetworkError = () => {
    const color = mainTheme.colors.error

    const isOffline = !window.navigator.onLine

    return (
      <NotificationBox onClose={closeNotification} color={color}>
        <NotificationIcon
          code="icon-warning"
          color={color}
          timeout_ms={timeout_ms}
          isVisible={isVisible}
        />
        <BoxContent>
          <Title>{t(`notifications.error.NETWORK_ERROR${isOffline ? '_NO_INTERNET' : ''}`)}</Title>
          <Text>
            {t(`notifications.error.NETWORK_ERROR_DESCRIPTION${isOffline ? '_NO_INTERNET' : ''}`)}
          </Text>
          <Spacer height={10} isVisible />
          <ErrorButton onClick={refreshApp}>{t('REFRESH_APP')}</ErrorButton>
        </BoxContent>
      </NotificationBox>
    )
  }

  //
  // RENDER
  //
  const renderNotification = () => {
    switch (props.notification?.type) {
      case 'text':
        return renderNotificationText()
      case 'event':
        return renderNotificationEvent()
      case 'feedback-error':
        return renderNotificationErrorFeedback()
      case 'network-error':
        return renderNotificationNetworkError()
      default:
        return null
    }
  }
  return (
    <Wrapper>
      <Spacer isVisible={isVisible} height={height} />
      <Absolute isVisible={isVisible} ref={heightRef}>
        {renderNotification()}
      </Absolute>
    </Wrapper>
  )
}

export default Notification
