import styled, { css } from 'styled-components'

import RoundAvatar from 'components/roundAvatar'
import SvgIcon from './svgIcon'
import { getAvailablePets } from 'store/selectors'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const Content = styled.div<{ isMinimized?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 45%;
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) =>
    props.isMinimized &&
    css`
      svg {
        opacity: 0;
      }
    `}
`

const Text = styled.div<{ isMinimized?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  --size: 24px;
  bottom: -20%;
  right: 20%;
  min-width: var(--size);
  height: var(--size);
  padding: 6px;
  border-radius: calc(var(--size) / 2);

  font-size: 14px;
  font-weight: 600;
  text-align: center;

  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.primary};
  box-shadow: inset ${(props) => props.theme.colors.primary}88 0px 0px 4px;

  ${(props) =>
    props.isMinimized &&
    css`
      top: -5px;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 16px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.primary};
      background: none;
      border: none;
      box-shadow: none;
    `}
`

const RoundAvatarAllPets = (props: {
  size?: number | string
  onClick?: (e) => void
  style?: any
  iconOverlay?: Element
  isMinimized?: boolean
  noRedirect?: boolean
}) => {
  const router = useRouter()
  const pets = useSelector(getAvailablePets)

  const getAvatarText = () => {
    return pets.length
  }

  const handleRedirect = () => {
    router.push(`/pets`)
  }

  const getOnClickFunction = () => {
    if (props.onClick) {
      return props.onClick
    }
    if (!props.noRedirect) {
      return handleRedirect
    }
    return undefined
  }

  return (
    <RoundAvatar
      size={props.size}
      onClick={getOnClickFunction()}
      style={props.style}
      iconOverlay={props.iconOverlay}
    >
      <Content isMinimized={props.isMinimized}>
        <Text isMinimized={props.isMinimized}>{getAvatarText()}</Text>
        <SvgIcon code="icon-placeholder-pet" />
      </Content>
    </RoundAvatar>
  )
}

export default RoundAvatarAllPets
