import {
  PushNotification,
  PushNotificationBody,
  PushNotificationDataEvent,
  PushNotificationLink,
} from 'types'

import { USERS_TABS } from 'pages/users'
import { addPushNotification } from 'entity/pushNotifications'
import { generateId } from './utils'
import { storeError } from 'entity/errors'

/**
 * Get array of links to be displayed in push notification pop-up
 *
 */
export const getPushNotificationLinks = (props: { notification: PushNotification }) => {
  const { notification } = props

  let links: PushNotificationLink[] = []

  if (notification?.data.type) {
    switch (notification.data.type) {
      case 'groupInvitation':
        links = [
          {
            slug: `/users?tab=${USERS_TABS.otherGroups}`,
            labelTranslationKey: 'push_notification.LINK_GROUP_INVITE',
          },
        ]
        break

      case 'eventAssigned':
      case 'eventUnassigned':
      case 'eventCompleted':
      case 'eventDueSoon':
      case 'eventTimeChanged':
        links = [
          {
            slug: `/event/${notification.data.eventId}`,
            labelTranslationKey: 'push_notification.LINK_EVENT',
          },
        ]
        break
      case 'eventOverdue':
        links = [
          {
            slug: `/overdue`,
            labelTranslationKey: 'push_notification.LINK_EVENTS_OVERDUE',
          },
        ]
        break
      default:
        break
    }
  }
  return links
}

/**
 * Get array of event data (if any)
 *
 */
export const getPushNotificationEventDataArray: (props: {
  notification: PushNotification
  dispatch: (func: any) => void
}) => PushNotificationDataEvent[] = (props) => {
  const { notification, dispatch } = props

  try {
    switch (notification.data.type) {
      case 'eventAssigned':
      case 'eventUnassigned':
      case 'eventCompleted':
      case 'eventDueSoon':
      case 'eventTimeChanged':
        return [notification.data]

      case 'eventOverdue':
        return JSON.parse(notification.data.events.replace(/'/g, '"'))
      default:
        return []
    }
  } catch (error) {
    dispatch(
      storeError({
        error,
        origin: 'PushNotificationListener/getPushNotificationEventDataArray',
        data: JSON.stringify(notification.data),
      })
    )
  }
}

/**
 * Generate push notification body for app use / redux
 *
 */
export const getPushNotificationBody: (props: {
  notification: PushNotification
  dispatch: (func: any) => void
  t: (translationKey: string) => string
}) => PushNotificationBody = (props) => {
  const { notification, dispatch } = props

  const type = notification?.data?.type

  const id = notification?.messageId || generateId()

  const title = notification?.notification?.title || ''

  const description = notification?.notification?.body || '/'

  const links = getPushNotificationLinks({ notification })

  const eventDataArray = getPushNotificationEventDataArray({
    notification,
    dispatch,
  })

  const petId = (notification?.data as any)?.petId

  const body: PushNotificationBody = {
    type,
    id,
    title,
    description,
    links,
    eventDataArray,
    petId,
  }

  return body
}

/**
 * Mock push notifications
 *
 */
const getMockPushNotificationMetaData = () => {
  return {
    sentTime: 0,
    from: 0,
    messageId: generateId(),
    ttl: 0,
    collapseKey: 'key',
  }
}

const getMockPushNotification_EventAssigned = () => {
  return {
    ...getMockPushNotificationMetaData(),
    notification: {
      title: `Event Assigned`,
      body: 'Notification text for eventAssigned type.',
    },
    data: {
      type: 'eventAssigned',
      eventId: '3AV1aJeBfbu',
      eventName: 'blah',
      eventCode: 'purchase',
      eventCompletedAt: '',
      eventTimelineAt: '2022-01-27T00:00:00.000Z',
      petName: 'Azor',
    },
  }
}

const getMockPushNotification_GroupInvitation = () => {
  return {
    ...getMockPushNotificationMetaData(),
    notification: {
      title: `Group Invitation`,
      body: 'Notification text for groupInvitation type.',
    },
    data: {
      type: 'groupInvitation',
      groupId: 'id',
    },
  }
}

export const mockPushNotification = (props: {
  type: 'groupInvitation' | 'eventAssigned'
  dispatch: (func: any) => void
  t: (translationKey: string) => string
}) => {
  const { type, dispatch, t } = props

  let notification
  if (type === 'eventAssigned') {
    notification = getMockPushNotification_EventAssigned()
  } else if (type === 'groupInvitation') {
    notification = getMockPushNotification_GroupInvitation()
  }

  if (notification) {
    dispatch(
      addPushNotification({
        notificationBody: getPushNotificationBody({ notification, dispatch, t }),
      })
    )
  }
}
