import SvgIcon, { IconName } from 'components/svgIcon'

import styled from 'styled-components'

const Button = styled.button<{ textColor?: string; iconColor: string; alignRight?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
  color: ${(props) => props.textColor || props.theme.colors.black};
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  margin: 0 20%;
  padding: 10px 0;

  svg {
    margin: 0 8px;
    color: ${(props) => props.iconColor || props.theme.colors.black};
    width: 14px;
    height: 14px;
    max-width: 14px;
    max-height: 14px;
  }
`

const FooterButtonText = (props: {
  text: string
  textColor?: string
  icon?: IconName
  iconRight?: IconName
  iconColor?: string
  onClick: () => void
  alignRight?: boolean
}) => {
  return (
    <Button
      onClick={props.onClick}
      textColor={props.textColor}
      iconColor={props.iconColor}
      alignRight={props.alignRight}
    >
      {props.icon && <SvgIcon code={props.icon} />}
      {props.text}
      {props.iconRight && <SvgIcon code={props.iconRight} />}
    </Button>
  )
}

export default FooterButtonText
