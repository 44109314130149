import { UserInfo } from 'apiLegacy'

/* CONST */
export const SAVE_USERS = 'SAVE_USERS'

/* DATA TYPES */
export type { UserInfo } from 'apiLegacy'

/* ACTION */
export interface SaveUsersActionProps {
  users: UserInfo[]
}
export interface SaveUsersActionType {
  type: typeof SAVE_USERS
  payload: SaveUsersActionProps
}

export const saveUsers = (payload) => ({
  type: SAVE_USERS,
  payload,
})

/* REDUCER */
export const saveUsersReducer = (state, action) => {
  return {
    ...state,
    users: {
      entities: {
        ...state.users.entities,
        ...action.payload.entities,
      },
      lists: {
        ...state.users.lists,
        ...action.payload.lists,
      },
    },
  }
}

/* SELECTOR */
export const getUserById = (id: string) => (state) => {
  return state?.user?.users.entities[id]
}
