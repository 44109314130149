import { call, put, takeLatest } from 'redux-saga/effects'

import { DashboardResponse } from 'api'
import { fetchDashboard } from 'services/dashboardServices'
import { storeError } from './errors'

//
// STATE
//
type StateType = {
  displayDate: Date
  data: {
    [yearMonth: string]: DashboardResponse
  }
}

const initialState: StateType = {
  displayDate: new Date(),
  data: {},
}

//
// HELPERS
//
type TimeFilter = { year: number; month: number }

const getTimeFilterFromDate = (date: Date) => {
  // month is 1-based !
  return { year: date?.getFullYear(), month: date?.getMonth() + 1 }
}

const getTimeFilterId = (timeFilter?: TimeFilter) => {
  const _timeFilter = timeFilter || getTimeFilterFromDate(new Date())
  return `${_timeFilter.year}-${_timeFilter.month}`
}

//
// FETCH DASHBOARD
//
const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST'
const FETCH_DASHBOARD_SUCCEED = 'FETCH_DASHBOARD_SUCCEED'
const FETCH_DASHBOARD_FAILED = 'FETCH_DASHBOARD_FAILED'

interface FetchDashboardRequestType {
  type: typeof FETCH_DASHBOARD_REQUEST
  payload: Date
}

export const fetchDashboardAction = (payload: Date): FetchDashboardRequestType => {
  return {
    type: FETCH_DASHBOARD_REQUEST,
    payload,
  }
}

interface FetchDashboardSucceedType {
  type: typeof FETCH_DASHBOARD_SUCCEED
  payload: {
    timeFilter: TimeFilter
    data?: DashboardResponse
  }
}

const fetchDashboardSucceed = (payload): FetchDashboardSucceedType => ({
  type: FETCH_DASHBOARD_SUCCEED,
  payload,
})

interface fetchDashboardFailedType {
  type: typeof FETCH_DASHBOARD_FAILED
  payload: any
}

const fetchDashboardFailed = (payload): fetchDashboardFailedType => ({
  type: FETCH_DASHBOARD_FAILED,
  payload,
})

/* reducer */
const fetchDashboardReducer = (state: StateType, action) => {
  const { timeFilter, data } = action.payload

  const timeFilterId = getTimeFilterId(timeFilter)
  const updatedState: StateType = { ...state }
  const storedData = updatedState?.data[timeFilterId]

  updatedState.data[timeFilterId] = { ...(storedData || {}), ...(data || {}) }

  return updatedState as StateType
}

/* saga */
function* fetchDashboardSaga(action): Generator {
  try {
    const date = action.payload
    const timeFilter = getTimeFilterFromDate(date)

    const response = yield call(fetchDashboard, timeFilter)

    yield put(fetchDashboardSucceed({ timeFilter, data: (response as any).data }))
  } catch (e) {
    yield put(fetchDashboardFailed(e))
    yield put(storeError({ error: e, origin: 'fetchDashboardSaga' }))
    console.error(e)
  }
}

//
// SET DASHBOARD DISPLAY DATE
//
const SET_DASHBOARD_DISPLAY_DATE_REQUEST = 'SET_DASHBOARD_DISPLAY_DATE_REQUEST'
const SET_DASHBOARD_DISPLAY_DATE_SUCCEED = 'SET_DASHBOARD_DISPLAY_DATE_SUCCEED'
const SET_DASHBOARD_DISPLAY_DATE_FAILED = 'SET_DASHBOARD_DISPLAY_DATE_FAILED'

interface SetDashboardDisplayDateRequestType {
  type: typeof SET_DASHBOARD_DISPLAY_DATE_REQUEST
  payload: Date
}

export const setDashboardDisplayDateAction = (
  payload: Date
): SetDashboardDisplayDateRequestType => {
  return {
    type: SET_DASHBOARD_DISPLAY_DATE_REQUEST,
    payload,
  }
}

interface SetDashboardDisplayDateSucceedType {
  type: typeof SET_DASHBOARD_DISPLAY_DATE_SUCCEED
  payload: Date
}

export const setDashboardDisplayDateSucceed = (payload): SetDashboardDisplayDateSucceedType => ({
  type: SET_DASHBOARD_DISPLAY_DATE_SUCCEED,
  payload,
})

interface setDashboardDisplayDateFailedType {
  type: typeof SET_DASHBOARD_DISPLAY_DATE_FAILED
  payload: any
}

export const setDashboardDisplayDateFailed = (payload): setDashboardDisplayDateFailedType => ({
  type: SET_DASHBOARD_DISPLAY_DATE_FAILED,
  payload,
})

/* reducer */
const setDashboardDisplayDateReducer = (state: StateType, action) => {
  const date = action.payload
  const updatedState: StateType = { ...state }
  updatedState.displayDate = date || new Date()

  return updatedState as StateType
}

/* saga */
function* setDashboardDisplayDateSaga(action): Generator {
  try {
    const date = action.payload
    yield put(setDashboardDisplayDateSucceed(date))
    yield put(fetchDashboardAction(date))
  } catch (e) {
    yield put(setDashboardDisplayDateFailed(e))
    yield put(storeError({ error: e, origin: 'setDashboardDisplayDateSaga' }))
    console.error(e)
  }
}

//
// REDUCER
//
export const dashboardsReducer = (state = initialState, action: any): StateType => {
  switch (action.type) {
    case FETCH_DASHBOARD_SUCCEED:
      return fetchDashboardReducer(state, action)
    case SET_DASHBOARD_DISPLAY_DATE_SUCCEED:
      return setDashboardDisplayDateReducer(state, action)
    default:
      return state
  }
}

//
// SAGA
//
export function* dashboardsSaga() {
  yield takeLatest(FETCH_DASHBOARD_REQUEST, fetchDashboardSaga)
  yield takeLatest(SET_DASHBOARD_DISPLAY_DATE_REQUEST, setDashboardDisplayDateSaga)
}

//
// SELECTORS
//
export const getDashboard = () => (state) => {
  const _state: StateType = state.dashboard
  const date = _state.displayDate
  return (_state.data[getTimeFilterId(getTimeFilterFromDate(date))] || null) as DashboardResponse
}

export const getDashboardDisplayDate = () => (state) => {
  const _state: StateType = state.dashboard
  const date = _state.displayDate
  return date
}
