import { all, spawn } from 'redux-saga/effects'

import appSaga from './appSaga'
import { dashboardsSaga } from 'entity/dashboard'
import { errorsSaga } from 'entity/errors'
import eventSaga from './eventSaga'
import { footerSaga } from 'entity/footer'
import { graphSaga } from 'entity/graphs'
import groupSaga from './groupSaga'
import { navigationSaga } from 'entity/navigation'
import { petDetailsSaga } from 'entity/petDetails'
import { petDocumentDetailsSaga } from 'entity/petDocumentDetails'
import { petDocumentsSaga } from 'entity/petDocuments'
import petSaga from './petSaga'
import { pinnedPlacesSaga } from 'entity/pinnedPlaces'
import { pushNotificationSaga } from 'entity/pushNotifications'
import { shareSaga } from 'entity/share'
import userSaga from './userSaga'

export default function* rootSaga() {
  yield all([
    spawn(eventSaga),
    spawn(appSaga),
    spawn(userSaga),
    spawn(footerSaga),
    spawn(errorsSaga),
    spawn(pushNotificationSaga),
    spawn(navigationSaga),
    spawn(petSaga),
    spawn(groupSaga),
    spawn(graphSaga),
    spawn(dashboardsSaga),
    spawn(petDetailsSaga),
    spawn(petDocumentsSaga),
    spawn(petDocumentDetailsSaga),
    spawn(shareSaga),
    spawn(pinnedPlacesSaga),
  ])
}
