import CloseIcon from 'components/svg/no.svg'
import LogoSvg from 'components/svg/logo-line-invert.svg'
import { clickable } from 'styles/themes'
import styled from 'styled-components'
import { toggleMenu } from 'store/actions'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

const Box = styled.div`
  position: relative;
  width: 100%;
  height: 45px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  background-color: ${(props) => props?.theme?.colors?.lightGrey};
  place-items: center;
`
const LogoWrapper = styled.div`
  display: grid;
  ${clickable};
  padding: 0px 10px;
`
const Central = styled.div`
  display: grid;
  place-items: center;
`
const Right = styled.div`
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  white-space: nowrap;
  padding: 5px 10px;
  margin-right: 5px;
`
const CloseIconStyled = styled(CloseIcon)`
  height: 30px;
  padding: 5px;
  color: white;
  ${clickable}
`

const Header = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  return (
    <Box>
      <LogoWrapper
        onClick={() => {
          dispatch(toggleMenu(false))
          router.push('/')
        }}
      >
        <LogoSvg height="36" />
      </LogoWrapper>
      <Central></Central>
      <Right>
        <CloseIconStyled onClick={() => dispatch(toggleMenu())} />
      </Right>
    </Box>
  )
}

export default Header
