import React, { useState } from 'react'
import { groupAccept, groupDecline, leaveGroup } from 'entity/group'

import PopUp from 'components/modals/PopUp'
import SvgIcon from 'components/svgIcon'
import { UserPetInfo } from 'apiLegacy'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'

const SectionBox = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 6px ${(props) => props.theme.colors.black}44;
  padding: 20px;
  padding-bottom: 5px;
  border-radius: 6px;
  margin-bottom: 15px;
`

const SectionBoxFooter = styled.div`
  border-top: 1px solid lightgrey;
  margin-top: 15px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const GroupButton = styled.button<{ grey?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: ${(props) => (props.grey ? 'grey' : props.theme.colors.primary)};
  cursor: pointer;
  background: none;
  border: none;
  font-size: inherit;
  padding: 10px 0;

  & > svg {
    width: 20px;
    height: 14px;
    fill: ${(props) => (props.grey ? 'black' : props.theme.colors.primary)};
    margin-left: 10px;
  }
`

const GroupTable = styled.table`
  & td {
    vertical-align: top;
    padding-right: 10px;
  }
`

const UserGroupCard = (props: {
  group: {
    id: string
    name: string
    ownerId: string
    invitationId?: string
    members: { id: string; name: string; images: any[] }[]
    pets: UserPetInfo[]
  }
  isInvite?: boolean
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const owner = props.group.members.find((member) => member.id === props.group.ownerId)
  const members = props.group.members
    .filter((member) => member.id !== props.group.ownerId)
    .map((pet) => pet.name)
    .join(', ')

  const [isConfirmPopUpOpen, setIsConfirmPopUpOpen] = useState<boolean>(false)
  const openConfirmPopUp = () => {
    setIsConfirmPopUpOpen(true)
  }
  const closeConfirmPopUp = () => {
    setIsConfirmPopUpOpen(false)
  }

  const handleSubmitDecline = () => {
    dispatch(groupDecline(props.group.invitationId))
    closeConfirmPopUp()
  }

  const handleSubmitJoin = () =>
    dispatch(
      groupAccept({
        invitationId: props.group.invitationId,
        groupId: props.group.id,
        userId: props.group.ownerId,
      })
    )

  const handleSubmitLeave = () => {
    dispatch(leaveGroup(props.group.id))
    closeConfirmPopUp()
  }

  return (
    <SectionBox>
      <GroupTable>
        <tbody>
          <tr>
            <td>{t('GROUP')}:</td>
            <td style={{ fontWeight: 700 }}>{props.group.name}</td>
          </tr>
          <tr>
            <td>{t('PETS')}:</td>
            <td>{props.group.pets.map((pet) => pet.name).join(', ')}</td>
          </tr>
          <tr>
            <td>{t('OWNER')}:</td>
            <td style={{ fontWeight: 700 }}>{owner?.name}</td>
          </tr>
          <tr>
            <td>{t('MEMBERS')}:</td>
            <td style={{ fontWeight: 700 }}>{members?.length > 0 ? members : '/'}</td>
          </tr>
        </tbody>
      </GroupTable>

      {/*
       ** BUTTONS
       */}
      {props.isInvite ? (
        <SectionBoxFooter>
          <GroupButton grey={true} onClick={openConfirmPopUp}>
            {t('DENY_INVITATION')}
            <SvgIcon code="icon-no" />
          </GroupButton>
          <GroupButton onClick={handleSubmitJoin}>
            {t('ACCEPT_INVITATION')}
            <SvgIcon code="icon-check" />
          </GroupButton>
        </SectionBoxFooter>
      ) : (
        <SectionBoxFooter>
          <GroupButton grey={true} onClick={openConfirmPopUp}>
            {t('LEAVE_GROUP')}
            <SvgIcon code="icon-no" />
          </GroupButton>
        </SectionBoxFooter>
      )}

      {/*
       ** CONFIRMATION POP-UP
       */}
      <PopUp
        isOpen={isConfirmPopUpOpen}
        close={closeConfirmPopUp}
        header={{
          title: t(props.isInvite ? 'DENY_INVITATION' : 'LEAVE_GROUP'),
          closeTitle: t('CANCEL'),
        }}
        submitButton={{ onClick: props.isInvite ? handleSubmitDecline : handleSubmitLeave }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t(props.isInvite ? 'DENY_INVITATION_CONFIRM' : 'LEAVE_GROUP_CONFIRM').replace(
              '{{ group_name }}',
              `<strong>${props.group.name}</strong>`
            ),
          }}
        />
      </PopUp>
    </SectionBox>
  )
}

export default UserGroupCard
