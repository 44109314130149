export * from './headerActions'
export * from './userActions'
export * from './appActions'
export * from './petActions'

import { HYDRATE } from 'next-redux-wrapper'

export const RESET_STATE = 'RESET_STATE'

export type CommonActionType = {
  type: typeof HYDRATE | typeof RESET_STATE
  payload: any
}
