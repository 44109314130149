import { PushNotification, PushNotificationBody } from 'types'
import {
  addPushNotification,
  getPushNotificationsToView,
  setFirebaseToken,
  setHiddenPushNotification,
} from 'entity/pushNotifications'
import { getUser, isAppInitialized } from 'store/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import PopUpNotification from 'components/modals/PopUpNotification'
import { fetchGroups } from 'entity/group'
import { getPushNotificationBody } from 'utils'
import useFirebaseToken from 'hooks/useFirebaseToken'
import usePushNotification from 'hooks/usePushNotification'
import useTranslation from 'next-translate/useTranslation'

const PushNotificationListener = () => {
  const { t } = useTranslation('common')

  const dispatch = useDispatch()
  const loggedUser = useSelector(getUser)
  const isAppInit = useSelector(isAppInitialized)
  const firebaseTokenFromWrapper = useFirebaseToken()
  const notification = usePushNotification()
  const notificationsToView = useSelector(getPushNotificationsToView)

  useEffect(() => {
    if (firebaseTokenFromWrapper) {
      dispatch(setFirebaseToken({ token: firebaseTokenFromWrapper }))
    }
  }, [firebaseTokenFromWrapper])

  //
  // HANDLE NOTIFICATIONS
  //
  const handleHideNotification = (notificationBody: PushNotificationBody) => {
    dispatch(setHiddenPushNotification({ notificationBody: notificationBody }))
  }

  const hideRemainingPopups = (options: { excludeIndex: number }) => {
    notificationsToView.forEach((notificationBody, i) => {
      if (i !== options.excludeIndex) {
        handleHideNotification(notificationBody)
      }
    })
  }

  const handleNewNotification = (notification: PushNotification) => {
    /* DEBUG POP-UP WITH RAW DATA (DO NOT DELETE) */
    /* dispatch(
      addPushNotification({
        notificationBody: {
          type: 'test',
          id: generateId(),
          title: 'DEBUG',
          description: JSON.stringify(notification, null, 2),
        },
      })
    ) */

    /* TODO - CHECK IF STILL NEEDED */
    if (notification.data.type === 'groupInvitation') {
      dispatch(fetchGroups())
    }

    dispatch(
      addPushNotification({
        notificationBody: getPushNotificationBody({ notification, dispatch, t }),
      })
    )
  }

  useEffect(() => {
    if (notification) {
      handleNewNotification(notification)
    }
  }, [notification])

  //
  // UNIFORM POP-UP HEIGHT
  //
  const targetRef = useRef()
  const [topPopUpHeight, setTopPopUpHeight] = useState<number>(undefined)

  useEffect(() => {
    if (targetRef?.current) {
      // reset heights to re-calculate top height
      setTopPopUpHeight(undefined)
    }
  }, [targetRef?.current, notificationsToView])

  useEffect(() => {
    if (targetRef.current) {
      setTopPopUpHeight((targetRef.current as any)?.clientHeight)
    } else {
      setTopPopUpHeight(undefined)
    }
  }, [targetRef?.current, topPopUpHeight])

  const getInvertedIndex = (i: number) => {
    return notificationsToView?.length - 1 - i
  }

  //
  // RENDER
  //
  return loggedUser && isAppInit ? (
    <>
      {notificationsToView?.map((notificationBody, i) => (
        <PopUpNotification
          innerRef={
            notificationsToView?.length > 0 && getInvertedIndex(i) === 0 ? targetRef : undefined
          }
          key={notificationBody.id}
          body={notificationBody}
          noOverlay={i > 0}
          index={notificationsToView?.length - 1 - i}
          height={topPopUpHeight}
          onLinkClick={() => hideRemainingPopups({ excludeIndex: i })}
        />
      ))}
    </>
  ) : null
}

export default PushNotificationListener
