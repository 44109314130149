export const variants = (pageWidth = 200, direction = 'left') => {
  const x = direction === 'left' ? -pageWidth : pageWidth
  return {
    hidden: {
      opacity: 0,
      x,
      y: 0,
      transition: {
        duration: 0.4,
      },
    },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        duration: 0.4,
      },
    },
    exit: {
      opacity: 0,
      x,
      y: 0,
      transition: {
        duration: 0.4,
      },
    },
  }
}
