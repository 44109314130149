export const setMapPath = (props: {
  directionsService: google.maps.DirectionsService
  origin: { lat: number; lng: number } | google.maps.LatLng
  destination: { lat: number; lng: number } | google.maps.LatLng
  callback: (result: google.maps.DirectionsResult) => void
  errorCallback?: () => void
}) => {
  props.directionsService?.route(
    {
      origin: props.origin,
      destination: props.destination,
      travelMode: google.maps.TravelMode.DRIVING, // TODO - OPTION TO CHOOSE ?
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        props.callback(result)
      } else {
        if (props.errorCallback) {
          props.errorCallback()
        }
        console.error(`error fetching directions ${result}`)
      }
    }
  )
}
