import { InsertEventBody, Pet, User } from 'apiLegacy'

import { Event } from 'types'
import { isAfter } from 'date-fns'

export const parseGroupEntities = (group, state) => {
  const entity = state.groups.groups.entities[group]
  const members = entity.members
    .map((id) => state.user.users.entities[id])
    .filter(Boolean) as User[]
  const pets = entity.pets.map((id) => state.pets.pets.entities[id]).filter(Boolean) as Pet[]

  return { ...entity, members, pets }
}

export const getPetListDirection = (options: {
  pets: Pet[]
  currentPetId: string
  previousPetId: string
}) => {
  const previousIndex = options.pets.findIndex((p) => p.id === options.previousPetId)
  const currentIndex = options.pets.findIndex((p) => p.id === options.currentPetId)
  if (currentIndex === -1 && previousIndex === options.pets.length - 1) {
    /* currentIndex === -1 handles 'all' pet.id */
    return 1
  }
  if (previousIndex === -1 && currentIndex === options.pets.length - 1) {
    return -1
  }
  return currentIndex < previousIndex ? -1 : 1
}

export const getEventType = (event: Event, options?: { isCompleted?: boolean }) => {
  if (!event) {
    return null
  }
  if (options?.isCompleted !== undefined) {
    return 'event'
  }
  return event?.isCompleted !== null || event?.completedAt?.length > 0
    ? 'event'
    : isAfter(getEventDate(event as any), Date.now())
    ? 'reminder'
    : 'overdue'
}

export const getEventDate = (event: Event | InsertEventBody) => {
  return event
    ? new Date((event as Event)?.timelineAt || ((event as InsertEventBody)?.items as any)?.date)
    : null
}
