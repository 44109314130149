import { CreateDocumentBody, DocumentApi, UpdateDocumentBody } from 'api/api'

import { createApiInstance } from 'utils'

const apiInstance = () => createApiInstance(DocumentApi)

export const fetchPetDocuments = (petId?: string) => {
  return apiInstance().getPetDocuments(petId)
}

export const fetchPetDocument = (props: { documentId: string }) => {
  return apiInstance().getDocument(props.documentId)
}

export const addPetDocument = (props: { petId: string; body: CreateDocumentBody }) => {
  return apiInstance().addDocument(props.petId, props.body)
}

export const updatePetDocument = (props: { documentId: string; body: UpdateDocumentBody }) => {
  return apiInstance().updateDocument(props.documentId, props.body)
}

export const deletePetDocument = (props: { documentId: string }) => {
  return apiInstance().deleteDocument(props.documentId)
}
