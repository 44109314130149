import { BreedsApi, GroupsApi, PetApi } from 'apiLegacy'
import { createApiInstance, createLegacyApiInstance } from 'utils'

import { PetApi as PetApiInstance } from 'api/api'
import { PetType } from 'types'

const breedsApi = () => createLegacyApiInstance(BreedsApi)
const petApi = () => createLegacyApiInstance(PetApi)
const groupApi = () => createLegacyApiInstance(GroupsApi)
const newPetApi = () => createApiInstance(PetApiInstance)

export const fetchBreeds = (language, petType: PetType) => {
  return breedsApi().findBreeds(language, petType)
}

export const savePet = (payload) => {
  return petApi().userPetSave(payload)
}

export const fetchPets = () => {
  return groupApi().findPetsFromUserGroups()
}

export const deletePet = (petId: string) => {
  return newPetApi().deletePet(petId)
}

export const disablePet = (petId: string) => {
  return newPetApi().disablePet(petId)
}

export const enablePet = (petId: string) => {
  return newPetApi().enablePet(petId)
}

export const archivePet = (petId: string) => {
  return newPetApi().archivePet(petId)
}
