import { awsURL, imgixURL } from 'constants/aws'

import { Images as ImageType } from 'apiLegacy/api'
import axios from 'axios'
import { getUploadHeaders } from 'services/awsService'

export const uploadImage = async (image) => {
  const fd = new FormData()

  const {
    data: { headers },
  } = await getUploadHeaders({ contentType: 'image/jpg' })

  const { key } = headers
  const imageId = key.replace('images/', '')

  // fill FormData with headers from BE
  Object.keys(headers).map((key) => fd.append(key, headers[key]))
  fd.append('file', image)

  try {
    const res = await axios.post(awsURL, fd)

    if (res.status === 201) {
      return imageId
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

const ImgixParamMap = {
  focalPointX: 'fp-x',
  focalPointY: 'fp-y',
  focalPointZ: 'fp-z',
  width: 'w',
  height: 'h',
  fit: 'fit',
}

export const getImageUrl = (
  imageId: string,
  options?: {
    focalPointX?: number
    focalPointY?: number
    focalPointZ?: number
    width?: number
    height?: number
    fit?: 'clamp' | 'clip' | 'crop' | 'facearea' | 'fill' | 'fillmax' | 'max' | 'min' | 'scale'

    /* TODO - ADD MORE IMGIX OPTIONS BASED ON DOCS - https://docs.imgix.com/apis/rendering/focalpoint-crop/fp-x */
  }
) => {
  const url = imgixURL + imageId
  const params = Object.entries(options || {}).map(
    ([key, value]) => encodeURIComponent(ImgixParamMap[key]) + '=' + encodeURIComponent(value)
  )
  if (!imageId) {
    return undefined
  }
  if (params.length > 0) {
    return `${url}?${params.join('&')}`
  }
  return url
}

export const getImageObjectFromId: (imageId: string) => ImageType = (imageId) => {
  const image: ImageType = {
    id: imageId,
    medium: getImageUrl(imageId, {
      height: 1024,
    }),
    thumbnail: getImageUrl(imageId, {
      focalPointX: 0.5,
      focalPointY: 0.5,
      focalPointZ: 1,
      fit: 'crop',
      width: 200,
      height: 200,
    }),
    original: getImageUrl(imageId),
  }

  return image
}
