import axios, { AxiosInstance } from 'axios'

import cfg from 'config'
import { getAuthToken } from 'services'
import { isServerSide } from 'utils'

export * from './widgets'

let failedResponses = []

const storeFailedResponse = (error) => {
  failedResponses.push({
    url: error.response?.config?.url,
    method: error.response?.config?.method,
    data: error.response?.config?.data,
    status: error.response?.request?.status,
    message: error.response?.data?.error?.message || error.message,
    sentryId: error.response?.data?.error?.requestId,
    requestId: error.response?.data?.error?.sentryId,
  })

  if (failedResponses.length > 10) {
    failedResponses = failedResponses.slice(-10)
  }
}

export const createApiInstance = (instance, options?: { isLegacy?: boolean }) => {
  const axiosInstance: AxiosInstance = axios.create()

  const baseURL = process.env.NEXT_PUBLIC_API_URL + (options?.isLegacy ? '/v1' : '')

  axiosInstance.interceptors.request.use((config) => {
    const token = getAuthToken() ? getAuthToken().accessToken : ''
    const git = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
    const locale = !isServerSide() && window?.locale

    config.baseURL = baseURL
    config.headers = {
      Authorization: `Bearer ${token}`,
      ...(locale && { 'app-locale': locale }),
      ...(git && { git }),
      ...(typeof window === undefined && { origin: cfg.APP_URL }),
      ...config.headers,
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      storeFailedResponse(error)
      return Promise.reject(error)
    }
  )

  const res = new instance(undefined, baseURL, axiosInstance)

  return res
}

export const createLegacyApiInstance = (instance) => {
  return createApiInstance(instance, { isLegacy: true })
}

export { failedResponses }
