import { useDispatch, useSelector } from 'react-redux'

import RoundAvatarUser from 'components/roundAvatarUser'
import Router from 'next/router'
import SvgIcon from 'components/svgIcon'
import { getUser } from 'store/selectors'
import { nameFromEmail } from 'utils'
import styled from 'styled-components'
import { toggleMenu } from 'store/actions'

const Wrapper = styled.div`
  position: relative;
  place-items: center;
  padding: 15px 25px;
  width: 100%;
  height: 100px;
  display: flex;
  background: ${(props) => props.theme.colors.lightGrey};
  background: linear-gradient(
    ${(props) => props.theme.colors.lightGrey} 70%,
    ${(props) => props.theme.colors.lightGrey}99
  );
`

const Avatar = styled.div`
  border-radius: 100%;
  border: 2px solid white;

  & > * {
    --size: 70px;
    ${(props) => props.theme.breakpoints.XXS} {
      --size: 50px;
    }
  }
`

const Title = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.white};
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  width: 0;
  min-width: calc(100% - 150px);

  & > div {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > div:first-of-type {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: -5px;
    height: 40px;

    ${(props) => props.theme.breakpoints.XXS} {
      font-size: 18px;
      margin-bottom: -15px;
    }
  }
`

const Icon = styled.div`
  display: inline-block;
  height: 100%;
  cursor: pointer;
  margin-left: 20px;
  width: 30px;
  fill: ${(props) => props.theme.colors.primary};
  position: relative;
  z-index: 1000;
  color: ${(props) => props.theme.colors.primary};
  & > svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  ${(props) => props.theme.breakpoints.XXS} {
    width: 25px;
  }
`

const UserSubHeader = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const handleClickEdit = () => {
    dispatch(toggleMenu())
    Router.push('/profile')
  }

  if (!user) return null

  const name = user.name || nameFromEmail(user.email) || '~ ~'
  const [firstName, ...middleName] = name.split(' ') || ['', '']
  const lastName = middleName.pop() || ''

  return (
    <Wrapper>
      <Avatar>
        <RoundAvatarUser user={user} />
      </Avatar>
      <Title>
        <div>{firstName}</div>
        <div>{[...middleName, lastName].join(' ')}</div>
      </Title>
      <Icon onClick={handleClickEdit}>
        <SvgIcon code="icon-edit" />
      </Icon>
    </Wrapper>
  )
}

export default UserSubHeader
