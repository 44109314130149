/// <reference no-default-lib="true"/>
/* eslint-disable */
export interface SagaStore extends Store {
  sagaTask?: Task
}

import { Store, applyMiddleware, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'

import authMiddleware from 'store/middleware/authMiddleware'
import createSagaMiddleware from 'redux-saga'
import { createWrapper } from 'next-redux-wrapper'
import rootReducer from './reducers'
import rootSaga from './sagas'
import storage from 'redux-persist/lib/storage'

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

let _store

export const makeStore = ({ isServer }): Store => {
  const sagaMiddleware = createSagaMiddleware()
  if (isServer) {
    const store = createStore(rootReducer, bindMiddleware([authMiddleware, sagaMiddleware]))
    store.sagaTask = sagaMiddleware.run(rootSaga)

    return store
  } else {
    const persistConfig = {
      key: 'petlifebook',
      whitelist: ['user'],
      blacklist: ['groups', 'users'],
      storage,
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const store = createStore(persistedReducer, bindMiddleware([authMiddleware, sagaMiddleware]))
    store.__persistor = persistStore(store)
    store.sagaTask = sagaMiddleware.run(rootSaga)

    _store = store
    return store
  }
}

export const wrapper = createWrapper(makeStore, { debug: true })
/* eslint-enable */

export { _store as store }
