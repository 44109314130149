export const isArray = (val: any) => Array.isArray(val)

export const flattenArray = (array) => {
  // eslint-disable-next-line prefer-spread
  return [...new Set([].concat.apply([], array))]
}

export const range = (from: number, to: number): number[] => {
  let reverse = false
  if (from > to) {
    ;[to, from] = [from, to]
    reverse = true
  }

  const length = to - from + 1
  const result = Array.from({ length }, (_, i) => i + from)
  return reverse ? result.reverse() : result
}
