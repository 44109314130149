import { GET_USER_DATA_SUCCEED, RESET_STATE, SET_USER, USER_UPDATE_SUCCEED } from 'store/actions'
import { GetGroupsResponse, User } from 'apiLegacy'
import { SAVE_USERS, saveUsersReducer } from 'entity/users'

import { UserEntity } from './../../apiLegacy/api'

type InitialStateTypes = {
  user: User | null
  users: {
    entities?: {
      [x: string]: UserEntity
    }
    lists?: string[]
  }
  groups?: GetGroupsResponse
}

export const initialState: InitialStateTypes = {
  user: null,
  users: {
    entities: {},
    lists: [],
  },
}

export default function userReducer(state = initialState, action: any): InitialStateTypes {
  switch (action.type) {
    case SAVE_USERS:
      return saveUsersReducer(state, action)
    case SET_USER:
      return { ...state, user: state.user ? { ...state.user, ...action.payload } : action.payload }
    case RESET_STATE:
      return initialState
    case GET_USER_DATA_SUCCEED:
      return { ...state, user: { ...state.user, ...action.payload } }
    case USER_UPDATE_SUCCEED:
      return { ...state, user: action.payload }
    default:
      return state
  }
}
