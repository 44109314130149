import LogoSvg from 'components/svg/logo-big.svg'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4vh;
`

const Text = styled.div`
  max-width: min(90%, 280px);
  font-size: 20px;
  text-align: center;
`

const AppBlocker = () => {
  const { t } = useTranslation('common')

  return (
    <Wrapper>
      <LogoSvg width="min(60%, 200px)" />

      <Text>{t('BLOCKER')}</Text>
    </Wrapper>
  )
}

export default AppBlocker
