import { mainTheme } from 'styles/themes'

export const CURRENCY_TYPE = {
  USD: 'USD',
  EUR: 'EUR',
  CZK: 'CZK',
}

export const CURRENCY_ARRAY: string[] = ['CZK', 'USD', 'EUR']

export const currencyColors = {
  USD: '#669966',
  EUR: '#5D7EA7',
  CZK: mainTheme.colors.text,
}
