import * as Sentry from '@sentry/nextjs'

import {
  FETCH_TIMEZONES_REQUEST,
  GET_USER_DATA_REQUEST,
  HANDLE_ERROR,
  INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_REQUEST,
  INVALIDATE_FIREBASE_TOKEN_REQUEST,
  REFRESH_TOKEN_REQUEST,
  REGISTER_ANONYMOUS_TOKEN_REQUEST,
  REGISTER_FIREBASE_TOKEN_REQUEST,
  RESET_STATE,
  SET_USER,
  USER_LOGOUT_REQUEST,
  fetchBreeds,
  fetchTimezones,
  fetchTimezonesFailed,
  fetchTimezonesSucceed,
  getUserData,
  getUserSettings,
  getUserSettingsFailed,
  getUserSettingsSucceed,
  invalidateAnonymousFirebaseTokenSucceed,
  invalidateFirebaseToken,
  invalidateFirebaseTokenFailed,
  invalidateFirebaseTokenSucceed,
  logoutUserSucceed,
  refreshTokenFailed,
  registerAnonymousTokenFailed,
  registerFirebaseTokenFailed,
  registerFirebaseTokenSucceed,
  setAppInitialized,
  setTimezones,
  setUser,
  toggleLoadingScreen,
  toggleMenu,
} from 'store/actions'
import { OAuthToken, Timezone } from 'apiLegacy'
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { fetchEventLists, fetchEventsDefinition } from 'entity/eventDefinition'
import {
  fetchTimezones as fetchTimezonesRequest,
  getAuthToken,
  getUserSettings as getUserSettingsService,
  invalidateAnonymousFirebaseToken as invalidateAnonymousFirebaseTokenService,
  invalidateFirebaseToken as invalidateFirebaseTokenService,
  refreshToken as refreshTokenService,
  registerAnonymousFirebaseToken as registerAnonymousFirebaseTokenService,
  registerFirebaseToken as registerFirebaseTokenService,
  setAuthToken,
} from 'services'
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'utils'
import { resetErrors, storeError } from 'entity/errors'

import { REHYDRATE } from 'redux-persist'
import Router from 'next/router'
import { addAppNotification } from 'entity/appNotifications'
import { fetchGraphDefinitions } from 'entity/graphs'
import { fetchGroups } from 'entity/group'
import { fetchPinnedVetsAction } from 'entity/pinnedPlaces'
import { getFirebaseToken } from 'entity/pushNotifications'
import { getUser } from 'store/selectors'
import { isServerSide } from 'utils'
import { loadOverdueCount } from 'entity/eventOverdue'
import { routes } from 'constants/routes'
import { setTutorialsAllowedByUser } from 'entity/tutorials'

// initial dispatch
export function* initialSaga(action) {
  if (isServerSide()) return

  try {
    // remove sentry error from localstorage
    yield call(removeLocalStorageItem, 'sentry_event_id')
    // First handle unprotected (vet share) page
    if (Router.pathname === routes.SHARED_PAGE) {
      yield put(fetchBreeds({ language: window?.locale }))
      yield put(setAppInitialized(true))
      return
    }

    const introVisited = yield getLocalStorageItem('introVisited')
    if (!introVisited) {
      // take note that the user has already seen the intro
      yield setLocalStorageItem('introVisited', 'true')
      yield call(Router.push, routes.INTRO_PAGE)
    }

    const user = yield select(getUser)
    const storedTokenInLocalstorage = yield call(getAuthToken)
    const storedToken = storedTokenInLocalstorage || action.payload

    if (user && !storedToken) {
      yield put({ type: RESET_STATE })
      // yield put(setUser(null))
      yield call(Router.push, '/login')
    } else if (user && Router.pathname === routes.LOGIN_PAGE) {
      // If user is already logged in, skip login page
      yield call(Router.push, '/')
    }

    yield delay(500)

    // dont request data if is user not logged in
    if (user !== null) {
      yield all([
        put(getUserData()),
        put(fetchGraphDefinitions()),
        put(getUserSettings()),
        put(fetchTimezones()),
        // put(fetchPets()),
        // put(fetchGroups()),
        put(fetchGroups()),
        put(fetchPinnedVetsAction()),
        put(loadOverdueCount()),
      ])
    }

    yield put(setAppInitialized(true))

    yield call(repeatedCallsInit)
  } catch (e) {
    yield put(storeError({ error: e, origin: 'initialSaga' }))
    console.error(e)
  }
}

export function* fetchLocalizedData(language) {
  yield all([put(fetchEventsDefinition()), put(fetchEventLists()), put(fetchBreeds({ language }))])
}

function* getUserSettingsSaga() {
  try {
    const response = yield call(getUserSettingsService)
    const data = yield response?.data
    if (data) {
      yield put(getUserSettingsSucceed(data))
      yield put(setTutorialsAllowedByUser(data.showHelp))
      yield fetchLocalizedData(data?.language)
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'getUserSettingsSaga' }))
    yield put(getUserSettingsFailed(e))
    console.error(e)
  }
}

const cycleDuration = 60000
const cronDef: Array<{ cycle: number; loggedOnly: boolean; run: any; actual?: number }> = [
  {
    cycle: 5, // 1 cycle = 60000ms = 1 min
    loggedOnly: true,
    run: put(loadOverdueCount()),
  },
]

function* repeatedCallsInit() {
  if (isServerSide()) return
  while (true) {
    const user = yield select(getUser)
    const toCall = cronDef.map((def) => {
      def.actual = (def?.actual || 1) - 1
      if (def.actual <= 0) def.actual = def.cycle
      if (def.actual === def.cycle && (!def?.loggedOnly || (def?.loggedOnly && !!user))) {
        return def.run
      }
      return undefined
    })
    yield all(toCall)
    yield delay(cycleDuration)
  }
}

function* refreshToken() {
  try {
    const token: OAuthToken | undefined = yield call(getAuthToken)
    if (!token) {
      yield call(logoutUser)
    } else {
      const newToken = yield call(refreshTokenService, token.refreshToken)
      if (newToken.status >= 200 && newToken.status < 300) {
        yield call(setAuthToken, newToken)
        window.refreshingToken = false
      } else {
        throw 'Failed to get refresh token! Logout.'
      }
    }
  } catch (e) {
    window.refreshingToken = false
    yield put(refreshTokenFailed(e))
    yield put(storeError({ error: e, origin: 'refreshToken' }))
    yield call(setAuthToken, null)
    yield call(logoutUser)
    return null
  }
}

function* logoutUser() {
  try {
    console.log('vola sa logout usera')
    const firebaseToken = yield select(getFirebaseToken)
    if (firebaseToken) {
      yield put(invalidateFirebaseToken(firebaseToken))
    }

    yield put(resetErrors())
    yield put({ type: RESET_STATE })
    yield call(setAuthToken, undefined)
    yield put(setUser(null))
    yield put(toggleMenu(false))
    yield call(Router.push, '/login')
    yield put(setAppInitialized(true))
    yield put(logoutUserSucceed())
    yield put(
      addAppNotification({
        type: 'text',
        text: 'notifications.success.LOGOUT',
        actionType: 'success',
      })
    )
  } catch (e) {
    yield put(storeError({ error: e, origin: 'logoutUser' }))
    console.error(e)
  }
}

function* errorHandlerSaga(action) {
  console.log('vola sa error')
  // dont work with errors at server side
  if (isServerSide()) return
  const error = yield action.payload
  const errorCode: number = yield error?.response?.status
  const isNetworkError = yield error?.isAxiosError

  console.log('error: ', error)

  if (!errorCode && !isNetworkError) return
  // catch unauthorized codes
  if (errorCode === 401 || errorCode === 403) {
    if (
      Router.pathname !== '/register' &&
      Router.pathname !== '/login' &&
      Router.pathname !== routes.INTRO_PAGE
    ) {
      console.log('vol sa logoutuser zo sagy')
      yield call(logoutUser)
      yield call(Router.push, '/login')
    }
  }

  if (errorCode >= 500 && errorCode < 600) {
    Sentry.captureException(new Error(`${errorCode}: ` + error))
  }

  if (isNetworkError) {
    Sentry.captureException(error)
  }
}

let pendingRequests = []

function* loadingScreenWatcher(action) {
  // fitler unusable actions
  if (isServerSide()) return
  // filter by type
  if (
    !action.type.endsWith('REQUEST') &&
    !action.type.endsWith('SUCCEED') &&
    !action.type.endsWith('FAILED')
  )
    return
  // filter by action name
  if (action.type === REFRESH_TOKEN_REQUEST) {
    return
  }

  // fill pendingRequests array with new request
  if (action.type.endsWith('REQUEST')) pendingRequests.push(action.type.split('_REQUEST')[0])

  // remove request from array on succeed or failed
  if (action.type.endsWith('SUCCEED'))
    pendingRequests = pendingRequests.filter((req) => req !== action.type.split('_SUCCEED')[0])

  if (action.type.endsWith('FAILED'))
    pendingRequests = pendingRequests.filter((req) => req !== action.type.split('_FAILED')[0])

  // show loading screen depends on pending requests
  if (pendingRequests.length > 0) {
    yield put(toggleLoadingScreen(true))
  } else {
    // TODO: find better solution for loading screen blink
    yield put(toggleLoadingScreen(false))
  }
}

function* fetchTimezonesSaga() {
  try {
    const timezonesResponse: any = yield call(fetchTimezonesRequest)
    const timezones: Timezone[] = yield timezonesResponse.data.items
    yield put(setTimezones(timezones))
    if (timezonesResponse) {
      yield put(fetchTimezonesSucceed())
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'fetchTimezonesSaga' }))
    yield put(fetchTimezonesFailed(e))
    console.error(e)
  }
}

function* registerFirebaseToken(action) {
  try {
    const response: any = yield call(registerFirebaseTokenService, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(registerFirebaseTokenSucceed())
    } else {
      yield put(registerFirebaseTokenFailed())
    }
  } catch (e) {
    yield put(registerFirebaseTokenFailed())
  }
}

function* registerAnonymousFirebaseToken(action) {
  try {
    const response: any = yield call(registerAnonymousFirebaseTokenService, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(registerAnonymousTokenFailed())
    } else {
      yield put(registerAnonymousTokenFailed())
    }
  } catch (e) {
    yield put(registerAnonymousTokenFailed())
  }
}

function* invalidateFirebaseTokenSaga(action) {
  try {
    const response: any = yield call(invalidateFirebaseTokenService, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(invalidateFirebaseTokenSucceed())
    } else {
      yield put(invalidateFirebaseTokenFailed())
    }
  } catch (e) {
    yield put(invalidateFirebaseTokenFailed())
  }
}

function* invalidateAnonymousFirebaseTokenSaga(action) {
  try {
    const response: any = yield call(invalidateAnonymousFirebaseTokenService, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(invalidateAnonymousFirebaseTokenSucceed())
    } else {
      yield put(invalidateAnonymousFirebaseTokenSucceed())
    }
  } catch (e) {
    yield put(invalidateAnonymousFirebaseTokenSucceed())
  }
}

function* appSaga() {
  // yield fork(initialSaga)
  yield takeEvery((action) => action, loadingScreenWatcher)
  yield takeEvery((action) => action.type.endsWith('FAILED'), errorHandlerSaga)
  yield takeEvery(HANDLE_ERROR, errorHandlerSaga)
  yield takeLatest(SET_USER, initialSaga)
  yield takeEvery(REHYDRATE, initialSaga)
  yield takeLeading(GET_USER_DATA_REQUEST, getUserSettingsSaga)
  yield takeLeading(FETCH_TIMEZONES_REQUEST, fetchTimezonesSaga)
  yield takeLeading(USER_LOGOUT_REQUEST, logoutUser)
  yield takeLeading(REFRESH_TOKEN_REQUEST, refreshToken)
  yield takeLatest(REGISTER_FIREBASE_TOKEN_REQUEST, registerFirebaseToken)
  yield takeLatest(REGISTER_ANONYMOUS_TOKEN_REQUEST, registerAnonymousFirebaseToken)
  yield takeLatest(INVALIDATE_FIREBASE_TOKEN_REQUEST, invalidateFirebaseTokenSaga)
  yield takeLatest(
    INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_REQUEST,
    invalidateAnonymousFirebaseTokenSaga
  )
}

export default appSaga
