import { EventDefinition, Pet, PetState } from 'apiLegacy'
import { GraphDefinition, getGraphDefinitions } from 'entity/graphs'
import Slider, { Item } from 'components/Slider'
import SvgIcon, { IconName } from './svgIcon'
import { TUTORIALS_PET_HEADER_CONTENT, TUTORIAL_IDS, addTutorialToShow } from 'entity/tutorials'
import {
  getActivePet,
  getAvailablePets,
  getSubHeaderSelectedEventCode,
  getSubHeaderSelectedGraphCode,
  getSubHeaderSliderOffsetPx,
  isPetHeaderOpen,
} from 'store/selectors'
import { memo, useEffect, useRef, useState } from 'react'
import {
  setActivePet,
  setSubHeaderEventCode,
  setSubHeaderGraphCode,
  setSubHeaderSliderOffsetPx,
} from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import AnimateHeight from 'react-animate-height'
import RoundAvatarAllPets from './roundAvatarAllPets'
import RoundAvatarPet from './roundAvatarPet'
import TutorialHighlight from './Tutorials/TutorialHighlight'
import { getEventDefinitions } from 'entity/eventDefinition'
import styled from 'styled-components'
import useClickOutside from 'hooks/useClickOutside'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

const HEADER_HEIGHT_OPEN = '100px'
const HEADER_HEIGHT_OPEN_XXS = '70px'
const HEADER_HEIGHT_CLOSED = '50px'
const HEADER_HEIGHT_CLOSED_XXS = '40px'

const Wrapper = styled.div<{ isLocked: boolean; isOpen: boolean; selectionIsOpen: boolean }>`
  position: relative;
  width: 100%;
  max-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: 'flex-start';
  border-radius: 0px 0px 25px 25px;

  touch-action: ${(props) => (props.isLocked ? 'none' : 'auto')};
  pointer-events: ${(props) => (props.isLocked ? 'none' : 'auto')};

  transition: all 0.3s ease;
  min-height: ${(props) => (props.isOpen ? HEADER_HEIGHT_OPEN : HEADER_HEIGHT_CLOSED)};

  ${(props) => props.theme.breakpoints.XXS} {
    min-height: ${(props) => (props.isOpen ? HEADER_HEIGHT_OPEN_XXS : HEADER_HEIGHT_CLOSED_XXS)};
  }
`

const SliderWrapper = styled.div<{ isOpen: boolean }>`
  transition: all 0.3s ease;

  height: ${(props) => (props.isOpen ? HEADER_HEIGHT_OPEN : HEADER_HEIGHT_CLOSED)};
  min-height: ${(props) => (props.isOpen ? HEADER_HEIGHT_OPEN : HEADER_HEIGHT_CLOSED)};

  ${(props) => props.theme.breakpoints.XXS} {
    height: ${(props) => (props.isOpen ? HEADER_HEIGHT_OPEN_XXS : HEADER_HEIGHT_CLOSED_XXS)};
    min-height: ${(props) => (props.isOpen ? HEADER_HEIGHT_OPEN_XXS : HEADER_HEIGHT_CLOSED_XXS)};
  }
`

const Info = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  ${(props) => props.theme.breakpoints.XXS} {
    gap: 0px;
  }
`

const MainInfo = styled.div`
  overflow: hidden;
  white-space: nowrap;

  font-size: 21px;
  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 18px;
  }
`

const MainInfoNote = styled.div`
  margin-top: -5px;
  opacity: 0.3;
  font-size: 60%;
`

const FilterDropdown = styled.button`
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  text-align: left;
  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 12px;
  }
`

const FilterDropdownIcon = styled(SvgIcon)`
  color: ${(props) => props.theme.colors.primary};
  fill: ${(props) => props.theme.colors.primary};
  --size: 16px;
  height: var(--size);
  width: var(--size);
  min-height: var(--size);
  min-width: var(--size);
  ${(props) => props.theme.breakpoints.XXS} {
    --size: 12px;
  }
`

const Chevron = styled(SvgIcon)`
  --size: 10px;
  height: var(--size);
  width: var(--size);
  min-height: var(--size);
  min-width: var(--size);
  margin-left: 8px;
`

const DetailContent = styled.div`
  height: 100%;
  overflow: auto;

  & > * > * {
    /* AnimateHeight component*/
    padding: 0 20px;
    padding-bottom: 50px;
  }
`

const EventList = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectionItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black}22;
  color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.colors.black)};

  svg {
    color: ${(props) => props.theme.colors.primary};
    fill: ${(props) => props.theme.colors.primary};
    width: 24px;
  }
`

const AvatarWrapper = styled.div<{ isOpen: boolean }>`
  & > * {
    --size: ${(props) => (props.isOpen ? '80px' : '40px')};

    ${(props) => props.theme.breakpoints.XXS} {
      --size: ${(props) => (props.isOpen ? '40px' : '27px')};
      margin-right: -4px;
    }
  }
`

const SubHeader = memo(
  (props: { isLocked?: boolean; petsSliderOnChange?: () => void; showTutorialId?: string }) => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const router = useRouter()

    const activePet = useSelector(getActivePet)
    const isOpen = useSelector(isPetHeaderOpen)
    const pets = useSelector(getAvailablePets)

    const eventDefinitions = useSelector(getEventDefinitions)
    const [selectedEvent, setSelectedEvent] = useState<EventDefinition>()
    const selectedEventCode = useSelector(getSubHeaderSelectedEventCode)

    const graphDefinitions = useSelector(getGraphDefinitions)
    const [selectedGraph, setSelectedGraph] = useState<GraphDefinition>()
    const selectedGraphCode = useSelector(getSubHeaderSelectedGraphCode)

    const [selectionMode, setSelectionMode] = useState<'events' | 'graphs'>()
    const [selectionIsOpen, setSelectionIsOpen] = useState<boolean>(false)
    const selectionRef = useRef()
    useClickOutside(selectionRef, () => {
      setSelectionIsOpen(false)
    })

    const sliderOffsetPx = useSelector(getSubHeaderSliderOffsetPx)
    const onSliderOffsetPxChange = (val: number) => {
      if (val !== 0 || activePet === 'all') {
        dispatch(setSubHeaderSliderOffsetPx(val))
      }
    }

    //
    // TUTORIALS
    //
    useEffect(() => {
      if (isOpen) {
        if (selectionMode === 'events') {
          //
          // TODO - UNCOMMENT ONCE FILTERS ON TIMELINE ARE WORKING
          //
          //dispatch(addTutorialToShow(TUTORIAL_IDS.PET_HEADER_FILTERS_EVENTS))
        }
        if (router.pathname === '/graphs' && activePet !== 'all' && selectionMode === 'graphs') {
          dispatch(addTutorialToShow(TUTORIAL_IDS.PET_HEADER_FILTERS_GRAPHS))
        }
      }
    }, [isOpen, selectionMode])

    //
    // HANDLE SELECTION OPEN/CLOSED; MODE
    //
    useEffect(() => {
      if (!isOpen) {
        setSelectionIsOpen(false)
      }
    }, [isOpen])

    useEffect(() => {
      // CLOSE HEADER ON PET CHANGE
      setSelectionIsOpen(false)
    }, [activePet])

    useEffect(() => {
      // CLOSE HEADER ON PET CHANGE
      if (router.pathname === '/graphs') {
        setSelectionMode('graphs')
      } else {
        setSelectionMode('events')
      }
    }, [router.pathname])

    //
    // UPDATE REDUX WITH SELECTED CODES
    //
    useEffect(() => {
      if (selectedEventCode) {
        setSelectedEvent(eventDefinitions[selectedEventCode])
      } else {
        setSelectedEvent(undefined)
      }
    }, [selectedEventCode])

    useEffect(() => {
      if (selectedGraphCode) {
        setSelectedGraph(graphDefinitions[selectedGraphCode])
      } else {
        setSelectedGraph(undefined)
      }
    }, [selectedGraphCode])
    const handleChange = (index) => {
      if (props.petsSliderOnChange) {
        props.petsSliderOnChange()
      }
      if (index === 0) {
        dispatch(setActivePet('all'))
      } else {
        dispatch(setActivePet(pets[index - 1]?.id))
      }
    }

    //
    // FUNC
    //
    const getActiveItemIndex = () => {
      if (activePet === 'all') return 0
      if (pets.length === 1) return 0
      return pets.findIndex((pet) => pet.id === activePet) + 1
    }

    const getSelectionDefaultName = () => {
      return selectionMode === 'graphs' ? 'dashboard.NO_GRAPH_SELECTED' : 'ALL_EVENTS'
    }

    const getSelectionAction = () => {
      return selectionMode === 'graphs' ? setSubHeaderGraphCode : setSubHeaderEventCode
    }

    const getSelectionItems = () => {
      return selectionMode === 'graphs' ? graphDefinitions : eventDefinitions
    }

    const getSelectedItem = () => {
      return selectionMode === 'graphs' ? selectedGraph : selectedEvent
    }

    //
    // RENDER COMPONENTS
    //
    const renderEventFilterDropdown = () => {
      const dropdownDisabled = selectionMode === 'events' // TODO - SET TO 'false' ONCE EVENT FILTERS IN TIMELINE ARE ENABLED
      const selectedItem = getSelectedItem()

      return (
        isOpen && (
          <FilterDropdown
            onClick={
              dropdownDisabled
                ? undefined
                : () => {
                    setSelectionIsOpen(!selectionIsOpen)
                  }
            }
          >
            <FilterDropdownIcon
              code={selectedItem ? (selectedItem.icon as IconName) : 'icon-paw-filled'}
            />
            {selectedItem
              ? selectionMode === 'graphs'
                ? t(selectedItem.name)
                : selectedItem.name
              : t(getSelectionDefaultName())}
            {!!selectionMode && !dropdownDisabled && <Chevron code="icon-arrow-down" />}
          </FilterDropdown>
        )
      )
    }

    const renderSelectItem = (item?: EventDefinition | GraphDefinition) => {
      const selectedItem = getSelectedItem()
      return (
        <SelectionItem
          key={item ? item.code : 'reset-filters'}
          onClick={() => {
            dispatch(getSelectionAction()(item?.code))
            setSelectionIsOpen(false)
          }}
          isActive={item && item.code === selectedItem?.code}
        >
          <SvgIcon code={item ? (item.icon as IconName) : 'icon-no'} />
          {item ? (
            selectionMode === 'graphs' ? (
              t(item.name)
            ) : (
              item.name
            )
          ) : (
            <strong>{t(getSelectionDefaultName())}</strong>
          )}
        </SelectionItem>
      )
    }

    const renderSelectList = () => {
      return (
        <DetailContent>
          <AnimateHeight
            height={selectionIsOpen ? 'auto' : 0.01}
            animateOpacity
            style={{ opacity: selectionIsOpen ? 1 : 0 }}
          >
            <EventList>
              {renderSelectItem()}
              {Object.values(getSelectionItems()).map((item: EventDefinition | GraphDefinition) =>
                renderSelectItem(item)
              )}
            </EventList>
          </AnimateHeight>
        </DetailContent>
      )
    }

    const renderSliderItem = (pet?: Pet) => {
      const key = pet ? pet.id : 'placeholder'

      const showTutorialFilters =
        props.showTutorialId === TUTORIAL_IDS.PET_HEADER_FILTERS_GRAPHS ||
        props.showTutorialId === TUTORIAL_IDS.PET_HEADER_FILTERS_EVENTS

      return (
        <Item key={key}>
          <TutorialHighlight hide={showTutorialFilters}>
            <AvatarWrapper isOpen={isOpen}>
              {pet ? <RoundAvatarPet pet={pet} /> : <RoundAvatarAllPets isMinimized={!isOpen} />}
            </AvatarWrapper>
          </TutorialHighlight>

          <Info>
            <TutorialHighlight hide={showTutorialFilters}>
              <MainInfo>
                {pet ? pet.name : t('ALL_PETS')}
                {pet?.state &&
                [PetState.Archived, PetState.Inactive].includes(pet?.state as any) ? (
                  <MainInfoNote>{`(${t(`pet-state.${pet.state}`)})`}</MainInfoNote>
                ) : null}
              </MainInfo>
            </TutorialHighlight>

            <TutorialHighlight hide={false} highlight={showTutorialFilters}>
              {renderEventFilterDropdown()}
            </TutorialHighlight>
          </Info>
        </Item>
      )
    }

    //
    // RENDER
    //
    return pets?.length > 0 ? (
      <Wrapper
        ref={selectionRef}
        isOpen={isOpen}
        selectionIsOpen={selectionIsOpen}
        isLocked={props.isLocked}
      >
        <SliderWrapper isOpen={isOpen}>
          <Slider
            isLocked={props.isLocked || pets.length === 1}
            activeItemIndex={getActiveItemIndex()}
            itemsCount={pets.length}
            onChange={(index) => handleChange(index)}
            showTutorialArrows={props.showTutorialId === TUTORIAL_IDS.PET_HEADER_ARROWS}
            showTutorialContent={TUTORIALS_PET_HEADER_CONTENT.includes(props.showTutorialId)}
            externalSliderOffsetPx={sliderOffsetPx}
            onSliderOffsetPxChange={onSliderOffsetPxChange}
          >
            {pets.length > 1 && renderSliderItem()}
            {pets.map((pet) => renderSliderItem(pet))}
          </Slider>
        </SliderWrapper>
        {renderSelectList()}
      </Wrapper>
    ) : null
  }
)

export default SubHeader
