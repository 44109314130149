import { useEffect, useState } from 'react'

import { PushNotification } from 'types'
import { storeError } from 'entity/errors'
import { useDispatch } from 'react-redux'

function useNotification() {
  const dispatch = useDispatch()
  const [notification, setNotification] = useState()
  const [notificationParsed, setNotificationParsed] = useState<PushNotification>()

  useEffect(() => {
    if (window) {
      // this will connect setNotification to the function accessible from the webview wrapper
      ;(window as any).setNotification = setNotification
    }
  }, [])

  useEffect(() => {
    if (notification) {
      try {
        setNotificationParsed(notification)
        setNotification(undefined)
      } catch (error) {
        dispatch(storeError({ error, origin: 'useNotificationHook', data: notification }))
      }
    }
  }, [notification])

  return notificationParsed
}

export default useNotification
