import {
  CANCEL_INVITE_SUCCEED,
  FETCH_GROUPS_SUCCEED,
  GROUP_ACCEPT_SUCCEED,
  GROUP_DECLINE_SUCCEED,
  GROUP_LEAVE_SUCCEED,
  GROUP_REMOVE_USER_SUCCEED,
  GroupActionsTypes,
  INVITE_USER_SUCCEED,
  groupAcceptReducer,
  groupDeclineReducer,
  groupDeletePetReducer,
  groupSavePetReducer,
  removeInvitationReducer,
  removeUserFromGroupReducer,
  saveGroupsReducer,
  saveInvitationReducer,
  userLeaveReducer,
} from 'entity/group'
import { CommonActionType, RESET_STATE, SAVE_PET_SUCCEED, SavePetSucceedType } from 'store/actions'
import { DELETE_PET_SUCCEED, DeletePetSucceedType } from './../actions/petActions'
import { GroupEntity, GroupInvitationEntity } from 'apiLegacy'

type InitialStateTypes = {
  groups: {
    entities: {
      [x: string]: GroupEntity
    }
    lists: {
      [x: string]: string[]
    }
  }
  invitations: {
    entities: {
      [x: string]: GroupInvitationEntity
    }
    lists: {
      [x: string]: string[]
    }
  }
}

export const initialState: InitialStateTypes | any = {
  groups: {
    entities: {},
    lists: {},
  },
  invitations: {
    entities: {},
    lists: {},
  },
}

export default function userReducer(
  state = initialState,
  action: GroupActionsTypes | CommonActionType | SavePetSucceedType | DeletePetSucceedType
): InitialStateTypes {
  switch (action.type) {
    case RESET_STATE:
      return initialState
    case FETCH_GROUPS_SUCCEED:
      return saveGroupsReducer(state, action)
    case INVITE_USER_SUCCEED:
      return saveInvitationReducer(state, action)
    case CANCEL_INVITE_SUCCEED:
      return removeInvitationReducer(state, action)
    case GROUP_LEAVE_SUCCEED:
      return userLeaveReducer(state, action)
    case GROUP_REMOVE_USER_SUCCEED:
      return removeUserFromGroupReducer(state, action)
    case GROUP_ACCEPT_SUCCEED:
      return groupAcceptReducer(state, action)
    case GROUP_DECLINE_SUCCEED:
      return groupDeclineReducer(state, action)
    case SAVE_PET_SUCCEED:
      return groupSavePetReducer(state, action)
    case DELETE_PET_SUCCEED:
      return groupDeletePetReducer(state, action)
    default:
      return state
  }
}
