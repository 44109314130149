import React, { useEffect, useState } from 'react'
import Tabs, { Tab } from 'components/Tabs'
import {
  fetchGroups,
  getGroupInvitations,
  getHomeGroupInvitations,
  getUserGroup,
  getUserGroups,
  inviteUser,
} from 'entity/group'
import { keyAction, validateEmail } from 'utils'
import { useDispatch, useSelector } from 'react-redux'

import Input from 'components/inputs/input'
import MainLayout from 'layouts/mainLayout'
import PopUp from 'components/modals/PopUp'
import SvgIcon from 'components/svgIcon'
import { TUTORIAL_IDS } from 'entity/tutorials'
import UserGroupCard from 'components/UserGroupCard'
import UserGroupMemberCard from 'components/UserGroupMemberCard'
import UserGroupPetCard from 'components/UserGroupPetCard'
import { getUser } from 'store/selectors'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export const USERS_TABS = {
  myGroup: 'my-group',
  otherGroups: 'groups',
}

const Wrapper = styled.div`
  padding-top: 10px;
`

const StyledTab = styled(Tab)`
  flex: 1;
  padding: 20px;
`

const InputLabel = styled.div`
  margin-bottom: 10px;
`

const Title = styled.div`
  font-size: 17px;
  border-bottom: 1px solid lightgrey;

  margin-bottom: 10px;
  margin-top: 30px;
  &:first-child {
    margin-top: 0px;
  }
`

const Members = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`

const PetGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  & > div {
    min-width: 140px;
    flex: 1;
    margin: 10px 10px;
  }
`

const RefreshButton = styled.button`
  margin: 15px auto;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 40px;
  border-radius: 30px;

  font-size: 16px;
  color: ${(props) => props.theme.colors.black}CC;
  background: ${(props) => props.theme.colors.black}11;

  svg {
    height: 16px;
  }
`

const UsersPage = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const [email, setEmail] = useState('')

  const userGroup = useSelector(getUserGroup)
  const user = useSelector(getUser)
  const userGroups = useSelector(getUserGroups)
  const homeGroupInvitations = useSelector(getHomeGroupInvitations)
  const groupsInvitations = useSelector(getGroupInvitations)

  const isFromRegistration = !!router.query.fromRegistration

  const [error, setError] = useState('')

  useEffect(() => {
    dispatch(fetchGroups())
  }, [])

  const handleSubmitInvite = () => {
    const { error } = validateEmail(email, { mode: 'group_invite' })
    if (error) {
      return setError(error)
    }
    if (email === user.email) {
      return setError('validation.group_invite.INVITE_SELF')
    }
    closeInvitePopUp()
    dispatch(inviteUser({ email, groupId: userGroup.id }))
    setEmail('')
    setError('')
  }

  const [isInvitePopUpOpen, setIsInvitePopUpOpen] = useState<boolean>(false)
  const openInvitePopUp = () => {
    setIsInvitePopUpOpen(true)
  }
  const closeInvitePopUp = () => {
    setIsInvitePopUpOpen(false)
  }

  const renderRefreshButton = () => {
    return (
      <RefreshButton
        onClick={() => dispatch(fetchGroups({ successNotification: 'REFRESH_GROUPS_SUCCESS' }))}
      >
        <SvgIcon code="icon-refresh" />
        {t('REFRESH_GROUPS')}
      </RefreshButton>
    )
  }

  if (!user) return null

  //
  // RENDER
  //
  const renderTabMyGroup = () => {
    return (
      <>
        <Title>{t('GROUP_OWNER')}:</Title>
        <UserGroupMemberCard
          key={user.id}
          member={user}
          userGroupId={userGroup?.id}
          memberRole="owner"
        />

        <Title>{t('PETS')}:</Title>
        <PetGrid>
          {userGroup?.pets
            ?.filter((pet) => pet.state !== 'INACTIVE')
            .map((pet) => (
              <UserGroupPetCard key={pet.id} pet={pet} />
            ))}
        </PetGrid>

        <Title>{t('INVITED_OWNERS')}:</Title>
        <Members>
          {userGroup?.members
            ?.filter((member) => member.id !== user.id)
            ?.map((member) => (
              <UserGroupMemberCard
                key={member.id}
                member={member}
                userGroupId={userGroup.id}
                memberRole="member"
              />
            ))}

          {homeGroupInvitations?.length > 0 &&
            homeGroupInvitations.map((member) => (
              <UserGroupMemberCard
                key={member.id}
                member={member}
                userGroupId={userGroup.id}
                memberRole="invitee"
              />
            ))}
        </Members>

        {homeGroupInvitations?.length === 0 || !homeGroupInvitations ? (
          <div>{t('NO_INVITATIONS')}</div>
        ) : (
          renderRefreshButton()
        )}
      </>
    )
  }

  const renderTabGroups = () => {
    return (
      <>
        {groupsInvitations.length > 0 && (
          <>
            <Title>{t('INVITATIONS')}:</Title>
            {groupsInvitations.map((group) => (
              <UserGroupCard group={group} isInvite key={group.id} />
            ))}
            {renderRefreshButton()}
          </>
        )}

        {userGroups.length === 0 ? (
          <>
            <Title>{t('I_AM_A_MEMBER')}:</Title>
            <div>{t('I_AM_NOT_A_MEMBER_OF_ANY_GROUP')}</div>
          </>
        ) : (
          <>
            <Title>{t('I_AM_A_MEMBER')}:</Title>
            {userGroups.map((group) => (
              <UserGroupCard group={group} key={group.id} />
            ))}
          </>
        )}

        {groupsInvitations.length === 0 && (
          <>
            <Title>{t('INVITATIONS')}:</Title>
            <div>{t('NO_INVITATIONS_RECEIVED')}</div>
            {renderRefreshButton()}
          </>
        )}
      </>
    )
  }

  return (
    <MainLayout
      petsInHeader={false}
      footer={{
        submitButton: {
          icon: 'icon-plus',
          onClick: openInvitePopUp,
          tutorialId: TUTORIAL_IDS.INVITE_USER_TO_GROUP,
        },
        contentLeft: {
          displayMode: 'back-button',
        },
        contentRight: {
          displayMode: isFromRegistration ? 'home-button' : 'empty',
        },
      }}
    >
      <Wrapper>
        <Tabs stretchLabels urlParamId="tab" tutorialId={TUTORIAL_IDS.USERS_TABS}>
          <StyledTab id={USERS_TABS.myGroup} name={t('MY_GROUP')}>
            {renderTabMyGroup()}
          </StyledTab>
          <StyledTab id={USERS_TABS.otherGroups} name={t('OTHER_GROUPS')}>
            {renderTabGroups()}
          </StyledTab>
        </Tabs>
      </Wrapper>

      <PopUp
        isOpen={isInvitePopUpOpen}
        close={closeInvitePopUp}
        header={{
          title: t('INVITE_OWNER_TITLE'),
          closeTitle: t('CANCEL'),
        }}
        submitButton={{ onClick: handleSubmitInvite }}
      >
        <InputLabel>{t('INSERT_EMAIL')}</InputLabel>
        <Input
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          onFocus={() => setError('')}
          onKeyDown={(e) => keyAction(e, { enter: handleSubmitInvite })}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          error={error && t(error)}
        />
      </PopUp>
    </MainLayout>
  )
}

export default UsersPage
