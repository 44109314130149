import { appNotificationReducer } from 'entity/appNotifications'
import appReducer from './appReducer'
import { combineReducers } from 'redux'
import { dashboardsReducer } from 'entity/dashboard'
import { errorsReducer } from 'entity/errors'
import eventReducer from './eventReducer'
import { footerReducer } from 'entity/footer'
import { graphReducer } from 'entity/graphs'
import groupReducer from './groupReducer'
import headerReducer from './headerReducer'
import { navigationReducer } from 'entity/navigation'
import { petDetailsReducer } from 'entity/petDetails'
import { petDocumentDetailsReducer } from 'entity/petDocumentDetails'
import petReducer from './petReducer'
import { pinnedPlacesReducer } from 'entity/pinnedPlaces'
import { pushNotificationReducer } from 'entity/pushNotifications'
import { shareReducer } from 'entity/share'
import { tutorialsReducer } from 'entity/tutorials'
import userReducer from './userReducer'

const rootReducer = combineReducers({
  app: appReducer,
  events: eventReducer,
  header: headerReducer,
  footer: footerReducer,
  appNotifications: appNotificationReducer,
  pushNotifications: pushNotificationReducer,
  user: userReducer,
  pets: petReducer,
  petDetails: petDetailsReducer,
  petDocumentDetails: petDocumentDetailsReducer,
  groups: groupReducer,
  errors: errorsReducer,
  dashboard: dashboardsReducer,
  graphs: graphReducer,
  tutorials: tutorialsReducer,
  navigation: navigationReducer,
  share: shareReducer,
  pinnedPlaces: pinnedPlacesReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
