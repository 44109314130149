import { Event } from 'apiLegacy/api'

export const formatEntitiesToShareVetTable = (events: Event[], eventType: Event['code'], t) => {
  let rows = []
  let columns = []
  switch (eventType) {
    case 'allergy':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        { label: t('ALLERGY_TYPE'), valueItem: 'allergyType.name' },
        { label: t('ALLERGEN'), valueItem: 'allergen' },
        {
          label: t('DATE_FROM'),
          valueItem: 'from',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('DATE_TILL'),
          valueItem: 'till',
          format: (val) => formatDateToTableValue(val),
        },
        { label: t('INTENSITY'), valueItem: 'intensity', format: (val) => val + ' / 5' },
        { label: t('NOTE'), valueItem: 'note' },
      ]
      break
    case 'heating':
      columns = [
        {
          label: t('DATE_FROM'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('DATE_TILL'),
          valueItem: 'till',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('INTENSITY'),
          valueItem: 'intensity',
          format: (val) => val + ' / 5',
        },
        { label: t('NOTE'), valueItem: 'note' },
      ]
      break
    case 'deworming':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('BRAND'),
          valueItem: 'brand',
        },
        { label: t('RESPONSE'), valueItem: 'response', format: (val) => val + ' / 5' },
        { label: t('NOTE'), valueItem: 'note' },
      ]
      break
    case 'antiparasitics':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        { label: t('VET'), valueItem: 'clinic.title' },
        {
          label: t('TYPE'),
          valueItem: 'type.name',
        },
        {
          label: t('BRAND'),
          valueItem: 'brand',
        },
        {
          label: t('DATE_FROM'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('DATE_TILL'),
          valueItem: 'till',
          format: (val) => formatDateToTableValue(val),
        },
        { label: t('RESPONSE'), valueItem: 'response', format: (val) => val + ' / 5' },
        { label: t('NOTE'), valueItem: 'note' },
      ]
      break
    case 'vet':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('VET'),
          valueItem: 'vet.title',
        },
        {
          label: t('ADDRESS'),
          valueItem: 'vet.address',
        },
        {
          label: t('NOTE'),
          valueItem: 'note',
        },
      ]
      break
    case 'vaccination':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('TYPE'),
          valueItem: 'type.name',
        },
        {
          label: t('VET'),
          valueItem: 'vet.title',
        },
        {
          label: t('BRAND'),
          valueItem: 'brand',
        },
        {
          label: t('EXPIRE'),
          valueItem: 'expire',
          format: (val) => formatDateToTableValue(val),
        },
        { label: t('RESPONSE'), valueItem: 'response', format: (val) => val + ' / 5' },
        {
          label: t('VET'),
          valueItem: 'veterinarian.title',
        },
        {
          label: t('NOTE'),
          valueItem: 'note',
        },
      ]
      break
    case 'purchase':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('PRICE'),
          valueItem: 'price',
          format: (val) => val.amount + ' ' + val.currencyCode,
        },
        {
          label: t('NOTE'),
          valueItem: 'note',
        },
      ]
      break
    case 'walking':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('NOTE'),
          valueItem: 'note',
        },
      ]
      break
    case 'height':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('HEIGHT'),
          valueItem: 'height',
          format: (val) => val + 'cm',
        },
        { label: t('NOTE'), valueItem: 'note' },
      ]
      break
    case 'weight':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('WEIGHT'),
          valueItem: 'weight',
          format: (val) => val + 'kg',
        },
        { label: t('NOTE'), valueItem: 'note' },
      ]
      break
    case 'task':
      columns = [
        {
          label: t('DATE'),
          valueItem: 'date',
          format: (val) => formatDateToTableValue(val),
        },
        {
          label: t('TITLE'),
          valueItem: 'name',
        },
        { label: t('NOTE'), valueItem: 'note' },
      ]
      break
    default:
      break
  }

  rows = formatRow(events, columns)

  return rows
}

const formatRow = (events, columns) => {
  return events.map((event) => {
    const photos = getPropertyByStringKey('photos', event.items) || []

    const _columns = columns
      .filter((column) => getPropertyByStringKey(column.valueItem, event.items))
      .map((column) => {
        return {
          label: column.label,
          value: column.format
            ? column.format(getPropertyByStringKey(column.valueItem, event.items))
            : getPropertyByStringKey(column.valueItem, event.items),
        }
      })

    return { columns: _columns, photos: photos.map((photo) => photo.id) }
  })
}

const getPropertyByStringKey = (stringKey, object) => {
  return stringKey.split('.').reduce((p, c) => (p && p[c]) || null, object)
}

const formatDateToTableValue = (date: Date) => {
  const _date = new Date(date)
  return (
    <div>
      {_date.getDate()}.{_date.getMonth()}.{_date.getFullYear()} <br /> {_date.getHours()}:
      {_date.getMinutes() <= 9 ? `0${_date.getMinutes()}` : _date.getMinutes()}
    </div>
  )
}
