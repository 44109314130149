import { NewPet, Pet } from 'apiLegacy'

import { PetType } from 'types'
import { SavePetsType } from 'entity/pet'

export const FETCH_BREEDS_REQUEST = 'FETCH_BREEDS_REQUEST'
export const FETCH_BREEDS_SUCCEED = 'FETCH_BREEDS_SUCCEED'
export const FETCH_BREEDS_FAILED = 'FETCH_BREEDS_FAILED'
export const SAVE_PET_FAILED = 'SAVE_PET_FAILED'
export const SAVE_PET_REQUEST = 'SAVE_PET_REQUEST'
export const SAVE_PET_SUCCEED = 'SAVE_PET_SUCCEED'
export const FETCH_PETS_REQUEST = 'FETCH_PETS_REQUEST'
export const FETCH_PETS_SUCCEED = 'FETCH_PETS_SUCCEED'
export const FETCH_PETS_FAILED = 'FETCH_PETS_FAILED'
export const UPDATE_PET_REQUEST = 'UPDATE_PET_REQUEST'
export const UPDATE_PET_SUCCEED = 'UPDATE_PET_SUCCEED'
export const UPDATE_PET_FAILED = 'UPDATE_PET_FAILED'
export const DELETE_PET_REQUEST = 'DELETE_PET_REQUEST'
export const DELETE_PET_SUCCEED = 'DELETE_PET_SUCCEED'
export const DELETE_PET_FAILED = 'DELETE_PET_FAILED'
export const DISABLE_PET_REQUEST = 'DISABLE_PET_REQUEST'
export const DISABLE_PET_FAILED = 'DISABLE_PET_FAILED'
export const DISABLE_PET_SUCCEED = 'DISABLE_PET_SUCCEED'
export const ENABLE_PET_REQUEST = 'ENABLE_PET_REQUEST'
export const ENABLE_PET_FAILED = 'ENABLE_PET_FAILED'
export const ENABLE_PET_SUCCEED = 'ENABLE_PET_SUCCEED'
export const ARCHIVE_PET_REQUEST = 'ARCHIVE_PET_REQUEST'
export const ARCHIVE_PET_FAILED = 'ARCHIVE_PET_FAILED'
export const ARCHIVE_PET_SUCCEED = 'ARCHIVE_PET_SUCCEED'

type FetchBreedsTypes = {
  type: typeof FETCH_BREEDS_REQUEST
  payload: any
}

export const fetchBreeds = (payload: {
  language: string
  petType?: PetType
}): FetchBreedsTypes => ({
  type: FETCH_BREEDS_REQUEST,
  payload,
})

type FetchBreedsSucceed = {
  type: typeof FETCH_BREEDS_SUCCEED
  payload: any
}

export const fetchBreedsSucceed = ({ breeds, type }): FetchBreedsSucceed => ({
  type: FETCH_BREEDS_SUCCEED,
  payload: { breeds, type },
})

type FetchBreedsFailed = {
  type: typeof FETCH_BREEDS_FAILED
  payload: any
}

export const fetchBreedsFailed = (payload) => ({
  type: FETCH_BREEDS_FAILED,
  payload,
})

type savePetType = {
  type: typeof SAVE_PET_REQUEST
  payload: NewPet
}

export const savePet = (payload): savePetType => ({
  type: SAVE_PET_REQUEST,
  payload,
})

export type SavePetSucceedType = {
  type: typeof SAVE_PET_SUCCEED
  payload: Pet
}

export const savePetSucceed = (payload): SavePetSucceedType => ({
  type: SAVE_PET_SUCCEED,
  payload,
})

type savePetFailedType = {
  type: typeof SAVE_PET_FAILED
  payload: any
}

export const savePetFailed = (payload) => ({
  type: SAVE_PET_FAILED,
  payload,
})

type FetchPetsType = {
  type: typeof FETCH_PETS_REQUEST
}

export const fetchPets = (): FetchPetsType => ({
  type: FETCH_PETS_REQUEST,
})

type FetchPetsSucceedType = {
  type: typeof FETCH_PETS_SUCCEED
  payload: any
}

export const fetchPetsSucceed = (payload): FetchPetsSucceedType => ({
  type: FETCH_PETS_SUCCEED,
  payload,
})

type FetchPetsFailedType = {
  type: typeof FETCH_PETS_FAILED
  payload: any
}

export const fetchPetsFailed = (error) => ({
  type: FETCH_PETS_FAILED,
  payload: error,
})

type UpdatePetType = {
  type: typeof UPDATE_PET_REQUEST
  payload: Pet
}

export const updatePet = (payload): UpdatePetType => ({
  type: UPDATE_PET_REQUEST,
  payload,
})

type UpdatePetFailedType = {
  type: typeof UPDATE_PET_FAILED
  payload: any
}

export const updatePetFailed = (error): UpdatePetFailedType => ({
  type: UPDATE_PET_FAILED,
  payload: error,
})

type UpdatePetSucceed = {
  type: typeof UPDATE_PET_SUCCEED
  payload: Pet
}

export const updatePetSucceed = (payload): UpdatePetSucceed => ({
  type: UPDATE_PET_SUCCEED,
  payload,
})

type DeletePetType = {
  type: typeof DELETE_PET_REQUEST
  payload: string
}

export const deletePet = (payload): DeletePetType => ({
  type: DELETE_PET_REQUEST,
  payload,
})

type DeletePetFailedType = {
  type: typeof DELETE_PET_FAILED
  payload?: any
}

export const deletePetFailed = (payload?): DeletePetFailedType => ({
  type: DELETE_PET_FAILED,
  payload,
})

export type DeletePetSucceedType = {
  type: typeof DELETE_PET_SUCCEED
  payload: string
}

export const deletePetSucceed = (payload): DeletePetSucceedType => ({
  type: DELETE_PET_SUCCEED,
  payload,
})

type EnablePet = {
  type: typeof ENABLE_PET_REQUEST
  payload: string
}

export const enablePet = (payload): EnablePet => ({
  type: ENABLE_PET_REQUEST,
  payload,
})

type EnablePetFailed = {
  type: typeof ENABLE_PET_FAILED
  payload?: any
}

export const enablePetFailed = (payload?): EnablePetFailed => ({
  type: ENABLE_PET_FAILED,
  payload,
})

type EnablePetSucceed = {
  type: typeof ENABLE_PET_SUCCEED
}

export const enablePetSucceed = (): EnablePetSucceed => ({
  type: ENABLE_PET_SUCCEED,
})

type DisablePet = {
  type: typeof DISABLE_PET_REQUEST
  payload: string
}

export const disablePet = (payload): DisablePet => ({
  type: DISABLE_PET_REQUEST,
  payload,
})

type DisablePetFailed = {
  type: typeof DISABLE_PET_FAILED
  payload?: any
}

export const disablePetFailed = (payload?): DisablePetFailed => ({
  type: DISABLE_PET_FAILED,
  payload,
})

type DisablePetSucceed = {
  type: typeof DISABLE_PET_SUCCEED
}

export const disablePetSucceed = (): DisablePetSucceed => ({
  type: DISABLE_PET_SUCCEED,
})

type ArchivePet = {
  type: typeof ARCHIVE_PET_REQUEST
  payload: string
}

export const archivePet = (payload): ArchivePet => ({
  type: ARCHIVE_PET_REQUEST,
  payload,
})

type ArchivePetFailed = {
  type: typeof ARCHIVE_PET_FAILED
  payload?: any
}

export const archivePetFailed = (payload?): ArchivePetFailed => ({
  type: ARCHIVE_PET_FAILED,
  payload,
})

type ArchivePetSucceed = {
  type: typeof ARCHIVE_PET_SUCCEED
}

export const archivePetSucceed = (): ArchivePetSucceed => ({
  type: ARCHIVE_PET_SUCCEED,
})

export type PetActionsTypes =
  | FetchBreedsTypes
  | FetchBreedsSucceed
  | FetchBreedsFailed
  | SavePetSucceedType
  | savePetFailedType
  | savePetType
  | FetchPetsType
  | FetchPetsSucceedType
  | FetchPetsFailedType
  | UpdatePetType
  | UpdatePetFailedType
  | UpdatePetSucceed
  | SavePetsType
  | DeletePetType
  | DeletePetFailedType
  | DeletePetSucceedType
  | DisablePet
  | DisablePetFailed
  | DisablePetSucceed
  | EnablePet
  | EnablePetFailed
  | EnablePetSucceed
  | ArchivePet
  | ArchivePetFailed
  | ArchivePetSucceed
