import {
  cancelInvitation as cancelInvitationService,
  groupAccept as groupAcceptService,
  groupDecline as groupDeclineService,
  inviteUser as inviteUserService,
  leaveGroup as leaveGroupService,
  removeUserFromGroup as removeUserFromGroupService,
} from 'services'
import { isEmptyObject, parseGroupEntities } from 'utils'

import { Invitation } from 'apiLegacy'
import { addAppNotification } from './appNotifications'
import { put } from '@redux-saga/core/effects'
import { saveUsers } from './users'
import { storeError } from './errors'

/* CONST */
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST'
export const INVITE_USER_FAILED = 'INVITE_USER_FAILED'
export const INVITE_USER_SUCCEED = 'INVITE_USER_SUCCEED'
export const CANCEL_INVITE_REQUEST = 'CANCEL_INVITE_REQUEST'
export const CANCEL_INVITE_FAILED = 'CANCEL_INVITE_FAILED'
export const CANCEL_INVITE_SUCCEED = 'CANCEL_INVITE_SUCCEED'
export const REACT_INVITE_REQUEST = 'REACT_INVITE_REQUEST'
export const REACT_INVITE_FAILED = 'REACT_INVITE_FAILED'
export const REACT_INVITE_SUCCEED = 'REACT_INVITE_SUCCEED'
export const GROUP_LEAVE_REQUEST = 'GROUP_LEAVE_REQUEST'
export const GROUP_LEAVE_FAILED = 'GROUP_LEAVE_FAILED'
export const GROUP_LEAVE_SUCCEED = 'GROUP_LEAVE_SUCCEED'
export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST'
export const FETCH_GROUPS_SUCCEED = 'FETCH_GROUPS_SUCCEED'
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED'
export const GROUP_REMOVE_USER_REQUEST = 'GROUP_REMOVE_USER_REQUEST'
export const GROUP_REMOVE_USER_FAILED = 'GROUP_REMOVE_USER_FAILED'
export const GROUP_REMOVE_USER_SUCCEED = 'GROUP_REMOVE_USER_SUCCEED'
export const GROUP_ACCEPT_REQUEST = 'GROUP_ACCEPT_REQUEST'
export const GROUP_ACCEPT_FAILED = 'GROUP_ACCEPT_FAILED'
export const GROUP_ACCEPT_SUCCEED = 'GROUP_ACCEPT_SUCCEED'
export const GROUP_DECLINE_REQUEST = 'GROUP_DECLINE_REQUEST'
export const GROUP_DECLINE_FAILED = 'GROUP_DECLINE_FAILED'
export const GROUP_DECLINE_SUCCEED = 'GROUP_DECLINE_SUCCEED'

export interface InviteUserType {
  type: typeof INVITE_USER_REQUEST
  payload: string
}

export const inviteUser = (payload): InviteUserType => ({
  type: INVITE_USER_REQUEST,
  payload,
})

export interface InviteUserFailedType {
  type: typeof INVITE_USER_FAILED
  payload?: any
}

export const inviteUserFailed = (payload?): InviteUserFailedType => ({
  type: INVITE_USER_FAILED,
  payload,
})

export interface InviteUserSucceedType {
  type: typeof INVITE_USER_SUCCEED
  payload: Invitation
}

export const inviteUserSucceed = (payload): InviteUserSucceedType => ({
  type: INVITE_USER_SUCCEED,
  payload,
})

export interface CancelInviteType {
  type: typeof CANCEL_INVITE_REQUEST
  payload: string
}

export const cancelInvite = (payload): CancelInviteType => ({
  type: CANCEL_INVITE_REQUEST,
  payload,
})

export interface CancelInviteFailedType {
  type: typeof CANCEL_INVITE_FAILED
  payload?: any
}

export const cancelInviteFailed = (payload?): CancelInviteFailedType => ({
  type: CANCEL_INVITE_FAILED,
  payload,
})

export interface CancelInviteSucceedType {
  type: typeof CANCEL_INVITE_SUCCEED
  payload: string
}

export const cancelInviteSucceed = (payload): CancelInviteSucceedType => ({
  type: CANCEL_INVITE_SUCCEED,
  payload,
})

export type reactionTypes = 'accept' | 'decline'

export type ReactInviteType = {
  type: typeof REACT_INVITE_REQUEST
  payload: {
    reaction: reactionTypes
    status: string
  }
}

export const reactInvite = (payload): ReactInviteType => ({
  type: REACT_INVITE_REQUEST,
  payload,
})

export interface ReactInviteFailedType {
  type: typeof REACT_INVITE_FAILED
  payload?: any
}

export const reactInviteFailed = (payload?) => ({
  type: REACT_INVITE_FAILED,
  payload,
})

export const reactInviteSucceed = (payload) => ({
  type: REACT_INVITE_SUCCEED,
  payload,
})

export interface LeaveGroupType {
  type: typeof GROUP_LEAVE_REQUEST
  payload: string
}

export const leaveGroup = (payload): LeaveGroupType => ({
  type: GROUP_LEAVE_REQUEST,
  payload,
})

export interface LeaveGroupFailedType {
  type: typeof GROUP_LEAVE_FAILED
  payload?: any
}

export const leaveGroupFailed = (payload?): LeaveGroupFailedType => ({
  type: GROUP_LEAVE_FAILED,
  payload,
})

export interface LeaveGroupSucceedType {
  type: typeof GROUP_LEAVE_SUCCEED
  payload: string
}

export const leaveGroupSucceed = (payload) => ({
  type: GROUP_LEAVE_SUCCEED,
  payload,
})

export type FetchGroupsPayloadType = { successNotification?: string }
type FetchGroupsType = {
  type: typeof FETCH_GROUPS_REQUEST
  payload?: FetchGroupsPayloadType
}

export const fetchGroups = (payload = { successNotification: undefined }): FetchGroupsType => ({
  type: FETCH_GROUPS_REQUEST,
  payload,
})

type FetchGroupsFailedType = {
  type: typeof FETCH_GROUPS_FAILED
  payload?: any
}

export const fetchGroupsFailed = (payload?): FetchGroupsFailedType => ({
  type: FETCH_GROUPS_FAILED,
  payload,
})

type FetchGroupsSucceedTypes = {
  type: typeof FETCH_GROUPS_SUCCEED
  payload: any[]
}

export const fetchGroupsSucceed = (payload): FetchGroupsSucceedTypes => ({
  type: FETCH_GROUPS_SUCCEED,
  payload,
})

type RemoveUserFromGroupTypes = {
  type: typeof GROUP_REMOVE_USER_REQUEST
  payload: string
}

export const removeUserFromGroup = (payload): RemoveUserFromGroupTypes => ({
  type: GROUP_REMOVE_USER_REQUEST,
  payload,
})

type RemoveUserFromGroupFailedType = {
  type: typeof GROUP_REMOVE_USER_FAILED
  payload?: any
}

export const removeUserFromGroupFailed = (payload?): RemoveUserFromGroupFailedType => ({
  type: GROUP_REMOVE_USER_FAILED,
  payload,
})

type RemoveUserFromGroupSucceedType = {
  type: typeof GROUP_REMOVE_USER_SUCCEED
  payload: string
}

export const removeUserFromGroupSucceed = (payload): RemoveUserFromGroupSucceedType => ({
  type: GROUP_REMOVE_USER_SUCCEED,
  payload,
})

type GroupAcceptType = {
  type: typeof GROUP_ACCEPT_REQUEST
  payload: string
}

export const groupAccept = (payload): GroupAcceptType => ({
  type: GROUP_ACCEPT_REQUEST,
  payload,
})

type GroupAcceptFailedType = {
  type: typeof GROUP_ACCEPT_FAILED
  payload?: any
}

export const groupAcceptFailed = (payload?): GroupAcceptFailedType => ({
  type: GROUP_ACCEPT_FAILED,
  payload,
})

type GroupAcceptSucceedType = {
  type: typeof GROUP_ACCEPT_SUCCEED
  payload: {
    groupId: string
    invitationId: string
  }
}

export const groupAcceptSucceed = (payload): GroupAcceptSucceedType => ({
  type: GROUP_ACCEPT_SUCCEED,
  payload,
})

type GroupDeclineType = {
  type: typeof GROUP_DECLINE_REQUEST
  payload: string
}

export const groupDecline = (payload): GroupDeclineType => ({
  type: GROUP_DECLINE_REQUEST,
  payload,
})

type GroupDeclineFailedType = {
  type: typeof GROUP_DECLINE_FAILED
  payload?: any
}

export const groupDeclineFailed = (payload?): GroupDeclineFailedType => ({
  type: GROUP_DECLINE_FAILED,
  payload,
})

type GroupDeclineSucceedType = {
  type: typeof GROUP_DECLINE_SUCCEED
  payload: string
}

export const groupDeclineSucceed = (payload): GroupDeclineSucceedType => ({
  type: GROUP_DECLINE_SUCCEED,
  payload,
})

export type GroupActionsTypes =
  | InviteUserType
  | InviteUserFailedType
  | InviteUserSucceedType
  | CancelInviteFailedType
  | CancelInviteSucceedType
  | CancelInviteType
  | ReactInviteType
  | ReactInviteFailedType
  | LeaveGroupType
  | LeaveGroupFailedType
  | LeaveGroupSucceedType
  | FetchGroupsType
  | FetchGroupsFailedType
  | FetchGroupsSucceedTypes
  | RemoveUserFromGroupFailedType
  | RemoveUserFromGroupTypes
  | RemoveUserFromGroupSucceedType
  | GroupAcceptType
  | GroupAcceptFailedType
  | GroupAcceptSucceedType
  | GroupDeclineType
  | GroupDeclineFailedType
  | GroupDeclineSucceedType

/* REDUCER */
export const saveInvitationReducer = (state, action) => {
  const exists = state.invitations.lists.homeGroupInvitationIds.includes(action.payload.id)

  if (exists) {
    return state
  }

  return {
    ...state,
    invitations: {
      ...state.invitations,
      entities: {
        ...state.invitations.entities,
        [action.payload.id]: action.payload,
      },
      lists: {
        ...state.invitations.lists,
        homeGroupInvitationIds: [
          ...state.invitations.lists.homeGroupInvitationIds,
          action.payload.id,
        ],
      },
    },
  }
}

export const removeInvitationReducer = (state, action) => {
  return {
    ...state,
    invitations: {
      ...state.invitations,
      lists: {
        ...state.invitations.lists,
        homeGroupInvitationIds: state.invitations.lists.homeGroupInvitationIds.filter(
          (inv) => inv !== action.payload
        ),
      },
    },
  }
}

export const userLeaveReducer = (state, action) => {
  const groupOwner = state.groups.entities[action.payload].ownerId
  return {
    ...state,
    groups: {
      ...state.groups,
      lists: {
        ...state.groups.lists,
        joinedGroupIds: state.groups.lists.joinedGroupIds.filter(
          (group) => group !== action.payload
        ),
      },
      entities: {
        ...state.groups.entities,
        [state.groups.lists.homeGroupId]: {
          ...state.groups.entities[state.groups.lists.homeGroupId],
          members: state.groups.entities[state.groups.lists.homeGroupId].members.filter(
            (userId) => userId !== groupOwner
          ),
        },
      },
    },
  }
}

export const saveGroupsReducer = (state, action) => {
  return {
    ...state,
    groups: {
      ...state.groups,
      entities: action.payload.entities.groups,
      lists: action.payload.lists,
    },
    invitations: {
      ...state.invitations,
      entities: action.payload.entities.invitations,
      lists: action.payload.lists,
    },
  }
}

export const removeUserFromGroupReducer = (state, action) => {
  const userGroup: any = Object.values(state.groups.entities).find(
    (group: any) => group.ownerId === action.payload
  )

  return {
    ...state,
    groups: {
      ...state.groups,
      entities: {
        ...state.groups.entities,
        [state.groups.lists.homeGroupId]: {
          ...state.groups.entities[state.groups.lists.homeGroupId],
          members: state.groups.entities[state.groups.lists.homeGroupId].members.filter(
            (member) => member !== action.payload
          ),
        },
      },
      lists: {
        ...state.groups.lists,
        joinedGroupIds: state.groups.lists.joinedGroupIds.filter((group) => group !== userGroup.id),
      },
    },
  }
}

export const groupAcceptReducer = (state, action) => {
  const { groupId, invitationId, userId } = action.payload
  return {
    ...state,
    invitations: {
      ...state.invitations,
      lists: {
        ...state.invitations.lists,
        otherGroupInvitationIds: state.invitations.lists.otherGroupInvitationIds.filter(
          (inv) => inv !== invitationId
        ),
      },
    },
    groups: {
      ...state.groups,
      entities: {
        ...state.groups.entities,
        [state.groups.lists.homeGroupId]: {
          ...state.groups.entities[state.groups.lists.homeGroupId],
          members: [...state.groups.entities[state.groups.lists.homeGroupId].members, userId],
        },
      },
      lists: {
        ...state.groups.lists,
        joinedGroupIds: [...state.groups.lists.joinedGroupIds, groupId],
      },
    },
  }
}

export const groupDeclineReducer = (state, action) => {
  return {
    ...state,
    invitations: {
      ...state.invitations,
      lists: {
        ...state.invitations.lists,
        otherGroupInvitationIds: state.invitations.lists.otherGroupInvitationIds.filter(
          (inv) => inv !== action.payload
        ),
      },
    },
  }
}

export const groupSavePetReducer = (state, action) => {
  if (state.groups.entities[state.groups.lists.homeGroupId].pets.includes(action.payload.id))
    return state
  return {
    ...state,
    groups: {
      ...state.groups,
      entities: {
        ...state.groups.entities,
        [state.groups.lists.homeGroupId]: {
          ...state.groups.entities[state.groups.lists.homeGroupId],
          pets: [...state.groups.entities[state.groups.lists.homeGroupId].pets, action.payload.id],
        },
      },
    },
  }
}

export const groupDeletePetReducer = (state, action) => {
  return {
    ...state,
    groups: {
      ...state.groups,
      entities: {
        ...state.groups.entities,
        [state.groups.lists.homeGroupId]: {
          ...state.groups.entities[state.groups.lists.homeGroupId],
          pets: state.groups.entities[state.groups.lists.homeGroupId].pets.filter(
            (pet) => pet.id !== action.payload
          ),
        },
      },
    },
  }
}

/* SAGA */
export function* inviteUserSaga(action) {
  try {
    const response = yield inviteUserService(action.payload)
    const data = yield response?.data

    const isAlreadyAMember = Object.keys(data).length === 0

    if (isAlreadyAMember) {
      yield put(inviteUserFailed())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.SEND_INVITATION_ALREADY_A_MEMBER',
          actionType: 'success',
        })
      )
    } else {
      yield put(inviteUserSucceed(data))
      if (data.isRegistered) {
        yield put(saveUsers({ entities: { [data.id]: data }, lists: [] }))
      }
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.SEND_INVITATION',
          actionType: 'success',
        })
      )
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'inviteUserSaga' }))
    console.error(e)
    yield put(inviteUserFailed(e))
  }
}

export function* cancelInviteSaga(action) {
  try {
    const response = yield cancelInvitationService(action.payload)
    const data = yield response?.data
    if (data.status === 'ok') {
      yield put(cancelInviteSucceed(action.payload))
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.CANCEL_INVITATION',
          actionType: 'success',
        })
      )
    } else {
      yield put(cancelInviteFailed())
    }
  } catch (e) {
    const errorData = e?.response?.data?.error

    if (errorData?.code === 'NOT_FOUND') {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.INVITATION_NOT_FOUND',
          actionType: 'error',
        })
      )
    } else {
      yield put(storeError({ error: e, origin: 'cancelInviteSaga' }))
      console.error(e)
    }
    yield put(cancelInviteFailed(e))
    yield put(fetchGroups())
  }
}

export function* leaveGroupSaga(action) {
  try {
    const response = yield leaveGroupService(action.payload)
    const data = yield response?.data

    if (data.status === 'ok') {
      yield put(leaveGroupSucceed(action.payload))
      yield put(fetchGroups())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.LEAVE_GROUP',
          actionType: 'success',
        })
      )
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'leaveGroupSaga' }))
    console.error(e)
    yield put(leaveGroupFailed(e))
  }
}

export function* removeUserFromGroupSaga(action) {
  try {
    const { userId, groupId } = action.payload
    const response = yield removeUserFromGroupService({ userId, groupId })
    const data = yield response?.data
    if (data.status === 'ok') {
      yield put(removeUserFromGroupSucceed(userId))
      yield put(fetchGroups())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.REMOVE_FROM_GROUP',
          actionType: 'success',
        })
      )
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'removeUserFromGroupSaga' }))
    console.error(e)
    yield put(removeUserFromGroupFailed(e))
  }
}

export function* groupAcceptSaga(action) {
  try {
    const { groupId, invitationId, userId } = action.payload
    const response = yield groupAcceptService(invitationId)
    const data = yield response?.data
    if (data.status === 'ok') {
      yield put(groupAcceptSucceed({ invitationId, groupId, userId }))
      yield put(fetchGroups())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.ACCEPT_INVITATION',
          actionType: 'success',
        })
      )
    }
  } catch (e) {
    const errorData = e?.response?.data?.error

    if (errorData?.code === 'NOT_FOUND') {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.INVITATION_NOT_FOUND',
          actionType: 'error',
        })
      )
    } else {
      yield put(storeError({ error: e, origin: 'groupAcceptSaga' }))
      console.error(e)
    }
    yield put(groupAcceptFailed(e))
    yield put(fetchGroups())
  }
}

export function* groupDeclineSaga(action) {
  try {
    const response = yield groupDeclineService(action.payload)
    const data = yield response?.data
    if (data.status === 'ok') {
      yield put(groupDeclineSucceed(action.payload))
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.DECLINE_INVITATION',
          actionType: 'success',
        })
      )
    }
  } catch (e) {
    const errorData = e?.response?.data?.error

    if (errorData?.code === 'NOT_FOUND') {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.INVITATION_NOT_FOUND',
          actionType: 'error',
        })
      )
    } else {
      yield put(storeError({ error: e, origin: 'groupDeclineSaga' }))
      console.error(e)
    }
    yield put(groupDeclineFailed(e))
    yield put(fetchGroups())
  }
}

/* SELECTOR */
export const getUserGroup = (state) => {
  if (state.groups.groups.lists.homeGroupId) {
    return parseGroupEntities(state.groups.groups.lists.homeGroupId, state)
  }

  return null
}

export const getUserGroups = (state) => {
  if (isEmptyObject(state.groups.groups?.lists)) {
    return []
  }

  return state.groups.groups.lists.joinedGroupIds.map((id) => {
    return parseGroupEntities(id, state)
  })
}

export const getHomeGroupInvitations = (state) =>
  state.groups.invitations.lists?.homeGroupInvitationIds?.map((inv) => {
    let name = undefined
    const entity = state.groups.invitations.entities[inv]
    if (entity.isRegistered) {
      name = state.user.users.entities[entity.userId]?.name || ''
    }
    return {
      ...entity,
      name,
    }
  })

export const getGroupInvitations = (state) => {
  return (
    state.groups.invitations.lists?.otherGroupInvitationIds?.map((inv) => {
      if (state.groups.groups.entities) {
        const entity = state.groups.invitations.entities[inv]
        const group = parseGroupEntities(entity.groupId, state)

        return {
          ...group,
          invitationId: inv,
        }
      }
    }) || []
  )
}

export const getInvitesCount = (state) =>
  state.groups?.invitations.lists?.otherGroupInvitationIds?.length || 0
