import { TrackParams, trackNavigation } from 'services'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getUser } from 'store/selectors'
import { storeError } from './errors'

//
// STATE
//
type RouteType = {
  route: string
  params?: TrackParams
}

type InitialStateTypes = {
  routeHistoryMaxLength: number
  routeHistory: RouteType[]
}

const initialState: InitialStateTypes = {
  routeHistoryMaxLength: 10,
  routeHistory: [],
}

//
// ACTION - ADD APP NOTIFICATION
//
const ADD_ROUTE_TO_HISTORY = 'ADD_ROUTE_TO_HISTORY'

interface AddRouteToHistoryActionType {
  type: typeof ADD_ROUTE_TO_HISTORY
  payload: RouteType
}

export const addRouteToHistory = (payload: RouteType): AddRouteToHistoryActionType => {
  return {
    type: ADD_ROUTE_TO_HISTORY,
    payload,
  }
}

const STORE_ROUTE = 'STORE_ROUTE'

interface StoreRouteActionType {
  type: typeof STORE_ROUTE
  payload: RouteType
}

export const storeRoute = (payload: RouteType): StoreRouteActionType => {
  return {
    type: STORE_ROUTE,
    payload,
  }
}

const addRouteToHistoryReducer = (state: InitialStateTypes, action) => {
  const route: RouteType = action.payload

  return {
    ...state,
    routeHistory: [route, ...state.routeHistory].slice(0, state.routeHistoryMaxLength),
  }
}

//
// REDUCER
//
export const navigationReducer = (state = initialState, action: any): InitialStateTypes => {
  switch (action.type) {
    case ADD_ROUTE_TO_HISTORY:
      return addRouteToHistoryReducer(state, action)
    default:
      return state
  }
}

//
// SAGA
//

function* addRouteToHistorySaga(action): Generator {
  try {
    const route: RouteType = action.payload

    if (route) {
      const state: any = yield select()
      const user = getUser(state)

      yield put(storeRoute(route))

      if (user) {
        const previousRouteData = getPreviousRoute(state)

        yield call(trackNavigation, {
          userId: user.id,
          routeFrom: previousRouteData?.route,
          route: route.route,
          params: route.params,
          paramsFrom: previousRouteData?.params,
        })
      }
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'addRouteToHistorySaga' }))
    console.error(e)
  }
}

/* final saga */
export function* navigationSaga() {
  yield takeLatest(ADD_ROUTE_TO_HISTORY, addRouteToHistorySaga)
}

//
// SELECTORS
//
export const getRouteHistory = (state) =>
  (state?.navigation as InitialStateTypes)?.routeHistory as RouteType[]

export const getRouteHistoryMaxLength = (state) =>
  (state?.navigation as InitialStateTypes)?.routeHistoryMaxLength as number

export const getCurrentRoute = (state) =>
  (state?.navigation as InitialStateTypes)?.routeHistory[0] as RouteType

export const getPreviousRoute = (state) =>
  (state?.navigation as InitialStateTypes)?.routeHistory[1] as RouteType
