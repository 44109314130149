import { CONFIRM_RESET_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST } from './../actions/userActions'
import {
  INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_REQUEST,
  REFRESH_TOKEN_REQUEST,
  REGISTER_ANONYMOUS_TOKEN_REQUEST,
  USER_LOGIN_REQUEST,
  USER_LOGOUT_REQUEST,
  USER_REGISTER_REQUEST,
} from 'store/actions'
import { RESET_ERRORS_REQUEST, STORE_ERROR_REQUEST } from 'entity/errors'
import { TOGGLE_FOOTER_IS_VISIBLE_REQUEST, TOGGLE_FOOTER_OPEN_REQUEST } from 'entity/footer'

import { FETCH_BREEDS_REQUEST } from './../actions/petActions'
import { FETCH_SHARE_PET_REUQEST } from 'entity/share'
import { getAuthToken } from 'services'
import { isServerSide } from 'utils'

const skippedActions = [
  USER_LOGOUT_REQUEST,
  USER_LOGIN_REQUEST,
  REFRESH_TOKEN_REQUEST,
  TOGGLE_FOOTER_OPEN_REQUEST,
  TOGGLE_FOOTER_IS_VISIBLE_REQUEST,
  USER_REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
  CONFIRM_RESET_PASSWORD_REQUEST,
  REGISTER_ANONYMOUS_TOKEN_REQUEST,
  INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_REQUEST,
  FETCH_SHARE_PET_REUQEST,
  FETCH_BREEDS_REQUEST,
  STORE_ERROR_REQUEST,
  RESET_ERRORS_REQUEST,
]

const authMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (isServerSide()) next(action)
    // check if it is request, if not, just continue to next action
    const isRequest = action.type.endsWith('_REQUEST')
    if (!isRequest) return next(action)

    // logout user action
    if (skippedActions.includes(action.type)) return next(action)

    // if user dont have a token in localstorage, run logout action
    const token = getAuthToken()
    if (!token) {
      return dispatch({ type: USER_LOGOUT_REQUEST })
    }

    const isValidToken =
      new Date(token.issued + token.expiresIn).getTime() >
      Math.floor(new Date().getTime() / 1000 + 200)

    // refresh token if is expired
    if (!isValidToken) {
      window.refreshingToken = true
      dispatch({ type: REFRESH_TOKEN_REQUEST })
      await waitForRefreshToken()
      next(action)
    } else {
      next(action)
    }
  }

const waitForRefreshToken = () => {
  return new Promise((resolve /* , reject */) => {
    setInterval(() => {
      if (!window.refreshingToken) {
        resolve(true)
      }
    }, 100)
  })
}

export default authMiddleware
