import SvgIcon, { IconName } from 'components/svgIcon'
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div<{ color: string }>`
  position: relative;
  background: ${(props) => props.color};
  border-radius: 50%;
  padding: 4px;

  --size: 56px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);

  ${(props) => props.theme.breakpoints.XS} {
    --size: 50px;
  }

  ${(props) => props.theme.breakpoints.XXS} {
    --size: 40px;
  }
`

const Icon = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  fill: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.color};
  box-shadow: 0px 0px 5px ${(props) => props.theme.colors.black}88;

  svg {
    width: 40%;
  }
`

const animProgress = keyframes`
  from {
    stroke-dashoffset: 100;
  }

  to {
    stroke-dashoffset: 0;
  }
`
const ProgressCircle = styled.div<{ timeout_ms: number }>`
  position: absolute;

  --padding: -12px;
  top: var(--padding);
  left: var(--padding);
  right: var(--padding);
  bottom: var(--padding);

  stroke-dasharray: 100;
  animation: ${animProgress} ${(props) => props.timeout_ms}ms linear;
`

const NotificationIcon = (props: {
  code: IconName
  color: string
  timeout_ms?: number
  isVisible: boolean
}) => {
  const svgSize = 80
  const svgStroke = 25

  //
  // RENDER
  //
  return (
    <Wrapper color={props.color}>
      <Icon color={props.color}>
        <SvgIcon code={props?.code} />
      </Icon>

      {!!props.timeout_ms && props.isVisible && (
        <ProgressCircle timeout_ms={props.timeout_ms}>
          <svg viewBox={`0 0 ${svgSize} ${svgSize}`}>
            <circle
              cx={svgSize / 2}
              cy={svgSize / 2}
              r={svgSize / 2 - svgStroke}
              fill="transparent"
              stroke="#fff3"
              strokeWidth={svgStroke}
            />
          </svg>
        </ProgressCircle>
      )}
    </Wrapper>
  )
}

export default NotificationIcon
