import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchPinnedVets, pinVet, unpinVet } from 'services/pinnedPlacesServices'

import { Vet } from 'api'
import { storeError } from './errors'

//
// STATE
//

type StateType = {
  vets: Vet[]
}

const initialState: StateType = { vets: [] }

//
// FETCH PINNED VETS
//
const FETCH_PINNED_VETS_REQUEST = 'FETCH_PINNED_VETS_REQUEST'
const FETCH_PINNED_VETS_SUCCEED = 'FETCH_PINNED_VETS_SUCCEED'
const FETCH_PINNED_VETS_FAILED = 'FETCH_PINNED_VETS_FAILED'

interface FetchPinnedVetsRequestType {
  type: typeof FETCH_PINNED_VETS_REQUEST
}

export const fetchPinnedVetsAction = (): FetchPinnedVetsRequestType => {
  return {
    type: FETCH_PINNED_VETS_REQUEST,
  }
}

interface FetchPinnedVetsSucceedType {
  type: typeof FETCH_PINNED_VETS_SUCCEED
  payload: {
    pinnedVets: Vet[]
  }
}

const fetchPinnedVetsSucceed = (payload): FetchPinnedVetsSucceedType => ({
  type: FETCH_PINNED_VETS_SUCCEED,
  payload,
})

interface fetchPinnedVetsFailedType {
  type: typeof FETCH_PINNED_VETS_FAILED
  payload: any
}

const fetchPinnedVetsFailed = (payload): fetchPinnedVetsFailedType => ({
  type: FETCH_PINNED_VETS_FAILED,
  payload,
})

/* reducer */
const fetchPinnedVetsReducer = (state: StateType, action) => {
  const { pinnedVets } = action.payload
  const updatedState = { ...state, vets: pinnedVets }
  return updatedState as StateType
}

/* saga */
function* fetchPinnedVetsSaga(/* action */): Generator {
  try {
    const response = yield call(fetchPinnedVets)
    yield put(fetchPinnedVetsSucceed({ pinnedVets: (response as any).data }))
  } catch (e) {
    yield put(fetchPinnedVetsFailed(e))
    yield put(storeError({ error: e, origin: 'fetchPinnedVetsSaga' }))
    console.error(e)
  }
}

//
// PIN VET
//
const PIN_VET_REQUEST = 'PIN_VET_REQUEST'
const PIN_VET_SUCCEED = 'PIN_VET_SUCCEED'
const PIN_VET_FAILED = 'PIN_VET_FAILED'

export type PinVetPayload = { id: string; title: string; address: string }

interface PinVetRequestType {
  type: typeof PIN_VET_REQUEST
  payload: PinVetPayload
}

export const pinVetAction = (payload: PinVetPayload): PinVetRequestType => {
  return {
    type: PIN_VET_REQUEST,
    payload,
  }
}

interface PinVetSucceedType {
  type: typeof PIN_VET_SUCCEED
  payload: {
    pinnedVets: Vet[]
  }
}

const pinVetSucceed = (payload): PinVetSucceedType => ({
  type: PIN_VET_SUCCEED,
  payload,
})

interface pinVetFailedType {
  type: typeof PIN_VET_FAILED
  payload: any
}

const pinVetFailed = (payload): pinVetFailedType => ({
  type: PIN_VET_FAILED,
  payload,
})

/* reducer */
const pinVetReducer = (state: StateType, action) => {
  const { pinnedVets } = action.payload
  const updatedState = { ...state, vets: pinnedVets }
  return updatedState as StateType
}

/* saga */
function* pinVetSaga(action): Generator {
  try {
    const { id, address, title } = action.payload
    const response = yield call(pinVet, { id, body: { address, title } })
    yield put(pinVetSucceed({ pinnedVets: (response as any).data }))
  } catch (e) {
    yield put(pinVetFailed(e))
    yield put(storeError({ error: e, origin: 'pinVetSaga' }))
    console.error(e)
  }
}

//
// UNPIN VET
//
const UNPIN_VET_REQUEST = 'UNPIN_VET_REQUEST'
const UNPIN_VET_SUCCEED = 'UNPIN_VET_SUCCEED'
const UNPIN_VET_FAILED = 'UNPIN_VET_FAILED'

export type UnpinVetPayload = { id: string }

interface UnpinVetRequestType {
  type: typeof UNPIN_VET_REQUEST
  payload: UnpinVetPayload
}

export const unpinVetAction = (payload: UnpinVetPayload): UnpinVetRequestType => {
  return {
    type: UNPIN_VET_REQUEST,
    payload,
  }
}

interface UnpinVetSucceedType {
  type: typeof UNPIN_VET_SUCCEED
  payload: {
    pinnedVets: Vet[]
  }
}

const unpinVetSucceed = (payload): UnpinVetSucceedType => ({
  type: UNPIN_VET_SUCCEED,
  payload,
})

interface unpinVetFailedType {
  type: typeof UNPIN_VET_FAILED
  payload: any
}

const unpinVetFailed = (payload): unpinVetFailedType => ({
  type: UNPIN_VET_FAILED,
  payload,
})

/* reducer */
const unpinVetReducer = (state: StateType, action) => {
  const { pinnedVets } = action.payload
  const updatedState = { ...state, vets: pinnedVets }
  return updatedState as StateType
}

/* saga */
function* unpinVetSaga(action): Generator {
  try {
    const { id } = action.payload
    const response = yield call(unpinVet, { id })
    yield put(unpinVetSucceed({ pinnedVets: (response as any).data }))
  } catch (e) {
    yield put(unpinVetFailed(e))
    yield put(storeError({ error: e, origin: 'unpinVetSaga' }))
    console.error(e)
  }
}

//
// REDUCER
//
export const pinnedPlacesReducer = (state = initialState, action: any): StateType => {
  switch (action.type) {
    case FETCH_PINNED_VETS_SUCCEED:
      return fetchPinnedVetsReducer(state, action)
    case PIN_VET_SUCCEED:
      return pinVetReducer(state, action)
    case UNPIN_VET_SUCCEED:
      return unpinVetReducer(state, action)
    default:
      return state
  }
}

//
// SAGA
//
export function* pinnedPlacesSaga() {
  yield takeLatest(FETCH_PINNED_VETS_REQUEST, fetchPinnedVetsSaga)
  yield takeLatest(PIN_VET_REQUEST, pinVetSaga)
  yield takeLatest(UNPIN_VET_REQUEST, unpinVetSaga)
}

//
// SELECTORS
//
export const getPinnedVets = (state) => state.pinnedPlaces.vets
