//
// DEFS
//
export type BreakpointEnumType = 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'

export const breakpoints: {
  XXL: string
  XL: string
  L: string
  M: string
  S: string
  XS: string
  XXS: string
} = {
  XXL: '2400px',
  XL: '1600px',
  L: '1000px',
  M: '700px',
  S: '500px',
  XS: '390px',
  XXS: '320px',
}

//
// BREAKPOINT OBJECT
//
const breakpointGen: any = {}
Object.entries(breakpoints).forEach((entry) => {
  const size = entry[0]
  const value = entry[1]
  breakpointGen[size] = `@media screen and (max-width: ${value})`
})

const breakpoint: typeof breakpointGen = breakpointGen

export default breakpoint
