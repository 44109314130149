import { put, takeLatest } from '@redux-saga/core/effects'

import { storeError } from './errors'

//
// STATE
//

type InitialStateType = {
  isVisible: boolean
  isOpen: boolean
}

const initialState: InitialStateType = {
  isVisible: false,
  isOpen: false,
}

//
// ACTION - TOGGLE FOOTER isVisible
//
export const TOGGLE_FOOTER_IS_VISIBLE_REQUEST = 'TOGGLE_FOOTER_IS_VISIBLE_REQUEST'
const TOGGLE_FOOTER_IS_VISIBLE_SUCCEED = 'TOGGLE_FOOTER_IS_VISIBLE_SUCCEED'
const TOGGLE_FOOTER_IS_VISIBLE_FAILED = 'TOGGLE_FOOTER_IS_VISIBLE_FAILED'
interface ToggleFooterIsVisibleActionType {
  type: typeof TOGGLE_FOOTER_IS_VISIBLE_REQUEST
  payload: boolean
}

export const toggleFooterIsVisible = (isVisible: boolean): ToggleFooterIsVisibleActionType => {
  return {
    type: TOGGLE_FOOTER_IS_VISIBLE_REQUEST,
    payload: isVisible,
  }
}

const toggleFooterIsVisibleSucceed = () => ({
  type: TOGGLE_FOOTER_IS_VISIBLE_SUCCEED,
})

const toggleFooterIsVisibleFailed = () => ({
  type: TOGGLE_FOOTER_IS_VISIBLE_FAILED,
})

const toggleFooterIsVisibleReducer = (state, action) => {
  const isVisible = action.payload
  return {
    ...state,
    isVisible: isVisible,
  }
}

function* toggleFooterIsVisibleSaga(/* action */): Generator {
  try {
    /* optionally do something with the action and catch errors */
    yield put(toggleFooterIsVisibleSucceed())
  } catch (e) {
    yield put(toggleFooterIsVisibleFailed())
    yield put(storeError({ error: e, origin: 'toggleFooterIsVisibleSaga' }))
    console.error(e)
  }
}

//
// ACTION - TOGGLE FOOTER isOpen
//
export const TOGGLE_FOOTER_OPEN_REQUEST = 'TOGGLE_FOOTER_OPEN_REQUEST'
const TOGGLE_FOOTER_OPEN_SUCCEED = 'TOGGLE_FOOTER_OPEN_SUCCEED'
const TOGGLE_FOOTER_OPEN_FAILED = 'TOGGLE_FOOTER_OPEN_FAILED'
interface ToggleFooterIsOpenActionType {
  type: typeof TOGGLE_FOOTER_OPEN_REQUEST
  payload: boolean
}

export const toggleFooterIsOpen = (isOpen: boolean): ToggleFooterIsOpenActionType => {
  return {
    type: TOGGLE_FOOTER_OPEN_REQUEST,
    payload: isOpen,
  }
}

const toggleFooterIsOpenSucceed = () => ({
  type: TOGGLE_FOOTER_OPEN_SUCCEED,
})

const toggleFooterIsOpenFailed = () => ({
  type: TOGGLE_FOOTER_OPEN_FAILED,
})

const toggleFooterIsOpenReducer = (state, action) => {
  const isOpen = action.payload
  return {
    ...state,
    isOpen: isOpen,
  }
}

function* toggleFooterIsOpenSaga(/* action */): Generator {
  try {
    /* optionally do something with the action and catch errors */
    yield put(toggleFooterIsOpenSucceed())
  } catch (e) {
    yield put(toggleFooterIsOpenFailed())
    yield put(storeError({ error: e, origin: 'toggleFooterIsOpenSaga' }))
    console.error(e)
  }
}

//
// REDUCER
//
export const footerReducer = (state = initialState, action: any): InitialStateType => {
  switch (action.type) {
    case TOGGLE_FOOTER_IS_VISIBLE_REQUEST:
      return toggleFooterIsVisibleReducer(state, action)
    case TOGGLE_FOOTER_OPEN_REQUEST:
      return toggleFooterIsOpenReducer(state, action)
    default:
      return state
  }
}

//
// SAGA
//
export function* footerSaga() {
  yield takeLatest(TOGGLE_FOOTER_IS_VISIBLE_REQUEST, toggleFooterIsVisibleSaga)
  yield takeLatest(TOGGLE_FOOTER_OPEN_REQUEST, toggleFooterIsOpenSaga)
}

//
// SELECTORS
//
export const getFooterIsVisible = (state) =>
  (state?.footer as InitialStateType)?.isVisible as boolean
export const getFooterIsOpen = (state) => (state?.footer as InitialStateType)?.isOpen as boolean
