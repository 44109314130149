import { mainTheme } from 'styles/themes'

const getSvgMarker = () => {
  const pinSVGHole =
    'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z'

  // const pinSVGFilled = 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z'

  return pinSVGHole
}

export const createUserMarker = (map: google.maps.Map) => {
  const marker = new google.maps.Marker({
    map,
    icon: {
      path: getSvgMarker(),
      anchor: new google.maps.Point(12, 22),
      fillOpacity: 1,
      fillColor: mainTheme.colors.lightGrey,
      strokeColor: `${mainTheme.colors.white}99`,
      strokeWeight: 2,
      scale: 2,
      labelOrigin: new google.maps.Point(12, 9),
    },
  })

  return marker
}

export const createPlaceMarker = (map: google.maps.Map) => {
  const marker = new google.maps.Marker({
    map,
    icon: {
      path: getSvgMarker(),
      anchor: new google.maps.Point(12, 22),
      fillOpacity: 1,
      fillColor: mainTheme.colors.primary,
      strokeColor: `${mainTheme.colors.white}99`,
      strokeWeight: 2,
      scale: 2,
      labelOrigin: new google.maps.Point(12, 9),
    },
  })

  return marker
}

export const addPlaceMarkerToMap = (props: {
  map: google.maps.Map
  placeId: string
  location: google.maps.LatLng
  onClick?: (placeId) => void
}) => {
  const marker = createPlaceMarker(props.map)

  // @ts-ignore TODO This should be in @typings/googlemaps.
  marker.setPlace({
    placeId: props.placeId,
    location: props.location,
  })
  marker.setVisible(true)

  if (props.onClick) {
    marker.setClickable(true)
    marker.addListener('click', () => props.onClick(props.placeId))
  }
}
