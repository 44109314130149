import Footer, { FooterProps } from 'components/Footer'
import { animated, clickThrough } from 'styles/themes'
import {
  getAvailablePets,
  getIsPetsFetched,
  isAppInitialized,
  isMenuVisible,
} from 'store/selectors'
import { getFooterIsVisible, toggleFooterIsVisible } from 'entity/footer'
import { useDispatch, useSelector } from 'react-redux'

import { EventDefinition } from 'apiLegacy'
import Header from 'components/header'
import Menu from 'components/Menu/menu'
import NoPetScreen from 'components/NoPetScreen'
import { m } from 'framer-motion'
import styled from 'styled-components'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { variants } from 'constants/animations'

const animationRightRoutes = []

const getContentTopPadding = (withHeader, withPets, petsEventFilter, size?: 'XXS') => {
  const headerHeight = 46
  const petHeaderHeight = size === 'XXS' ? 40 : 55
  const petFilterHeight = size === 'XXS' ? 40 : 55

  return !withHeader
    ? '0px'
    : `${
        0 +
        (withHeader ? headerHeight : 0) +
        (withPets ? petHeaderHeight : 0) +
        (petsEventFilter ? petFilterHeight : 0)
      }px`
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgb(195, 195, 195);
  background: linear-gradient(
    20deg,
    rgba(195, 195, 195, 1) 0%,
    rgba(228, 228, 228, 1) 21%,
    rgba(255, 255, 255, 1) 100%
  );
  font-family: 'Titillium Web', sans-serif;
  color: ${(props) => props.theme?.colors?.text || 'gray'};
  display: grid;
  place-items: center;
`

const MainBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.theme?.layout?.maxWidth};
  overflow: hidden;
  box-shadow: #00000066 2px 2px 10px;
`

const Main = styled.div<{ withHeader: boolean; withFooter: boolean; backgroundColor?: string }>`
  display: grid;
  position: relative;
  grid-template-rows: ${(props) =>
    (props.withHeader ? 'auto' : '') + ' 1fr ' + (props.withFooter ? 'auto' : '')};
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor || props.theme?.colors?.primaryBackground || 'white'};
`

const ContentBox = styled.article<{
  withHeader: boolean
  withFooter: boolean
  withPets: boolean
  petsEventFilter: boolean
}>`
  position: relative;
  overflow: auto;

  padding-bottom: ${(props) => (props.withFooter ? 100 : 0)}px;
  padding-top: ${(props) =>
    getContentTopPadding(props.withHeader, props.withPets, props.petsEventFilter)};

  ${(props) => props.theme.breakpoints.XXS} {
    padding-top: ${(props) =>
      getContentTopPadding(props.withHeader, props.withPets, props.petsEventFilter, 'XXS')};
  }

  transition: padding-top 0.3s ease;
`

const MenuWrapper = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.theme?.layout?.maxWidth};
  overflow: hidden;
  touch-action: none;
  z-index: ${(props) => props.theme?.layout?.layers?.menu};
  ${clickThrough};
`

const MenuSlider = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  ${animated};
  pointer-events: all;
  transform: translate(${(props) => (props.isOpen ? 0 : 110)}%, 0px);
`

const MainLayout = ({
  children,
  withHeader = true,
  petsRequired = false,
  petsInHeader = true,
  petsSliderOnChange = undefined,
  petsEventFilter = false,
  petsEventFilterSelectedPetOnly = false,
  lockedPet = false,
  contentBoxId = undefined,
  footer = undefined,
  backgroundColor = undefined,
}: {
  children?: any
  withHeader?: boolean
  petsRequired?: boolean
  petsInHeader?: boolean
  petsSliderOnChange?: () => void
  petsEventFilter?: boolean
  petsEventFilterSelectedPetOnly?: boolean
  petsEventFilterOnChange?: (selectedEvent: EventDefinition) => void
  lockedPet?: boolean
  contentBoxId?: string
  footer?: FooterProps | boolean
  backgroundColor?: string
}) => {
  const router = useRouter()
  const dispatch = useDispatch()

  const menuVisible = useSelector(isMenuVisible)
  const isAppInit = useSelector(isAppInitialized)
  const isPetsFetched = useSelector(getIsPetsFetched)
  const pets = useSelector(getAvailablePets)

  const noPetsWhenNeeded = petsRequired ? !pets || pets.length === 0 : false
  const renderContent = petsRequired ? isPetsFetched : true

  const isFooterVisible = useSelector(getFooterIsVisible)

  useEffect(() => {
    const withFooter = !!footer
    if (withFooter !== isFooterVisible) {
      dispatch(toggleFooterIsVisible(withFooter))
    }
  }, [footer])

  if (!isAppInit) {
    return null
  }

  const visiblePets = petsInHeader && Object.keys(pets).length > 0

  return (
    <Wrapper>
      <MainBox>
        <Main withHeader={withHeader} withFooter={!!footer} backgroundColor={backgroundColor}>
          {withHeader && (
            <Header
              lockedPet={lockedPet}
              visiblePets={visiblePets}
              visiblePetsEventFilter={petsEventFilter}
              visiblePetsEventFilterForSelectedPet={petsEventFilterSelectedPetOnly}
              petsSliderOnChange={petsSliderOnChange}
            />
          )}

          <ContentBox
            id={contentBoxId}
            withPets={visiblePets}
            withHeader={withHeader}
            withFooter={!!footer}
            petsEventFilter={(pets as any)?.length > 0 && petsEventFilter}
          >
            {renderContent && (
              <m.div
                initial="hidden"
                animate="enter"
                exit="exit"
                variants={variants(
                  200,
                  animationRightRoutes.includes(router.route) ? 'left' : 'right'
                )}
                transition={{ type: 'linear' }}
              >
                {noPetsWhenNeeded ? <NoPetScreen /> : children}
              </m.div>
            )}
          </ContentBox>

          {footer &&
            (typeof footer === 'boolean' ? (
              <Footer submitButton={{ isDisabled: true }} />
            ) : footer.submitButton ? (
              <Footer
                {...({
                  ...footer,
                  submitButton: {
                    ...footer.submitButton,
                    isDisabled: noPetsWhenNeeded ? true : footer.submitButton.isDisabled,
                    icon: noPetsWhenNeeded ? 'icon-no' : footer.submitButton.icon,
                  },
                } as FooterProps)}
              />
            ) : (
              <Footer {...(footer as FooterProps)} />
            ))}

          <MenuWrapper>
            <MenuSlider isOpen={menuVisible}>
              <Menu />
            </MenuSlider>
          </MenuWrapper>
        </Main>
      </MainBox>
    </Wrapper>
  )
}

export default MainLayout
