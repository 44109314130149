import {
  AppNotificationErrorFeedbackType,
  AppNotificationNetworkErrorType,
  AppNotificationType,
} from 'types'

//
// STATE
//
type InitialStateTypes = {
  notificationsToView: AppNotificationType[]
}

const initialState: InitialStateTypes = {
  notificationsToView: [],
}

//
// HELPERS
//
const getUniqueId = () => {
  return 'app_notification_' + new Date().getTime() + '_' + Math.random().toString(16).slice(2)
}

const removeNotificationFromList = (
  notificationList: AppNotificationType[],
  notificationToRemove: AppNotificationType
) => {
  return [...notificationList].filter((n: AppNotificationType) => n.id !== notificationToRemove.id)
}

const addNotificationToList = (
  notificationList: AppNotificationType[],
  notificationToAdd: AppNotificationType
) => {
  return [notificationToAdd, ...notificationList]
}

//
// ACTION - ADD APP NOTIFICATION
//
const ADD_APP_NOTIFICATION = 'ADD_APP_NOTIFICATION'

interface AddAppNotificationActionType {
  type: typeof ADD_APP_NOTIFICATION
  payload: AppNotificationType
}

export const addAppNotification = (payload: AppNotificationType): AddAppNotificationActionType => {
  const id = getUniqueId()

  if (payload.type === 'feedback-error') {
    return {
      type: ADD_APP_NOTIFICATION,
      payload: {
        id,
        type: 'feedback-error',
        timeout_ms: 0,
      } as AppNotificationErrorFeedbackType,
    }
  }

  if (payload.type === 'network-error') {
    return {
      type: ADD_APP_NOTIFICATION,
      payload: {
        id,
        type: 'network-error',
        timeout_ms: 0,
      } as AppNotificationNetworkErrorType,
    }
  }

  return {
    type: ADD_APP_NOTIFICATION,
    payload: { id, ...payload },
  }
}

const addAppNotificationReducer = (state: InitialStateTypes, action) => {
  const notification: AppNotificationType = action.payload

  const feedbackErrorAlreadyNotified =
    notification.type === 'feedback-error' &&
    state.notificationsToView.map((n) => n.type).includes('feedback-error')

  const networkErrorAlreadyNotified =
    notification.type === 'network-error' &&
    state.notificationsToView.map((n) => n.type).includes('network-error')

  if (!notification || feedbackErrorAlreadyNotified || networkErrorAlreadyNotified) {
    return state
  }
  return {
    ...state,
    notificationsToView: addNotificationToList(state.notificationsToView, notification),
  }
}

//
// ACTION - REMOVE APP NOTIFICATION
//
const REMOVE_APP_NOTIFICATION = 'REMOVE_APP_NOTIFICATION'

interface RemoveAppNotificationActionType {
  type: typeof REMOVE_APP_NOTIFICATION
  payload: AppNotificationType
}

export const removeAppNotification = (
  payload: AppNotificationType
): RemoveAppNotificationActionType => ({
  type: REMOVE_APP_NOTIFICATION,
  payload,
})

const removeAppNotificationReducer = (state: InitialStateTypes, action) => {
  const notification: AppNotificationType = action.payload
  if (!notification) {
    return state
  }
  return {
    ...state,
    notificationsToView: removeNotificationFromList(state.notificationsToView, notification),
  }
}

//
// ACTION - REMOVE APP NOTIFICATION FEEDBACK ERROR
//
const REMOVE_APP_NOTIFICATION_FEEDBACK_ERROR = 'REMOVE_APP_NOTIFICATION_FEEDBACK_ERROR'

interface RemoveAppNotificationFeedbackErrorActionType {
  type: typeof REMOVE_APP_NOTIFICATION_FEEDBACK_ERROR
}

export const removeAppNotificationFeedbackError =
  (): RemoveAppNotificationFeedbackErrorActionType => ({
    type: REMOVE_APP_NOTIFICATION_FEEDBACK_ERROR,
  })

const removeAppNotificationFeedbackErrorReducer = (state: InitialStateTypes) => {
  return {
    ...state,
    notificationsToView: [...state.notificationsToView].filter((n) => n.type !== 'feedback-error'),
  }
}

//
// ACTION - CLEAR APP NOTIFICATIONS
//
const CLEAR_APP_NOTIFICATIONS = 'CLEAR_APP_NOTIFICATIONS'

interface ClearAppNotificationsActionType {
  type: typeof CLEAR_APP_NOTIFICATIONS
}

export const clearAppNotifications = (): ClearAppNotificationsActionType => ({
  type: CLEAR_APP_NOTIFICATIONS,
})

const clearAppNotificationsReducer = (state: InitialStateTypes) => {
  return {
    ...state,
    notificationsToView: [],
  }
}

//
// REDUCER
//
export const appNotificationReducer = (state = initialState, action: any): InitialStateTypes => {
  switch (action.type) {
    case ADD_APP_NOTIFICATION:
      return addAppNotificationReducer(state, action)
    case REMOVE_APP_NOTIFICATION:
      return removeAppNotificationReducer(state, action)
    case REMOVE_APP_NOTIFICATION_FEEDBACK_ERROR:
      return removeAppNotificationFeedbackErrorReducer(state)
    case CLEAR_APP_NOTIFICATIONS:
      return clearAppNotificationsReducer(state)
    default:
      return state
  }
}

//
// SELECTORS
//
export const getAppNotificationsToView = (state) =>
  (state?.appNotifications as InitialStateTypes)?.notificationsToView as AppNotificationType[]
