export const fonts = [
  {
    family: 'Titillium Web',
    src: '/fonts/TitilliumWeb-ExtraLight.woff2',
    style: 'normal',
    weight: 200,
    display: 'swap',
  },
  {
    family: 'Titillium Web',
    src: '/fonts/TitilliumWeb-Light.woff2',
    style: 'normal',
    weight: 300,
    display: 'swap',
  },
  {
    family: 'Titillium Web',
    src: '/fonts/TitilliumWeb-Regular.woff2',
    style: 'normal',
    weight: 400,
    display: 'swap',
  },
  {
    family: 'Titillium Web',
    src: '/fonts/TitilliumWeb-Bold.woff2',
    style: 'normal',
    weight: 700,
    display: 'swap',
  },
  // {
  //   family: 'Titillium Web',
  //   src: '/fonts/TitilliumWeb-Black.woff2',
  //   style: 'normal',
  //   weight: 900,
  //   display: 'swap',
  // },
]

export const defaultFont = fonts[0]
