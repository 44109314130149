import { put, takeLatest } from '@redux-saga/core/effects'

import { addAppNotification } from 'entity/appNotifications'

//
// CONST
//
export const STORE_ERROR_REQUEST = 'STORE_ERROR_REQUEST'
const STORE_ERROR_SUCCEED = 'STORE_ERROR_SUCCEED'
const STORE_ERROR_FAILED = 'STORE_ERROR_FAILED'
export const RESET_ERRORS_REQUEST = 'RESET_ERRORS_REQUEST'
const RESET_ERRORS_SUCCEED = 'RESET_ERRORS_SUCCEED'
const RESET_ERRORS_FAILED = 'RESET_ERRORS_FAILED'

export type ErrorType = {
  timestamp?: string
  origin?: string
  data?: string
  message: string
  stack: string
  response?: {
    message?: string
    requestId?: string
    sentryId?: string
    status?: string
  }
}

const isNetworkError = (action) => {
  const errorMessage = action?.payload?.error?.message
  return errorMessage === 'Network Error'
}

//
// ACTIONS
//

/* Store error */
interface StoreErrorActionProps {
  error: ErrorType
  origin?: string
  data?: string
}
interface StoreErrorActionType {
  type: typeof STORE_ERROR_REQUEST
  payload: StoreErrorActionProps
}

export const storeError = ({
  error,
  origin,
  data,
}: StoreErrorActionProps): StoreErrorActionType => {
  return {
    type: STORE_ERROR_REQUEST,
    payload: { error, origin, data },
  }
}

const storeErrorSucceed = () => ({
  type: STORE_ERROR_SUCCEED,
})

const storeErrorFailed = () => ({
  type: STORE_ERROR_FAILED,
})

/* Reset errors */
export const resetErrors = () => ({
  type: RESET_ERRORS_REQUEST,
})

const resetErrorsSucceed = () => ({
  type: RESET_ERRORS_SUCCEED,
})

const resetErrorsFailed = () => ({
  type: RESET_ERRORS_FAILED,
})

//
// REDUCERS
//
type InitialStateTypes = {
  errors: ErrorType[]
}

const initialState: InitialStateTypes = {
  errors: [],
}

const storeErrorReducer = (state, action) => {
  if (isNetworkError(action)) {
    return state
  }

  const { error, origin, data } = action.payload
  if (!error) {
    return state
  }

  const errorResponse = error?.response?.data?.error

  const errorObject: ErrorType = {
    origin,
    data,
    message: error.message,
    stack: error.stack,
    timestamp: new Date().toISOString(),
    response: errorResponse || null,
  }

  return {
    ...state,
    errors: [...state.errors, errorObject],
  }
}

/* final reducer */
export const errorsReducer = (state = initialState, action: any): InitialStateTypes => {
  switch (action.type) {
    case STORE_ERROR_REQUEST:
      return storeErrorReducer(state, action)
    case RESET_ERRORS_REQUEST:
      return initialState
    default:
      return state
  }
}

//
// SAGA
//
function* storeErrorSaga(action): Generator {
  try {
    yield put(storeErrorSucceed())
    if (isNetworkError(action)) {
      yield put(addAppNotification({ type: 'network-error' }))
    } else {
      yield put(addAppNotification({ type: 'feedback-error' }))
    }
  } catch (e) {
    yield put(storeErrorFailed())
    console.error(e)
  }
}

function* resetErrorsSaga(/* action */): Generator {
  try {
    /* optionally do something with the action and catch errors */
    yield put(resetErrorsSucceed())
  } catch (e) {
    yield put(resetErrorsFailed())
    console.error(e)
  }
}

/* final saga */
export function* errorsSaga() {
  yield takeLatest(STORE_ERROR_REQUEST, storeErrorSaga)
  yield takeLatest(RESET_ERRORS_REQUEST, resetErrorsSaga)
}

//
// SELECTORS
//
export const getAppErrors = (state) => (state?.errors as InitialStateTypes)?.errors as ErrorType[]
