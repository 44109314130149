import IconPaw from 'components/svg/paw.svg'
import dynamic from 'next/dynamic'

export const iconMap = {
  'icon-placeholder-image': dynamic(() => import('components/svg/placeholder-image.svg')),
  'icon-placeholder-pet': dynamic(() => import('components/svg/placeholder-pet.svg')),
  'icon-placeholder-user': dynamic(() => import('components/svg/placeholder-user.svg')),
  'icon-no': dynamic(() => import('components/svg/no.svg')),
  'icon-dog': dynamic(() => import('components/svg/dog.svg')),
  'icon-weighing': dynamic(() => import('components/svg/weighing-scale.svg')),
  'icon-weight': dynamic(() => import('components/svg/weighing-scale.svg')),
  'icon-walking': dynamic(() => import('components/svg/paw.svg')),
  'icon-paw': IconPaw,
  'icon-paw-filled': dynamic(() => import('components/svg/paw-filled.svg')),
  'icon-vaccination': dynamic(() => import('components/svg/syringe.svg')),
  'icon-deworming': dynamic(() => import('components/svg/pills.svg')),
  'icon-claw-cutting': dynamic(() => import('components/svg/claws.svg')),
  'icon-claws': dynamic(() => import('components/svg/claws.svg')),
  'icon-teeth-check': dynamic(() => import('components/svg/tooth.svg')),
  'icon-tooth': dynamic(() => import('components/svg/tooth.svg')),
  'icon-doctor-check': dynamic(() => import('components/svg/vet.svg')),
  'icon-trimming': dynamic(() => import('components/svg/scissors-line.svg')),
  'icon-scissors-line': dynamic(() => import('components/svg/scissors-line.svg')),
  'icon-heating': dynamic(() => import('components/svg/droplet.svg')),
  'icon-photo': dynamic(() => import('components/svg/camera.svg')),
  'icon-purchase': dynamic(() => import('components/svg/cart.svg')),
  'icon-expenses': dynamic(() => import('components/svg/cart.svg')),
  'icon-allergy': dynamic(() => import('components/svg/hair.svg')),
  'icon-allergy-advise': dynamic(() => import('components/svg/allergy-advise.svg')),
  'icon-notice': dynamic(() => import('components/svg/reminder.svg')),
  'icon-reminder': dynamic(() => import('components/svg/reminder.svg')),
  'icon-antiparasitics': dynamic(() => import('components/svg/parasite.svg')),
  'icon-height-measurement': dynamic(() => import('components/svg/measure.svg')),
  'icon-height': dynamic(() => import('components/svg/measure.svg')),
  'icon-person': dynamic(() => import('components/svg/person.svg')),
  'icon-check': dynamic(() => import('components/svg/check.svg')),
  'icon-plus': dynamic(() => import('components/svg/plus.svg')),
  'icon-plus-circle': dynamic(() => import('components/svg/plus-circle.svg')),
  'icon-minus': dynamic(() => import('components/svg/minus.svg')),
  'icon-arrow-left-short': dynamic(() => import('components/svg/arrow-left-short.svg')),
  'icon-arrow-right-short': dynamic(() => import('components/svg/arrow-left-short.svg')),
  'icon-trash': dynamic(() => import('components/svg/trash.svg')),
  'icon-trash-small': dynamic(() => import('components/svg/trash-small.svg')),
  'icon-arrow-left': dynamic(() => import('components/svg/arrow.svg')),
  'icon-arrow-right': dynamic(() => import('components/svg/arrow.svg')),
  'icon-arrow-down': dynamic(() => import('components/svg/arrow.svg')),
  'icon-arrow-up': dynamic(() => import('components/svg/arrow.svg')),
  'icon-clock': dynamic(() => import('components/svg/clock.svg')),
  'icon-graph-up': dynamic(() => import('components/svg/graph-up.svg')),
  'icon-graph-grow': dynamic(() => import('components/svg/graph-grow.svg')),
  'icon-catalog': dynamic(() => import('components/svg/catalog.svg')),
  'icon-calendar': dynamic(() => import('components/svg/calendar.svg')),
  'icon-refresh': dynamic(() => import('components/svg/refresh.svg')),
  'icon-settings': dynamic(() => import('components/svg/settings.svg')),
  'icon-close': dynamic(() => import('components/svg/no.svg')),
  'icon-info': dynamic(() => import('components/svg/info.svg')),
  'icon-warning': dynamic(() => import('components/svg/warning.svg')),
  'icon-notification': dynamic(() => import('components/svg/notification-line.svg')),
  'icon-hamburger': dynamic(() => import('components/svg/hamburger.svg')),
  'icon-home': dynamic(() => import('components/svg/home.svg')),
  'icon-history': dynamic(() => import('components/svg/history.svg')),
  'icon-no-pet': dynamic(() => import('components/svg/no-pet.svg')),
  'icon-save': dynamic(() => import('components/svg/save.svg')),
  'icon-search': dynamic(() => import('components/svg/search.svg')),
  'icon-edit': dynamic(() => import('components/svg/edit-solid.svg')),
  'icon-loader': dynamic(() => import('components/svg/loadingSpiner')),
  'icon-file': dynamic(() => import('components/svg/file.svg')),
  'icon-document': dynamic(() => import('components/svg/document.svg')),
  'icon-vet': dynamic(() => import('components/svg/vet.svg')),
  'icon-find-veterinarian': dynamic(() => import('components/svg/map.svg')),
  'icon-map': dynamic(() => import('components/svg/map.svg')),
  'icon-share': dynamic(() => import('components/svg/share.svg')),
  'icon-dots': dynamic(() => import('components/svg/dots.svg')),
  'icon-copy': dynamic(() => import('components/svg/copy.svg')),
  'icon-mail': dynamic(() => import('components/svg/mail.svg')),
  'icon-open': dynamic(() => import('components/svg/open.svg')),
  'icon-cake': dynamic(() => import('components/svg/cake.svg')),
  'icon-cake-white': dynamic(() => import('components/svg/cake-white.svg')),
  'icon-heart': dynamic(() => import('components/svg/heart.svg')),
  'icon-location': dynamic(() => import('components/svg/location.svg')),
  'icon-laurel-branches': dynamic(() => import('components/svg/laurel-branches.svg')),
  'icon-video': dynamic(() => import('components/svg/video.svg')),
  'icon-feedback': dynamic(() => import('components/svg/outline-feedback.svg')),
  'icon-task': dynamic(() => import('components/svg/custom-event.svg')),
  'icon-podiums': dynamic(() => import('components/svg/podiums.svg')),
  'icon-medicine': dynamic(() => import('components/svg/medicine.svg')),
  'icon-bandage': dynamic(() => import('components/svg/bandage.svg')),
  'icon-password': dynamic(() => import('components/svg/key.svg')),
  'icon-logout': dynamic(() => import('components/svg/logout.svg')),
}

export type IconName =
  | 'icon-placeholder-image'
  | 'icon-placeholder-pet'
  | 'icon-placeholder-user'
  | 'icon-no'
  | 'icon-dog'
  | 'icon-weighing'
  | 'icon-weight'
  | 'icon-walking'
  | 'icon-paw'
  | 'icon-paw-filled'
  | 'icon-vaccination'
  | 'icon-deworming'
  | 'icon-claw-cutting'
  | 'icon-teeth-check'
  | 'icon-doctor-check'
  | 'icon-trimming'
  | 'icon-heating'
  | 'icon-photo'
  | 'icon-purchase'
  | 'icon-expenses'
  | 'icon-allergy'
  | 'icon-allergy-advise'
  | 'icon-notice'
  | 'icon-antiparasitics'
  | 'icon-height-measurement'
  | 'icon-height'
  | 'icon-person'
  | 'icon-check'
  | 'icon-plus'
  | 'icon-plus-circle'
  | 'icon-minus'
  | 'icon-arrow-left-short'
  | 'icon-arrow-right-short'
  | 'icon-trash'
  | 'icon-trash-small'
  | 'icon-arrow-left'
  | 'icon-arrow-right'
  | 'icon-arrow-down'
  | 'icon-arrow-up'
  | 'icon-clock'
  | 'icon-graph-up'
  | 'icon-graph-grow'
  | 'icon-catalog'
  | 'icon-calendar'
  | 'icon-refresh'
  | 'icon-settings'
  | 'icon-close'
  | 'icon-info'
  | 'icon-warning'
  | 'icon-notification'
  | 'icon-hamburger'
  | 'icon-home'
  | 'icon-history'
  | 'icon-no-pet'
  | 'icon-save'
  | 'icon-search'
  | 'icon-edit'
  | 'icon-loader'
  | 'icon-file'
  | 'icon-document'
  | 'icon-vet'
  | 'icon-find-veterinarian'
  | 'icon-map'
  | 'icon-share'
  | 'icon-dots'
  | 'icon-copy'
  | 'icon-mail'
  | 'icon-open'
  | 'icon-cake'
  | 'icon-cake-white'
  | 'icon-heart'
  | 'icon-location'
  | 'icon-laurel-branches'
  | 'icon-video'
  | 'icon-feedback'
  | 'icon-task'
  | 'icon-tooth'
  | 'icon-scissors-line'
  | 'icon-reminder'
  | 'icon-podiums'
  | 'icon-medicine'
  | 'icon-claws'
  | 'icon-bandage'
  | 'icon-password'
  | 'icon-logout'

export const isExistingIcon = (code: string) => {
  const icon = iconMap[code]
  return !!icon
}

const DefaultIcon = dynamic(() => import('components/svg/no.svg'))

const SvgIcon = (props: {
  code?: IconName
  className?: string
  style?: any
  onClick?: () => void
}) => {
  const Icon = iconMap[props.code || 'unknown'] || DefaultIcon

  let style = {}
  if (props.onClick) {
    style = { ...style, cursor: 'pointer' }
  }
  if (props.code === 'icon-arrow-down') {
    style = { ...style, transform: 'rotate(90deg)' }
  }
  if (props.code === 'icon-arrow-up') {
    style = { ...style, transform: 'rotate(-90deg)' }
  }
  if (props.code === 'icon-arrow-left' || props.code === 'icon-arrow-right-short') {
    style = { ...style, transform: 'rotate(180deg)' }
  }

  return (
    <Icon
      className={props.className}
      onClick={props.onClick}
      style={{ ...style, ...props.style }}
    />
  )
}
export default SvgIcon
