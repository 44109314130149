import {
  AuthApi,
  GroupsApi,
  OAuthToken,
  PasswordChangeRequest,
  PasswordResetCodeRequest,
  UserApi,
  UserSettingsUpdate,
  UserUpdateRequest,
} from 'apiLegacy'

import { createLegacyApiInstance } from 'utils'

const authApiInstance = () => createLegacyApiInstance(AuthApi)
const userApiInstance = () => createLegacyApiInstance(UserApi)
const groupApiInstance = () => createLegacyApiInstance(GroupsApi)

export const registerUser = ({ name, password, email }, options = {}): OAuthToken => {
  return authApiInstance().register({ name, password, email }, options)
}

export const loginUser = ({ email, password }, options = {}) => {
  return authApiInstance().login({ email, password }, options)
}

export const changePassword = (payload: PasswordChangeRequest) => {
  return authApiInstance().passwordChange(payload)
}

export const getUserData = (options = {}) => {
  return userApiInstance().userCheck({}, options)
}

export const updateUser = (payload: UserUpdateRequest) => {
  return userApiInstance().userUpdate(payload)
}

export const getUserGroups = () => {
  return groupApiInstance().groups()
}

export const inviteUser = (payload) => {
  return groupApiInstance().sendGroupInvitation(payload)
}

export const cancelInvitation = (payload) => {
  return groupApiInstance().cancelGroupInvitation({ invitationId: payload })
}

export const leaveGroup = (payload) => {
  return groupApiInstance().leaveGroup({ groupId: payload })
}

export const removeUserFromGroup = ({ userId, groupId }) => {
  return groupApiInstance().removeUser({ userId, groupId })
}

export const groupAccept = (invitationId) => {
  return groupApiInstance().acceptGroupInvitation({ invitationId })
}

export const groupDecline = (invitationId) => {
  return groupApiInstance().declineGroupInvitation({ invitationId })
}

export const getUserSettings = () => {
  return userApiInstance().userSettings()
}

export const updateUserSettings = (payload: UserSettingsUpdate) => {
  return userApiInstance().userSettingsUpdate(payload)
}

export const resetPassword = (payload: string) => {
  return authApiInstance().passwordResetEmail(payload)
}

export const confirmResetPassword = (payload: PasswordResetCodeRequest) => {
  return authApiInstance().passwordResetCode(payload)
}
