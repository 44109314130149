import { capitalizeFirstWord, ellipsis, mainTheme } from 'styles/themes'
import { getActivePet, getPet, getUser } from 'store/selectors'
import {
  getEventIcon,
  getEventName,
  getEventType,
  getFormattedDate,
  getFormattedTime,
  getLocalizedDateString,
  nameFromEmail,
} from 'utils'
import styled, { css } from 'styled-components'

import CheckSvg from 'components/svg/check.svg'
import { Event } from 'types'
import InView from 'react-intersection-observer'
import Link from 'next/link'
import NoSvg from 'components/svg/no.svg'
import SvgIcon from './svgIcon'
import { getEventDefinition } from 'entity/eventDefinition'
import { getUserById } from 'entity/users'
import { useSelector } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'

const MAIN_ICON_SIZE = 56

const Wrapper = styled.div`
  cursor: pointer;
  margin: 8px 0;
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;

  grid-template-columns: 48px ${MAIN_ICON_SIZE}px 1fr;
  ${(props) => props.theme.breakpoints.XXS} {
    grid-template-columns: 38px ${MAIN_ICON_SIZE}px 1fr;
  }
`

const TimeHeader = styled.div`
  line-height: 0.9rem;
  display: grid;
  place-items: center;
  text-align: center;
  background: repeating-linear-gradient(to bottom, transparent 0 3px, #d5d5d5 3px 4px) 50%/2px 100%
    no-repeat;
  height: 115%;

  font-size: 12px;
  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 10px;
  }
`

const TimeBg = styled.div<{ type: string }>`
  background-color: ${(props) => props.theme?.colors?.primaryBackground};
  color: ${(props) =>
    props.type === 'overdue' ? props.theme?.colors?.red : props.theme?.colors?.text};
  width: 100%;
`

const DateElement = styled.span`
  font-weight: bold;
`

const Time = styled.span``

const MainIcon = styled.div<{
  type: string
}>`
  position: relative;
  background-color: ${(props) => props.theme?.events?.[props.type]?.color || 'gray'};
  width: ${MAIN_ICON_SIZE}px;
  height: ${MAIN_ICON_SIZE}px;
  flex: 0 1 ${MAIN_ICON_SIZE}px;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0px 0px 10px #00000066;
  display: grid;
  place-items: center;
  border: ${(props) => props.theme?.events?.[props.type]?.colorDarker || 'darkgray'} 4px solid;
`

const Icon = styled(SvgIcon)`
  width: 21px;
  height: 21px;
  fill: ${(props) => props.theme?.colors?.white};
  color: ${(props) => props.theme?.colors?.white};
`

const Content = styled.div<{ showPetName: boolean }>`
  background-color: ${(props) => props.theme?.colors?.white};
  margin-left: ${-MAIN_ICON_SIZE / 2}px;
  padding-left: ${MAIN_ICON_SIZE / 2 + 8}px;
  padding-right: 6px;
  margin-right: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #0000000f;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 5px;
  align-items: center;
  overflow: hidden;

  height: 60px;
  font-size: 17px;
  line-height: 1.1rem;

  ${(props) => props.theme.breakpoints.XS} {
    font-size: 16px;
  }

  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 14px;
  }

  ${(props) => props.showPetName && css``}
`

const TextContent = styled.div`
  ${ellipsis};
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Fulfilled = styled(CheckSvg)`
  width: 26px;
  height: 26px;
  padding: 6px;
  fill: ${(props) => props.theme?.colors?.green || 'green'};
`

const NotFulfilled = styled(NoSvg)`
  width: 26px;
  height: 26px;
  padding: 6px;
  fill: ${(props) => props.theme?.colors?.red || 'red'};
`

const FirstLineContent = styled.div`
  ${ellipsis}
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
`

const EventName = styled.span`
  ${capitalizeFirstWord};
  padding-top: 2px;
`

const EventInfo = styled.span`
  ${ellipsis}
  flex-shrink: 0;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 85%;
  font-weight: 600;
  opacity: 0.7;
  margin-right: 5px;

  svg {
    height: 11px;
    fill: ${(props) => props.theme?.colors?.text};
    color: ${(props) => props.theme?.colors?.text};
  }
`

const SecondLineContent = styled.div`
  display: flex;
  align-items: center;
  overflow: none;
`

const Description = styled.div`
  ${ellipsis}
  min-width: 0;
  font-size: 85%;
  opacity: 0.7;
  padding-right: 3px;
  margin-right: 5px;
`

const UserName = styled.span<{ color: string }>`
  background-color: ${(props) => props.color || props.theme?.colors?.lighterGrey};
  color: ${(props) => props.theme?.colors?.white};
  border-radius: 7px;
  padding: 2px 6px;
  font-size: 85%;
  font-weight: 600;
`

const PetName = styled.div`
  font-weight: 500;
  font-size: 85%;
  opacity: 0.7;
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export type TimelineItemProps = {
  event: Event
  showPetName?: boolean
  onIsInViewChange?: (isVisible: boolean) => void
}

const TimelineItem: React.FC<TimelineItemProps> = (props) => {
  const { t, lang } = useTranslation('common')

  const { event, showPetName } = props
  const { timelineAt, code, id } = event

  const definition = useSelector(getEventDefinition(code))
  const parsedDate = new Date(timelineAt)
  const hasTime = definition?.items?.find(
    (item) => item?.code === 'date' && item?.type === 'datetime'
  )
    ? true
    : false

  // TODO: fulfill should have 3 states ( yes / no / undefined )
  const pet = useSelector(getPet(event.petId))
  const description = (event?.items?.note || '') as string
  const assignedUserId = event?.assignedUserId
  const assignedUser = useSelector(getUserById(assignedUserId))
  const loggedUser = useSelector(getUser)
  const assignedToMe = loggedUser?.id === assignedUserId
  const eventType = getEventType(event)
  const activePet = useSelector(getActivePet)

  //
  // SUB-RENDERS
  //
  const renderEventInfo = () => {
    if (event.code === 'height' && event.items?.height) {
      return <EventInfo>{`${event.items?.height}cm`}</EventInfo>
    }
    if (event.code === 'weight' && event.items?.weight) {
      return <EventInfo>{`${event.items?.weight}kg`}</EventInfo>
    }
    if (event.code === 'vaccination' && event.items?.type?.name) {
      return <EventInfo>{`${event.items?.type?.name}`}</EventInfo>
    }
    if (event.code === 'heating' && event.items?.till) {
      return (
        <EventInfo>{`${t('DATE_TILL').toLowerCase()} ${getLocalizedDateString(
          event.items?.till,
          lang,
          {
            format: 'dd-mm-year',
          }
        )}`}</EventInfo>
      )
    }
    if (event.code === 'allergy' && event.items?.till) {
      return (
        <EventInfo>{`${event.items.allergyType?.name ? `${event.items.allergyType?.name} ` : ''}${t(
          'DATE_TILL'
        ).toLowerCase()} ${getLocalizedDateString(event.items?.till, lang, {
          format: 'dd-mm-year',
        })}`}</EventInfo>
      )
    }
    if (event.code === 'antiparasitics' && event.items?.type?.name) {
      return <EventInfo>{`${event.items?.type?.name}`}</EventInfo>
    }
    if (['photo', 'task'].includes(event.code) && event.items?.photos) {
      return (
        <EventInfo>
          {`${event.items?.photos.length}x`}
          <SvgIcon code="icon-placeholder-image" />
        </EventInfo>
      )
    }
    if (event.code === 'vet' && event.items?.vet?.title) {
      return <EventInfo>{`${event.items?.vet?.title}`}</EventInfo>
    }
    if (['purchase', 'deworming'].includes(event.code) && event.items?.price) {
      return (
        <EventInfo>{`${event.items?.price.amount} ${event.items?.price.currencyCode}`}</EventInfo>
      )
    }

    return null
  }

  //
  // RENDER
  //
  return (
    <InView onChange={props.onIsInViewChange}>
      <Link
        href={{ pathname: `/event/${id}`, query: { fromPet: activePet } }}
        passHref
        prefetch={false}
        shallow={true}
      >
        <Wrapper>
          <TimeHeader>
            <TimeBg type={eventType}>
              <DateElement>{getFormattedDate(parsedDate, { today: t('TODAY') })}</DateElement>
              {hasTime && (
                <>
                  <br />
                  <Time>{getFormattedTime(parsedDate)}</Time>
                </>
              )}
            </TimeBg>
          </TimeHeader>

          <MainIcon type={eventType}>
            <Icon code={getEventIcon(event, definition)} />
          </MainIcon>

          <Content showPetName={showPetName}>
            <TextContent>
              <FirstLineContent>
                <EventName>{getEventName(event, definition)}</EventName>

                {showPetName && (
                  <PetName>
                    <span>{`(${event.petName || pet?.name || '~?~'})`}</span>
                  </PetName>
                )}
              </FirstLineContent>

              <SecondLineContent>
                <InfoRow>
                  {renderEventInfo()}
                  {description && <Description>{description}</Description>}
                </InfoRow>
                {assignedUser && (
                  <UserName color={assignedToMe ? mainTheme.colors.orange : undefined}>
                    {assignedToMe
                      ? t('ME')
                      : assignedUser?.name || nameFromEmail(assignedUser?.email)}
                  </UserName>
                )}
              </SecondLineContent>
            </TextContent>

            {event?.isCompleted !== null && (event?.completedAt ? <Fulfilled /> : <NotFulfilled />)}
          </Content>
        </Wrapper>
      </Link>
    </InView>
  )
}

export default TimelineItem
