import { capitalizeFirstWord, clickable, styled } from 'styles/themes'
import { getActivePet, getFooterEventParams } from 'store/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { Pet } from 'apiLegacy'
import PopUpPetSelection from 'components/modals/PopUpPetSelection'
import SvgIcon from 'components/svgIcon'
import { encodeUrlParams } from 'utils'
import { setActivePet } from 'store/actions'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div``
const MenuItem = styled.div`
  height: 60px;
  display: grid;
  grid-template-columns: 60px 1fr;
  border-bottom: #e6e6e6 2px solid;
  ${clickable};

  ${(props) => props.theme.breakpoints.XXS} {
    grid-template-columns: 40px 1fr;
    height: 50px;
  }
`
const Icon = styled(SvgIcon)`
  place-self: center;
  color: ${(props) => props.theme?.colors?.primary};
  fill: ${(props) => props.theme?.colors?.primary};
  width: 35px;
  height: 35px;

  ${(props) => props.theme.breakpoints.XXS} {
    width: 25px;
    height: 25px;
  }
`
const Text = styled.div`
  align-self: center;
  ${capitalizeFirstWord};
`

const FooterDetailItems = ({ items = [], singlePetRequired = false }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const activePetId = useSelector(getActivePet)
  const params = useSelector(getFooterEventParams)

  const [itemClicked, setItemCodeClicked] = useState<{ code: string; name: string }>()
  const [showPetSelection, setShowPetSelection] = useState<boolean>(false)

  const [fromPet, setFromPet] = useState<string>()

  // 1 - Mark item as clicked > triggers pet selection if necessary
  const onItemClick = (item) => {
    if (singlePetRequired && activePetId === 'all') {
      setShowPetSelection(true)
    } else {
      setFromPet(activePetId)
    }
    setItemCodeClicked(item)
  }

  // 2 - Pet is selected > triggers useEffect below
  const onPetClick = (pet: Pet) => {
    if (pet) {
      setFromPet(activePetId)
      dispatch(setActivePet(pet.id))
      setShowPetSelection(false)
    }
  }

  // 3 - React to item/pet clicks
  useEffect(() => {
    if (fromPet) {
      if (itemClicked && (!singlePetRequired || (activePetId && activePetId !== 'all'))) {
        router.push({
          pathname: `/event/new/${itemClicked.code}`,
          query: encodeUrlParams({ petId: activePetId, fromPet, ...params }),
        })
      }
      setFromPet(undefined)
    }
  }, [itemClicked, fromPet, activePetId])

  return (
    <Wrapper>
      {items?.map((item) => {
        return (
          <MenuItem key={item.code} onClick={() => onItemClick(item)}>
            <Icon code={item.icon} />
            <Text>{item.name}</Text>
          </MenuItem>
        )
      })}

      {singlePetRequired && (
        <PopUpPetSelection
          body={{
            title: t('PET'),
            description: t('SELECT_PET_FOR_CREATE_EVENT').replace(
              '{{ event }}',
              `"${itemClicked?.name}"`
            ),
          }}
          isOpen={showPetSelection}
          onClose={() => {
            setItemCodeClicked(undefined)
            setShowPetSelection(false)
          }}
          onPetClick={onPetClick}
        />
      )}
    </Wrapper>
  )
}
export default FooterDetailItems
