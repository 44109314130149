import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import ArrowIcon from 'components/svg/arrow.svg'
import Link from 'next/link'
import PopUp from 'components/modals/PopUp'
import { changeWebviewLanguage } from 'utils/app/webviewControl'
import { getUserSettings } from 'store/selectors'
import i18nConfig from 'i18n.js'
import { updateUserSettings } from 'store/actions'
import useTranslation from 'next-translate/useTranslation'

type ContextType = 'on-page' | 'in-menu'

const Wrapper = styled.div<{ context?: ContextType }>`
  position: absolute;
  z-index: 999;

  ${(props) =>
    props.context === 'in-menu'
      ? css`
          top: 0px;
          right: 15px;
        `
      : css`
          top: 16px;
          right: 16px;
        `}
`

const Button = styled.button<{ context?: ContextType }>`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-items: center;
  border-radius: 4px;
  padding: 4px 10px;
  outline: none;
  &:focus {
    box-shadow: ${(props) => ` ${props.theme?.colors?.primary} 0px 0px 0px 2px`};
  }

  color: ${(props) => props.theme?.colors?.text || '#000'};
  text-transform: uppercase;

  ${(props) =>
    props.context === 'in-menu' &&
    css`
      color: ${(props) => props.theme?.colors?.white || '#000'};
    `}
`

const Arrow = styled.div`
  margin-left: 8px;
  & > svg {
    height: 10px;
    transform: rotate(90deg);
    fill: ${(props) => props.theme?.colors?.primary};
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: column;

  button {
    outline: none;
    border: none;
    text-align: left;
    background: none;
    font-size: inherit;
    font-weight: inherit;
  }
`

type LanguageSwitchButtonProps = {
  active: boolean
}

const LangItem = styled.div<LanguageSwitchButtonProps>`
  padding: 12px 0;
  margin: 0 20px;
  border-bottom: #e6e6e6 1px solid;
  color: ${(props) => (props.active ? props.theme?.colors?.primary : props.theme?.colors?.text)};
  font-weight: ${(props) => (props.active ? 700 : 400)};
  &:last-of-type {
    border: none;
  }
`

const { locales } = i18nConfig

type LanguageSwitcherProps = {
  href: string
  context?: ContextType
}

const LanguageSwitcher = ({ href, context = 'on-page' }: LanguageSwitcherProps) => {
  const { lang, t } = useTranslation('common')
  const dispatch = useDispatch()

  const [popupOpen, setPopupOpen] = useState(false)

  const userSettings = useSelector(getUserSettings)

  const handleUserLanguageChange = (language) => {
    dispatch(updateUserSettings({ language }))
    changeWebviewLanguage(language)
  }

  const renderLangItem = (locale) => {
    return <LangItem active={locale === lang}>{t(`locales.${locale}`)}</LangItem>
  }

  return (
    <Wrapper context={context}>
      <Button context={context} type="button" onClick={() => setPopupOpen(true)}>
        <div>{lang}</div>
        <Arrow>
          <ArrowIcon height="25" />
        </Arrow>
      </Button>
      <PopUp
        isOpen={popupOpen}
        close={() => setPopupOpen(false)}
        header={{ title: t('CHANGE_LANGUAGE') }}
        closeOnOverlayClick={true}
        index={-1000} /* to display over opened menu */
      >
        <Links onClick={() => setPopupOpen(false)}>
          {locales.map((locale) =>
            userSettings?.language ? (
              <button key={locale} onClick={() => handleUserLanguageChange(locale)}>
                {renderLangItem(locale)}
              </button>
            ) : (
              <Link prefetch={false} key={locale} href={href} passHref locale={locale}>
                {renderLangItem(locale)}
              </Link>
            )
          )}
        </Links>
      </PopUp>
    </Wrapper>
  )
}

export default LanguageSwitcher
