import InfoIcon from 'components/svg/info.svg'
import { Pet } from 'apiLegacy'
import PetCard from 'components/PetCard'
import PopUp from 'components/modals/PopUp'
import { getActivePets } from 'store/selectors'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'

const Content = styled.div`
  --padding: 17px;
  ${(props) => props.theme.breakpoints.XXS} {
    --padding: 14px;
  }
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: calc(-1 * var(--padding));
  padding-right: var(--padding);
  min-height: 50px;
`

const Description = styled.div`
  margin-bottom: 10px;
`

const Pets = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    cursor: pointer;
    padding: 8px 0px;
    padding-left: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.black}11;
    &:last-child {
      border: none;
    }
  }
`

const PopUpPetSelection = (props: {
  isOpen: boolean
  body: {
    title: string
    description?: string
  }
  onClose: () => void
  onPetClick: (pet: Pet) => void
}) => {
  const { t } = useTranslation('common')

  const pets = useSelector(getActivePets)

  return (
    <PopUp
      isOpen={props.isOpen}
      close={props.onClose}
      header={{
        icon: <InfoIcon />,
        title: props.body.title,
        closeTitle: t('push_notification.CLOSE'),
      }}
      closeOnOverlayClick
    >
      <Content>
        {!!props.body.description && <Description>{props.body.description}</Description>}

        <Pets>
          {pets.map((pet) => (
            <PetCard key={pet.id} pet={pet} onClick={() => props.onPetClick(pet)} noRedirect />
          ))}
        </Pets>
      </Content>
    </PopUp>
  )
}

export default PopUpPetSelection
