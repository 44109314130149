import { isInTutorialsSeenInLocalStorage, pushTutorialSeenToLocalStorage } from 'utils'

import { RootState } from 'store/reducers'

export const TUTORIAL_IDS = {
  CREATE_NEW_EVENT: 'CREATE_NEW_EVENT',
  PLAN_NEW_EVENT: 'PLAN_NEW_EVENT',
  FILL_REQUIRED_EVENT_FIELDS: 'FILL_REQUIRED_EVENT_FIELDS',
  INVITE_USER_TO_GROUP: 'INVITE_USER_TO_GROUP',
  COMPLETE_EVENT: 'COMPLETE_EVENT',
  CREATE_AGAIN_EVENT: 'CREATE_AGAIN_EVENT',
  ADD_PET: 'ADD_PET',
  SAVE_PET_NEW: 'SAVE_PET_NEW',
  SAVE_PET_EDIT: 'SAVE_PET_EDIT',
  SAVE_USER_EDIT: 'SAVE_USER_EDIT',
  USERS_TABS: 'USERS_TABS',
  HEADER_INFO: 'HEADER_INFO',
  HEADER_ICON_ERROR: 'HEADER_ICON_ERROR',
  HEADER_ICON_EVENTS_OVERDUE: 'HEADER_ICON_EVENTS_OVERDUE',
  HEADER_ICON_PUSH_NOTIFICATIONS: 'HEADER_ICON_PUSH_NOTIFICATIONS',
  PET_HEADER_ARROWS: 'PET_HEADER_ARROWS',
  PET_HEADER_FILTERS_GRAPHS: 'PET_HEADER_FILTERS_GRAPHS',
  PET_HEADER_FILTERS_EVENTS: 'PET_HEADER_FILTERS_EVENTS',
  SAVE_PET_DOCUMENT_NEW: 'SAVE_PET_DOCUMENT_NEW',
  SAVE_PET_DOCUMENT_EDIT: 'SAVE_PET_DOCUMENT_EDIT',
  PET_PROFILE_TABS: 'PET_PROFILE_TABS',
  GRAPHS_CURRENCY_SELECTION: 'GRAPHS_CURRENCY_SELECTION',
  ADD_PET_SHARE_LINK: 'ADD_PET_SHARE_LINK',
  FEEDBACK_SEND: 'FEEDBACK_SEND',
  FEEDBACK_DESCRIPTION: 'FEEDBACK_DESCRIPTION',
}

export const TUTORIALS_PET_HEADER_CONTENT = [
  TUTORIAL_IDS.PET_HEADER_FILTERS_GRAPHS,
  TUTORIAL_IDS.PET_HEADER_FILTERS_EVENTS,
]

export const TUTORIALS_PET_HEADER = [
  TUTORIAL_IDS.PET_HEADER_ARROWS,
  ...TUTORIALS_PET_HEADER_CONTENT,
]

//
// STATE
//
type TutorialsStateType = {
  tutorialsToShow: string[]
  isAllowedByUser: boolean // comes from user settings (on/off)
  isAllowedByApp: boolean // app shouldn't use tutorials in pet after registration, for example
}

const initialState: TutorialsStateType = {
  tutorialsToShow: [],
  isAllowedByUser: true,
  isAllowedByApp: true, // TODO - currently unused;; for app logic (turn off by default and turn on only after registration and new pet, for example)
}

//
// ACTION - RESET TUTORIALS
//
const RESET_TUTORIALS = 'RESET_TUTORIALS'

type ResetTutorialsAction = {
  type: typeof RESET_TUTORIALS
}

export const resetTutorials = (): ResetTutorialsAction => {
  return {
    type: RESET_TUTORIALS,
  }
}

const ResetTutorialsReducer = (state) => {
  return { ...state, tutorialsToShow: [] } as TutorialsStateType
}

//
// ACTION - ADD TUTORIAL TO SHOW
//
const ADD_TUTORIAL_TO_SHOW = 'ADD_TUTORIAL_TO_SHOW'

type AddTutorialToShowAction = {
  type: typeof ADD_TUTORIAL_TO_SHOW
  payload: string
}

export const addTutorialToShow = (code): AddTutorialToShowAction => {
  return {
    type: ADD_TUTORIAL_TO_SHOW,
    payload: code,
  }
}

const AddTutorialToShowReducer = (state: TutorialsStateType, action: AddTutorialToShowAction) => {
  const tutorialId = action.payload
  const tutorialsToShow = state.tutorialsToShow

  if (
    tutorialId &&
    !isInTutorialsSeenInLocalStorage(tutorialId) &&
    getAllowTutorials({ tutorials: state } as RootState) &&
    !tutorialsToShow?.includes(tutorialId)
  ) {
    return { ...state, tutorialsToShow: [...tutorialsToShow, tutorialId] } as TutorialsStateType
  }
  return state
}

//
// ACTION - SET TUTORIAL AS SEEN
//
const SET_TUTORIAL_AS_SEEN = 'SET_TUTORIAL_AS_SEEN'

type SetTutorialAsSeenAction = {
  type: typeof SET_TUTORIAL_AS_SEEN
  payload: string
}

export const setTutorialAsSeen = (code): SetTutorialAsSeenAction => {
  return {
    type: SET_TUTORIAL_AS_SEEN,
    payload: code,
  }
}

const SetTutorialAsSeenReducer = (state: TutorialsStateType, action: SetTutorialAsSeenAction) => {
  const tutorialId = action.payload
  const tutorialsToShow = state.tutorialsToShow

  if (tutorialId && tutorialsToShow.includes(tutorialId)) {
    pushTutorialSeenToLocalStorage(tutorialId)
    return {
      ...state,
      tutorialsToShow: [...tutorialsToShow].filter((id) => id !== tutorialId),
    } as TutorialsStateType
  }
  return state
}

//
// ACTION - SET ALLOWED BY USER
//
const SET_ALLOWED_BY_USER = 'SET_ALLOWED_BY_USER'

type SetTutorialsAllowedByUserAction = {
  type: typeof SET_ALLOWED_BY_USER
  payload: boolean
}

export const setTutorialsAllowedByUser = (isAllowed): SetTutorialsAllowedByUserAction => {
  return {
    type: SET_ALLOWED_BY_USER,
    payload: isAllowed,
  }
}

const SetTutorialsAllowedByUserReducer = (
  state: TutorialsStateType,
  action: SetTutorialsAllowedByUserAction
) => {
  return {
    ...state,
    isAllowedByUser: action.payload,
  } as TutorialsStateType
}

//
// REDUCER
//
export function tutorialsReducer(
  state = initialState,
  action:
    | AddTutorialToShowAction
    | SetTutorialAsSeenAction
    | SetTutorialsAllowedByUserAction
    | ResetTutorialsAction
) {
  switch (action.type) {
    case RESET_TUTORIALS:
      return ResetTutorialsReducer(state)
    case ADD_TUTORIAL_TO_SHOW:
      return AddTutorialToShowReducer(state, action)
    case SET_TUTORIAL_AS_SEEN:
      return SetTutorialAsSeenReducer(state, action)
    case SET_ALLOWED_BY_USER:
      return SetTutorialsAllowedByUserReducer(state, action)
    default:
      return state
  }
}

//
// SELECTORS
//
export const getTutorialsToShow = (state: RootState) => state.tutorials?.tutorialsToShow as string[]

export const isTutorialToShow = (tutorialId: string) => (state: RootState) => {
  const areTutorialsAllowed = getAllowTutorials(state)

  const firstInQueue = (state.tutorials?.tutorialsToShow || [])[0]
  return {
    show: areTutorialsAllowed && firstInQueue ? tutorialId === firstInQueue : false,
    id: firstInQueue, // if only boolean is returned, useSelector won't update if 'true' comes after another 'true'
  }
}

export const getAllowTutorials = (state: RootState) =>
  state.tutorials?.isAllowedByUser && (state.tutorials?.isAllowedByApp as boolean)
