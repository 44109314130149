import { RootState } from 'store/reducers'
import { getPets } from 'store/selectors'

export const isLoading = (state: RootState) => state.app.isLoading
export const getActivePet = (state: RootState) => {
  const pets = getPets(state)
  if (pets.length > 1) {
    return state.app.activePet
  }

  if (pets.length === 0) return 'all'

  return pets[0].id
}
export const getActivePetState = (state: RootState) =>
  // @ts-ignore
  state.app.activePet ? state.pets.pets.entities[state.app.activePet]?.state : undefined
export const getCalendarType = (state: RootState) => state.app.calendarType
export const getCalendarDate = (state: RootState) => state.app.calendarDate
export const getPreviousActivePet = (state: RootState) => state.app.previousActivePet
export const isAppInitialized = (state: RootState) => state.app.appInitialized
export const getFooterEventParams = (state: RootState) => state.app.footerEventParams
export const getTimezones = (state: RootState) => state.app.timezones
export const isPetHeaderOpen = (state: RootState) => state.app.petHeaderOpen
export const getUserSettings = (state: RootState) => state.app.userSettings
export const getIsRefreshingToken = (state: RootState) => state.app.refreshingToken
