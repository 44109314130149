import { call, put } from 'redux-saga/effects'

import { getOverdueCount as getOverdueCountService } from 'services'
import { storeError } from './errors'

/* CONST */
export const LOAD_OVERDUE_COUNT_REQUEST = 'LOAD_OVERDUE_COUNT_REQUEST'
export const LOAD_OVERDUE_COUNT_SUCCEED = 'LOAD_OVERDUE_COUNT_SUCCEED'
export const LOAD_OVERDUE_COUNT_FAILED = 'LOAD_OVERDUE_COUNT_FAILED'
export const STORE_OVERDUE_COUNT = 'STORE_OVERDUE_COUNT'

/* DATA TYPES */

/* ACTION */
export interface OverdueCountProps {
  petId?: string
}
export interface OverdueCountActionType {
  type: typeof LOAD_OVERDUE_COUNT_REQUEST
}
export const loadOverdueCount = (): OverdueCountActionType => ({
  type: LOAD_OVERDUE_COUNT_REQUEST,
})

export interface OverdueCountSucceedType {
  type: typeof LOAD_OVERDUE_COUNT_SUCCEED
  payload: any
}

export const loadOverdueCountSucceed = (payload: any): OverdueCountSucceedType => ({
  type: LOAD_OVERDUE_COUNT_SUCCEED,
  payload,
})

type LoadOverdueCountFailedType = {
  type: typeof LOAD_OVERDUE_COUNT_FAILED
  payload: any
}

export const loadOverdueCountFailed = (payload): LoadOverdueCountFailedType => ({
  type: LOAD_OVERDUE_COUNT_FAILED,
  payload,
})

type OverdueCountTypes =
  | OverdueCountActionType
  | OverdueCountSucceedType
  | LoadOverdueCountFailedType

export interface storeOverdueCountProps {
  count: number
}
export interface storeOverdueActionType {
  type: typeof STORE_OVERDUE_COUNT
  payload: storeOverdueCountProps
}
export const storeOverdueCount = (payload: storeOverdueCountProps): storeOverdueActionType => ({
  type: STORE_OVERDUE_COUNT,
  payload,
})

/* STORE */
export type overdueCountStoreType = {
  overdueCount: number
}
export const overdueCountStoreDefault: overdueCountStoreType = {
  overdueCount: 0,
}

/* REDUCER */
export const overdureCountReducer = (state, action: storeOverdueActionType) => {
  return { ...state, overdueCount: action.payload.count }
}

/* SAGA */
export function* overdueCountSaga(action): Generator {
  try {
    const response: any = yield call(getOverdueCountService)
    const data: any = yield response.data
    // TODO: vyhodnotit výsledek ..
    yield put(storeOverdueCount({ count: parseInt(data.count) }))
    yield put(loadOverdueCountSucceed({ count: parseInt(data.count) }))
  } catch (e) {
    yield put(storeError({ error: e, origin: 'overdueCountSaga' }))
    yield put(loadOverdueCountFailed(e))
    console.error(e)
  }
}

/* SELECTOR */
export const getOverdueCount = (state) => state?.events?.overdueCount
