import { Pet } from 'apiLegacy'

export const SAVE_PETS = 'SAVE_PETS'

export type SavePetsType = {
  type: typeof SAVE_PETS
  payload: {
    entities: {
      [x: string]: Pet
    }
    lists: {
      [x: string]: string[]
    }
  }
}
export const savePets = (payload): SavePetsType => ({
  type: SAVE_PETS,
  payload,
})

export const savePetsReducer = (state, action) => {
  return {
    ...state,
    pets: {
      ...state.pets,
      entitiesFetched: true,
      entities: {
        ...state.pets.entities,
        ...action.payload.entities,
      },
      lists: { ...state.pets.lists, ...action.payload.lists },
    },
  }
}

export const savePetReducer = (state, action) => {
  return {
    ...state,
    pets: {
      ...state.pets,
      entities: {
        ...state.pets.entities,
        [action.payload.id]: action.payload,
      },
    },
  }
}
