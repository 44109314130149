import { VetApi, VetInfo } from 'api/api'

import { createApiInstance } from 'utils'

const apiInstance = () => createApiInstance(VetApi)

export const fetchPinnedVets = () => {
  return apiInstance().findPinnedVets()
}

export const pinVet = (props: { id: string; body: VetInfo }) => {
  return apiInstance().pinVet(props.id, props.body)
}

export const unpinVet = (props: { id: string }) => {
  return apiInstance().unpinVet(props.id)
}
