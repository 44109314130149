import { isEmailString } from 'utils/types'

export const validateEmail: (
  email: string,
  options?: { mode?: 'default' | 'group_invite' }
) => { error?: string } = (email, options = { mode: 'default' }) => {
  const MISSING_EMAIL =
    options?.mode === 'group_invite'
      ? 'validation.group_invite.EMPTY_EMAIL'
      : 'validation.email.REQUIRED'
  const INVALID_EMAIL =
    options?.mode === 'group_invite'
      ? 'validation.group_invite.INVALID_EMAIL'
      : 'validation.email.VALID'

  if (!email || email === '') {
    return { error: MISSING_EMAIL }
  }
  if (!isEmailString(email)) {
    return { error: INVALID_EMAIL }
  }
  return { error: undefined }
}

export const validatePassword: (
  password: string,
  passwordConfirm: string
) => { error?: string; confirmError?: string } = (password, passwordConfirm = undefined) => {
  const MISSING_PASS = 'validation.password.REQUIRED'
  const SHORT_PASS = 'validation.password.LENGTH'
  const CONFIRM_REQUIRED = 'validation.password.CONFIRM'
  const INVALID_CONFIRM = 'validation.password.CONFIRMED'

  if (!password || password === '') {
    return { error: MISSING_PASS }
  }
  if (password.length < 8) {
    return { error: SHORT_PASS }
  }
  if (passwordConfirm === '') {
    return { confirmError: CONFIRM_REQUIRED }
  }
  if (password !== passwordConfirm) {
    return { confirmError: INVALID_CONFIRM }
  }
  return { error: undefined }
}
