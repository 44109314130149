export const isValidNumber = (n: number) => typeof n === 'number' && !isNaN(n)

export const getRandomInt = (max = 1) => {
  return Math.floor(Math.random() * max)
}

export const getNearestMultiple = (i, j) => {
  return Math.round(i / j) * j
}

export const round = (num: number, decimalPlaces?: number) => {
  const d = decimalPlaces || 2

  const r = parseInt(
    // eslint-disable-next-line prefer-spread
    `1${Array.apply(null, Array(d))
      .map(() => '0')
      .join('')}`
  )

  const result = Math.round(num * r) / r

  return result
}

export const leadingZeros = (num, padToLength) => {
  num = num.toString()
  while (num.length < padToLength) num = '0' + num
  return num
}
