import { call, put } from 'redux-saga/effects'

import { Dictionary } from '../types'
import Router from 'next/router'
import { encodeUrlParams } from 'utils'
import { getFollowup } from 'services'
import { storeError } from './errors'

/* CONST */
export const FETCH_FOLLOWUP = 'FETCH_FOLLOWUP'

/* DATA TYPES */
// export type { UserInfo } from 'api'

/* ACTION */
export interface LoadFollowupActionProps {
  eventId: string
}
export interface LoadFollowupActionType {
  type: typeof FETCH_FOLLOWUP
  payload: LoadFollowupActionProps
}
export const loadFollowup = (payload: LoadFollowupActionProps): LoadFollowupActionType => ({
  type: FETCH_FOLLOWUP,
  payload,
})

/* REDUCER */

/* SAGA */

export function* loadFollowupSaga(action: LoadFollowupActionType): Generator {
  const payload = action.payload
  try {
    const { eventId } = payload
    const eventResponse: any = yield call(getFollowup, eventId)
    const data = (yield eventResponse.data) as Dictionary<any>
    const { code, ...rest } = data
    yield call(Router.push, `/event/new/${code}?` + encodeUrlParams(rest), undefined)
  } catch (e) {
    yield put(storeError({ error: e, origin: 'loadFollowupSaga' }))
    console.error(e)
  }
}

/* SELECTOR */
