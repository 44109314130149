import { TrackUserRequest, UsertrackerApi } from '../apiLegacy/api'

import { createLegacyApiInstance } from 'utils'

const UsertrackerApiInstance = () => createLegacyApiInstance(UsertrackerApi)

//
// EVENT NAMES
//
export const EVENT_REGISTRATION_STARTED = 'Registration Started'
export const EVENT_NAVIGATION = 'Screen Changed'
export const EVENT_PUSH_NOTIFICATION_CLOSED = 'Push Notification Pop-Up Closed'

//
// TRACKING - GENERAL
//
export const trackUser = (payload: TrackUserRequest) => {
  //console.log('TRACKING', payload)
  return UsertrackerApiInstance().track(payload)
}

export interface TrackParams {
  [key: string]: string
}

//
// TRACKING - NAVIGATION
//
interface RouteNames {
  [key: string]: string
}

// These routes will be tracked:
export const ROUTE_NAMES: RouteNames = {
  '/': 'Timeline',
  '/calendar': 'Calendar',
  '/feedback': 'Feedback',
  '/graphs': 'Dashboard',
  '/history': 'History',
  '/history/[eventId]': 'History Event Detail',
  '/intro': 'Intro',
  '/login': 'Login',
  '/overdue': 'Overdue',
  '/pets': 'Pets',
  '/profile': 'Profile',
  '/register': 'Register',
  '/settings': 'Settings',
  '/switcher': 'Switcher',
  '/users': 'Users',
  '/users?tab=my-group': 'Users',
  '/users?tab=groups': 'Users',
  '/pet': 'Create Pet',
  '/pet/[petId]': 'Pet Detail',
  '/event/[eventId]': 'Event Detail',
  '/event/new/[code]': 'Create Event',
  '/shared/list': 'List of Shared Links',
  '/shared/[token]': 'Shared Link',
  '/document': 'Create Document',
  '/document/[documentId]': 'Document Detail',
}

export const ROUTE_NAME_GRAPH = 'Graph'

export const getScreenNameFromRoute = (route: string) => {
  const toScreenName = ROUTE_NAMES[route]
  return toScreenName ? toScreenName : !route.startsWith('/') ? route : undefined
}

export const trackNavigation = (props: {
  userId: any
  route: string
  params?: TrackParams
  routeFrom: string
  paramsFrom?: TrackParams
}) => {
  const fromScreen = ROUTE_NAMES[props.routeFrom] || props.routeFrom || ''
  const fromParams = {}
  if (props.paramsFrom) {
    Object.entries(props.paramsFrom).forEach(([key, value]) => (fromParams[`From ${key}`] = value))
  }

  const toScreen = getScreenNameFromRoute(props.route)

  if (toScreen) {
    trackUser({
      user_id: props.userId,
      event_name: EVENT_NAVIGATION,
      properties: {
        'From Screen': fromScreen,
        'To Screen': toScreen,
        ...(props.params || {}),
        ...fromParams,
      },
    })
  }
}

//
// TRACKING - PUSH NOTIFICATIONS
//
export type TrackClosePushNotificationType = 'close' | 'minimize' | 'redirect'

export const trackPushNotificationClose = (props: {
  userId: any
  type: TrackClosePushNotificationType
  params?: TrackParams
}) => {
  const typeNames = {
    close: 'Close',
    minimize: 'Minimize',
    redirect: 'Redirect',
  }

  trackUser({
    user_id: props.userId,
    event_name: EVENT_PUSH_NOTIFICATION_CLOSED,
    properties: {
      Type: typeNames[props.type],
      ...(props.params || {}),
    },
  })
}
