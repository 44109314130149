import { cancelInvite, removeUserFromGroup } from 'entity/group'

import PopUp from 'components/modals/PopUp'
import RoundAvatarUser from 'components/roundAvatarUser'
import SvgIcon from 'components/svgIcon'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div<{ withImage?: boolean }>`
  margin: 15px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  padding-left: ${(props) => (props.withImage ? '54px' : '20px')};
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 6px ${(props) => props.theme.colors.black}44;
  font-weight: 700;
  position: relative;
  border-radius: 6px;

  ${(props) => props.theme.breakpoints.XXS} {
    padding-left: ${(props) => (props.withImage ? '44px' : '15px')};
  }
`

const Avatar = styled.div`
  position: absolute;
  left: -5px;
  top: 17px;
  transform: translateY(-50%);

  & > * {
    --size: 50px;
    ${(props) => props.theme.breakpoints.XXS} {
      --size: 40px;
    }
  }
`

const StatusWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  padding-right: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000;

  svg {
    color: ${(props) => props.theme.colors.primary};
    fill: ${(props) => props.theme.colors.primary};
    width: 16px;
    cursor: pointer;
  }
`

const TruncatedText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding-right: 5px;
  min-width: 120px;
`

const Status = styled.div<{ succeed?: boolean }>`
  font-size: 14px;
  font-weight: 300;
  color: ${(props) => (props.succeed ? props.theme.colors.primary : 'grey')};
  margin-right: 10px;
`

type MemberRole = 'owner' | 'member' | 'invitee'

const UserGroupMemberCard = (props: {
  member: any
  memberRole?: MemberRole
  userGroupId: string
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const role: MemberRole = props.memberRole || 'owner'
  const withImage = role !== 'invitee'
  const name = props.member.name || props.member.email

  const [isConfirmPopUpOpen, setIsConfirmPopUpOpen] = useState<boolean>(false)

  const openConfirmPopUp = () => {
    setIsConfirmPopUpOpen(true)
  }
  const closeConfirmPopUp = () => {
    setIsConfirmPopUpOpen(false)
  }

  return (
    <Wrapper withImage={withImage}>
      {withImage && (
        <Avatar>
          <RoundAvatarUser user={props.member} />
        </Avatar>
      )}

      <TruncatedText>{name}</TruncatedText>

      {role !== 'owner' && (
        <StatusWrapper>
          <Status succeed={true}>{t(role === 'member' ? 'ACCEPTED' : 'SENT')}</Status>
          <IconButton onClick={() => openConfirmPopUp()}>
            <SvgIcon code="icon-no" />
          </IconButton>
        </StatusWrapper>
      )}

      {/*
       ** CONFIRMATION POP-UP
       */}
      <PopUp
        isOpen={isConfirmPopUpOpen}
        close={closeConfirmPopUp}
        header={{
          title: t(role === 'member' ? 'REMOVE_GROUP_MEMBER' : 'REMOVE_GROUP_MEMBER_INVITATION'),
          closeTitle: t('CANCEL'),
        }}
        submitButton={{
          onClick: () => {
            setIsConfirmPopUpOpen(false)
            dispatch(
              role === 'member'
                ? removeUserFromGroup({ userId: props.member.id, groupId: props.userGroupId })
                : cancelInvite(props.member.id)
            )
          },
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              role === 'member'
                ? 'REMOVE_GROUP_MEMBER_CONFIRM'
                : 'REMOVE_GROUP_MEMBER_INVITATION_CONFIRM'
            ).replace('{{ member }}', `<strong>${name}</strong>`),
          }}
        />
      </PopUp>
    </Wrapper>
  )
}

export default UserGroupMemberCard
