import { OAuthToken, Timezone, UserSettings, UserSettingsUpdate } from 'apiLegacy'

export const TOGGLE_LOADING_SCREEN = 'TOGGLE_LOADING_SCREEN'
export const SET_ACTIVE_PET = 'SET_ACTIVE_PET'
export const SET_CALENDAR_TYPE = 'SET_CALENDAR_TYPE'
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE'
export const SET_APP_INITIALIZED = 'SET_APP_INITIALIZED'
export const SET_EVENT_FOOTER_PARAMS = 'SET_EVENT_FOOTER_PARAMS'
export const TOGGLE_ACTIVE_TUTORIAL = 'TOGGLE_ACTIVE_TUTORIAL'
export const SET_TUTORIALS = 'SET_TUTORIALS'
export const SET_ACTIVE_TUTORIAL = 'SET_ACTIVE_TUTORIAL'
export const FETCH_TIMEZONES_REQUEST = 'FETCH_TIMEZONES_REQUEST'
export const FETCH_TIMEZONES_FAILED = 'FETCH_TIMEZONES_FAILED'
export const FETCH_TIMEZONES_SUCCEED = 'FETCH_TIMEZONES_SUCCEED'
export const SET_TIMEZONES = 'SET_TIMEZONES'
export const TOGGLE_PET_HEADER = 'TOGGLE_PET_HEADER'
export const SET_PET_HEADER_SELECTED_EVENT_ID = 'TOGGLE_PET_HEADER'
export const GET_USER_SETTINGS_REQUEST = 'GET_USER_SETTINGS_REQUEST'
export const GET_USER_SETTINGS_FAILED = 'GET_USER_SETTINGS_FAILED'
export const GET_USER_SETTINGS_SUCCEED = 'GET_USER_SETTINGS_SUCCEED'
export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST'
export const UPDATE_USER_SETTINGS_FAILED = 'UPDATE_USER_SETTINGS_FAILED'
export const UPDATE_USER_SETTINGS_SUCCEED = 'UPDATE_USER_SETTINGS_SUCCEED'
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCEED = 'REFRESH_TOKEN_SUCCEED'
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED'
export const SET_REFRESHING_TOKEN = 'SET_PENDING_REQUESTS'
export const HANDLE_ERROR = 'HANDLE_ERROR'

type ToggleLoadingScreenType = {
  type: typeof TOGGLE_LOADING_SCREEN
  payload: boolean
}

export const toggleLoadingScreen = (payload): ToggleLoadingScreenType => ({
  type: TOGGLE_LOADING_SCREEN,
  payload,
})

type SetActivePetTypes = {
  type: typeof SET_ACTIVE_PET
  payload: string
}

export const setActivePet = (payload) => ({
  type: SET_ACTIVE_PET,
  payload,
})

type SetCalendarTypes = {
  type: typeof SET_CALENDAR_TYPE
  payload: 'month' | 'week' | 'day'
}

export const setCalendarType = (payload): SetCalendarTypes => ({
  type: SET_CALENDAR_TYPE,
  payload,
})

type SetCalendarDateType = {
  type: typeof SET_CALENDAR_DATE
  payload: Date
}

export const setCalendarDate = (payload): SetCalendarDateType => ({
  type: SET_CALENDAR_DATE,
  payload,
})

type SetAppInitializedType = {
  type: typeof SET_APP_INITIALIZED
  payload: boolean
}

export const setAppInitialized = (payload): SetAppInitializedType => ({
  type: SET_APP_INITIALIZED,
  payload,
})

type SetEventFooterParamsType = {
  type: typeof SET_EVENT_FOOTER_PARAMS
  payload: any
}

export const setEventFooterParams = (payload): SetEventFooterParamsType => ({
  type: SET_EVENT_FOOTER_PARAMS,
  payload,
})

type SetTimezonesType = {
  type: typeof SET_TIMEZONES
  payload: Timezone[]
}

export const setTimezones = (payload): SetTimezonesType => ({
  type: SET_TIMEZONES,
  payload,
})

type FetchTimezonesType = {
  type: typeof FETCH_TIMEZONES_REQUEST
}

export const fetchTimezones = (): FetchTimezonesType => ({
  type: FETCH_TIMEZONES_REQUEST,
})

type FetchTimezonesFailedType = {
  type: typeof FETCH_TIMEZONES_FAILED
  payload: any
}

export const fetchTimezonesFailed = (payload): FetchTimezonesFailedType => ({
  type: FETCH_TIMEZONES_FAILED,
  payload,
})

type FetchTimezonesSucceedType = {
  type: typeof FETCH_TIMEZONES_SUCCEED
}

export const fetchTimezonesSucceed = (): FetchTimezonesSucceedType => ({
  type: FETCH_TIMEZONES_SUCCEED,
})

type TogglePetHeaderType = {
  type: typeof TOGGLE_PET_HEADER
  payload: boolean
}

export const togglePetHeader = (payload): TogglePetHeaderType => ({
  type: TOGGLE_PET_HEADER,
  payload,
})

type GetUserSettingsType = {
  type: typeof GET_USER_SETTINGS_REQUEST
}

export const getUserSettings = (): GetUserSettingsType => ({
  type: GET_USER_SETTINGS_REQUEST,
})

type GetUserSettingsFailedType = {
  type: typeof GET_USER_SETTINGS_FAILED
  payload: any
}

export const getUserSettingsFailed = (payload): GetUserSettingsFailedType => ({
  type: GET_USER_SETTINGS_FAILED,
  payload,
})

type GetUserSettingsSucceedType = {
  type: typeof GET_USER_SETTINGS_SUCCEED
  payload: UserSettings
}

export const getUserSettingsSucceed = (payload): GetUserSettingsSucceedType => ({
  type: GET_USER_SETTINGS_SUCCEED,
  payload,
})

type UpdateUserSettings = {
  type: typeof UPDATE_USER_SETTINGS_REQUEST
  payload: UserSettingsUpdate
}

export const updateUserSettings = (payload): UpdateUserSettings => ({
  type: UPDATE_USER_SETTINGS_REQUEST,
  payload,
})

type UpdateUserSettingsFailed = {
  type: typeof UPDATE_USER_SETTINGS_FAILED
  payload: any
}

export const updateUserSettingsFailed = (payload): UpdateUserSettingsFailed => ({
  type: UPDATE_USER_SETTINGS_FAILED,
  payload,
})

type UpdateUserSettingsSucceed = {
  type: typeof UPDATE_USER_SETTINGS_SUCCEED
  payload: UserSettings
}

export const updateUserSettingsSucceed = (payload): UpdateUserSettingsSucceed => ({
  type: UPDATE_USER_SETTINGS_SUCCEED,
  payload,
})

type RefreshTokenRequestType = {
  type: typeof REFRESH_TOKEN_REQUEST
}

export const refreshTokenRequest = (): RefreshTokenRequestType => ({
  type: REFRESH_TOKEN_REQUEST,
})

type RefreshTokenSucceedType = {
  type: typeof REFRESH_TOKEN_SUCCEED
  payload: OAuthToken
}

export const refreshTokenSucceed = (payload: OAuthToken): RefreshTokenSucceedType => ({
  type: REFRESH_TOKEN_SUCCEED,
  payload,
})

type RefreshTokenFailedType = {
  type: typeof REFRESH_TOKEN_FAILED
  payload: any
}

export const refreshTokenFailed = (payload) => ({
  type: REFRESH_TOKEN_FAILED,
  payload,
})

type SetRefreshingTokenType = {
  type: typeof SET_REFRESHING_TOKEN
  payload: boolean
}

export const setRefreshingToken = (payload: boolean): SetRefreshingTokenType => ({
  type: SET_REFRESHING_TOKEN,
  payload,
})

export type AppActionsTypes =
  | ToggleLoadingScreenType
  | SetActivePetTypes
  | SetCalendarTypes
  | SetAppInitializedType
  | SetEventFooterParamsType
  | TogglePetHeaderType
  | SetTimezonesType
  | SetCalendarDateType
  | GetUserSettingsFailedType
  | GetUserSettingsType
  | GetUserSettingsSucceedType
  | UpdateUserSettings
  | UpdateUserSettingsFailed
  | UpdateUserSettingsSucceed
  | RefreshTokenRequestType
  | RefreshTokenSucceedType
  | RefreshTokenFailedType
  | SetRefreshingTokenType
