import { EventsApi, InsertEventBody, UpdateEventBody } from 'apiLegacy'

import { createLegacyApiInstance } from 'utils'

const apiInstance = () => createLegacyApiInstance(EventsApi)

export type GetEventsServiceType = {
  id?: string
  petId?: string
  overdueFilter?: boolean
  from?: Date
  count?: number
  increment?: boolean
}
export const getEvents = (
  id = undefined,
  petId = undefined,
  overdueFilter = undefined,
  count = undefined,
  fromId = undefined,
  increment = undefined
): GetEventsServiceType => {
  return apiInstance().getEvent(
    id,
    petId,
    overdueFilter ? true : undefined,
    count,
    fromId,
    increment
  )
}

export const getEvent = (id: string) => {
  return apiInstance().getEvent(id)
}

export const putEvent = (event: UpdateEventBody) => {
  return apiInstance().updateEvent(event)
}

export const postEvent = (event: InsertEventBody) => {
  return apiInstance().insertEvent(event)
}

export const getEventsDefinition = () => {
  return apiInstance().getEventDefinition()
}

export const getFollowup = (eventId: string) => {
  return apiInstance().getEventFollowup(eventId)
}

export const getOverdueCount = () => {
  return apiInstance().getEventOverdueCount()
}

export const getEventLists = () => {
  return apiInstance().getListValues()
}

export const completeEvent = (eventId: string, isCompleted: boolean) => {
  return apiInstance().completeEvent({ eventId, isCompleted })
}

export const getAssignableUsers = () => {
  return apiInstance().getAssignableUsers()
}

export const postAssignEvent = (eventId: string, userId: string) => {
  return apiInstance().assignEvent({ eventId, userId })
}
