import { addMonths, differenceInMonths, differenceInYears, isBefore } from 'date-fns'

import { capitalizeFirstLetter } from './string'
import { roundToNearestMinutes } from 'date-fns'

export const addHours = (d: Date, hours: number): Date => {
  const res = new Date(d)
  res.setHours(res.getHours() + hours)
  return res
}
export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}
export const dateToObject = (d: Date) => ({
  day: d.getDate(),
  month: d.getMonth(),
  year: d.getFullYear(),
  hour: d.getHours(),
  minute: d.getMinutes(),
  second: d.getSeconds(),
})

export const isValidDate = (d: Date) => d instanceof Date && !isNaN(d.getTime())

export const getValidDate = (d: Date, def: Date = new Date()) => (isValidDate(d) ? d : def)

export const formatDate = (date: Date, locale: string, emptyText = '', options = {}): string =>
  isValidDate(date) ? new Intl.DateTimeFormat(locale, options).format(date) : emptyText

export const dateFrom = (date: Date) => new Date(date.valueOf())

export const getMonthList = (
  locales?: string | string[],
  format: 'long' | 'short' = 'long'
): string[] => {
  const year = new Date().getFullYear()
  const monthList = [...Array(12).keys()]
  const formatter = new Intl.DateTimeFormat(locales, {
    month: format,
  })

  const getMonthName = (monthIndex: number) =>
    capitalizeFirstLetter(formatter.format(new Date(year, monthIndex)) as any)

  return monthList.map(getMonthName)
}

export const getDayList = (
  locales?: string | string[],
  format: 'long' | 'short' = 'long'
): string[] => {
  const formater = new Intl.DateTimeFormat(locales, { weekday: format }).format
  return [...Array(7).keys()].map((day) => formater(new Date(Date.UTC(2021, 5, day))))
}

export const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

export const isSameDay = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

export const isSameMonth = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
}

export const isSameDayAndTime = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate() &&
    d1.getHours() === d2.getHours()
  )
}

export const isBirthday = (date1) => {
  const d1 = new Date(date1)
  const today = new Date()

  return d1.getMonth() === today.getMonth() && d1.getDate() === today.getDate()
}

export const getWeekMonday = (date) => {
  // parse to date (for sure)
  const currentDate = new Date(date)

  // current date is Monday
  if (currentDate.getDay() === 1) {
    return currentDate
  }
  return new Date(currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7)))
}

export const getWeekDates = (mondayDate) => {
  const dates = []
  const monday = new Date(mondayDate)

  dates.push(new Date(monday))

  for (let i = 0; i <= 5; i++) {
    dates.push(new Date(monday.setDate(monday.getDate() + 1)))
  }

  return dates
}

export const getLastWeekDay = (mondayDate) => {
  const monday = new Date(mondayDate)

  const lastDay = new Date(monday.setDate(monday.getDate() + 6))
  return lastDay
}

export const getLocalizedDateString = (
  datetime: string | Date,
  lang: string,
  options?: { format?: 'dd-mm-year' | 'month-year' }
) => {
  if (!datetime) {
    return ''
  }
  const format =
    options?.format === 'dd-mm-year'
      ? {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        }
      : options?.format === 'month-year'
      ? {
          month: 'long',
          year: 'numeric',
        }
      : {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }

  const date = new Date(datetime)

  return new Intl.DateTimeFormat(lang, {
    timeZone: typeof datetime !== 'string' ? 'UTC' : undefined,
    ...format,
  } as any).format(date)
}

export const getDayNames = (options: { locale: string; format: 'long' | 'short' | 'narrow' }) => {
  const formatter = new Intl.DateTimeFormat(options.locale, {
    weekday: options.format,
    timeZone: 'UTC',
  })
  const days = [3, 4, 5, 6, 7, 1, 2].map((day) => {
    const dd = day < 10 ? `0${day}` : day
    return new Date(`2000-01-${dd}T00:00:00+00:00`)
  })
  return days.map((date) => formatter.format(date))
}

export const getMonthNames = (options: { locale: string; format: 'long' | 'short' | 'narrow' }) => {
  const formatter = new Intl.DateTimeFormat(options.locale, {
    month: options.format,
    timeZone: 'UTC',
  })
  const months = [...Array(12).keys()]
    .map((k) => k + 1)
    .map((month) => {
      const dd = month < 10 ? `0${month}` : month
      return new Date(`2000-${dd}-01T00:00:00+00:00`)
    })
  return months.map((date) => formatter.format(date))
}

export const getMonthNumbers = () => {
  return [...Array(12).keys()].map((k) => `${k + 1}`)
}

interface getFormattedDateOptions {
  today?: string
  hideSameYear?: boolean
}
export const getFormattedDate = (
  parsedDate: Date,
  opt: getFormattedDateOptions = { today: 'today', hideSameYear: true }
): string => {
  const today = new Date()
  if (parsedDate.toDateString() === today.toDateString()) return opt.today

  const date = parsedDate.getDate()
  const month = parsedDate.getMonth() + 1
  const yearString = parsedDate.getFullYear()
  const year = today.getFullYear() === yearString ? '' : yearString.toString().slice(-2)
  return `${date}.${month}.${year}`
}

export const getFormattedTime = (parsedDate) => {
  let hours = parsedDate.getHours()
  let minutes = parsedDate.getMinutes()

  if (hours.toString().length === 1) {
    hours = `0${hours}`
  }

  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`
  }

  return `${hours}:${minutes}`
}

export const getNumberOfDaysInMonth = (date) => {
  return new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0).getDate()
}

export const isBeforeToday = (date) => {
  const today = new Date()
  const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
  return isBefore(date, todayStart)
}

export const isBeforeThisMonth = (date: Date) => {
  const today = new Date()
  return isBefore(date, addMonths(today, -1))
}

export const isDateBetweenDates = (range: { from: Date; to: Date }, date: Date) => {
  const rangeDateFrom = new Date(range.from)
  const rangeDateTo = new Date(range.to)
  const rangeDate = new Date(date)
  if (
    rangeDate.getTime() <= rangeDateTo.getTime() &&
    rangeDate.getTime() >= rangeDateFrom.getTime()
  ) {
    return true
  }

  return false
}

export const getAgeString = (birthday: Date, t) => {
  const current = new Date()
  const birthDayDate = new Date(birthday)

  const age = differenceInYears(current, birthDayDate)

  if (age === 0) {
    const monthDiff = differenceInMonths(current, birthDayDate)
    return t('AGE.MONTH_OLD', { count: monthDiff })
  }

  return t('AGE.YEAR_OLD', { count: age })
}

type PrettyDateOptions = {
  roundMinutesStep?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}

export const getPrettyDate = (props: { date: Date | 'today'; options?: PrettyDateOptions }) => {
  const _date = props.date === 'today' ? new Date() : props.date

  _date.setHours(props.options?.hours !== undefined ? props.options?.hours : 12)
  _date.setMinutes(props.options?.minutes !== undefined ? props.options?.minutes : 0)
  _date.setSeconds(props.options?.seconds !== undefined ? props.options?.seconds : 0)
  _date.setMilliseconds(props.options?.milliseconds !== undefined ? props.options?.milliseconds : 0)

  if (
    props.options?.minutes !== undefined ||
    props.options?.seconds !== undefined ||
    props.options?.milliseconds !== undefined
  ) {
    // DO NOT ROUND-TO-NEAREST-MINUTES IF MINUTES, SECONDS OR MILLISECONDS ARE GIVEN (THIS DATA WOULD BE LOST WITH ROUNDING)
    return _date
  }

  const dateRounded = roundToNearestMinutes(_date, {
    nearestTo: props.options?.roundMinutesStep !== undefined ? props.options?.roundMinutesStep : 5,
  })

  return dateRounded
}
