import { AppApi } from 'apiLegacy'
import { createLegacyApiInstance } from 'utils'

const apiInstance = () => createLegacyApiInstance(AppApi)

// eslint-disable-next-line no-console
const log = (text) => console.info(`%c${text}`, 'font-weight: bold')

export const registerFirebaseToken = (token) => {
  log('REGISTER PUSH TOKEN')
  return apiInstance().registerPushToken({ pushToken: token })
}

export const invalidateFirebaseToken = (token) => {
  log('INVALIDATE PUSH TOKEN')
  return apiInstance().registerPushToken({ invalidatePushToken: token })
}

export const registerAnonymousFirebaseToken = (token) => {
  log('REGISTER ANONYMOUS PUSH TOKEN')
  return apiInstance().registerAnonymousPushToken({ pushToken: token })
}

export const invalidateAnonymousFirebaseToken = (token) => {
  log('INVALIDATE ANONYMOUS PUSH TOKEN')
  return apiInstance().registerAnonymousPushToken({ invalidatePushToken: token })
}
