import { clearAppNotifications, getAppNotificationsToView } from 'entity/appNotifications'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import Notification from './Notification'
import SvgIcon from 'components/svgIcon'
import { getFooterIsVisible } from 'entity/footer'
import { isAppInitialized } from 'store/selectors'
import useTranslation from 'next-translate/useTranslation'

const ANIM_LENGTH_MS = 150

const Wrapper = styled.div<{
  withFooter?: boolean
  hasHiddenNotifications?: boolean
  isVisible?: boolean
}>`
  z-index: ${(props) => props.theme.layout.layers.notification};

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: ${(props) => props.theme?.layout?.maxWidth};

  display: flex;
  align-items: stretch;
  flex-direction: column;

  border-radius: 27px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: ${(props) => props.theme.colors.darkGrey}99;
  backdrop-filter: blur(2px);
  padding: 14px;
  padding-top: ${(props) => (props.hasHiddenNotifications ? '34px' : '20px')};

  ${(props) =>
    props.withFooter &&
    css`
      padding-bottom: 100px;
    `}

  transition: all ${ANIM_LENGTH_MS}ms ease-out;
  ${(props) =>
    props.isVisible
      ? css`
          opacity: 1;
          transform: none;
        `
      : css`
          opacity: 0;
          padding-top: 0px;
          padding-bottom: 0px;
        `}
`

const Count = styled.div<{ withFooter?: boolean; isVisible?: boolean }>`
  position: absolute;
  top: 0px;
  color: ${(props) => props.theme.colors.white}BB;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 8px 20px;
  opacity: 1;

  transition: all 0.2s ease;

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0;
    `}
`

const Close = styled.button<{ withFooter?: boolean; isVisible?: boolean }>`
  position: absolute;
  outline: none;
  border: none;
  bottom: ${(props) => (props.withFooter ? '68px' : '10px')};
  right: 14px;
  padding: 10px 20px;
  background: none;
  color: ${(props) => props.theme.colors.white}BB;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  opacity: 1;
  -webkit-tap-highlight-color: transparent;

  svg {
    height: 12px;
  }

  transition: all 0.2s ease;

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0;
    `}
`

const AppNotifications = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const isAppInit = useSelector(isAppInitialized)
  const notificationsToView = useSelector(getAppNotificationsToView)
  const maxNotificationDisplayed = 3

  const [isRendered, setIsRendered] = useState<boolean>()
  const setIsRenderedRef = useRef(setIsRendered)

  const [isVisible, setIsVisible] = useState<boolean>()
  const setIsVisibleRef = useRef(setIsVisible)

  const [hiddenCount, setHiddenCount] = useState<number>(0)

  const withFooter = useSelector(getFooterIsVisible)

  useEffect(() => {
    setHiddenCount(notificationsToView?.length - maxNotificationDisplayed)
    if (!isRendered && notificationsToView?.length) {
      // Trigger render (not visible yet)
      setIsRendered(true)
    }
    if (isRendered && !notificationsToView?.length) {
      // Trigger hide (still rendered - due to exit animations)
      setIsVisible(false)
    }
  }, [notificationsToView])

  useEffect(() => {
    if (isRendered) {
      const timer = setTimeout(() => setIsVisibleRef.current(true), 10)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isRendered])

  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => setIsRenderedRef.current(false), ANIM_LENGTH_MS)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isVisible])

  const getHiddenNotificationsMessage = () => {
    const count = Math.max(1, hiddenCount) // to avoid rendering '0' in animated transition on hide
    return `${count} ${t('app_notification.HIDDEN.key', {
      count: count,
    })}`
  }

  //
  // RENDER
  //
  return isAppInit && isRendered ? (
    <Wrapper withFooter={withFooter} isVisible={isVisible} hasHiddenNotifications={hiddenCount > 0}>
      <Count withFooter={withFooter} isVisible={hiddenCount > 0}>
        {getHiddenNotificationsMessage()}
      </Count>

      {notificationsToView?.map((notification, i) =>
        notification ? (
          <Notification
            key={notification.id}
            notification={notification}
            isVisible={i > notificationsToView.length - maxNotificationDisplayed - 1}
          />
        ) : null
      )}

      <Close
        withFooter={withFooter}
        onClick={() => dispatch(clearAppNotifications())}
        isVisible={hiddenCount > 0}
      >
        {t('app_notification.CLOSE_ALL')}
        <SvgIcon code="icon-no" />
      </Close>
    </Wrapper>
  ) : null
}

export default AppNotifications
