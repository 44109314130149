import { Coordinates, GoogleMapsOrigin, GooglePlacesAutocompleteItem } from 'types'

export const getGoogleMapsOriginValues: (origin: GoogleMapsOrigin) => {
  value: string
  valueSecondary: string
} = (origin) => {
  if (origin) {
    if (typeof (origin as Coordinates).lat === 'number') {
      //const _origin = origin as Coordinates
      return {
        value: undefined,
        valueSecondary: undefined,
      }
    } else {
      const _origin = origin as GooglePlacesAutocompleteItem

      return {
        value: _origin.structured_formatting?.main_text,
        valueSecondary: _origin.structured_formatting?.secondary_text,
      }
    }
  }
  return {
    value: undefined,
    valueSecondary: undefined,
  }
}
