import Router, { useRouter } from 'next/router'
import SvgIcon, { IconName } from 'components/svgIcon'
import { logoutUser, toggleMenu } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import Header from './header'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { USERS_TABS } from 'pages/users'
import UserSubHeader from './userSubheader'
import { getInvitesCount } from 'entity/group'
import styled from 'styled-components'
import { useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  background-color: ${(props) => props?.theme?.colors?.darkGrey};
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
  touch-action: none;
`

const List = styled.ul`
  position: relative;
  list-style-type: none;
  padding: 10px 15px;
  overflow-y: auto;
`

const ListItem = styled.li`
  transition: all 0.2s ease;
  color: white;
  text-transform: uppercase;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 8px 0;
  margin-left: 20px;

  &:last-of-type {
    font-weight: 700;
    text-transform: none;
  }

  &:hover {
    filter: brightness(1.5);
  }
`

const ListIcon = styled.div<{ size?: number }>`
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
  svg {
    width: ${(props) => props.size || 24}px;
    height: ${(props) => props.size || 24}px;
    fill: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
  }
`

const ListDivider = styled.li`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.lightGrey};
  margin-top: 30px;
`

const Badge = styled.span`
  margin-left: -8px;
  margin-top: -8px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  background: ${(props) => props.theme.colors.red};
`

const FeedbackLink = styled.div`
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  margin-bottom: 3vh;
  color: ${(props) => props.theme.colors.lighterGrey};

  display: flex;
  align-items: center;
  gap: 14px;

  svg {
    margin-top: 4px;
    height: 20px;
  }
`

const AppVersion = styled.div`
  color: ${(props) => props.theme.colors.lighterGrey};
  padding: 15px;
  font-size: 10px;
  text-align: right;
`

const handleBeforeInstall = () => {
  // let deferredPrompt = e
  // e.preventDefault()
  // TODO: Show own install prompt or set to be installable using https://web.dev/customize-install/
  // eslint-disable-next-line no-console
  console.log(`'beforeinstallprompt' event was fired.`)
}

const Menu = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const invitesCount = useSelector(getInvitesCount)

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstall)
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstall)
    }
  }, [])

  const handleClickItem = (href) => {
    dispatch(toggleMenu())
    Router.push(href)
  }

  const MenuItem = (options: {
    name: string
    icon: IconName
    iconSize?: number
    onClick: () => void
    badgeCount?: number
  }) => {
    return (
      <ListItem onClick={options.onClick}>
        <ListIcon size={options.iconSize}>
          <SvgIcon code={options.icon} />
        </ListIcon>
        <div role="presentation">{options.name}</div>
        {!!options.badgeCount && <Badge>{options.badgeCount}</Badge>}
      </ListItem>
    )
  }

  return (
    <Wrapper>
      <Header />
      <UserSubHeader />

      <List>
        <LanguageSwitcher href={router.pathname} context="in-menu" />

        <MenuItem
          name={t('HOME')}
          icon="icon-home"
          iconSize={20}
          onClick={() => handleClickItem('/')}
        />

        <MenuItem
          name={t('PETS')}
          icon="icon-dog"
          iconSize={20}
          onClick={() => handleClickItem('/pets')}
        />

        <MenuItem
          name={t('USERS')}
          icon="icon-person"
          iconSize={20}
          onClick={() =>
            handleClickItem(
              invitesCount
                ? `/users?tab=${USERS_TABS.otherGroups}`
                : `/users?tab=${USERS_TABS.myGroup}`
            )
          }
          badgeCount={invitesCount}
        />

        <MenuItem
          name={t('HISTORY')}
          icon="icon-history"
          iconSize={20}
          onClick={() => handleClickItem('/history')}
        />

        <MenuItem
          name={t('SETTINGS')}
          icon="icon-settings"
          iconSize={20}
          onClick={() => handleClickItem('/settings')}
        />

        <MenuItem
          name={t('switcher.SHARE_WITH_VETERINARIAN')}
          icon="icon-share"
          iconSize={20}
          onClick={() => handleClickItem('/shared/list')}
        />

        <ListDivider />

        <ListItem onClick={() => dispatch(logoutUser())}>{t('LOGOUT')}</ListItem>
      </List>

      <FeedbackLink onClick={() => handleClickItem('/feedback')}>
        <SvgIcon code="icon-feedback" />
        {t('FEEDBACK_FROM_MENU')}
      </FeedbackLink>

      <AppVersion>
        {t('VERSION')}: {process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}
      </AppVersion>
    </Wrapper>
  )
}

export default Menu
