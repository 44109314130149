/* tslint:disable */
/* eslint-disable */
/**
 * PetLifeBook API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptTeamInvitationRequest
 */
export interface AcceptTeamInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof AcceptTeamInvitationRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptTeamInvitationRequest
     */
    'invitationCode'?: string;
}
/**
 * 
 * @export
 * @interface AcceptTeamInvitationRequestAllOf
 */
export interface AcceptTeamInvitationRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof AcceptTeamInvitationRequestAllOf
     */
    'invitationCode'?: string;
}
/**
 * 
 * @export
 * @interface AcceptTeamInvitationResponse
 */
export interface AcceptTeamInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof AcceptTeamInvitationResponse
     */
    'teamId': string;
}
/**
 * 
 * @export
 * @interface ActivitiesPercentDetail
 */
export interface ActivitiesPercentDetail {
    /**
     * 
     * @type {number}
     * @memberof ActivitiesPercentDetail
     */
    'jogging': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitiesPercentDetail
     */
    'running': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitiesPercentDetail
     */
    'stairs_up': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitiesPercentDetail
     */
    'stairs_down': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitiesPercentDetail
     */
    'walking': number;
}
/**
 * 
 * @export
 * @interface ActivitiesPercentRequest
 */
export interface ActivitiesPercentRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivitiesPercentRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesPercentRequest
     */
    'petId': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof ActivitiesPercentRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
    /**
     * 
     * @type {Interval}
     * @memberof ActivitiesPercentRequest
     */
    'interval': Interval;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesPercentRequest
     */
    'date': string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof ActivitiesPercentRequest
     */
    'timeUnit': TimeUnit;
}
/**
 * 
 * @export
 * @interface ActivitiesPercentRequestAllOf
 */
export interface ActivitiesPercentRequestAllOf {
    /**
     * 
     * @type {Interval}
     * @memberof ActivitiesPercentRequestAllOf
     */
    'interval': Interval;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesPercentRequestAllOf
     */
    'date': string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof ActivitiesPercentRequestAllOf
     */
    'timeUnit': TimeUnit;
}
/**
 * 
 * @export
 * @interface ActivitiesPercentResponse
 */
export interface ActivitiesPercentResponse {
    /**
     * 
     * @type {string}
     * @memberof ActivitiesPercentResponse
     */
    'date'?: string;
    /**
     * 
     * @type {Array<ActivityPercentItem>}
     * @memberof ActivitiesPercentResponse
     */
    'activities': Array<ActivityPercentItem>;
    /**
     * 
     * @type {ActivityPercentSummary}
     * @memberof ActivitiesPercentResponse
     */
    'summary': ActivityPercentSummary;
}
/**
 * 
 * @export
 * @interface ActivitiesRequest
 */
export interface ActivitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivitiesRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesRequest
     */
    'petId': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof ActivitiesRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
    /**
     * 
     * @type {Interval}
     * @memberof ActivitiesRequest
     */
    'interval': Interval;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesRequest
     */
    'date': string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof ActivitiesRequest
     */
    'timeUnit'?: TimeUnit;
}
/**
 * 
 * @export
 * @interface ActivitiesRequestAllOf
 */
export interface ActivitiesRequestAllOf {
    /**
     * 
     * @type {Interval}
     * @memberof ActivitiesRequestAllOf
     */
    'interval': Interval;
    /**
     * 
     * @type {string}
     * @memberof ActivitiesRequestAllOf
     */
    'date': string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof ActivitiesRequestAllOf
     */
    'timeUnit'?: TimeUnit;
}
/**
 * 
 * @export
 * @interface ActivitiesResponse
 */
export interface ActivitiesResponse {
    /**
     * 
     * @type {string}
     * @memberof ActivitiesResponse
     */
    'date'?: string;
    /**
     * 
     * @type {Array<ActivityItem>}
     * @memberof ActivitiesResponse
     */
    'activities': Array<ActivityItem>;
    /**
     * 
     * @type {ActivitySummary}
     * @memberof ActivitiesResponse
     */
    'summary': ActivitySummary;
}
/**
 * 
 * @export
 * @interface ActivityItem
 */
export interface ActivityItem {
    /**
     * 
     * @type {string}
     * @memberof ActivityItem
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityItem
     */
    'water': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityItem
     */
    'rest': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityItem
     */
    'activity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityItem
     */
    'food': number;
}
/**
 * 
 * @export
 * @interface ActivityPercentItem
 */
export interface ActivityPercentItem {
    /**
     * 
     * @type {string}
     * @memberof ActivityPercentItem
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'activity': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'aport': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'food': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'jogging': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'rest': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'running': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'stairs_up': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'stairs_down': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'walking': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentItem
     */
    'water': number;
    /**
     * 
     * @type {ActivitiesPercentDetail}
     * @memberof ActivityPercentItem
     */
    'percent': ActivitiesPercentDetail;
}
/**
 * 
 * @export
 * @interface ActivityPercentSummary
 */
export interface ActivityPercentSummary {
    /**
     * 
     * @type {BowlSummary}
     * @memberof ActivityPercentSummary
     */
    'water': BowlSummary;
    /**
     * 
     * @type {BowlSummary}
     * @memberof ActivityPercentSummary
     */
    'food': BowlSummary;
    /**
     * 
     * @type {ActivitySummaryRest}
     * @memberof ActivityPercentSummary
     */
    'rest': ActivitySummaryRest;
    /**
     * 
     * @type {ActivityPercentSummaryActivity}
     * @memberof ActivityPercentSummary
     */
    'activity': ActivityPercentSummaryActivity;
}
/**
 * 
 * @export
 * @interface ActivityPercentSummaryActivity
 */
export interface ActivityPercentSummaryActivity {
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentSummaryActivity
     */
    'activity': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentSummaryActivity
     */
    'aport': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentSummaryActivity
     */
    'jogging': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentSummaryActivity
     */
    'running': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentSummaryActivity
     */
    'stairs_up': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentSummaryActivity
     */
    'stairs_down': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPercentSummaryActivity
     */
    'walking': number;
    /**
     * 
     * @type {ActivitiesPercentDetail}
     * @memberof ActivityPercentSummaryActivity
     */
    'percent': ActivitiesPercentDetail;
}
/**
 * 
 * @export
 * @interface ActivityRequestAll
 */
export interface ActivityRequestAll {
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestAll
     */
    'userId'?: string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof ActivityRequestAll
     */
    'timeUnit'?: TimeUnit;
}
/**
 * 
 * @export
 * @interface ActivityRequestAllAllOf
 */
export interface ActivityRequestAllAllOf {
    /**
     * 
     * @type {TimeUnit}
     * @memberof ActivityRequestAllAllOf
     */
    'timeUnit'?: TimeUnit;
}
/**
 * 
 * @export
 * @interface ActivityRequestPet
 */
export interface ActivityRequestPet {
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestPet
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityRequestPet
     */
    'petId': string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof ActivityRequestPet
     */
    'timeUnit'?: TimeUnit;
}
/**
 * 
 * @export
 * @interface ActivitySummary
 */
export interface ActivitySummary {
    /**
     * 
     * @type {BowlSummary}
     * @memberof ActivitySummary
     */
    'water': BowlSummary;
    /**
     * 
     * @type {BowlSummary}
     * @memberof ActivitySummary
     */
    'food': BowlSummary;
    /**
     * 
     * @type {ActivitySummaryRest}
     * @memberof ActivitySummary
     */
    'rest': ActivitySummaryRest;
    /**
     * 
     * @type {ActivitySummaryActivity}
     * @memberof ActivitySummary
     */
    'activity': ActivitySummaryActivity;
}
/**
 * 
 * @export
 * @interface ActivitySummaryActivity
 */
export interface ActivitySummaryActivity {
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryActivity
     */
    'play': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryActivity
     */
    'active': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryActivity
     */
    'walking': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryActivity
     */
    'veryActive': number;
}
/**
 * 
 * @export
 * @interface ActivitySummaryRest
 */
export interface ActivitySummaryRest {
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryRest
     */
    'sleep': number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummaryRest
     */
    'repose': number;
}
/**
 * 
 * @export
 * @interface AddDevice
 */
export interface AddDevice {
    /**
     * Which pet is going to eat from it
     * @type {string}
     * @memberof AddDevice
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddDevice
     */
    'deviceId'?: string;
    /**
     * 
     * @type {Color}
     * @memberof AddDevice
     */
    'color': Color;
    /**
     * 
     * @type {DevicePurpose}
     * @memberof AddDevice
     */
    'purpose'?: DevicePurpose;
    /**
     * Required when device type is bowl
     * @type {string}
     * @memberof AddDevice
     */
    'name'?: string;
    /**
     * 
     * @type {DeviceType}
     * @memberof AddDevice
     */
    'type': DeviceType;
}
/**
 * 
 * @export
 * @interface AddDeviceUser
 */
export interface AddDeviceUser {
    /**
     * 
     * @type {string}
     * @memberof AddDeviceUser
     */
    'userId'?: string;
    /**
     * Which pet is going to eat from it
     * @type {string}
     * @memberof AddDeviceUser
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddDeviceUser
     */
    'deviceId'?: string;
    /**
     * 
     * @type {Color}
     * @memberof AddDeviceUser
     */
    'color': Color;
    /**
     * 
     * @type {DevicePurpose}
     * @memberof AddDeviceUser
     */
    'purpose'?: DevicePurpose;
    /**
     * Required when device type is bowl
     * @type {string}
     * @memberof AddDeviceUser
     */
    'name'?: string;
    /**
     * 
     * @type {DeviceType}
     * @memberof AddDeviceUser
     */
    'type': DeviceType;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {number}
     * @memberof ApiError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'fields'?: string;
}
/**
 * 
 * @export
 * @interface AppLoginRequest
 */
export interface AppLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof AppLoginRequest
     */
    'appId': string;
    /**
     * 
     * @type {string}
     * @memberof AppLoginRequest
     */
    'appSecret': string;
    /**
     * 
     * @type {string}
     * @memberof AppLoginRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppLoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AppLoginRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AppVersionRequest
 */
export interface AppVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof AppVersionRequest
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionRequest
     */
    'os': string;
}
/**
 * 
 * @export
 * @interface AppVersionResponse
 */
export interface AppVersionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AppVersionResponse
     */
    'expired': boolean;
}
/**
 * 
 * @export
 * @interface AssignEventPetRequest
 */
export interface AssignEventPetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignEventPetRequest
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof AssignEventPetRequest
     */
    'petId': string;
}
/**
 * 
 * @export
 * @interface AssignEventRequest
 */
export interface AssignEventRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignEventRequest
     */
    'eventId': string;
}
/**
 * 
 * @export
 * @interface Barkfie
 */
export interface Barkfie {
    /**
     * 
     * @type {string}
     * @memberof Barkfie
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Barkfie
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof Barkfie
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof Barkfie
     */
    'uploadedAt': string;
    /**
     * 
     * @type {Images}
     * @memberof Barkfie
     */
    'image': Images;
}
/**
 * 
 * @export
 * @interface BarkfieRemove
 */
export interface BarkfieRemove {
    /**
     * 
     * @type {string}
     * @memberof BarkfieRemove
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BarkfieRemove
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface BarkfieRequest
 */
export interface BarkfieRequest {
    /**
     * 
     * @type {string}
     * @memberof BarkfieRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BarkfieRequest
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BarkfieRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BarkfieRequest
     */
    'toDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof BarkfieRequest
     */
    'limit'?: number;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof BarkfieRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface BarkfieSave
 */
export interface BarkfieSave {
    /**
     * 
     * @type {string}
     * @memberof BarkfieSave
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof BarkfieSave
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof BarkfieSave
     */
    'userId'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof BarkfieSave
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface BowlFirmwareCheckRequest
 */
export interface BowlFirmwareCheckRequest {
    /**
     * 
     * @type {number}
     * @memberof BowlFirmwareCheckRequest
     */
    'hwVersion': number;
    /**
     * 
     * @type {number}
     * @memberof BowlFirmwareCheckRequest
     */
    'fwVersion': number;
    /**
     * 
     * @type {number}
     * @memberof BowlFirmwareCheckRequest
     */
    'softDevice': number;
    /**
     * 
     * @type {string}
     * @memberof BowlFirmwareCheckRequest
     */
    'type': BowlFirmwareCheckRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BowlFirmwareCheckRequest
     */
    'fileType': BowlFirmwareCheckRequestFileTypeEnum;
}

export const BowlFirmwareCheckRequestTypeEnum = {
    Firmware: 'firmware',
    Bootloader: 'bootloader'
} as const;

export type BowlFirmwareCheckRequestTypeEnum = typeof BowlFirmwareCheckRequestTypeEnum[keyof typeof BowlFirmwareCheckRequestTypeEnum];
export const BowlFirmwareCheckRequestFileTypeEnum = {
    Bin: 'bin',
    Dat: 'dat'
} as const;

export type BowlFirmwareCheckRequestFileTypeEnum = typeof BowlFirmwareCheckRequestFileTypeEnum[keyof typeof BowlFirmwareCheckRequestFileTypeEnum];

/**
 * 
 * @export
 * @interface BowlStatsBody
 */
export interface BowlStatsBody {
    /**
     * 
     * @type {string}
     * @memberof BowlStatsBody
     */
    'deviceId': string;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'wifi': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'ajb': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'auth': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'tim': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'hf': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'wd': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'sf': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'false'?: number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsBody
     */
    'lock': number;
}
/**
 * 
 * @export
 * @interface BowlStatsHeaders
 */
export interface BowlStatsHeaders {
    /**
     * 
     * @type {number}
     * @memberof BowlStatsHeaders
     */
    'fwVersion': number;
    /**
     * 
     * @type {number}
     * @memberof BowlStatsHeaders
     */
    'battery': number;
}
/**
 * 
 * @export
 * @interface BowlSummary
 */
export interface BowlSummary {
    /**
     * 
     * @type {number}
     * @memberof BowlSummary
     */
    'morning'?: number;
    /**
     * 
     * @type {number}
     * @memberof BowlSummary
     */
    'afternoon'?: number;
    /**
     * 
     * @type {number}
     * @memberof BowlSummary
     */
    'evening'?: number;
    /**
     * 
     * @type {number}
     * @memberof BowlSummary
     */
    'weekDayAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof BowlSummary
     */
    'weekendDayAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof BowlSummary
     */
    'maxInDay'?: number;
    /**
     * 
     * @type {number}
     * @memberof BowlSummary
     */
    'minInDay'?: number;
}
/**
 * 
 * @export
 * @interface BowlSyncBulkRequest
 */
export interface BowlSyncBulkRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BowlSyncBulkRequest
     */
    'data': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BowlSyncBulkRequest
     */
    'devices': Array<string>;
}
/**
 * 
 * @export
 * @interface BowlSyncRequest
 */
export interface BowlSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof BowlSyncRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof BowlSyncRequest
     */
    'hexString': string;
    /**
     * 
     * @type {number}
     * @memberof BowlSyncRequest
     */
    'weightAbsolute'?: number;
}
/**
 * 
 * @export
 * @interface Breed
 */
export interface Breed {
    /**
     * 
     * @type {string}
     * @memberof Breed
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Breed
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Breed
     */
    'species': string;
}
/**
 * 
 * @export
 * @interface Breeds
 */
export interface Breeds {
    /**
     * 
     * @type {Array<Breed>}
     * @memberof Breeds
     */
    'items': Array<Breed>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChallengeCategory = {
    Sleeper: 'sleeper',
    NightOwl: 'night_owl',
    Smooth: 'smooth',
    Speedster: 'speedster',
    EarlyBird: 'early_bird',
    Master: 'master'
} as const;

export type ChallengeCategory = typeof ChallengeCategory[keyof typeof ChallengeCategory];


/**
 * 
 * @export
 * @enum {string}
 */

export const Color = {
    Red: 'red',
    DarkGreen: 'dark_green',
    Pink: 'pink',
    LightGreen: 'light_green',
    Violet: 'violet',
    Orange: 'orange',
    Blue: 'blue'
} as const;

export type Color = typeof Color[keyof typeof Color];


/**
 * 
 * @export
 * @interface CompleteEventRequest
 */
export interface CompleteEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteEventRequest
     */
    'eventId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompleteEventRequest
     */
    'isCompleted'?: boolean;
}
/**
 * 
 * @export
 * @interface ConfirmDevice
 */
export interface ConfirmDevice {
    /**
     * 
     * @type {string}
     * @memberof ConfirmDevice
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmDevice
     */
    'passkey': string;
}
/**
 * 
 * @export
 * @interface ConfirmDeviceUser
 */
export interface ConfirmDeviceUser {
    /**
     * 
     * @type {string}
     * @memberof ConfirmDeviceUser
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmDeviceUser
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmDeviceUser
     */
    'passkey': string;
}
/**
 * 
 * @export
 * @interface ConnectionLogItem
 */
export interface ConnectionLogItem {
    /**
     * 
     * @type {string}
     * @memberof ConnectionLogItem
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionLogItem
     */
    'connectTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionLogItem
     */
    'disconnectTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionLogItem
     */
    'readyTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectionLogItem
     */
    'trackerTimestamp'?: number;
}
/**
 * 
 * @export
 * @interface CustomImageSizeRequest
 */
export interface CustomImageSizeRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomImageSizeRequest
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CustomImageSizeRequest
     */
    'height': number;
}
/**
 * 
 * @export
 * @interface Dashboard
 */
export interface Dashboard {
    /**
     * 
     * @type {string}
     * @memberof Dashboard
     */
    'petId': string;
    /**
     * 
     * @type {ActivitySummary}
     * @memberof Dashboard
     */
    'todaySummary': ActivitySummary;
    /**
     * 
     * @type {boolean}
     * @memberof Dashboard
     */
    'hadBowl': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dashboard
     */
    'hadTracker': boolean;
}
/**
 * 
 * @export
 * @interface DateValuePair
 */
export interface DateValuePair {
    /**
     * 
     * @type {string}
     * @memberof DateValuePair
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DateValuePair
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'deviceId': string;
    /**
     * 
     * @type {DeviceType}
     * @memberof Device
     */
    'type': DeviceType;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'passkey': string;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'confirmed': boolean;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    'firmware'?: number;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'name'?: string;
    /**
     * 
     * @type {DevicePurpose}
     * @memberof Device
     */
    'purpose'?: DevicePurpose;
}
/**
 * 
 * @export
 * @interface DeviceEdit
 */
export interface DeviceEdit {
    /**
     * 
     * @type {string}
     * @memberof DeviceEdit
     */
    'userId'?: string;
    /**
     * Which pet is going to eat from it
     * @type {string}
     * @memberof DeviceEdit
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceEdit
     */
    'deviceId'?: string;
    /**
     * 
     * @type {Color}
     * @memberof DeviceEdit
     */
    'color'?: Color;
    /**
     * 
     * @type {string}
     * @memberof DeviceEdit
     */
    'name'?: string;
    /**
     * 
     * @type {DevicePurpose}
     * @memberof DeviceEdit
     */
    'purpose'?: DevicePurpose;
}
/**
 * 
 * @export
 * @interface DeviceEditAllOf
 */
export interface DeviceEditAllOf {
    /**
     * Which pet is going to eat from it
     * @type {string}
     * @memberof DeviceEditAllOf
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceEditAllOf
     */
    'deviceId'?: string;
    /**
     * 
     * @type {Color}
     * @memberof DeviceEditAllOf
     */
    'color'?: Color;
    /**
     * 
     * @type {string}
     * @memberof DeviceEditAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {DevicePurpose}
     * @memberof DeviceEditAllOf
     */
    'purpose'?: DevicePurpose;
}
/**
 * 
 * @export
 * @interface DeviceId
 */
export interface DeviceId {
    /**
     * 
     * @type {string}
     * @memberof DeviceId
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface DeviceInfo
 */
export interface DeviceInfo {
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'state': DeviceInfoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'petName'?: string;
}

export const DeviceInfoStateEnum = {
    My: 'my',
    Paired: 'paired',
    Unpaired: 'unpaired'
} as const;

export type DeviceInfoStateEnum = typeof DeviceInfoStateEnum[keyof typeof DeviceInfoStateEnum];

/**
 * 
 * @export
 * @interface DeviceLog
 */
export interface DeviceLog {
    /**
     * 
     * @type {string}
     * @memberof DeviceLog
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLog
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLog
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLog
     */
    'connectionId': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLog
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeviceLogEventType = {
    BowlAuthTokenSet: 'bowl_auth_token_set',
    BowlConnected: 'bowl_connected',
    BowlConnecting: 'bowl_connecting',
    BowlConnectionError: 'bowl_connection_error',
    BowlDisconnected: 'bowl_disconnected',
    BowlReady: 'bowl_ready',
    BowlSetFoodLevel: 'bowl_set_food_level',
    BowlSetFoodLevelError: 'bowl_set_food_level_error',
    BowlSetTareScale: 'bowl_set_tare_scale',
    BowlSetTareScaleError: 'bowl_set_tare_scale_error',
    BowlSetupEnvironment: 'bowl_setup_environment',
    BowlWifiConnection: 'bowl_wifi_connection',
    BowlWifiConnectionError: 'bowl_wifi_connection_error',
    DeviceFirmwareUpdate: 'device_firmware_update',
    DeviceFirmwareUpdateError: 'device_firmware_update_error',
    TrackerConnected: 'tracker_connected',
    TrackerConnectionError: 'tracker_connection_error',
    TrackerDataRead: 'tracker_data_read',
    TrackerDataReadError: 'tracker_data_read_error',
    TrackerDataSent: 'tracker_data_sent',
    TrackerDataSentError: 'tracker_data_sent_error',
    TrackerDisconnected: 'tracker_disconnected',
    TrackerReady: 'tracker_ready',
    TrackerTimestampSet: 'tracker_timestamp_set'
} as const;

export type DeviceLogEventType = typeof DeviceLogEventType[keyof typeof DeviceLogEventType];


/**
 * 
 * @export
 * @interface DeviceLogRequest
 */
export interface DeviceLogRequest {
    /**
     * 
     * @type {Array<DeviceLog>}
     * @memberof DeviceLogRequest
     */
    'logEvents': Array<DeviceLog>;
}
/**
 * 
 * @export
 * @interface DeviceMetadataRequest
 */
export interface DeviceMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequest
     */
    'hwVersion': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequest
     */
    'fwVersion': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequest
     */
    'softDevice'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequest
     */
    'blVersion': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequest
     */
    'deviceTime'?: string;
}
/**
 * 
 * @export
 * @interface DeviceMetadataRequestAllOf
 */
export interface DeviceMetadataRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequestAllOf
     */
    'blVersion': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequestAllOf
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataRequestAllOf
     */
    'deviceTime'?: string;
}
/**
 * 
 * @export
 * @interface DeviceMetadataResponse
 */
export interface DeviceMetadataResponse {
    /**
     * 
     * @type {FirmwareCheck}
     * @memberof DeviceMetadataResponse
     */
    'firmwareCheck'?: FirmwareCheck;
    /**
     * 
     * @type {Array<FirmwareUpdate>}
     * @memberof DeviceMetadataResponse
     */
    'firmwareUpdates': Array<FirmwareUpdate>;
    /**
     * 
     * @type {DeviceInfo}
     * @memberof DeviceMetadataResponse
     */
    'deviceInfo'?: DeviceInfo;
    /**
     * 
     * @type {string}
     * @memberof DeviceMetadataResponse
     */
    'serverTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceMetadataResponse
     */
    'setDeviceTime': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceMetadataResponse
     */
    'deviceStatsUpToDate': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DevicePurpose = {
    Food: 'food',
    Water: 'water'
} as const;

export type DevicePurpose = typeof DevicePurpose[keyof typeof DevicePurpose];


/**
 * 
 * @export
 * @interface DeviceStats
 */
export interface DeviceStats {
    /**
     * 
     * @type {string}
     * @memberof DeviceStats
     */
    'deviceId': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'systemOffCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'doubleTapCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'softResetCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'lockup'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'watchdogCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'hardfaultCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'chargingCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceStats
     */
    'fwVersion'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceStats
     */
    'batteryLevel'?: number;
}
/**
 * 
 * @export
 * @interface DeviceSync
 */
export interface DeviceSync {
    /**
     * 
     * @type {string}
     * @memberof DeviceSync
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceSync
     */
    'hexString': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceSync
     */
    'notificationId'?: number;
}
/**
 * 
 * @export
 * @interface DeviceSyncTime
 */
export interface DeviceSyncTime {
    /**
     * 
     * @type {string}
     * @memberof DeviceSyncTime
     */
    'deviceId': string;
    /**
     * 
     * @type {number}
     * @memberof DeviceSyncTime
     */
    'time': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeviceType = {
    Tracker: 'tracker',
    Bowl: 'bowl'
} as const;

export type DeviceType = typeof DeviceType[keyof typeof DeviceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const Distance = {
    Km: 'km',
    Mile: 'mile'
} as const;

export type Distance = typeof Distance[keyof typeof Distance];


/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * Event ID
     * @type {string}
     * @memberof Event
     */
    'id': string;
    /**
     * Event code
     * @type {string}
     * @memberof Event
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'completedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'timelineAt': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'assignedUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'petId': string;
    /**
     * 
     * @type {object}
     * @memberof Event
     */
    'items': object;
}
/**
 * 
 * @export
 * @interface EventAttributeDefinition
 */
export interface EventAttributeDefinition {
    /**
     * 
     * @type {EventAttributeType}
     * @memberof EventAttributeDefinition
     */
    'type': EventAttributeType;
    /**
     * List code - property present only for attributes of type \'list\'
     * @type {string}
     * @memberof EventAttributeDefinition
     */
    'listCode'?: string;
    /**
     * Attribute code
     * @type {string}
     * @memberof EventAttributeDefinition
     */
    'code': string;
    /**
     * Attribute name
     * @type {string}
     * @memberof EventAttributeDefinition
     */
    'name': string;
    /**
     * User must provide the attribute
     * @type {boolean}
     * @memberof EventAttributeDefinition
     */
    'required': boolean;
    /**
     * Attribute is not displayed on UI by default
     * @type {boolean}
     * @memberof EventAttributeDefinition
     */
    'primary': boolean;
    /**
     * 
     * @type {number}
     * @memberof EventAttributeDefinition
     */
    'order': number;
}
/**
 * Technical type – may be a hint how to render an item.
 * @export
 * @enum {string}
 */

export const EventAttributeType = {
    String: 'string',
    Text: 'text',
    Number: 'number',
    Datetime: 'datetime',
    List: 'list',
    Price: 'price',
    Images: 'images',
    Intensity: 'intensity',
    Date: 'date',
    Vet: 'vet'
} as const;

export type EventAttributeType = typeof EventAttributeType[keyof typeof EventAttributeType];


/**
 * 
 * @export
 * @interface EventChange
 */
export interface EventChange {
    /**
     * 
     * @type {EventChangeCode}
     * @memberof EventChange
     */
    'changeCode': EventChangeCode;
    /**
     * 
     * @type {string}
     * @memberof EventChange
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof EventChange
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof EventChange
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof EventChange
     */
    'oldValue': string;
    /**
     * 
     * @type {string}
     * @memberof EventChange
     */
    'newValue': string;
    /**
     * 
     * @type {EventChangeExtras}
     * @memberof EventChange
     */
    'extras'?: EventChangeExtras;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventChangeCode = {
    Created: 'event-created',
    StateChanged: 'event-state-changed',
    AssigneeChanged: 'event-assignee-changed',
    ItemChanged: 'event-item-changed'
} as const;

export type EventChangeCode = typeof EventChangeCode[keyof typeof EventChangeCode];


/**
 * 
 * @export
 * @interface EventChangeExtras
 */
export interface EventChangeExtras {
    /**
     * Business code, e.g. allergyType, note, brand
     * @type {string}
     * @memberof EventChangeExtras
     */
    'itemCode'?: string;
    /**
     * 
     * @type {EventAttributeType}
     * @memberof EventChangeExtras
     */
    'itemType'?: EventAttributeType;
    /**
     * 
     * @type {string}
     * @memberof EventChangeExtras
     */
    'oldValueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventChangeExtras
     */
    'newValueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventChangeExtras
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface EventDefinition
 */
export interface EventDefinition {
    /**
     * Entity code
     * @type {string}
     * @memberof EventDefinition
     */
    'code': string;
    /**
     * Entity name
     * @type {string}
     * @memberof EventDefinition
     */
    'name': string;
    /**
     * Icon code of entity
     * @type {string}
     * @memberof EventDefinition
     */
    'icon': string;
    /**
     * True when event can be assigned to another user
     * @type {boolean}
     * @memberof EventDefinition
     */
    'isAssignable': boolean;
    /**
     * 
     * @type {Array<EventAttributeDefinition>}
     * @memberof EventDefinition
     */
    'items': Array<EventAttributeDefinition>;
}
/**
 * 
 * @export
 * @interface EventHistory
 */
export interface EventHistory {
    /**
     * 
     * @type {string}
     * @memberof EventHistory
     */
    'eventCode': string;
    /**
     * 
     * @type {string}
     * @memberof EventHistory
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof EventHistory
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof EventHistory
     */
    'petName': string;
    /**
     * 
     * @type {Array<EventChange>}
     * @memberof EventHistory
     */
    'changes': Array<EventChange>;
}
/**
 * 
 * @export
 * @interface EventList
 */
export interface EventList {
    /**
     * 
     * @type {string}
     * @memberof EventList
     */
    'code': string;
    /**
     * 
     * @type {Array<EventListValue>}
     * @memberof EventList
     */
    'values': Array<EventListValue>;
}
/**
 * 
 * @export
 * @interface EventListValue
 */
export interface EventListValue {
    /**
     * 
     * @type {string}
     * @memberof EventListValue
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EventListValue
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventState = {
    Created: 'created',
    Completed: 'completed',
    Incompleted: 'incompleted'
} as const;

export type EventState = typeof EventState[keyof typeof EventState];


/**
 * 
 * @export
 * @interface EventUser
 */
export interface EventUser {
    /**
     * 
     * @type {string}
     * @memberof EventUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EventUser
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EventsHistoryResponse
 */
export interface EventsHistoryResponse {
    /**
     * 
     * @type {Array<EventHistory>}
     * @memberof EventsHistoryResponse
     */
    'events': Array<EventHistory>;
}
/**
 * 
 * @export
 * @interface FacebookLoginRequest
 */
export interface FacebookLoginRequest {
    /**
     * FB access token
     * @type {string}
     * @memberof FacebookLoginRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface FeedItem
 */
export interface FeedItem {
    /**
     * 
     * @type {string}
     * @memberof FeedItem
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof FeedItem
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface FeedObject
 */
export interface FeedObject {
    /**
     * 
     * @type {string}
     * @memberof FeedObject
     */
    'date': string;
    /**
     * 
     * @type {Array<FeedItem>}
     * @memberof FeedObject
     */
    'values': Array<FeedItem>;
}
/**
 * 
 * @export
 * @interface FeedbackError
 */
export interface FeedbackError {
    /**
     * 
     * @type {string}
     * @memberof FeedbackError
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackError
     */
    'origin'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackError
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackError
     */
    'message'?: string;
    /**
     * 
     * @type {FeedbackErrorResponse}
     * @memberof FeedbackError
     */
    'response'?: FeedbackErrorResponse;
}
/**
 * 
 * @export
 * @interface FeedbackErrorResponse
 */
export interface FeedbackErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof FeedbackErrorResponse
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackErrorResponse
     */
    'requestId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackErrorResponse
     */
    'sentryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeedbackErrorResponse
     */
    'httpStatus'?: number;
}
/**
 * 
 * @export
 * @interface FeedbackRequest
 */
export interface FeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    'message': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedbackRequest
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {Array<FeedbackError>}
     * @memberof FeedbackRequest
     */
    'errors'?: Array<FeedbackError>;
}
/**
 * 
 * @export
 * @interface FindAssignablePetsLists
 */
export interface FindAssignablePetsLists {
    /**
     * 
     * @type {Array<string>}
     * @memberof FindAssignablePetsLists
     */
    'allIds': Array<string>;
}
/**
 * 
 * @export
 * @interface FindAssignablePetsResponse
 */
export interface FindAssignablePetsResponse {
    /**
     * 
     * @type {FindAssignablePetsLists}
     * @memberof FindAssignablePetsResponse
     */
    'lists': FindAssignablePetsLists;
    /**
     * 
     * @type {FindAssignablePetsResponseEntities}
     * @memberof FindAssignablePetsResponse
     */
    'entities': FindAssignablePetsResponseEntities;
}
/**
 * 
 * @export
 * @interface FindAssignablePetsResponseEntities
 */
export interface FindAssignablePetsResponseEntities {
    /**
     * 
     * @type {{ [key: string]: PetEntity; }}
     * @memberof FindAssignablePetsResponseEntities
     */
    'pets': { [key: string]: PetEntity; };
}
/**
 * 
 * @export
 * @interface FirmwareCheck
 */
export interface FirmwareCheck {
    /**
     * je aktualni
     * @type {boolean}
     * @memberof FirmwareCheck
     */
    'upToDate': boolean;
    /**
     * adresa novyho firmware
     * @type {string}
     * @memberof FirmwareCheck
     */
    'firmwareUrl'?: string;
    /**
     * sha1 toho firmware
     * @type {string}
     * @memberof FirmwareCheck
     */
    'sha256'?: string;
    /**
     * version changelog
     * @type {string}
     * @memberof FirmwareCheck
     */
    'changeLog'?: string;
}
/**
 * 
 * @export
 * @interface FirmwareCheckRequest
 */
export interface FirmwareCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof FirmwareCheckRequest
     */
    'hwVersion': string;
    /**
     * 
     * @type {string}
     * @memberof FirmwareCheckRequest
     */
    'fwVersion': string;
    /**
     * 
     * @type {string}
     * @memberof FirmwareCheckRequest
     */
    'softDevice'?: string;
}
/**
 * 
 * @export
 * @interface FirmwareUpdate
 */
export interface FirmwareUpdate {
    /**
     * url of firmware file
     * @type {string}
     * @memberof FirmwareUpdate
     */
    'firmwareUrl': string;
    /**
     * version changelog
     * @type {string}
     * @memberof FirmwareUpdate
     */
    'changeLog': string;
}
/**
 * 
 * @export
 * @interface FirmwareUpload
 */
export interface FirmwareUpload {
    /**
     * 
     * @type {boolean}
     * @memberof FirmwareUpload
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof FirmwareUpload
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetAssignableUsersResponse
 */
export interface GetAssignableUsersResponse {
    /**
     * 
     * @type {Array<EventUser>}
     * @memberof GetAssignableUsersResponse
     */
    'users': Array<EventUser>;
}
/**
 * 
 * @export
 * @interface GetEventEntities
 */
export interface GetEventEntities {
    /**
     * 
     * @type {{ [key: string]: UserEntity; }}
     * @memberof GetEventEntities
     */
    'users': { [key: string]: UserEntity; };
    /**
     * 
     * @type {{ [key: string]: PetEntity; }}
     * @memberof GetEventEntities
     */
    'pets': { [key: string]: PetEntity; };
}
/**
 * 
 * @export
 * @interface GetEventOverdueCountResponse
 */
export interface GetEventOverdueCountResponse {
    /**
     * 
     * @type {number}
     * @memberof GetEventOverdueCountResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface GetEventResponse
 */
export interface GetEventResponse {
    /**
     * 
     * @type {Array<Event>}
     * @memberof GetEventResponse
     */
    'events': Array<Event>;
    /**
     * 
     * @type {GetEventEntities}
     * @memberof GetEventResponse
     */
    'entities': GetEventEntities;
}
/**
 * 
 * @export
 * @interface GetGroupsResponse
 */
export interface GetGroupsResponse {
    /**
     * 
     * @type {GetGroupsResponseLists}
     * @memberof GetGroupsResponse
     */
    'lists': GetGroupsResponseLists;
    /**
     * 
     * @type {GetGroupsResponseEntities}
     * @memberof GetGroupsResponse
     */
    'entities': GetGroupsResponseEntities;
}
/**
 * 
 * @export
 * @interface GetGroupsResponseEntities
 */
export interface GetGroupsResponseEntities {
    /**
     * 
     * @type {{ [key: string]: GroupEntity; }}
     * @memberof GetGroupsResponseEntities
     */
    'groups': { [key: string]: GroupEntity; };
    /**
     * 
     * @type {{ [key: string]: UserEntity; }}
     * @memberof GetGroupsResponseEntities
     */
    'users': { [key: string]: UserEntity; };
    /**
     * 
     * @type {{ [key: string]: PetEntity; }}
     * @memberof GetGroupsResponseEntities
     */
    'pets': { [key: string]: PetEntity; };
    /**
     * 
     * @type {{ [key: string]: GroupInvitationEntity; }}
     * @memberof GetGroupsResponseEntities
     */
    'invitations': { [key: string]: GroupInvitationEntity; };
}
/**
 * 
 * @export
 * @interface GetGroupsResponseLists
 */
export interface GetGroupsResponseLists {
    /**
     * 
     * @type {string}
     * @memberof GetGroupsResponseLists
     */
    'homeGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetGroupsResponseLists
     */
    'joinedGroupIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetGroupsResponseLists
     */
    'homeGroupInvitationIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetGroupsResponseLists
     */
    'otherGroupInvitationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GetUserPetRequest
 */
export interface GetUserPetRequest {
    /**
     * 
     * @type {string}
     * @memberof GetUserPetRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserPetRequest
     */
    'petId': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof GetUserPetRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface GetUserPetsResponse
 */
export interface GetUserPetsResponse {
    /**
     * 
     * @type {Array<UserPetInfo>}
     * @memberof GetUserPetsResponse
     */
    'pets': Array<UserPetInfo>;
}
/**
 * 
 * @export
 * @interface GroupEntity
 */
export interface GroupEntity {
    /**
     * 
     * @type {string}
     * @memberof GroupEntity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntity
     */
    'ownerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupEntity
     */
    'members': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupEntity
     */
    'pets': Array<string>;
}
/**
 * 
 * @export
 * @interface GroupInvitationEntity
 */
export interface GroupInvitationEntity {
    /**
     * 
     * @type {string}
     * @memberof GroupInvitationEntity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupInvitationEntity
     */
    'groupId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupInvitationEntity
     */
    'isRegistered': boolean;
    /**
     * 
     * @type {string}
     * @memberof GroupInvitationEntity
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupInvitationEntity
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface GroupInvitationRequest
 */
export interface GroupInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof GroupInvitationRequest
     */
    'invitationId': string;
}
/**
 * 
 * @export
 * @interface Healthbook
 */
export interface Healthbook {
    /**
     * 
     * @type {Array<HealthbookItem>}
     * @memberof Healthbook
     */
    'items': Array<HealthbookItem>;
}
/**
 * 
 * @export
 * @interface HealthbookActivitiesRequest
 */
export interface HealthbookActivitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof HealthbookActivitiesRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookActivitiesRequest
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookActivitiesRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookActivitiesRequest
     */
    'endDate': string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof HealthbookActivitiesRequest
     */
    'timeUnit'?: TimeUnit;
    /**
     * 
     * @type {string}
     * @memberof HealthbookActivitiesRequest
     */
    'timeAggregation'?: HealthbookActivitiesRequestTimeAggregationEnum;
}

export const HealthbookActivitiesRequestTimeAggregationEnum = {
    Day: 'day'
} as const;

export type HealthbookActivitiesRequestTimeAggregationEnum = typeof HealthbookActivitiesRequestTimeAggregationEnum[keyof typeof HealthbookActivitiesRequestTimeAggregationEnum];

/**
 * 
 * @export
 * @interface HealthbookImage
 */
export interface HealthbookImage {
    /**
     * 
     * @type {string}
     * @memberof HealthbookImage
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookImage
     */
    'thumbnail'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookImage
     */
    'medium'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookImage
     */
    'original'?: string;
}
/**
 * 
 * @export
 * @interface HealthbookItem
 */
export interface HealthbookItem {
    /**
     * 
     * @type {string}
     * @memberof HealthbookItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookItem
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookItem
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookItem
     */
    'veterinarian'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthbookItem
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthbookItem
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthbookItem
     */
    'weight'?: number;
    /**
     * 
     * @type {Array<HealthbookImage>}
     * @memberof HealthbookItem
     */
    'images'?: Array<HealthbookImage>;
    /**
     * 
     * @type {Array<HealthbookType>}
     * @memberof HealthbookItem
     */
    'type': Array<HealthbookType>;
    /**
     * 
     * @type {string}
     * @memberof HealthbookItem
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface HealthbookPetRequest
 */
export interface HealthbookPetRequest {
    /**
     * 
     * @type {string}
     * @memberof HealthbookPetRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookPetRequest
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookPetRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookPetRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookPetRequest
     */
    'type'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof HealthbookPetRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface HealthbookRemoveItem
 */
export interface HealthbookRemoveItem {
    /**
     * 
     * @type {string}
     * @memberof HealthbookRemoveItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookRemoveItem
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface HealthbookSave
 */
export interface HealthbookSave {
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'petId': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof HealthbookSave
     */
    'customImageSize'?: CustomImageSizeRequest;
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'veterinarian'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthbookSave
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthbookSave
     */
    'weight'?: number;
    /**
     * 
     * @type {Array<HealthbookImage>}
     * @memberof HealthbookSave
     */
    'images'?: Array<HealthbookImage>;
    /**
     * 
     * @type {Array<HealthbookType>}
     * @memberof HealthbookSave
     */
    'type': Array<HealthbookType>;
    /**
     * 
     * @type {string}
     * @memberof HealthbookSave
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface HealthbookTokenRequest
 */
export interface HealthbookTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof HealthbookTokenRequest
     */
    'appId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookTokenRequest
     */
    'appSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookTokenRequest
     */
    'accessCode': string;
}
/**
 * 
 * @export
 * @interface HealthbookTokenResponse
 */
export interface HealthbookTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthbookTokenResponse
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookTokenResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookTokenResponse
     */
    'petId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HealthbookType = {
    Doctor: 'doctor',
    Services: 'services',
    Vaccination: 'vaccination',
    Medicaments: 'medicaments',
    Parasites: 'parasites',
    Diseases: 'diseases',
    Surgery: 'surgery',
    Dental: 'dental',
    Tests: 'tests',
    Weight: 'weight'
} as const;

export type HealthbookType = typeof HealthbookType[keyof typeof HealthbookType];


/**
 * 
 * @export
 * @interface HealthbookUrlRequest
 */
export interface HealthbookUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof HealthbookUrlRequest
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof HealthbookUrlRequest
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface HealthbookUrlResponse
 */
export interface HealthbookUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof HealthbookUrlResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Images
 */
export interface Images {
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'thumbnail': string;
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'medium': string;
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'original': string;
}
/**
 * 
 * @export
 * @interface InfoMessage
 */
export interface InfoMessage {
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface InsertEventBody
 */
export interface InsertEventBody {
    /**
     * Event code
     * @type {string}
     * @memberof InsertEventBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof InsertEventBody
     */
    'assignedUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertEventBody
     */
    'petId': string;
    /**
     * Event attributes
     * @type {object}
     * @memberof InsertEventBody
     */
    'items': object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Interval = {
    Month: 'month',
    Week: 'week',
    Day: 'day',
    All: 'all'
} as const;

export type Interval = typeof Interval[keyof typeof Interval];


/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'petId': string;
}
/**
 * 
 * @export
 * @interface InvitationInfoRequest
 */
export interface InvitationInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationInfoRequest
     */
    'invitationCode': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationInfoRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface InvitationInfoResponse
 */
export interface InvitationInfoResponse {
    /**
     * 
     * @type {Team}
     * @memberof InvitationInfoResponse
     */
    'team': Team;
}
/**
 * 
 * @export
 * @interface InviteTeamMembersRequest
 */
export interface InviteTeamMembersRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteTeamMembersRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteTeamMembersRequest
     */
    'teamId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteTeamMembersRequest
     */
    'userEmails'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InviteTeamMembersRequestAllOf
 */
export interface InviteTeamMembersRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof InviteTeamMembersRequestAllOf
     */
    'teamId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteTeamMembersRequestAllOf
     */
    'userEmails'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Language = {
    En: 'en',
    De: 'de',
    Cs: 'cs'
} as const;

export type Language = typeof Language[keyof typeof Language];


/**
 * 
 * @export
 * @interface LatestBarkfieRequest
 */
export interface LatestBarkfieRequest {
    /**
     * 
     * @type {number}
     * @memberof LatestBarkfieRequest
     */
    'sectionLimit': number;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof LatestBarkfieRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface LatestPetBarkfies
 */
export interface LatestPetBarkfies {
    /**
     * 
     * @type {string}
     * @memberof LatestPetBarkfies
     */
    'petId': string;
    /**
     * 
     * @type {Array<Barkfie>}
     * @memberof LatestPetBarkfies
     */
    'morning': Array<Barkfie>;
    /**
     * 
     * @type {Array<Barkfie>}
     * @memberof LatestPetBarkfies
     */
    'day': Array<Barkfie>;
    /**
     * 
     * @type {Array<Barkfie>}
     * @memberof LatestPetBarkfies
     */
    'night': Array<Barkfie>;
}
/**
 * 
 * @export
 * @interface LeaveGroupRequest
 */
export interface LeaveGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaveGroupRequest
     */
    'groupId': string;
}
/**
 * 
 * @export
 * @interface ListItem
 */
export interface ListItem {
    /**
     * 
     * @type {string}
     * @memberof ListItem
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ListItem
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface NewPet
 */
export interface NewPet {
    /**
     * 
     * @type {string}
     * @memberof NewPet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPet
     */
    'breedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPet
     */
    'gender'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewPet
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewPet
     */
    'activityGoal'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewPet
     */
    'foodGoal'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPet
     */
    'timezoneId'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof NewPet
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface NotificationTime
 */
export interface NotificationTime {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationTime
     */
    'notification': boolean;
    /**
     * 
     * @type {Time}
     * @memberof NotificationTime
     */
    'time': Time;
}
/**
 * 
 * @export
 * @interface NotificationTimes
 */
export interface NotificationTimes {
    /**
     * 
     * @type {NotificationTime}
     * @memberof NotificationTimes
     */
    'monday': NotificationTime;
    /**
     * 
     * @type {NotificationTime}
     * @memberof NotificationTimes
     */
    'tuesday': NotificationTime;
    /**
     * 
     * @type {NotificationTime}
     * @memberof NotificationTimes
     */
    'wednesday': NotificationTime;
    /**
     * 
     * @type {NotificationTime}
     * @memberof NotificationTimes
     */
    'thursday': NotificationTime;
    /**
     * 
     * @type {NotificationTime}
     * @memberof NotificationTimes
     */
    'friday': NotificationTime;
    /**
     * 
     * @type {NotificationTime}
     * @memberof NotificationTimes
     */
    'saturday': NotificationTime;
    /**
     * 
     * @type {NotificationTime}
     * @memberof NotificationTimes
     */
    'sunday': NotificationTime;
}
/**
 * 
 * @export
 * @interface NotifyRequest
 */
export interface NotifyRequest {
    /**
     * 
     * @type {string}
     * @memberof NotifyRequest
     */
    'topic': NotifyRequestTopicEnum;
    /**
     * 
     * @type {number}
     * @memberof NotifyRequest
     */
    'count'?: number;
}

export const NotifyRequestTopicEnum = {
    GroupInvitation: 'groupInvitation',
    EventAssigned: 'eventAssigned',
    EventUnassigned: 'eventUnassigned',
    EventCompleted: 'eventCompleted',
    EventDueSoon: 'eventDueSoon',
    EventOverdue: 'eventOverdue',
    EventTimeChanged: 'eventTimeChanged',
    PetBirthdayToday: 'petBirthdayToday'
} as const;

export type NotifyRequestTopicEnum = typeof NotifyRequestTopicEnum[keyof typeof NotifyRequestTopicEnum];

/**
 * 
 * @export
 * @interface OAuthToken
 */
export interface OAuthToken {
    /**
     * 
     * @type {string}
     * @memberof OAuthToken
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof OAuthToken
     */
    'refreshToken': string;
    /**
     * 
     * @type {string}
     * @memberof OAuthToken
     */
    'tokenType': string;
    /**
     * 
     * @type {number}
     * @memberof OAuthToken
     */
    'issued': number;
    /**
     * 
     * @type {number}
     * @memberof OAuthToken
     */
    'expiresIn': number;
}
/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof Owner
     */
    'pendingInvitation': boolean;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'fullname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'lastActive'?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface Owners
 */
export interface Owners {
    /**
     * 
     * @type {string}
     * @memberof Owners
     */
    'petId': string;
    /**
     * 
     * @type {Array<Owner>}
     * @memberof Owners
     */
    'owners': Array<Owner>;
}
/**
 * 
 * @export
 * @interface PairBowlRequest
 */
export interface PairBowlRequest {
    /**
     * 
     * @type {string}
     * @memberof PairBowlRequest
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof PairBowlRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {DevicePurpose}
     * @memberof PairBowlRequest
     */
    'purpose': DevicePurpose;
    /**
     * 
     * @type {string}
     * @memberof PairBowlRequest
     */
    'name': string;
    /**
     * 
     * @type {Color}
     * @memberof PairBowlRequest
     */
    'color'?: Color;
}
/**
 * 
 * @export
 * @interface PairTrackerRequest
 */
export interface PairTrackerRequest {
    /**
     * 
     * @type {string}
     * @memberof PairTrackerRequest
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof PairTrackerRequest
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PasswordResetCodeRequest
 */
export interface PasswordResetCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetCodeRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetCodeRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetCodeRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PasswordResetEmailRequest
 */
export interface PasswordResetEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetEmailRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'breedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'gender'?: string;
    /**
     * 
     * @type {number}
     * @memberof Pet
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pet
     */
    'activityGoal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pet
     */
    'foodGoal'?: number;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'timezoneId'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof Pet
     */
    'customImageSize'?: CustomImageSizeRequest;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'role'?: string;
    /**
     * 
     * @type {Images}
     * @memberof Pet
     */
    'images'?: Images;
    /**
     * 
     * @type {boolean}
     * @memberof Pet
     */
    'hasActivity'?: boolean;
    /**
     * 
     * @type {Array<Owner>}
     * @memberof Pet
     */
    'owners'?: Array<Owner>;
    /**
     * 
     * @type {boolean}
     * @memberof Pet
     */
    'hasTracker'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'species'?: string;
    /**
     * 
     * @type {PetState}
     * @memberof Pet
     */
    'state': PetState;
}
/**
 * 
 * @export
 * @interface PetAllOf
 */
export interface PetAllOf {
    /**
     * 
     * @type {string}
     * @memberof PetAllOf
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PetAllOf
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetAllOf
     */
    'role'?: string;
    /**
     * 
     * @type {Images}
     * @memberof PetAllOf
     */
    'images'?: Images;
    /**
     * 
     * @type {boolean}
     * @memberof PetAllOf
     */
    'hasActivity'?: boolean;
    /**
     * 
     * @type {Array<Owner>}
     * @memberof PetAllOf
     */
    'owners'?: Array<Owner>;
    /**
     * 
     * @type {boolean}
     * @memberof PetAllOf
     */
    'hasTracker'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PetAllOf
     */
    'species'?: string;
    /**
     * 
     * @type {PetState}
     * @memberof PetAllOf
     */
    'state': PetState;
}
/**
 * 
 * @export
 * @interface PetBarkfieCount
 */
export interface PetBarkfieCount {
    /**
     * 
     * @type {string}
     * @memberof PetBarkfieCount
     */
    'petId': string;
    /**
     * 
     * @type {number}
     * @memberof PetBarkfieCount
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PetEntity
 */
export interface PetEntity {
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'gender': string;
    /**
     * 
     * @type {Species}
     * @memberof PetEntity
     */
    'species': Species;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'breedId': string;
    /**
     * 
     * @type {Images}
     * @memberof PetEntity
     */
    'images': Images;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'adoptedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'tagNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface PetFeedRequest
 */
export interface PetFeedRequest {
    /**
     * 
     * @type {string}
     * @memberof PetFeedRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetFeedRequest
     */
    'petId': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof PetFeedRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
    /**
     * 
     * @type {string}
     * @memberof PetFeedRequest
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof PetFeedRequest
     */
    'toDate': string;
}
/**
 * 
 * @export
 * @interface PetFeedRequestAllOf
 */
export interface PetFeedRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof PetFeedRequestAllOf
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof PetFeedRequestAllOf
     */
    'toDate': string;
}
/**
 * 
 * @export
 * @interface PetFeedResponse
 */
export interface PetFeedResponse {
    /**
     * 
     * @type {Array<FeedObject>}
     * @memberof PetFeedResponse
     */
    'feed': Array<FeedObject>;
    /**
     * 
     * @type {Array<FeedObject>}
     * @memberof PetFeedResponse
     */
    'water': Array<FeedObject>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PetState = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Archived: 'ARCHIVED',
    Deleted: 'DELETED'
} as const;

export type PetState = typeof PetState[keyof typeof PetState];


/**
 * 
 * @export
 * @interface Pets
 */
export interface Pets {
    /**
     * 
     * @type {Array<Pet>}
     * @memberof Pets
     */
    'pets': Array<Pet>;
}
/**
 * 
 * @export
 * @interface PetsDashboard
 */
export interface PetsDashboard {
    /**
     * 
     * @type {Array<Dashboard>}
     * @memberof PetsDashboard
     */
    'dashboards': Array<Dashboard>;
}
/**
 * 
 * @export
 * @interface PushNotificationTypes
 */
export interface PushNotificationTypes {
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationTypes
     */
    'groupInvitation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationTypes
     */
    'eventAssigned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationTypes
     */
    'eventUnassigned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationTypes
     */
    'eventCompleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationTypes
     */
    'eventDueSoon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationTypes
     */
    'eventOverdue'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationTypes
     */
    'eventTimeChanged'?: boolean;
}
/**
 * 
 * @export
 * @interface RefreshBowlTokenRequest
 */
export interface RefreshBowlTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshBowlTokenRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RemoveUserFromGroupRequest
 */
export interface RemoveUserFromGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveUserFromGroupRequest
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface SavePet
 */
export interface SavePet {
    /**
     * 
     * @type {string}
     * @memberof SavePet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePet
     */
    'breedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePet
     */
    'gender'?: string;
    /**
     * 
     * @type {number}
     * @memberof SavePet
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof SavePet
     */
    'activityGoal'?: number;
    /**
     * 
     * @type {number}
     * @memberof SavePet
     */
    'foodGoal'?: number;
    /**
     * 
     * @type {string}
     * @memberof SavePet
     */
    'timezoneId'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof SavePet
     */
    'customImageSize'?: CustomImageSizeRequest;
    /**
     * 
     * @type {string}
     * @memberof SavePet
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePet
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePet
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface SavePetAllOf
 */
export interface SavePetAllOf {
    /**
     * 
     * @type {string}
     * @memberof SavePetAllOf
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePetAllOf
     */
    'petId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePetAllOf
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface Screen
 */
export interface Screen {
    /**
     * 
     * @type {boolean}
     * @memberof Screen
     */
    'isQuestion': boolean;
    /**
     * 
     * @type {string}
     * @memberof Screen
     */
    'template': string;
    /**
     * 
     * @type {Array<ScreenElements>}
     * @memberof Screen
     */
    'screenElements': Array<ScreenElements>;
}
/**
 * 
 * @export
 * @interface ScreenElements
 */
export interface ScreenElements {
    /**
     * 
     * @type {string}
     * @memberof ScreenElements
     */
    'type': string;
    /**
     * 
     * @type {ScreenElementsPayload}
     * @memberof ScreenElements
     */
    'payload': ScreenElementsPayload;
}
/**
 * 
 * @export
 * @interface ScreenElementsObject
 */
export interface ScreenElementsObject {
    /**
     * 
     * @type {Array<ScreenElements>}
     * @memberof ScreenElementsObject
     */
    'screenElements': Array<ScreenElements>;
}
/**
 * 
 * @export
 * @interface ScreenElementsPayload
 */
export interface ScreenElementsPayload {
    /**
     * 
     * @type {string}
     * @memberof ScreenElementsPayload
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface SendGroupInvitationRequest
 */
export interface SendGroupInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof SendGroupInvitationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SendGroupInvitationRequest
     */
    'groupId': string;
}
/**
 * 
 * @export
 * @interface SendInvitation
 */
export interface SendInvitation {
    /**
     * 
     * @type {string}
     * @memberof SendInvitation
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendInvitation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SendInvitation
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof SendInvitation
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SendInvitationAllOf
 */
export interface SendInvitationAllOf {
    /**
     * 
     * @type {string}
     * @memberof SendInvitationAllOf
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SleepActivityType = {
    Sleep: 'sleep',
    Wriggle: 'wriggle',
    WokeUp: 'wokeUp'
} as const;

export type SleepActivityType = typeof SleepActivityType[keyof typeof SleepActivityType];


/**
 * 
 * @export
 * @interface SleepDashboardActivities
 */
export interface SleepDashboardActivities {
    /**
     * 
     * @type {Array<SleepDashboardHour>}
     * @memberof SleepDashboardActivities
     */
    'hours'?: Array<SleepDashboardHour>;
    /**
     * 
     * @type {Array<SleepDashboardDay>}
     * @memberof SleepDashboardActivities
     */
    'days'?: Array<SleepDashboardDay>;
}
/**
 * 
 * @export
 * @interface SleepDashboardDay
 */
export interface SleepDashboardDay {
    /**
     * 
     * @type {string}
     * @memberof SleepDashboardDay
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardDay
     */
    'sleep': number;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardDay
     */
    'wriggle': number;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardDay
     */
    'wokeUp': number;
}
/**
 * 
 * @export
 * @interface SleepDashboardHour
 */
export interface SleepDashboardHour {
    /**
     * 
     * @type {string}
     * @memberof SleepDashboardHour
     */
    'date': string;
    /**
     * 
     * @type {Array<SleepMinuteRange>}
     * @memberof SleepDashboardHour
     */
    'activity': Array<SleepMinuteRange>;
}
/**
 * 
 * @export
 * @interface SleepDashboardRequest
 */
export interface SleepDashboardRequest {
    /**
     * 
     * @type {string}
     * @memberof SleepDashboardRequest
     */
    'petId': string;
    /**
     * 
     * @type {Interval}
     * @memberof SleepDashboardRequest
     */
    'interval': Interval;
    /**
     * 
     * @type {string}
     * @memberof SleepDashboardRequest
     */
    'date': string;
    /**
     * 
     * @type {TimeUnit}
     * @memberof SleepDashboardRequest
     */
    'timeUnit': TimeUnit;
}
/**
 * 
 * @export
 * @interface SleepDashboardResponse
 */
export interface SleepDashboardResponse {
    /**
     * 
     * @type {string}
     * @memberof SleepDashboardResponse
     */
    'date'?: string;
    /**
     * 
     * @type {SleepDashboardActivities}
     * @memberof SleepDashboardResponse
     */
    'activities': SleepDashboardActivities;
    /**
     * 
     * @type {SleepDashboardSummary}
     * @memberof SleepDashboardResponse
     */
    'summary': SleepDashboardSummary;
}
/**
 * 
 * @export
 * @interface SleepDashboardSummary
 */
export interface SleepDashboardSummary {
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummary
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummary
     */
    'score': number;
    /**
     * 
     * @type {SleepDashboardSummaryActivity}
     * @memberof SleepDashboardSummary
     */
    'activity': SleepDashboardSummaryActivity;
}
/**
 * 
 * @export
 * @interface SleepDashboardSummaryActivity
 */
export interface SleepDashboardSummaryActivity {
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummaryActivity
     */
    'sleep': number;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummaryActivity
     */
    'wriggle': number;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummaryActivity
     */
    'wokeUp': number;
    /**
     * 
     * @type {SleepDashboardSummaryActivityPercent}
     * @memberof SleepDashboardSummaryActivity
     */
    'percent': SleepDashboardSummaryActivityPercent;
}
/**
 * 
 * @export
 * @interface SleepDashboardSummaryActivityPercent
 */
export interface SleepDashboardSummaryActivityPercent {
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummaryActivityPercent
     */
    'sleep': number;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummaryActivityPercent
     */
    'wriggle': number;
    /**
     * 
     * @type {number}
     * @memberof SleepDashboardSummaryActivityPercent
     */
    'wokeUp': number;
}
/**
 * 
 * @export
 * @interface SleepMinuteRange
 */
export interface SleepMinuteRange {
    /**
     * 
     * @type {SleepActivityType}
     * @memberof SleepMinuteRange
     */
    'type': SleepActivityType;
    /**
     * 
     * @type {number}
     * @memberof SleepMinuteRange
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof SleepMinuteRange
     */
    'till': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Species = {
    Dog: 'dog',
    Cat: 'cat'
} as const;

export type Species = typeof Species[keyof typeof Species];


/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {Images}
     * @memberof Team
     */
    'image'?: Images;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'createdByUserId': string;
}
/**
 * 
 * @export
 * @interface TeamActivityRequest
 */
export interface TeamActivityRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamActivityRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamActivityRequest
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamActivityRequest
     */
    'period': TeamActivityRequestPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamActivityRequest
     */
    'startDate': string;
}

export const TeamActivityRequestPeriodEnum = {
    Week: 'week',
    Month: 'month'
} as const;

export type TeamActivityRequestPeriodEnum = typeof TeamActivityRequestPeriodEnum[keyof typeof TeamActivityRequestPeriodEnum];

/**
 * 
 * @export
 * @interface TeamActivityResponse
 */
export interface TeamActivityResponse {
    /**
     * 
     * @type {number}
     * @memberof TeamActivityResponse
     */
    'total': number;
    /**
     * 
     * @type {Array<TeamActivityResponseActivitiesInner>}
     * @memberof TeamActivityResponse
     */
    'activities': Array<TeamActivityResponseActivitiesInner>;
}
/**
 * 
 * @export
 * @interface TeamActivityResponseActivitiesInner
 */
export interface TeamActivityResponseActivitiesInner {
    /**
     * 
     * @type {string}
     * @memberof TeamActivityResponseActivitiesInner
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof TeamActivityResponseActivitiesInner
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface TeamCharityRequest
 */
export interface TeamCharityRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamCharityRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamCharityRequest
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamCharityRequest
     */
    'period': TeamCharityRequestPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamCharityRequest
     */
    'startDate': string;
}

export const TeamCharityRequestPeriodEnum = {
    Week: 'week',
    Month: 'month'
} as const;

export type TeamCharityRequestPeriodEnum = typeof TeamCharityRequestPeriodEnum[keyof typeof TeamCharityRequestPeriodEnum];

/**
 * 
 * @export
 * @interface TeamCharityResponse
 */
export interface TeamCharityResponse {
    /**
     * 
     * @type {number}
     * @memberof TeamCharityResponse
     */
    'points': number;
    /**
     * 
     * @type {number}
     * @memberof TeamCharityResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof TeamCharityResponse
     */
    'currency': string;
    /**
     * 
     * @type {Array<TeamCharityResponsePetsInner>}
     * @memberof TeamCharityResponse
     */
    'pets': Array<TeamCharityResponsePetsInner>;
}
/**
 * 
 * @export
 * @interface TeamCharityResponsePetsInner
 */
export interface TeamCharityResponsePetsInner {
    /**
     * 
     * @type {string}
     * @memberof TeamCharityResponsePetsInner
     */
    'petId': string;
    /**
     * 
     * @type {number}
     * @memberof TeamCharityResponsePetsInner
     */
    'points': number;
    /**
     * 
     * @type {number}
     * @memberof TeamCharityResponsePetsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof TeamCharityResponsePetsInner
     */
    'currency': string;
}
/**
 * 
 * @export
 * @interface TeamDashboardPet
 */
export interface TeamDashboardPet {
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardPet
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardPet
     */
    'ownerId': string;
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPet
     */
    'rank': number;
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardPet
     */
    'addedAt': string;
    /**
     * 
     * @type {TeamDashboardPetActivity}
     * @memberof TeamDashboardPet
     */
    'activity': TeamDashboardPetActivity;
    /**
     * 
     * @type {TeamDashboardPetRest}
     * @memberof TeamDashboardPet
     */
    'rest': TeamDashboardPetRest;
}
/**
 * 
 * @export
 * @interface TeamDashboardPetActivity
 */
export interface TeamDashboardPetActivity {
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPetActivity
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPetActivity
     */
    'veryActive': number;
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPetActivity
     */
    'morning': number;
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPetActivity
     */
    'night': number;
}
/**
 * 
 * @export
 * @interface TeamDashboardPetRest
 */
export interface TeamDashboardPetRest {
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPetRest
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPetRest
     */
    'morning': number;
    /**
     * 
     * @type {number}
     * @memberof TeamDashboardPetRest
     */
    'night': number;
}
/**
 * 
 * @export
 * @interface TeamDashboardRequest
 */
export interface TeamDashboardRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardRequest
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardRequest
     */
    'period': TeamDashboardRequestPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardRequest
     */
    'startDate': string;
    /**
     * 
     * @type {ChallengeCategory}
     * @memberof TeamDashboardRequest
     */
    'category': ChallengeCategory;
}

export const TeamDashboardRequestPeriodEnum = {
    Week: 'week',
    Month: 'month'
} as const;

export type TeamDashboardRequestPeriodEnum = typeof TeamDashboardRequestPeriodEnum[keyof typeof TeamDashboardRequestPeriodEnum];

/**
 * 
 * @export
 * @interface TeamDashboardResponse
 */
export interface TeamDashboardResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardResponse
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof TeamDashboardResponse
     */
    'category': string;
    /**
     * 
     * @type {Array<TeamDashboardPet>}
     * @memberof TeamDashboardResponse
     */
    'dashboard': Array<TeamDashboardPet>;
}
/**
 * 
 * @export
 * @interface TeamPetDashboardRequest
 */
export interface TeamPetDashboardRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardRequest
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardRequest
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardRequest
     */
    'period': TeamPetDashboardRequestPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardRequest
     */
    'startDate': string;
}

export const TeamPetDashboardRequestPeriodEnum = {
    Week: 'week',
    Month: 'month'
} as const;

export type TeamPetDashboardRequestPeriodEnum = typeof TeamPetDashboardRequestPeriodEnum[keyof typeof TeamPetDashboardRequestPeriodEnum];

/**
 * 
 * @export
 * @interface TeamPetDashboardResponse
 */
export interface TeamPetDashboardResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardResponse
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof TeamPetDashboardResponse
     */
    'petId': string;
    /**
     * 
     * @type {Array<DateValuePair>}
     * @memberof TeamPetDashboardResponse
     */
    'activity': Array<DateValuePair>;
    /**
     * 
     * @type {Array<DateValuePair>}
     * @memberof TeamPetDashboardResponse
     */
    'rest': Array<DateValuePair>;
    /**
     * 
     * @type {TeamPetDashboardResponseRankings}
     * @memberof TeamPetDashboardResponse
     */
    'rankings': TeamPetDashboardResponseRankings;
}
/**
 * 
 * @export
 * @interface TeamPetDashboardResponseRankings
 */
export interface TeamPetDashboardResponseRankings {
    /**
     * 
     * @type {number}
     * @memberof TeamPetDashboardResponseRankings
     */
    'sleeper': number;
    /**
     * 
     * @type {number}
     * @memberof TeamPetDashboardResponseRankings
     */
    'night_owl': number;
    /**
     * 
     * @type {number}
     * @memberof TeamPetDashboardResponseRankings
     */
    'smooth': number;
    /**
     * 
     * @type {number}
     * @memberof TeamPetDashboardResponseRankings
     */
    'speedster': number;
    /**
     * 
     * @type {number}
     * @memberof TeamPetDashboardResponseRankings
     */
    'early_bird': number;
    /**
     * 
     * @type {number}
     * @memberof TeamPetDashboardResponseRankings
     */
    'master': number;
}
/**
 * 
 * @export
 * @interface Teams
 */
export interface Teams {
    /**
     * 
     * @type {Array<Team>}
     * @memberof Teams
     */
    'teams': Array<Team>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Temperature = {
    C: 'C',
    F: 'F'
} as const;

export type Temperature = typeof Temperature[keyof typeof Temperature];


/**
 * 
 * @export
 * @interface Time
 */
export interface Time {
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    'hour': number;
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    'minute': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TimeFormat = {
    _12h: '12h',
    _24h: '24h'
} as const;

export type TimeFormat = typeof TimeFormat[keyof typeof TimeFormat];


/**
 * 
 * @export
 * @enum {string}
 */

export const TimeUnit = {
    Hour: 'hour',
    Minute: 'minute'
} as const;

export type TimeUnit = typeof TimeUnit[keyof typeof TimeUnit];


/**
 * 
 * @export
 * @interface Timezone
 */
export interface Timezone {
    /**
     * 
     * @type {string}
     * @memberof Timezone
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Timezone
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Timezone
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Timezone
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface Timezones
 */
export interface Timezones {
    /**
     * 
     * @type {Array<Timezone>}
     * @memberof Timezones
     */
    'items': Array<Timezone>;
}
/**
 * 
 * @export
 * @interface TrackUserRequest
 */
export interface TrackUserRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackUserRequest
     */
    'event_name': string;
    /**
     * 
     * @type {string}
     * @memberof TrackUserRequest
     */
    'user_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof TrackUserRequest
     */
    'properties': object;
}
/**
 * 
 * @export
 * @interface Tracking
 */
export interface Tracking {
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'startedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'stoppedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Tracking
     */
    'distance': number;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface Unit
 */
export interface Unit {
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'abbr'?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'measure'?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'system'?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'singular'?: string;
    /**
     * 
     * @type {string}
     * @memberof Unit
     */
    'plural'?: string;
}
/**
 * 
 * @export
 * @interface Units
 */
export interface Units {
    /**
     * 
     * @type {Array<Unit>}
     * @memberof Units
     */
    'metric': Array<Unit>;
    /**
     * 
     * @type {Array<Unit>}
     * @memberof Units
     */
    'imperial': Array<Unit>;
}
/**
 * 
 * @export
 * @interface UpdateEventBody
 */
export interface UpdateEventBody {
    /**
     * Event code
     * @type {string}
     * @memberof UpdateEventBody
     */
    'code': string;
    /**
     * Event ID
     * @type {string}
     * @memberof UpdateEventBody
     */
    'id': string;
    /**
     * Event attributes to update
     * @type {object}
     * @memberof UpdateEventBody
     */
    'items': object;
}
/**
 * 
 * @export
 * @interface UploadHeaders
 */
export interface UploadHeaders {
    /**
     * 
     * @type {string}
     * @memberof UploadHeaders
     */
    'contentType': string;
}
/**
 * 
 * @export
 * @interface UploadHeadersResponse
 */
export interface UploadHeadersResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadHeadersResponse
     */
    'headers': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLogin': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'birthday': string;
    /**
     * 
     * @type {Images}
     * @memberof User
     */
    'images': Images;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'fbId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'fwPrerelease': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastActive'?: string;
}
/**
 * 
 * @export
 * @interface UserBarkfiesRequest
 */
export interface UserBarkfiesRequest {
    /**
     * 
     * @type {string}
     * @memberof UserBarkfiesRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBarkfiesRequest
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof UserBarkfiesRequest
     */
    'toDate': string;
}
/**
 * 
 * @export
 * @interface UserDevicesRequest
 */
export interface UserDevicesRequest {
    /**
     * 
     * @type {string}
     * @memberof UserDevicesRequest
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface UserDevicesResponse
 */
export interface UserDevicesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDevicesResponse
     */
    'devices': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserDevicesResponse
     */
    'lastTimestamps': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UserDevicesResponse
     */
    'timestamp': number;
}
/**
 * 
 * @export
 * @interface UserEntity
 */
export interface UserEntity {
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    'name': string;
    /**
     * 
     * @type {Images}
     * @memberof UserEntity
     */
    'images': Images;
}
/**
 * 
 * @export
 * @interface UserId
 */
export interface UserId {
    /**
     * 
     * @type {string}
     * @memberof UserId
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserPet
 */
export interface UserPet {
    /**
     * 
     * @type {string}
     * @memberof UserPet
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPet
     */
    'petId': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof UserPet
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface UserPetInfo
 */
export interface UserPetInfo {
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'species': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'breedId': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'birthday': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInfo
     */
    'role': string;
    /**
     * 
     * @type {Images}
     * @memberof UserPetInfo
     */
    'images': Images;
}
/**
 * 
 * @export
 * @interface UserPetInvitationAcceptRequest
 */
export interface UserPetInvitationAcceptRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPetInvitationAcceptRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPetInvitationAcceptRequest
     */
    'invitationCode': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof UserPetInvitationAcceptRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface UserPetOwnerDeleteRequest
 */
export interface UserPetOwnerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPetOwnerDeleteRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPetOwnerDeleteRequest
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof UserPetOwnerDeleteRequest
     */
    'ownerId': string;
}
/**
 * 
 * @export
 * @interface UserPetOwnersRequest
 */
export interface UserPetOwnersRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPetOwnersRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPetOwnersRequest
     */
    'petId': string;
}
/**
 * 
 * @export
 * @interface UserPetsRequest
 */
export interface UserPetsRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPetsRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof UserPetsRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
    /**
     * 
     * @type {boolean}
     * @memberof UserPetsRequest
     */
    'withOwners'?: boolean;
}
/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof UserRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {Distance}
     * @memberof UserSettings
     */
    'distance'?: Distance;
    /**
     * 
     * @type {Weight}
     * @memberof UserSettings
     */
    'weight'?: Weight;
    /**
     * 
     * @type {Temperature}
     * @memberof UserSettings
     */
    'temperature'?: Temperature;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'currency'?: string;
    /**
     * 
     * @type {Volume}
     * @memberof UserSettings
     */
    'volume'?: Volume;
    /**
     * 
     * @type {TimeFormat}
     * @memberof UserSettings
     */
    'timeFormat'?: TimeFormat;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    'marketingMaterialsConsent'?: boolean;
    /**
     * 
     * @type {Language}
     * @memberof UserSettings
     */
    'language'?: Language;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    'showHelp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    'pushNotifications'?: boolean;
    /**
     * 
     * @type {PushNotificationTypes}
     * @memberof UserSettings
     */
    'pushNotificationsDetailed'?: PushNotificationTypes;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'timezoneId'?: string;
}
/**
 * 
 * @export
 * @interface UserSettingsUpdate
 */
export interface UserSettingsUpdate {
    /**
     * 
     * @type {Distance}
     * @memberof UserSettingsUpdate
     */
    'distance'?: Distance;
    /**
     * 
     * @type {Weight}
     * @memberof UserSettingsUpdate
     */
    'weight'?: Weight;
    /**
     * 
     * @type {Temperature}
     * @memberof UserSettingsUpdate
     */
    'temperature'?: Temperature;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsUpdate
     */
    'currency'?: string;
    /**
     * 
     * @type {Volume}
     * @memberof UserSettingsUpdate
     */
    'volume'?: Volume;
    /**
     * 
     * @type {TimeFormat}
     * @memberof UserSettingsUpdate
     */
    'timeFormat'?: TimeFormat;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsUpdate
     */
    'marketingMaterialsConsent'?: boolean;
    /**
     * 
     * @type {Language}
     * @memberof UserSettingsUpdate
     */
    'language'?: Language;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsUpdate
     */
    'showHelp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsUpdate
     */
    'pushNotifications'?: boolean;
    /**
     * 
     * @type {PushNotificationTypes}
     * @memberof UserSettingsUpdate
     */
    'pushNotificationsDetailed'?: PushNotificationTypes;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsUpdate
     */
    'timezoneId'?: string;
}
/**
 * 
 * @export
 * @interface UserTeamRequest
 */
export interface UserTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof UserTeamRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamRequest
     */
    'teamId': string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof UserTeamRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface UserTeamResponse
 */
export interface UserTeamResponse {
    /**
     * 
     * @type {Team}
     * @memberof UserTeamResponse
     */
    'team': Team;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserTeamResponse
     */
    'members'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserTeamResponse
     */
    'pets'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserTeamSaveRequest
 */
export interface UserTeamSaveRequest {
    /**
     * 
     * @type {string}
     * @memberof UserTeamSaveRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamSaveRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamSaveRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamSaveRequest
     */
    'image'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof UserTeamSaveRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface UserTeamUserUpdateRequest
 */
export interface UserTeamUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserTeamUserUpdateRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamUserUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamUserUpdateRequest
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamUserUpdateRequest
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTeamUserUpdateRequest
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'country'?: string;
    /**
     * 
     * @type {CustomImageSizeRequest}
     * @memberof UserUpdateRequest
     */
    'customImageSize'?: CustomImageSizeRequest;
}
/**
 * 
 * @export
 * @interface Veterinarians
 */
export interface Veterinarians {
    /**
     * 
     * @type {Array<string>}
     * @memberof Veterinarians
     */
    'veterinarians': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Volume = {
    Ml: 'ml',
    Oz: 'oz'
} as const;

export type Volume = typeof Volume[keyof typeof Volume];


/**
 * 
 * @export
 * @enum {string}
 */

export const Weight = {
    Kg: 'kg',
    Lbs: 'lbs'
} as const;

export type Weight = typeof Weight[keyof typeof Weight];



/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register push token for anonymous user
         * @param {object} registerAnonymousPushTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAnonymousPushToken: async (registerAnonymousPushTokenBody: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerAnonymousPushTokenBody' is not null or undefined
            assertParamExists('registerAnonymousPushToken', 'registerAnonymousPushTokenBody', registerAnonymousPushTokenBody)
            const localVarPath = `/push/register-anonymous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerAnonymousPushTokenBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register push token or invalidate push token for current user
         * @param {object} registerPushTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPushToken: async (registerPushTokenBody: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerPushTokenBody' is not null or undefined
            assertParamExists('registerPushToken', 'registerPushTokenBody', registerPushTokenBody)
            const localVarPath = `/push/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerPushTokenBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppVersionRequest} appVersionBody verify app version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: async (appVersionBody: AppVersionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appVersionBody' is not null or undefined
            assertParamExists('version', 'appVersionBody', appVersionBody)
            const localVarPath = `/app/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appVersionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register push token for anonymous user
         * @param {object} registerAnonymousPushTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAnonymousPushToken(registerAnonymousPushTokenBody: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAnonymousPushToken(registerAnonymousPushTokenBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register push token or invalidate push token for current user
         * @param {object} registerPushTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPushToken(registerPushTokenBody: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPushToken(registerPushTokenBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppVersionRequest} appVersionBody verify app version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async version(appVersionBody: AppVersionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.version(appVersionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Register push token for anonymous user
         * @param {object} registerAnonymousPushTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAnonymousPushToken(registerAnonymousPushTokenBody: object, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.registerAnonymousPushToken(registerAnonymousPushTokenBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register push token or invalidate push token for current user
         * @param {object} registerPushTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPushToken(registerPushTokenBody: object, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.registerPushToken(registerPushTokenBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppVersionRequest} appVersionBody verify app version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(appVersionBody: AppVersionRequest, options?: any): AxiosPromise<AppVersionResponse> {
            return localVarFp.version(appVersionBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary Register push token for anonymous user
     * @param {object} registerAnonymousPushTokenBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public registerAnonymousPushToken(registerAnonymousPushTokenBody: object, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).registerAnonymousPushToken(registerAnonymousPushTokenBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register push token or invalidate push token for current user
     * @param {object} registerPushTokenBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public registerPushToken(registerPushTokenBody: object, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).registerPushToken(registerPushTokenBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppVersionRequest} appVersionBody verify app version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public version(appVersionBody: AppVersionRequest, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).version(appVersionBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AppLoginRequest} appLoginBody App login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appLogin: async (appLoginBody: AppLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appLoginBody' is not null or undefined
            assertParamExists('appLogin', 'appLoginBody', appLoginBody)
            const localVarPath = `/auth/app/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appLoginBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates access token for bowl
         * @param {UserId} createBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBowlToken: async (createBowlTokenBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBowlTokenBody' is not null or undefined
            assertParamExists('createBowlToken', 'createBowlTokenBody', createBowlTokenBody)
            const localVarPath = `/auth/bowl/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBowlTokenBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookTokenRequest} healthbookTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (healthbookTokenRequest: HealthbookTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookTokenRequest' is not null or undefined
            assertParamExists('createToken', 'healthbookTokenRequest', healthbookTokenRequest)
            const localVarPath = `/auth/healthbook/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FacebookLoginRequest} facebookLoginBody User FB login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookLogin: async (facebookLoginBody: FacebookLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facebookLoginBody' is not null or undefined
            assertParamExists('facebookLogin', 'facebookLoginBody', facebookLoginBody)
            const localVarPath = `/auth/facebook/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facebookLoginBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} loginBody User login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginBody: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginBody' is not null or undefined
            assertParamExists('login', 'loginBody', loginBody)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [emptyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (emptyBody?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emptyBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordChangeRequest} passwordChangeBody Change password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChange: async (passwordChangeBody: PasswordChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChangeBody' is not null or undefined
            assertParamExists('passwordChange', 'passwordChangeBody', passwordChangeBody)
            const localVarPath = `/auth/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordResetCodeRequest} passwordResetBody Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetCode: async (passwordResetBody: PasswordResetCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetBody' is not null or undefined
            assertParamExists('passwordResetCode', 'passwordResetBody', passwordResetBody)
            const localVarPath = `/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordResetEmailRequest} passwordResetEmailBody Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetEmail: async (passwordResetEmailBody: PasswordResetEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetEmailBody' is not null or undefined
            assertParamExists('passwordResetEmail', 'passwordResetEmailBody', passwordResetEmailBody)
            const localVarPath = `/auth/password/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetEmailBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (refreshBody: RefreshBowlTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshBody' is not null or undefined
            assertParamExists('refresh', 'refreshBody', refreshBody)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBowlToken: async (refreshBowlTokenBody: RefreshBowlTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshBowlTokenBody' is not null or undefined
            assertParamExists('refreshBowlToken', 'refreshBowlTokenBody', refreshBowlTokenBody)
            const localVarPath = `/bowl/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshBowlTokenBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterRequest} registerBody User register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerBody: RegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerBody' is not null or undefined
            assertParamExists('register', 'registerBody', registerBody)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} grantType 
         * @param {string} redirectUri 
         * @param {string} clientId 
         * @param {string} clientSecret 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token: async (grantType: string, redirectUri: string, clientId: string, clientSecret: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('token', 'grantType', grantType)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('token', 'redirectUri', redirectUri)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('token', 'clientId', clientId)
            // verify required parameter 'clientSecret' is not null or undefined
            assertParamExists('token', 'clientSecret', clientSecret)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('token', 'code', code)
            const localVarPath = `/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (redirectUri !== undefined) { 
                localVarFormParams.set('redirect_uri', redirectUri as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.set('code', code as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AppLoginRequest} appLoginBody App login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appLogin(appLoginBody: AppLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appLogin(appLoginBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates access token for bowl
         * @param {UserId} createBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBowlToken(createBowlTokenBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBowlToken(createBowlTokenBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookTokenRequest} healthbookTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(healthbookTokenRequest: HealthbookTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthbookTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createToken(healthbookTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FacebookLoginRequest} facebookLoginBody User FB login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async facebookLogin(facebookLoginBody: FacebookLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.facebookLogin(facebookLoginBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginRequest} loginBody User login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginBody: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [emptyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(emptyBody?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(emptyBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordChangeRequest} passwordChangeBody Change password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChange(passwordChangeBody: PasswordChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChange(passwordChangeBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordResetCodeRequest} passwordResetBody Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetCode(passwordResetBody: PasswordResetCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetCode(passwordResetBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordResetEmailRequest} passwordResetEmailBody Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetEmail(passwordResetEmailBody: PasswordResetEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetEmail(passwordResetEmailBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(refreshBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(refreshBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshBowlToken(refreshBowlTokenBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshBowlToken(refreshBowlTokenBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterRequest} registerBody User register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerBody: RegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} grantType 
         * @param {string} redirectUri 
         * @param {string} clientId 
         * @param {string} clientSecret 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async token(grantType: string, redirectUri: string, clientId: string, clientSecret: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.token(grantType, redirectUri, clientId, clientSecret, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {AppLoginRequest} appLoginBody App login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appLogin(appLoginBody: AppLoginRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.appLogin(appLoginBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates access token for bowl
         * @param {UserId} createBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBowlToken(createBowlTokenBody: UserId, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.createBowlToken(createBowlTokenBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookTokenRequest} healthbookTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(healthbookTokenRequest: HealthbookTokenRequest, options?: any): AxiosPromise<HealthbookTokenResponse> {
            return localVarFp.createToken(healthbookTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FacebookLoginRequest} facebookLoginBody User FB login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facebookLogin(facebookLoginBody: FacebookLoginRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.facebookLogin(facebookLoginBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginRequest} loginBody User login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginBody: LoginRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.login(loginBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [emptyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(emptyBody?: object, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.logout(emptyBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordChangeRequest} passwordChangeBody Change password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChange(passwordChangeBody: PasswordChangeRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.passwordChange(passwordChangeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordResetCodeRequest} passwordResetBody Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetCode(passwordResetBody: PasswordResetCodeRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.passwordResetCode(passwordResetBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordResetEmailRequest} passwordResetEmailBody Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetEmail(passwordResetEmailBody: PasswordResetEmailRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.passwordResetEmail(passwordResetEmailBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(refreshBody: RefreshBowlTokenRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.refresh(refreshBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBowlToken(refreshBowlTokenBody: RefreshBowlTokenRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.refreshBowlToken(refreshBowlTokenBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterRequest} registerBody User register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerBody: RegisterRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.register(registerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} grantType 
         * @param {string} redirectUri 
         * @param {string} clientId 
         * @param {string} clientSecret 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        token(grantType: string, redirectUri: string, clientId: string, clientSecret: string, code: string, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.token(grantType, redirectUri, clientId, clientSecret, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AppLoginRequest} appLoginBody App login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public appLogin(appLoginBody: AppLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).appLogin(appLoginBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates access token for bowl
     * @param {UserId} createBowlTokenBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createBowlToken(createBowlTokenBody: UserId, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).createBowlToken(createBowlTokenBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates public URL of healthbook
     * @param {HealthbookTokenRequest} healthbookTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createToken(healthbookTokenRequest: HealthbookTokenRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).createToken(healthbookTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FacebookLoginRequest} facebookLoginBody User FB login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public facebookLogin(facebookLoginBody: FacebookLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).facebookLogin(facebookLoginBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginRequest} loginBody User login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginBody: LoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(loginBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [emptyBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(emptyBody?: object, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(emptyBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordChangeRequest} passwordChangeBody Change password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public passwordChange(passwordChangeBody: PasswordChangeRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).passwordChange(passwordChangeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordResetCodeRequest} passwordResetBody Reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public passwordResetCode(passwordResetBody: PasswordResetCodeRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).passwordResetCode(passwordResetBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordResetEmailRequest} passwordResetEmailBody Reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public passwordResetEmail(passwordResetEmailBody: PasswordResetEmailRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).passwordResetEmail(passwordResetEmailBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshBowlTokenRequest} refreshBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refresh(refreshBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refresh(refreshBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshBowlToken(refreshBowlTokenBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshBowlToken(refreshBowlTokenBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterRequest} registerBody User register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public register(registerBody: RegisterRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).register(registerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} grantType 
     * @param {string} redirectUri 
     * @param {string} clientId 
     * @param {string} clientSecret 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public token(grantType: string, redirectUri: string, clientId: string, clientSecret: string, code: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).token(grantType, redirectUri, clientId, clientSecret, code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AwsApi - axios parameter creator
 * @export
 */
export const AwsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UploadHeaders} getUploadHeadersBody S3 Upload headers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadHeaders: async (getUploadHeadersBody: UploadHeaders, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getUploadHeadersBody' is not null or undefined
            assertParamExists('getUploadHeaders', 'getUploadHeadersBody', getUploadHeadersBody)
            const localVarPath = `/aws/getUploadHeaders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUploadHeadersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AwsApi - functional programming interface
 * @export
 */
export const AwsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AwsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UploadHeaders} getUploadHeadersBody S3 Upload headers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadHeaders(getUploadHeadersBody: UploadHeaders, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadHeadersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadHeaders(getUploadHeadersBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AwsApi - factory interface
 * @export
 */
export const AwsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AwsApiFp(configuration)
    return {
        /**
         * 
         * @param {UploadHeaders} getUploadHeadersBody S3 Upload headers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadHeaders(getUploadHeadersBody: UploadHeaders, options?: any): AxiosPromise<UploadHeadersResponse> {
            return localVarFp.getUploadHeaders(getUploadHeadersBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AwsApi - object-oriented interface
 * @export
 * @class AwsApi
 * @extends {BaseAPI}
 */
export class AwsApi extends BaseAPI {
    /**
     * 
     * @param {UploadHeaders} getUploadHeadersBody S3 Upload headers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwsApi
     */
    public getUploadHeaders(getUploadHeadersBody: UploadHeaders, options?: AxiosRequestConfig) {
        return AwsApiFp(this.configuration).getUploadHeaders(getUploadHeadersBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BarkfieApi - axios parameter creator
 * @export
 */
export const BarkfieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} barkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieCount: async (barkfieCountBody: UserBarkfiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'barkfieCountBody' is not null or undefined
            assertParamExists('barkfieCount', 'barkfieCountBody', barkfieCountBody)
            const localVarPath = `/barkfie/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(barkfieCountBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of barkfies
         * @param {BarkfieRequest} petBarkfiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieList: async (petBarkfiesBody: BarkfieRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBarkfiesBody' is not null or undefined
            assertParamExists('barkfieList', 'petBarkfiesBody', petBarkfiesBody)
            const localVarPath = `/barkfie/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBarkfiesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove barkfie record
         * @param {BarkfieRemove} petBarkfieSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieRemove: async (petBarkfieSaveBody: BarkfieRemove, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBarkfieSaveBody' is not null or undefined
            assertParamExists('barkfieRemove', 'petBarkfieSaveBody', petBarkfieSaveBody)
            const localVarPath = `/barkfie/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBarkfieSaveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save barkfie record
         * @param {BarkfieSave} petBarkfieSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieSave: async (petBarkfieSaveBody: BarkfieSave, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBarkfieSaveBody' is not null or undefined
            assertParamExists('barkfieSave', 'petBarkfieSaveBody', petBarkfieSaveBody)
            const localVarPath = `/barkfie/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBarkfieSaveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Latest barkfies of all pets
         * @param {LatestBarkfieRequest} latestBarkfiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll: async (latestBarkfiesBody: LatestBarkfieRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latestBarkfiesBody' is not null or undefined
            assertParamExists('listAll', 'latestBarkfiesBody', latestBarkfiesBody)
            const localVarPath = `/barkfie/listAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(latestBarkfiesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BarkfieApi - functional programming interface
 * @export
 */
export const BarkfieApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BarkfieApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} barkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async barkfieCount(barkfieCountBody: UserBarkfiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetBarkfieCount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.barkfieCount(barkfieCountBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of barkfies
         * @param {BarkfieRequest} petBarkfiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async barkfieList(petBarkfiesBody: BarkfieRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Barkfie>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.barkfieList(petBarkfiesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove barkfie record
         * @param {BarkfieRemove} petBarkfieSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async barkfieRemove(petBarkfieSaveBody: BarkfieRemove, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.barkfieRemove(petBarkfieSaveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save barkfie record
         * @param {BarkfieSave} petBarkfieSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async barkfieSave(petBarkfieSaveBody: BarkfieSave, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barkfie>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.barkfieSave(petBarkfieSaveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Latest barkfies of all pets
         * @param {LatestBarkfieRequest} latestBarkfiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll(latestBarkfiesBody: LatestBarkfieRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LatestPetBarkfies>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAll(latestBarkfiesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BarkfieApi - factory interface
 * @export
 */
export const BarkfieApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BarkfieApiFp(configuration)
    return {
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} barkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieCount(barkfieCountBody: UserBarkfiesRequest, options?: any): AxiosPromise<Array<PetBarkfieCount>> {
            return localVarFp.barkfieCount(barkfieCountBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of barkfies
         * @param {BarkfieRequest} petBarkfiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieList(petBarkfiesBody: BarkfieRequest, options?: any): AxiosPromise<Array<Barkfie>> {
            return localVarFp.barkfieList(petBarkfiesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove barkfie record
         * @param {BarkfieRemove} petBarkfieSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieRemove(petBarkfieSaveBody: BarkfieRemove, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.barkfieRemove(petBarkfieSaveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save barkfie record
         * @param {BarkfieSave} petBarkfieSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        barkfieSave(petBarkfieSaveBody: BarkfieSave, options?: any): AxiosPromise<Barkfie> {
            return localVarFp.barkfieSave(petBarkfieSaveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Latest barkfies of all pets
         * @param {LatestBarkfieRequest} latestBarkfiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll(latestBarkfiesBody: LatestBarkfieRequest, options?: any): AxiosPromise<Array<LatestPetBarkfies>> {
            return localVarFp.listAll(latestBarkfiesBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BarkfieApi - object-oriented interface
 * @export
 * @class BarkfieApi
 * @extends {BaseAPI}
 */
export class BarkfieApi extends BaseAPI {
    /**
     * 
     * @summary Return count of barkfies in given interval of each pet
     * @param {UserBarkfiesRequest} barkfieCountBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarkfieApi
     */
    public barkfieCount(barkfieCountBody: UserBarkfiesRequest, options?: AxiosRequestConfig) {
        return BarkfieApiFp(this.configuration).barkfieCount(barkfieCountBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of barkfies
     * @param {BarkfieRequest} petBarkfiesBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarkfieApi
     */
    public barkfieList(petBarkfiesBody: BarkfieRequest, options?: AxiosRequestConfig) {
        return BarkfieApiFp(this.configuration).barkfieList(petBarkfiesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove barkfie record
     * @param {BarkfieRemove} petBarkfieSaveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarkfieApi
     */
    public barkfieRemove(petBarkfieSaveBody: BarkfieRemove, options?: AxiosRequestConfig) {
        return BarkfieApiFp(this.configuration).barkfieRemove(petBarkfieSaveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save barkfie record
     * @param {BarkfieSave} petBarkfieSaveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarkfieApi
     */
    public barkfieSave(petBarkfieSaveBody: BarkfieSave, options?: AxiosRequestConfig) {
        return BarkfieApiFp(this.configuration).barkfieSave(petBarkfieSaveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Latest barkfies of all pets
     * @param {LatestBarkfieRequest} latestBarkfiesBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarkfieApi
     */
    public listAll(latestBarkfiesBody: LatestBarkfieRequest, options?: AxiosRequestConfig) {
        return BarkfieApiFp(this.configuration).listAll(latestBarkfiesBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BowlApi - axios parameter creator
 * @export
 */
export const BowlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BowlSyncBulkRequest} bulkSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSync: async (bulkSync: BowlSyncBulkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkSync' is not null or undefined
            assertParamExists('bulkSync', 'bulkSync', bulkSync)
            const localVarPath = `/bowl/device/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates access token for bowl
         * @param {UserId} createBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBowlToken: async (createBowlTokenBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBowlTokenBody' is not null or undefined
            assertParamExists('createBowlToken', 'createBowlTokenBody', createBowlTokenBody)
            const localVarPath = `/auth/bowl/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBowlTokenBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns firmware binary file
         * @param {BowlFirmwareCheckRequest} bowlFwBody 
         * @param {string} [range] required byte range, e.g. \&#39;bytes&#x3D;0-255\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBowlFirmware: async (bowlFwBody: BowlFirmwareCheckRequest, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bowlFwBody' is not null or undefined
            assertParamExists('getBowlFirmware', 'bowlFwBody', bowlFwBody)
            const localVarPath = `/bowl/fw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (range !== undefined && range !== null) {
                localVarHeaderParameter['Range'] = String(range);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bowlFwBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns array of device IDs belonging to bowl\'s owner. For bowl firmware 15+ returns an array of [DEVICE_ID, LAST_TIMESTAMP,...] as binary data.
         * @param {UserDevicesRequest} getUserDevicesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDevices: async (getUserDevicesBody: UserDevicesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getUserDevicesBody' is not null or undefined
            assertParamExists('getUserDevices', 'getUserDevicesBody', getUserDevicesBody)
            const localVarPath = `/bowl/user/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUserDevicesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBowlToken: async (refreshBowlTokenBody: RefreshBowlTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshBowlTokenBody' is not null or undefined
            assertParamExists('refreshBowlToken', 'refreshBowlTokenBody', refreshBowlTokenBody)
            const localVarPath = `/bowl/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshBowlTokenBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BowlSyncRequest} bowlSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync: async (bowlSync: BowlSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bowlSync' is not null or undefined
            assertParamExists('sync', 'bowlSync', bowlSync)
            const localVarPath = `/bowl/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bowlSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BowlApi - functional programming interface
 * @export
 */
export const BowlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BowlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BowlSyncBulkRequest} bulkSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkSync(bulkSync: BowlSyncBulkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkSync(bulkSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates access token for bowl
         * @param {UserId} createBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBowlToken(createBowlTokenBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBowlToken(createBowlTokenBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns firmware binary file
         * @param {BowlFirmwareCheckRequest} bowlFwBody 
         * @param {string} [range] required byte range, e.g. \&#39;bytes&#x3D;0-255\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBowlFirmware(bowlFwBody: BowlFirmwareCheckRequest, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBowlFirmware(bowlFwBody, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns array of device IDs belonging to bowl\'s owner. For bowl firmware 15+ returns an array of [DEVICE_ID, LAST_TIMESTAMP,...] as binary data.
         * @param {UserDevicesRequest} getUserDevicesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDevices(getUserDevicesBody: UserDevicesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDevicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDevices(getUserDevicesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshBowlToken(refreshBowlTokenBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshBowlToken(refreshBowlTokenBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BowlSyncRequest} bowlSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sync(bowlSync: BowlSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sync(bowlSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BowlApi - factory interface
 * @export
 */
export const BowlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BowlApiFp(configuration)
    return {
        /**
         * 
         * @param {BowlSyncBulkRequest} bulkSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkSync(bulkSync: BowlSyncBulkRequest, options?: any): AxiosPromise<any> {
            return localVarFp.bulkSync(bulkSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates access token for bowl
         * @param {UserId} createBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBowlToken(createBowlTokenBody: UserId, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.createBowlToken(createBowlTokenBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns firmware binary file
         * @param {BowlFirmwareCheckRequest} bowlFwBody 
         * @param {string} [range] required byte range, e.g. \&#39;bytes&#x3D;0-255\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBowlFirmware(bowlFwBody: BowlFirmwareCheckRequest, range?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getBowlFirmware(bowlFwBody, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns array of device IDs belonging to bowl\'s owner. For bowl firmware 15+ returns an array of [DEVICE_ID, LAST_TIMESTAMP,...] as binary data.
         * @param {UserDevicesRequest} getUserDevicesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDevices(getUserDevicesBody: UserDevicesRequest, options?: any): AxiosPromise<UserDevicesResponse> {
            return localVarFp.getUserDevices(getUserDevicesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBowlToken(refreshBowlTokenBody: RefreshBowlTokenRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.refreshBowlToken(refreshBowlTokenBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BowlSyncRequest} bowlSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync(bowlSync: BowlSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sync(bowlSync, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BowlApi - object-oriented interface
 * @export
 * @class BowlApi
 * @extends {BaseAPI}
 */
export class BowlApi extends BaseAPI {
    /**
     * 
     * @param {BowlSyncBulkRequest} bulkSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BowlApi
     */
    public bulkSync(bulkSync: BowlSyncBulkRequest, options?: AxiosRequestConfig) {
        return BowlApiFp(this.configuration).bulkSync(bulkSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates access token for bowl
     * @param {UserId} createBowlTokenBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BowlApi
     */
    public createBowlToken(createBowlTokenBody: UserId, options?: AxiosRequestConfig) {
        return BowlApiFp(this.configuration).createBowlToken(createBowlTokenBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns firmware binary file
     * @param {BowlFirmwareCheckRequest} bowlFwBody 
     * @param {string} [range] required byte range, e.g. \&#39;bytes&#x3D;0-255\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BowlApi
     */
    public getBowlFirmware(bowlFwBody: BowlFirmwareCheckRequest, range?: string, options?: AxiosRequestConfig) {
        return BowlApiFp(this.configuration).getBowlFirmware(bowlFwBody, range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns array of device IDs belonging to bowl\'s owner. For bowl firmware 15+ returns an array of [DEVICE_ID, LAST_TIMESTAMP,...] as binary data.
     * @param {UserDevicesRequest} getUserDevicesBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BowlApi
     */
    public getUserDevices(getUserDevicesBody: UserDevicesRequest, options?: AxiosRequestConfig) {
        return BowlApiFp(this.configuration).getUserDevices(getUserDevicesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshBowlTokenRequest} refreshBowlTokenBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BowlApi
     */
    public refreshBowlToken(refreshBowlTokenBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig) {
        return BowlApiFp(this.configuration).refreshBowlToken(refreshBowlTokenBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BowlSyncRequest} bowlSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BowlApi
     */
    public sync(bowlSync: BowlSyncRequest, options?: AxiosRequestConfig) {
        return BowlApiFp(this.configuration).sync(bowlSync, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BreedsApi - axios parameter creator
 * @export
 */
export const BreedsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get breeds
         * @param {string} [language] 
         * @param {string} [species] species are &#x60;dog | cat&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBreeds: async (language?: string, species?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lists/breeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (species !== undefined) {
                localVarQueryParameter['species'] = species;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BreedsApi - functional programming interface
 * @export
 */
export const BreedsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BreedsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get breeds
         * @param {string} [language] 
         * @param {string} [species] species are &#x60;dog | cat&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBreeds(language?: string, species?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Breeds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBreeds(language, species, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BreedsApi - factory interface
 * @export
 */
export const BreedsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BreedsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get breeds
         * @param {string} [language] 
         * @param {string} [species] species are &#x60;dog | cat&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBreeds(language?: string, species?: string, options?: any): AxiosPromise<Breeds> {
            return localVarFp.findBreeds(language, species, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BreedsApi - object-oriented interface
 * @export
 * @class BreedsApi
 * @extends {BaseAPI}
 */
export class BreedsApi extends BaseAPI {
    /**
     * 
     * @summary Get breeds
     * @param {string} [language] 
     * @param {string} [species] species are &#x60;dog | cat&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedsApi
     */
    public findBreeds(language?: string, species?: string, options?: AxiosRequestConfig) {
        return BreedsApiFp(this.configuration).findBreeds(language, species, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevelopmentApi - axios parameter creator
 * @export
 */
export const DevelopmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeedbackRequest} appFeedbackBody provide app feedback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedback: async (appFeedbackBody: FeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appFeedbackBody' is not null or undefined
            assertParamExists('feedback', 'appFeedbackBody', appFeedbackBody)
            const localVarPath = `/development/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appFeedbackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send test notification
         * @param {NotifyRequest} [notifyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notify: async (notifyBody?: NotifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/development/notify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notifyBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevelopmentApi - functional programming interface
 * @export
 */
export const DevelopmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevelopmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeedbackRequest} appFeedbackBody provide app feedback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedback(appFeedbackBody: FeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedback(appFeedbackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send test notification
         * @param {NotifyRequest} [notifyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notify(notifyBody?: NotifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notify(notifyBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevelopmentApi - factory interface
 * @export
 */
export const DevelopmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevelopmentApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedbackRequest} appFeedbackBody provide app feedback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedback(appFeedbackBody: FeedbackRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.feedback(appFeedbackBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send test notification
         * @param {NotifyRequest} [notifyBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notify(notifyBody?: NotifyRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.notify(notifyBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevelopmentApi - object-oriented interface
 * @export
 * @class DevelopmentApi
 * @extends {BaseAPI}
 */
export class DevelopmentApi extends BaseAPI {
    /**
     * 
     * @param {FeedbackRequest} appFeedbackBody provide app feedback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevelopmentApi
     */
    public feedback(appFeedbackBody: FeedbackRequest, options?: AxiosRequestConfig) {
        return DevelopmentApiFp(this.configuration).feedback(appFeedbackBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send test notification
     * @param {NotifyRequest} [notifyBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevelopmentApi
     */
    public notify(notifyBody?: NotifyRequest, options?: AxiosRequestConfig) {
        return DevelopmentApiFp(this.configuration).notify(notifyBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddDeviceUser} deviceAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDevice: async (deviceAddBody: AddDeviceUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceAddBody' is not null or undefined
            assertParamExists('addDevice', 'deviceAddBody', deviceAddBody)
            const localVarPath = `/device/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAddBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceStats: async (deviceStatsAddBody: DeviceStats, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceStatsAddBody' is not null or undefined
            assertParamExists('addDeviceStats', 'deviceStatsAddBody', deviceStatsAddBody)
            const localVarPath = `/internal/device/stats/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceStatsAddBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmDeviceUser} deviceConfirmPasskey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPasskey: async (deviceConfirmPasskey: ConfirmDeviceUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceConfirmPasskey' is not null or undefined
            assertParamExists('confirmPasskey', 'deviceConfirmPasskey', deviceConfirmPasskey)
            const localVarPath = `/device/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceConfirmPasskey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceConnectionLog: async (deviceInfo: Array<ConnectionLogItem>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceInfo' is not null or undefined
            assertParamExists('deviceConnectionLog', 'deviceInfo', deviceInfo)
            const localVarPath = `/internal/device/connectionlog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceId} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfo: async (deviceInfo: DeviceId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceInfo' is not null or undefined
            assertParamExists('deviceInfo', 'deviceInfo', deviceInfo)
            const localVarPath = `/internal/device/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLastSync: async (deviceLastSync: DeviceId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLastSync' is not null or undefined
            assertParamExists('deviceLastSync', 'deviceLastSync', deviceLastSync)
            const localVarPath = `/internal/device/lastsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLastSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceMetadata: async (firmwareCheckBody: DeviceMetadataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmwareCheckBody' is not null or undefined
            assertParamExists('deviceMetadata', 'firmwareCheckBody', firmwareCheckBody)
            const localVarPath = `/internal/device/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication FirmwareAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(firmwareCheckBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync: async (deviceSync: DeviceSync, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSync' is not null or undefined
            assertParamExists('deviceSync', 'deviceSync', deviceSync)
            const localVarPath = `/internal/device/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSyncEcho: async (deviceSync: DeviceSync, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSync' is not null or undefined
            assertParamExists('deviceSyncEcho', 'deviceSync', deviceSync)
            const localVarPath = `/internal/device/syncecho`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceEdit} deviceEditBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDevice: async (deviceEditBody: DeviceEdit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceEditBody' is not null or undefined
            assertParamExists('editDevice', 'deviceEditBody', deviceEditBody)
            const localVarPath = `/device/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceEditBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserId} deviceListBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (deviceListBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceListBody' is not null or undefined
            assertParamExists('list', 'deviceListBody', deviceListBody)
            const localVarPath = `/device/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceListBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PairBowlRequest} pairBowlBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairBowl: async (pairBowlBody: PairBowlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairBowlBody' is not null or undefined
            assertParamExists('pairBowl', 'pairBowlBody', pairBowlBody)
            const localVarPath = `/internal/device/pair-bowl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairBowlBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PairTrackerRequest} pairTrackerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairTracker: async (pairTrackerBody: PairTrackerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairTrackerBody' is not null or undefined
            assertParamExists('pairTracker', 'pairTrackerBody', pairTrackerBody)
            const localVarPath = `/internal/device/pair-tracker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairTrackerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceId} deviceUnpairBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpairDevice: async (deviceUnpairBody: DeviceId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUnpairBody' is not null or undefined
            assertParamExists('unpairDevice', 'deviceUnpairBody', deviceUnpairBody)
            const localVarPath = `/internal/device/unpair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceUnpairBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddDeviceUser} deviceAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDevice(deviceAddBody: AddDeviceUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDevice(deviceAddBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeviceStats(deviceStatsAddBody: DeviceStats, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDeviceStats(deviceStatsAddBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfirmDeviceUser} deviceConfirmPasskey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPasskey(deviceConfirmPasskey: ConfirmDeviceUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPasskey(deviceConfirmPasskey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceConnectionLog(deviceInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceId} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceInfo(deviceInfo: DeviceId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceInfo(deviceInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceLastSync(deviceLastSync: DeviceId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSyncTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceLastSync(deviceLastSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceMetadata(firmwareCheckBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSync(deviceSync: DeviceSync, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSync(deviceSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSyncEcho(deviceSync: DeviceSync, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSyncEcho(deviceSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceEdit} deviceEditBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editDevice(deviceEditBody: DeviceEdit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editDevice(deviceEditBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserId} deviceListBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(deviceListBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Device>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(deviceListBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PairBowlRequest} pairBowlBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pairBowl(pairBowlBody: PairBowlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pairBowl(pairBowlBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PairTrackerRequest} pairTrackerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pairTracker(pairTrackerBody: PairTrackerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pairTracker(pairTrackerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceId} deviceUnpairBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpairDevice(deviceUnpairBody: DeviceId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpairDevice(deviceUnpairBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @param {AddDeviceUser} deviceAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDevice(deviceAddBody: AddDeviceUser, options?: any): AxiosPromise<Device> {
            return localVarFp.addDevice(deviceAddBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceStats(deviceStatsAddBody: DeviceStats, options?: any): AxiosPromise<void> {
            return localVarFp.addDeviceStats(deviceStatsAddBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmDeviceUser} deviceConfirmPasskey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPasskey(deviceConfirmPasskey: ConfirmDeviceUser, options?: any): AxiosPromise<Device> {
            return localVarFp.confirmPasskey(deviceConfirmPasskey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.deviceConnectionLog(deviceInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceId} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfo(deviceInfo: DeviceId, options?: any): AxiosPromise<DeviceInfo> {
            return localVarFp.deviceInfo(deviceInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLastSync(deviceLastSync: DeviceId, options?: any): AxiosPromise<DeviceSyncTime> {
            return localVarFp.deviceLastSync(deviceLastSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: any): AxiosPromise<DeviceMetadataResponse> {
            return localVarFp.deviceMetadata(firmwareCheckBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync(deviceSync: DeviceSync, options?: any): AxiosPromise<void> {
            return localVarFp.deviceSync(deviceSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSyncEcho(deviceSync: DeviceSync, options?: any): AxiosPromise<void> {
            return localVarFp.deviceSyncEcho(deviceSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceEdit} deviceEditBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDevice(deviceEditBody: DeviceEdit, options?: any): AxiosPromise<Device> {
            return localVarFp.editDevice(deviceEditBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserId} deviceListBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(deviceListBody: UserId, options?: any): AxiosPromise<Array<Device>> {
            return localVarFp.list(deviceListBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PairBowlRequest} pairBowlBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairBowl(pairBowlBody: PairBowlRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.pairBowl(pairBowlBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PairTrackerRequest} pairTrackerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairTracker(pairTrackerBody: PairTrackerRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.pairTracker(pairTrackerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceId} deviceUnpairBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpairDevice(deviceUnpairBody: DeviceId, options?: any): AxiosPromise<void> {
            return localVarFp.unpairDevice(deviceUnpairBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @param {AddDeviceUser} deviceAddBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public addDevice(deviceAddBody: AddDeviceUser, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).addDevice(deviceAddBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceStats} deviceStatsAddBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public addDeviceStats(deviceStatsAddBody: DeviceStats, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).addDeviceStats(deviceStatsAddBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfirmDeviceUser} deviceConfirmPasskey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public confirmPasskey(deviceConfirmPasskey: ConfirmDeviceUser, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).confirmPasskey(deviceConfirmPasskey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ConnectionLogItem>} deviceInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceConnectionLog(deviceInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceId} deviceInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceInfo(deviceInfo: DeviceId, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceInfo(deviceInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceId} deviceLastSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceLastSync(deviceLastSync: DeviceId, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceLastSync(deviceLastSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Firmware check
     * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceMetadata(firmwareCheckBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceSync} deviceSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceSync(deviceSync: DeviceSync, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceSync(deviceSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceSync} deviceSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceSyncEcho(deviceSync: DeviceSync, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceSyncEcho(deviceSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceEdit} deviceEditBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public editDevice(deviceEditBody: DeviceEdit, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).editDevice(deviceEditBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserId} deviceListBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public list(deviceListBody: UserId, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).list(deviceListBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PairBowlRequest} pairBowlBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public pairBowl(pairBowlBody: PairBowlRequest, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).pairBowl(pairBowlBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PairTrackerRequest} pairTrackerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public pairTracker(pairTrackerBody: PairTrackerRequest, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).pairTracker(pairTrackerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceId} deviceUnpairBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public unpairDevice(deviceUnpairBody: DeviceId, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).unpairDevice(deviceUnpairBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign event to other user
         * @param {AssignEventRequest} assignEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEvent: async (assignEventRequest: AssignEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignEventRequest' is not null or undefined
            assertParamExists('assignEvent', 'assignEventRequest', assignEventRequest)
            const localVarPath = `/event/user/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign pet to the event
         * @param {AssignEventPetRequest} assignEventPetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEventPet: async (assignEventPetRequest: AssignEventPetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignEventPetRequest' is not null or undefined
            assertParamExists('assignEventPet', 'assignEventPetRequest', assignEventPetRequest)
            const localVarPath = `/event/pet/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignEventPetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete event
         * @param {CompleteEventRequest} completeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeEvent: async (completeEventRequest: CompleteEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeEventRequest' is not null or undefined
            assertParamExists('completeEvent', 'completeEventRequest', completeEventRequest)
            const localVarPath = `/event/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return pets belonging to all groups where the user is a member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignablePets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/assignable-pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns pets created by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserPets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/user/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of events
         * @param {string} [id] 
         * @param {string} [petId] 
         * @param {boolean} [overdue] 
         * @param {number} [count] 
         * @param {string} [fromId] 
         * @param {boolean} [increment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (id?: string, petId?: string, overdue?: boolean, count?: number, fromId?: string, increment?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (petId !== undefined) {
                localVarQueryParameter['petId'] = petId;
            }

            if (overdue !== undefined) {
                localVarQueryParameter['overdue'] = overdue;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (fromId !== undefined) {
                localVarQueryParameter['fromId'] = fromId;
            }

            if (increment !== undefined) {
                localVarQueryParameter['increment'] = increment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get event definition
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDefinition: async (code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/definition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data for followup event
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventFollowup: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventFollowup', 'eventId', eventId)
            const localVarPath = `/event/followup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of event changes in time
         * @param {string} [eventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventHistory: async (eventId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get count of overdue events
         * @param {string} [petId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventOverdueCount: async (petId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/overdue/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (petId !== undefined) {
                localVarQueryParameter['petId'] = petId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all list values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListValues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Insert event with items
         * @param {InsertEventBody} [insertEventBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertEvent: async (insertEventBody?: InsertEventBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertEventBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates one or more event items
         * @param {UpdateEventBody} [updateEventBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: async (updateEventBody?: UpdateEventBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign event to other user
         * @param {AssignEventRequest} assignEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignEvent(assignEventRequest: AssignEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignEvent(assignEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign pet to the event
         * @param {AssignEventPetRequest} assignEventPetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignEventPet(assignEventPetRequest: AssignEventPetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignEventPet(assignEventPetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete event
         * @param {CompleteEventRequest} completeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeEvent(completeEventRequest: CompleteEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeEvent(completeEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return pets belonging to all groups where the user is a member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAssignablePets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAssignablePetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAssignablePets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns pets created by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserPets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserPetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserPets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of events
         * @param {string} [id] 
         * @param {string} [petId] 
         * @param {boolean} [overdue] 
         * @param {number} [count] 
         * @param {string} [fromId] 
         * @param {boolean} [increment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(id?: string, petId?: string, overdue?: boolean, count?: number, fromId?: string, increment?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(id, petId, overdue, count, fromId, increment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get event definition
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventDefinition(code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventDefinition(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get data for followup event
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventFollowup(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventFollowup(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of event changes in time
         * @param {string} [eventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventHistory(eventId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventHistory(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get count of overdue events
         * @param {string} [petId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventOverdueCount(petId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventOverdueCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventOverdueCount(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all list values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListValues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListValues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Insert event with items
         * @param {InsertEventBody} [insertEventBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertEvent(insertEventBody?: InsertEventBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertEvent(insertEventBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates one or more event items
         * @param {UpdateEventBody} [updateEventBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvent(updateEventBody?: UpdateEventBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(updateEventBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign event to other user
         * @param {AssignEventRequest} assignEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEvent(assignEventRequest: AssignEventRequest, options?: any): AxiosPromise<GetEventResponse> {
            return localVarFp.assignEvent(assignEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign pet to the event
         * @param {AssignEventPetRequest} assignEventPetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEventPet(assignEventPetRequest: AssignEventPetRequest, options?: any): AxiosPromise<GetEventResponse> {
            return localVarFp.assignEventPet(assignEventPetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete event
         * @param {CompleteEventRequest} completeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeEvent(completeEventRequest: CompleteEventRequest, options?: any): AxiosPromise<GetEventResponse> {
            return localVarFp.completeEvent(completeEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return pets belonging to all groups where the user is a member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignablePets(options?: any): AxiosPromise<FindAssignablePetsResponse> {
            return localVarFp.findAssignablePets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns pets created by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserPets(options?: any): AxiosPromise<GetUserPetsResponse> {
            return localVarFp.findUserPets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of events
         * @param {string} [id] 
         * @param {string} [petId] 
         * @param {boolean} [overdue] 
         * @param {number} [count] 
         * @param {string} [fromId] 
         * @param {boolean} [increment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id?: string, petId?: string, overdue?: boolean, count?: number, fromId?: string, increment?: boolean, options?: any): AxiosPromise<Array<GetEventResponse>> {
            return localVarFp.getEvent(id, petId, overdue, count, fromId, increment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get event definition
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDefinition(code?: string, options?: any): AxiosPromise<Array<EventDefinition>> {
            return localVarFp.getEventDefinition(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get data for followup event
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventFollowup(eventId: string, options?: any): AxiosPromise<GetEventResponse> {
            return localVarFp.getEventFollowup(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of event changes in time
         * @param {string} [eventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventHistory(eventId?: string, options?: any): AxiosPromise<EventsHistoryResponse> {
            return localVarFp.getEventHistory(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get count of overdue events
         * @param {string} [petId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventOverdueCount(petId?: string, options?: any): AxiosPromise<GetEventOverdueCountResponse> {
            return localVarFp.getEventOverdueCount(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all list values
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListValues(options?: any): AxiosPromise<Array<EventList>> {
            return localVarFp.getListValues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Insert event with items
         * @param {InsertEventBody} [insertEventBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertEvent(insertEventBody?: InsertEventBody, options?: any): AxiosPromise<Array<GetEventResponse>> {
            return localVarFp.insertEvent(insertEventBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates one or more event items
         * @param {UpdateEventBody} [updateEventBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(updateEventBody?: UpdateEventBody, options?: any): AxiosPromise<Array<GetEventResponse>> {
            return localVarFp.updateEvent(updateEventBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary Assign event to other user
     * @param {AssignEventRequest} assignEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public assignEvent(assignEventRequest: AssignEventRequest, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).assignEvent(assignEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign pet to the event
     * @param {AssignEventPetRequest} assignEventPetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public assignEventPet(assignEventPetRequest: AssignEventPetRequest, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).assignEventPet(assignEventPetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete event
     * @param {CompleteEventRequest} completeEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public completeEvent(completeEventRequest: CompleteEventRequest, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).completeEvent(completeEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return pets belonging to all groups where the user is a member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public findAssignablePets(options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).findAssignablePets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns pets created by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public findUserPets(options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).findUserPets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of events
     * @param {string} [id] 
     * @param {string} [petId] 
     * @param {boolean} [overdue] 
     * @param {number} [count] 
     * @param {string} [fromId] 
     * @param {boolean} [increment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEvent(id?: string, petId?: string, overdue?: boolean, count?: number, fromId?: string, increment?: boolean, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEvent(id, petId, overdue, count, fromId, increment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get event definition
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventDefinition(code?: string, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventDefinition(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get data for followup event
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventFollowup(eventId: string, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventFollowup(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of event changes in time
     * @param {string} [eventId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventHistory(eventId?: string, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventHistory(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get count of overdue events
     * @param {string} [petId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventOverdueCount(petId?: string, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventOverdueCount(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all list values
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getListValues(options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).getListValues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Insert event with items
     * @param {InsertEventBody} [insertEventBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public insertEvent(insertEventBody?: InsertEventBody, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).insertEvent(insertEventBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates one or more event items
     * @param {UpdateEventBody} [updateEventBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public updateEvent(updateEventBody?: UpdateEventBody, options?: AxiosRequestConfig) {
        return EventsApiFp(this.configuration).updateEvent(updateEventBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirmwareApi - axios parameter creator
 * @export
 */
export const FirmwareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload verze firmware
         * @param {any} file 
         * @param {string} fwVersion 
         * @param {string} hwVersion 
         * @param {string} type 
         * @param {string} hashType 
         * @param {string} zipFileHash 
         * @param {string} [opCode] 
         * @param {string} [signatureType] 
         * @param {string} [signature] 
         * @param {string} [sdReq] 
         * @param {string} [sdReqTarget] 
         * @param {string} [sdSize] 
         * @param {string} [blSize] 
         * @param {string} [appSize] 
         * @param {boolean} [isDebug] 
         * @param {string} [changelogDev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (file: any, fwVersion: string, hwVersion: string, type: string, hashType: string, zipFileHash: string, opCode?: string, signatureType?: string, signature?: string, sdReq?: string, sdReqTarget?: string, sdSize?: string, blSize?: string, appSize?: string, isDebug?: boolean, changelogDev?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('upload', 'file', file)
            // verify required parameter 'fwVersion' is not null or undefined
            assertParamExists('upload', 'fwVersion', fwVersion)
            // verify required parameter 'hwVersion' is not null or undefined
            assertParamExists('upload', 'hwVersion', hwVersion)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('upload', 'type', type)
            // verify required parameter 'hashType' is not null or undefined
            assertParamExists('upload', 'hashType', hashType)
            // verify required parameter 'zipFileHash' is not null or undefined
            assertParamExists('upload', 'zipFileHash', zipFileHash)
            const localVarPath = `/dev/firmware/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication FirmwareAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (opCode !== undefined) { 
                localVarFormParams.append('opCode', opCode as any);
            }
    
            if (signatureType !== undefined) { 
                localVarFormParams.append('signatureType', signatureType as any);
            }
    
            if (signature !== undefined) { 
                localVarFormParams.append('signature', signature as any);
            }
    
            if (fwVersion !== undefined) { 
                localVarFormParams.append('fwVersion', fwVersion as any);
            }
    
            if (hwVersion !== undefined) { 
                localVarFormParams.append('hwVersion', hwVersion as any);
            }
    
            if (sdReq !== undefined) { 
                localVarFormParams.append('sdReq', sdReq as any);
            }
    
            if (sdReqTarget !== undefined) { 
                localVarFormParams.append('sdReqTarget', sdReqTarget as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (sdSize !== undefined) { 
                localVarFormParams.append('sdSize', sdSize as any);
            }
    
            if (blSize !== undefined) { 
                localVarFormParams.append('blSize', blSize as any);
            }
    
            if (appSize !== undefined) { 
                localVarFormParams.append('appSize', appSize as any);
            }
    
            if (hashType !== undefined) { 
                localVarFormParams.append('hashType', hashType as any);
            }
    
            if (zipFileHash !== undefined) { 
                localVarFormParams.append('zipFileHash', zipFileHash as any);
            }
    
            if (isDebug !== undefined) { 
                localVarFormParams.append('isDebug', isDebug as any);
            }
    
            if (changelogDev !== undefined) { 
                localVarFormParams.append('changelogDev', changelogDev as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmwareApi - functional programming interface
 * @export
 */
export const FirmwareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmwareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload verze firmware
         * @param {any} file 
         * @param {string} fwVersion 
         * @param {string} hwVersion 
         * @param {string} type 
         * @param {string} hashType 
         * @param {string} zipFileHash 
         * @param {string} [opCode] 
         * @param {string} [signatureType] 
         * @param {string} [signature] 
         * @param {string} [sdReq] 
         * @param {string} [sdReqTarget] 
         * @param {string} [sdSize] 
         * @param {string} [blSize] 
         * @param {string} [appSize] 
         * @param {boolean} [isDebug] 
         * @param {string} [changelogDev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(file: any, fwVersion: string, hwVersion: string, type: string, hashType: string, zipFileHash: string, opCode?: string, signatureType?: string, signature?: string, sdReq?: string, sdReqTarget?: string, sdSize?: string, blSize?: string, appSize?: string, isDebug?: boolean, changelogDev?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmwareUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload(file, fwVersion, hwVersion, type, hashType, zipFileHash, opCode, signatureType, signature, sdReq, sdReqTarget, sdSize, blSize, appSize, isDebug, changelogDev, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmwareApi - factory interface
 * @export
 */
export const FirmwareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmwareApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload verze firmware
         * @param {any} file 
         * @param {string} fwVersion 
         * @param {string} hwVersion 
         * @param {string} type 
         * @param {string} hashType 
         * @param {string} zipFileHash 
         * @param {string} [opCode] 
         * @param {string} [signatureType] 
         * @param {string} [signature] 
         * @param {string} [sdReq] 
         * @param {string} [sdReqTarget] 
         * @param {string} [sdSize] 
         * @param {string} [blSize] 
         * @param {string} [appSize] 
         * @param {boolean} [isDebug] 
         * @param {string} [changelogDev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(file: any, fwVersion: string, hwVersion: string, type: string, hashType: string, zipFileHash: string, opCode?: string, signatureType?: string, signature?: string, sdReq?: string, sdReqTarget?: string, sdSize?: string, blSize?: string, appSize?: string, isDebug?: boolean, changelogDev?: string, options?: any): AxiosPromise<FirmwareUpload> {
            return localVarFp.upload(file, fwVersion, hwVersion, type, hashType, zipFileHash, opCode, signatureType, signature, sdReq, sdReqTarget, sdSize, blSize, appSize, isDebug, changelogDev, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmwareApi - object-oriented interface
 * @export
 * @class FirmwareApi
 * @extends {BaseAPI}
 */
export class FirmwareApi extends BaseAPI {
    /**
     * 
     * @summary Upload verze firmware
     * @param {any} file 
     * @param {string} fwVersion 
     * @param {string} hwVersion 
     * @param {string} type 
     * @param {string} hashType 
     * @param {string} zipFileHash 
     * @param {string} [opCode] 
     * @param {string} [signatureType] 
     * @param {string} [signature] 
     * @param {string} [sdReq] 
     * @param {string} [sdReqTarget] 
     * @param {string} [sdSize] 
     * @param {string} [blSize] 
     * @param {string} [appSize] 
     * @param {boolean} [isDebug] 
     * @param {string} [changelogDev] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmwareApi
     */
    public upload(file: any, fwVersion: string, hwVersion: string, type: string, hashType: string, zipFileHash: string, opCode?: string, signatureType?: string, signature?: string, sdReq?: string, sdReqTarget?: string, sdSize?: string, blSize?: string, appSize?: string, isDebug?: boolean, changelogDev?: string, options?: AxiosRequestConfig) {
        return FirmwareApiFp(this.configuration).upload(file, fwVersion, hwVersion, type, hashType, zipFileHash, opCode, signatureType, signature, sdReq, sdReqTarget, sdSize, blSize, appSize, isDebug, changelogDev, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User accepts invitation to the other group
         * @param {GroupInvitationRequest} acceptGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptGroupInvitation: async (acceptGroupInvitationRequest: GroupInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptGroupInvitationRequest' is not null or undefined
            assertParamExists('acceptGroupInvitation', 'acceptGroupInvitationRequest', acceptGroupInvitationRequest)
            const localVarPath = `/user/group/invitation/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptGroupInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Group owner cancels existing invitation
         * @param {GroupInvitationRequest} cancelGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGroupInvitation: async (cancelGroupInvitationRequest: GroupInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelGroupInvitationRequest' is not null or undefined
            assertParamExists('cancelGroupInvitation', 'cancelGroupInvitationRequest', cancelGroupInvitationRequest)
            const localVarPath = `/user/group/invitation/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelGroupInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User declines invitation to the other group
         * @param {GroupInvitationRequest} declineGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineGroupInvitation: async (declineGroupInvitationRequest: GroupInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'declineGroupInvitationRequest' is not null or undefined
            assertParamExists('declineGroupInvitation', 'declineGroupInvitationRequest', declineGroupInvitationRequest)
            const localVarPath = `/user/group/invitation/decline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(declineGroupInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return all users groups with members and pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User leaves the group
         * @param {LeaveGroupRequest} leaveGroupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveGroup: async (leaveGroupBody: LeaveGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaveGroupBody' is not null or undefined
            assertParamExists('leaveGroup', 'leaveGroupBody', leaveGroupBody)
            const localVarPath = `/user/group/leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leaveGroupBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes user from group
         * @param {RemoveUserFromGroupRequest} removeUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser: async (removeUserBody: RemoveUserFromGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeUserBody' is not null or undefined
            assertParamExists('removeUser', 'removeUserBody', removeUserBody)
            const localVarPath = `/user/group/user/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Group owner invites other user to the group
         * @param {SendGroupInvitationRequest} sendGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupInvitation: async (sendGroupInvitationRequest: SendGroupInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendGroupInvitationRequest' is not null or undefined
            assertParamExists('sendGroupInvitation', 'sendGroupInvitationRequest', sendGroupInvitationRequest)
            const localVarPath = `/user/group/invitation/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendGroupInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User accepts invitation to the other group
         * @param {GroupInvitationRequest} acceptGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptGroupInvitation(acceptGroupInvitationRequest: GroupInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptGroupInvitation(acceptGroupInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Group owner cancels existing invitation
         * @param {GroupInvitationRequest} cancelGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelGroupInvitation(cancelGroupInvitationRequest: GroupInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelGroupInvitation(cancelGroupInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User declines invitation to the other group
         * @param {GroupInvitationRequest} declineGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineGroupInvitation(declineGroupInvitationRequest: GroupInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declineGroupInvitation(declineGroupInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return all users groups with members and pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User leaves the group
         * @param {LeaveGroupRequest} leaveGroupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaveGroup(leaveGroupBody: LeaveGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaveGroup(leaveGroupBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes user from group
         * @param {RemoveUserFromGroupRequest} removeUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUser(removeUserBody: RemoveUserFromGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUser(removeUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Group owner invites other user to the group
         * @param {SendGroupInvitationRequest} sendGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendGroupInvitation(sendGroupInvitationRequest: SendGroupInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendGroupInvitation(sendGroupInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary User accepts invitation to the other group
         * @param {GroupInvitationRequest} acceptGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptGroupInvitation(acceptGroupInvitationRequest: GroupInvitationRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.acceptGroupInvitation(acceptGroupInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Group owner cancels existing invitation
         * @param {GroupInvitationRequest} cancelGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGroupInvitation(cancelGroupInvitationRequest: GroupInvitationRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.cancelGroupInvitation(cancelGroupInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User declines invitation to the other group
         * @param {GroupInvitationRequest} declineGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineGroupInvitation(declineGroupInvitationRequest: GroupInvitationRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.declineGroupInvitation(declineGroupInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return all users groups with members and pets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groups(options?: any): AxiosPromise<GetGroupsResponse> {
            return localVarFp.groups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User leaves the group
         * @param {LeaveGroupRequest} leaveGroupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveGroup(leaveGroupBody: LeaveGroupRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.leaveGroup(leaveGroupBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes user from group
         * @param {RemoveUserFromGroupRequest} removeUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser(removeUserBody: RemoveUserFromGroupRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.removeUser(removeUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Group owner invites other user to the group
         * @param {SendGroupInvitationRequest} sendGroupInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupInvitation(sendGroupInvitationRequest: SendGroupInvitationRequest, options?: any): AxiosPromise<UserEntity> {
            return localVarFp.sendGroupInvitation(sendGroupInvitationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * 
     * @summary User accepts invitation to the other group
     * @param {GroupInvitationRequest} acceptGroupInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public acceptGroupInvitation(acceptGroupInvitationRequest: GroupInvitationRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).acceptGroupInvitation(acceptGroupInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Group owner cancels existing invitation
     * @param {GroupInvitationRequest} cancelGroupInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public cancelGroupInvitation(cancelGroupInvitationRequest: GroupInvitationRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).cancelGroupInvitation(cancelGroupInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User declines invitation to the other group
     * @param {GroupInvitationRequest} declineGroupInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public declineGroupInvitation(declineGroupInvitationRequest: GroupInvitationRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).declineGroupInvitation(declineGroupInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return all users groups with members and pets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groups(options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).groups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User leaves the group
     * @param {LeaveGroupRequest} leaveGroupBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public leaveGroup(leaveGroupBody: LeaveGroupRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).leaveGroup(leaveGroupBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes user from group
     * @param {RemoveUserFromGroupRequest} removeUserBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public removeUser(removeUserBody: RemoveUserFromGroupRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).removeUser(removeUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Group owner invites other user to the group
     * @param {SendGroupInvitationRequest} sendGroupInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public sendGroupInvitation(sendGroupInvitationRequest: SendGroupInvitationRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).sendGroupInvitation(sendGroupInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthbookApi - axios parameter creator
 * @export
 */
export const HealthbookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookTokenRequest} healthbookTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (healthbookTokenRequest: HealthbookTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookTokenRequest' is not null or undefined
            assertParamExists('createToken', 'healthbookTokenRequest', healthbookTokenRequest)
            const localVarPath = `/auth/healthbook/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookUrlRequest} healthbookCreateUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUrl: async (healthbookCreateUrlRequest: HealthbookUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookCreateUrlRequest' is not null or undefined
            assertParamExists('createUrl', 'healthbookCreateUrlRequest', healthbookCreateUrlRequest)
            const localVarPath = `/healthbook/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookCreateUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s activities
         * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookActivitiesRequestBody' is not null or undefined
            assertParamExists('getActivities', 'healthbookActivitiesRequestBody', healthbookActivitiesRequestBody)
            const localVarPath = `/healthbook/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookActivitiesRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s healthbook records
         * @param {HealthbookPetRequest} healthbookBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecords: async (healthbookBody: HealthbookPetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookBody' is not null or undefined
            assertParamExists('getRecords', 'healthbookBody', healthbookBody)
            const localVarPath = `/healthbook/records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove healthbook item
         * @param {HealthbookRemoveItem} healthbookRemoveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (healthbookRemoveBody: HealthbookRemoveItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookRemoveBody' is not null or undefined
            assertParamExists('remove', 'healthbookRemoveBody', healthbookRemoveBody)
            const localVarPath = `/healthbook/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookRemoveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update healthbook item
         * @param {HealthbookSave} healthbookSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (healthbookSaveBody: HealthbookSave, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookSaveBody' is not null or undefined
            assertParamExists('save', 'healthbookSaveBody', healthbookSaveBody)
            const localVarPath = `/healthbook/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookSaveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User\'s veterinarians
         * @param {UserId} healthbookVeterinariansBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veterinarians: async (healthbookVeterinariansBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookVeterinariansBody' is not null or undefined
            assertParamExists('veterinarians', 'healthbookVeterinariansBody', healthbookVeterinariansBody)
            const localVarPath = `/healthbook/veterinarians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookVeterinariansBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthbookApi - functional programming interface
 * @export
 */
export const HealthbookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthbookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookTokenRequest} healthbookTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(healthbookTokenRequest: HealthbookTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthbookTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createToken(healthbookTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookUrlRequest} healthbookCreateUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUrl(healthbookCreateUrlRequest: HealthbookUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthbookUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUrl(healthbookCreateUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s activities
         * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(healthbookActivitiesRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s healthbook records
         * @param {HealthbookPetRequest} healthbookBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecords(healthbookBody: HealthbookPetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecords(healthbookBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove healthbook item
         * @param {HealthbookRemoveItem} healthbookRemoveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(healthbookRemoveBody: HealthbookRemoveItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(healthbookRemoveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create or update healthbook item
         * @param {HealthbookSave} healthbookSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(healthbookSaveBody: HealthbookSave, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthbookItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(healthbookSaveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User\'s veterinarians
         * @param {UserId} healthbookVeterinariansBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async veterinarians(healthbookVeterinariansBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Veterinarians>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.veterinarians(healthbookVeterinariansBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthbookApi - factory interface
 * @export
 */
export const HealthbookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthbookApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookTokenRequest} healthbookTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(healthbookTokenRequest: HealthbookTokenRequest, options?: any): AxiosPromise<HealthbookTokenResponse> {
            return localVarFp.createToken(healthbookTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates public URL of healthbook
         * @param {HealthbookUrlRequest} healthbookCreateUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUrl(healthbookCreateUrlRequest: HealthbookUrlRequest, options?: any): AxiosPromise<HealthbookUrlResponse> {
            return localVarFp.createUrl(healthbookCreateUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s activities
         * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options?: any): AxiosPromise<ActivitiesResponse> {
            return localVarFp.getActivities(healthbookActivitiesRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s healthbook records
         * @param {HealthbookPetRequest} healthbookBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecords(healthbookBody: HealthbookPetRequest, options?: any): AxiosPromise<Healthbook> {
            return localVarFp.getRecords(healthbookBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove healthbook item
         * @param {HealthbookRemoveItem} healthbookRemoveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(healthbookRemoveBody: HealthbookRemoveItem, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.remove(healthbookRemoveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update healthbook item
         * @param {HealthbookSave} healthbookSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(healthbookSaveBody: HealthbookSave, options?: any): AxiosPromise<HealthbookItem> {
            return localVarFp.save(healthbookSaveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User\'s veterinarians
         * @param {UserId} healthbookVeterinariansBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veterinarians(healthbookVeterinariansBody: UserId, options?: any): AxiosPromise<Veterinarians> {
            return localVarFp.veterinarians(healthbookVeterinariansBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthbookApi - object-oriented interface
 * @export
 * @class HealthbookApi
 * @extends {BaseAPI}
 */
export class HealthbookApi extends BaseAPI {
    /**
     * 
     * @summary Creates public URL of healthbook
     * @param {HealthbookTokenRequest} healthbookTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthbookApi
     */
    public createToken(healthbookTokenRequest: HealthbookTokenRequest, options?: AxiosRequestConfig) {
        return HealthbookApiFp(this.configuration).createToken(healthbookTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates public URL of healthbook
     * @param {HealthbookUrlRequest} healthbookCreateUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthbookApi
     */
    public createUrl(healthbookCreateUrlRequest: HealthbookUrlRequest, options?: AxiosRequestConfig) {
        return HealthbookApiFp(this.configuration).createUrl(healthbookCreateUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s activities
     * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthbookApi
     */
    public getActivities(healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options?: AxiosRequestConfig) {
        return HealthbookApiFp(this.configuration).getActivities(healthbookActivitiesRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s healthbook records
     * @param {HealthbookPetRequest} healthbookBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthbookApi
     */
    public getRecords(healthbookBody: HealthbookPetRequest, options?: AxiosRequestConfig) {
        return HealthbookApiFp(this.configuration).getRecords(healthbookBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove healthbook item
     * @param {HealthbookRemoveItem} healthbookRemoveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthbookApi
     */
    public remove(healthbookRemoveBody: HealthbookRemoveItem, options?: AxiosRequestConfig) {
        return HealthbookApiFp(this.configuration).remove(healthbookRemoveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update healthbook item
     * @param {HealthbookSave} healthbookSaveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthbookApi
     */
    public save(healthbookSaveBody: HealthbookSave, options?: AxiosRequestConfig) {
        return HealthbookApiFp(this.configuration).save(healthbookSaveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User\'s veterinarians
     * @param {UserId} healthbookVeterinariansBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthbookApi
     */
    public veterinarians(healthbookVeterinariansBody: UserId, options?: AxiosRequestConfig) {
        return HealthbookApiFp(this.configuration).veterinarians(healthbookVeterinariansBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceStats: async (deviceStatsAddBody: DeviceStats, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceStatsAddBody' is not null or undefined
            assertParamExists('addDeviceStats', 'deviceStatsAddBody', deviceStatsAddBody)
            const localVarPath = `/internal/device/stats/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceStatsAddBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceConnectionLog: async (deviceInfo: Array<ConnectionLogItem>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceInfo' is not null or undefined
            assertParamExists('deviceConnectionLog', 'deviceInfo', deviceInfo)
            const localVarPath = `/internal/device/connectionlog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceId} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfo: async (deviceInfo: DeviceId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceInfo' is not null or undefined
            assertParamExists('deviceInfo', 'deviceInfo', deviceInfo)
            const localVarPath = `/internal/device/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLastSync: async (deviceLastSync: DeviceId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLastSync' is not null or undefined
            assertParamExists('deviceLastSync', 'deviceLastSync', deviceLastSync)
            const localVarPath = `/internal/device/lastsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLastSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceLogRequest} deviceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLog: async (deviceLog: DeviceLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLog' is not null or undefined
            assertParamExists('deviceLog', 'deviceLog', deviceLog)
            const localVarPath = `/internal/device/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceMetadata: async (firmwareCheckBody: DeviceMetadataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmwareCheckBody' is not null or undefined
            assertParamExists('deviceMetadata', 'firmwareCheckBody', firmwareCheckBody)
            const localVarPath = `/internal/device/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication FirmwareAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(firmwareCheckBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync: async (deviceSync: DeviceSync, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSync' is not null or undefined
            assertParamExists('deviceSync', 'deviceSync', deviceSync)
            const localVarPath = `/internal/device/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSyncEcho: async (deviceSync: DeviceSync, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSync' is not null or undefined
            assertParamExists('deviceSyncEcho', 'deviceSync', deviceSync)
            const localVarPath = `/internal/device/syncecho`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PairBowlRequest} pairBowlBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairBowl: async (pairBowlBody: PairBowlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairBowlBody' is not null or undefined
            assertParamExists('pairBowl', 'pairBowlBody', pairBowlBody)
            const localVarPath = `/internal/device/pair-bowl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairBowlBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PairTrackerRequest} pairTrackerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairTracker: async (pairTrackerBody: PairTrackerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pairTrackerBody' is not null or undefined
            assertParamExists('pairTracker', 'pairTrackerBody', pairTrackerBody)
            const localVarPath = `/internal/device/pair-tracker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pairTrackerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceId} deviceUnpairBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpairDevice: async (deviceUnpairBody: DeviceId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUnpairBody' is not null or undefined
            assertParamExists('unpairDevice', 'deviceUnpairBody', deviceUnpairBody)
            const localVarPath = `/internal/device/unpair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceUnpairBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeviceStats(deviceStatsAddBody: DeviceStats, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDeviceStats(deviceStatsAddBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceConnectionLog(deviceInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceId} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceInfo(deviceInfo: DeviceId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceInfo(deviceInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceLastSync(deviceLastSync: DeviceId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSyncTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceLastSync(deviceLastSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceLogRequest} deviceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceLog(deviceLog: DeviceLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceLog(deviceLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceMetadata(firmwareCheckBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSync(deviceSync: DeviceSync, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSync(deviceSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSyncEcho(deviceSync: DeviceSync, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSyncEcho(deviceSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PairBowlRequest} pairBowlBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pairBowl(pairBowlBody: PairBowlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pairBowl(pairBowlBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PairTrackerRequest} pairTrackerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pairTracker(pairTrackerBody: PairTrackerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pairTracker(pairTrackerBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceId} deviceUnpairBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpairDevice(deviceUnpairBody: DeviceId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpairDevice(deviceUnpairBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceStats(deviceStatsAddBody: DeviceStats, options?: any): AxiosPromise<void> {
            return localVarFp.addDeviceStats(deviceStatsAddBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.deviceConnectionLog(deviceInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceId} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfo(deviceInfo: DeviceId, options?: any): AxiosPromise<DeviceInfo> {
            return localVarFp.deviceInfo(deviceInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLastSync(deviceLastSync: DeviceId, options?: any): AxiosPromise<DeviceSyncTime> {
            return localVarFp.deviceLastSync(deviceLastSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceLogRequest} deviceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLog(deviceLog: DeviceLogRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deviceLog(deviceLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: any): AxiosPromise<DeviceMetadataResponse> {
            return localVarFp.deviceMetadata(firmwareCheckBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync(deviceSync: DeviceSync, options?: any): AxiosPromise<void> {
            return localVarFp.deviceSync(deviceSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSyncEcho(deviceSync: DeviceSync, options?: any): AxiosPromise<void> {
            return localVarFp.deviceSyncEcho(deviceSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PairBowlRequest} pairBowlBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairBowl(pairBowlBody: PairBowlRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.pairBowl(pairBowlBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PairTrackerRequest} pairTrackerBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairTracker(pairTrackerBody: PairTrackerRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.pairTracker(pairTrackerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceId} deviceUnpairBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpairDevice(deviceUnpairBody: DeviceId, options?: any): AxiosPromise<void> {
            return localVarFp.unpairDevice(deviceUnpairBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @param {DeviceStats} deviceStatsAddBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public addDeviceStats(deviceStatsAddBody: DeviceStats, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).addDeviceStats(deviceStatsAddBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ConnectionLogItem>} deviceInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deviceConnectionLog(deviceInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceId} deviceInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deviceInfo(deviceInfo: DeviceId, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deviceInfo(deviceInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceId} deviceLastSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deviceLastSync(deviceLastSync: DeviceId, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deviceLastSync(deviceLastSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceLogRequest} deviceLog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deviceLog(deviceLog: DeviceLogRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deviceLog(deviceLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Firmware check
     * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deviceMetadata(firmwareCheckBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceSync} deviceSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deviceSync(deviceSync: DeviceSync, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deviceSync(deviceSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceSync} deviceSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public deviceSyncEcho(deviceSync: DeviceSync, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).deviceSyncEcho(deviceSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PairBowlRequest} pairBowlBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public pairBowl(pairBowlBody: PairBowlRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).pairBowl(pairBowlBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PairTrackerRequest} pairTrackerBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public pairTracker(pairTrackerBody: PairTrackerRequest, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).pairTracker(pairTrackerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceId} deviceUnpairBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public unpairDevice(deviceUnpairBody: DeviceId, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).unpairDevice(deviceUnpairBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ListsApi - axios parameter creator
 * @export
 */
export const ListsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lists/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of currencies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currencies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lists/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get breeds
         * @param {string} [language] 
         * @param {string} [species] species are &#x60;dog | cat&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBreeds: async (language?: string, species?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lists/breeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (species !== undefined) {
                localVarQueryParameter['species'] = species;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezones: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/timezones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListsApi - functional programming interface
 * @export
 */
export const ListsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of currencies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currencies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get breeds
         * @param {string} [language] 
         * @param {string} [species] species are &#x60;dog | cat&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBreeds(language?: string, species?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Breeds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBreeds(language, species, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timezones(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Timezones>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timezones(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListsApi - factory interface
 * @export
 */
export const ListsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(options?: any): AxiosPromise<Array<ListItem>> {
            return localVarFp.countries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of currencies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currencies(options?: any): AxiosPromise<Array<ListItem>> {
            return localVarFp.currencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get breeds
         * @param {string} [language] 
         * @param {string} [species] species are &#x60;dog | cat&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBreeds(language?: string, species?: string, options?: any): AxiosPromise<Breeds> {
            return localVarFp.findBreeds(language, species, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezones(options?: any): AxiosPromise<Timezones> {
            return localVarFp.timezones(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListsApi - object-oriented interface
 * @export
 * @class ListsApi
 * @extends {BaseAPI}
 */
export class ListsApi extends BaseAPI {
    /**
     * 
     * @summary Get list of countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListsApi
     */
    public countries(options?: AxiosRequestConfig) {
        return ListsApiFp(this.configuration).countries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of currencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListsApi
     */
    public currencies(options?: AxiosRequestConfig) {
        return ListsApiFp(this.configuration).currencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get breeds
     * @param {string} [language] 
     * @param {string} [species] species are &#x60;dog | cat&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListsApi
     */
    public findBreeds(language?: string, species?: string, options?: AxiosRequestConfig) {
        return ListsApiFp(this.configuration).findBreeds(language, species, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of timezones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListsApi
     */
    public timezones(options?: AxiosRequestConfig) {
        return ListsApiFp(this.configuration).timezones(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OverdueApi - axios parameter creator
 * @export
 */
export const OverdueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get count of overdue events
         * @param {string} [petId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventOverdueCount: async (petId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/overdue/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (petId !== undefined) {
                localVarQueryParameter['petId'] = petId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OverdueApi - functional programming interface
 * @export
 */
export const OverdueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OverdueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get count of overdue events
         * @param {string} [petId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventOverdueCount(petId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEventOverdueCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventOverdueCount(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OverdueApi - factory interface
 * @export
 */
export const OverdueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OverdueApiFp(configuration)
    return {
        /**
         * 
         * @summary Get count of overdue events
         * @param {string} [petId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventOverdueCount(petId?: string, options?: any): AxiosPromise<GetEventOverdueCountResponse> {
            return localVarFp.getEventOverdueCount(petId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OverdueApi - object-oriented interface
 * @export
 * @class OverdueApi
 * @extends {BaseAPI}
 */
export class OverdueApi extends BaseAPI {
    /**
     * 
     * @summary Get count of overdue events
     * @param {string} [petId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OverdueApi
     */
    public getEventOverdueCount(petId?: string, options?: AxiosRequestConfig) {
        return OverdueApiFp(this.configuration).getEventOverdueCount(petId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OwnersApi - axios parameter creator
 * @export
 */
export const OwnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationAccept: async (petInvitationAcceptBody: UserPetInvitationAcceptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationAcceptBody' is not null or undefined
            assertParamExists('userPetInvitationAccept', 'petInvitationAcceptBody', petInvitationAcceptBody)
            const localVarPath = `/user/pet/invitation/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationAcceptBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationCancel: async (petInvitationCancelBody: Invitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationCancelBody' is not null or undefined
            assertParamExists('userPetInvitationCancel', 'petInvitationCancelBody', petInvitationCancelBody)
            const localVarPath = `/user/pet/invitation/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationCancelBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationSend: async (petInvitationSendBody: SendInvitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationSendBody' is not null or undefined
            assertParamExists('userPetInvitationSend', 'petInvitationSendBody', petInvitationSendBody)
            const localVarPath = `/user/pet/invitation/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationSendBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwnerDelete: async (userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetOwnerDeleteBody' is not null or undefined
            assertParamExists('userPetOwnerDelete', 'userPetOwnerDeleteBody', userPetOwnerDeleteBody)
            const localVarPath = `/user/pet/owner/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetOwnerDeleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwners: async (userPetOwnersBody: UserPetOwnersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetOwnersBody' is not null or undefined
            assertParamExists('userPetOwners', 'userPetOwnersBody', userPetOwnersBody)
            const localVarPath = `/user/pet/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetOwnersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetsOwners: async (userPetsOwnersBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetsOwnersBody' is not null or undefined
            assertParamExists('userPetsOwners', 'userPetsOwnersBody', userPetsOwnersBody)
            const localVarPath = `/user/pets/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetsOwnersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OwnersApi - functional programming interface
 * @export
 */
export const OwnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OwnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationAccept(petInvitationAcceptBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationCancel(petInvitationCancelBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationSend(petInvitationSendBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetOwnerDelete(userPetOwnerDeleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owners>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetOwners(userPetOwnersBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetsOwners(userPetsOwnersBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Owners>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetsOwners(userPetsOwnersBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OwnersApi - factory interface
 * @export
 */
export const OwnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OwnersApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: any): AxiosPromise<Pet> {
            return localVarFp.userPetInvitationAccept(petInvitationAcceptBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetInvitationCancel(petInvitationCancelBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetInvitationSend(petInvitationSendBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetOwnerDelete(userPetOwnerDeleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: any): AxiosPromise<Owners> {
            return localVarFp.userPetOwners(userPetOwnersBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetsOwners(userPetsOwnersBody: UserId, options?: any): AxiosPromise<Array<Owners>> {
            return localVarFp.userPetsOwners(userPetsOwnersBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OwnersApi - object-oriented interface
 * @export
 * @class OwnersApi
 * @extends {BaseAPI}
 */
export class OwnersApi extends BaseAPI {
    /**
     * 
     * @summary Accept owner invitation
     * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnersApi
     */
    public userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: AxiosRequestConfig) {
        return OwnersApiFp(this.configuration).userPetInvitationAccept(petInvitationAcceptBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel owner invitation
     * @param {Invitation} petInvitationCancelBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnersApi
     */
    public userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: AxiosRequestConfig) {
        return OwnersApiFp(this.configuration).userPetInvitationCancel(petInvitationCancelBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send owner invitation
     * @param {SendInvitation} petInvitationSendBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnersApi
     */
    public userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: AxiosRequestConfig) {
        return OwnersApiFp(this.configuration).userPetInvitationSend(petInvitationSendBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete owner
     * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnersApi
     */
    public userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: AxiosRequestConfig) {
        return OwnersApiFp(this.configuration).userPetOwnerDelete(userPetOwnerDeleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet owners list
     * @param {UserPetOwnersRequest} userPetOwnersBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnersApi
     */
    public userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: AxiosRequestConfig) {
        return OwnersApiFp(this.configuration).userPetOwners(userPetOwnersBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Owners lists of all pets
     * @param {UserId} userPetsOwnersBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnersApi
     */
    public userPetsOwners(userPetsOwnersBody: UserId, options?: AxiosRequestConfig) {
        return OwnersApiFp(this.configuration).userPetsOwners(userPetsOwnersBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PetApi - axios parameter creator
 * @export
 */
export const PetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestAll} petsDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPetsDashboard: async (petsDashboardBody: ActivityRequestAll, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petsDashboardBody' is not null or undefined
            assertParamExists('allPetsDashboard', 'petsDashboardBody', petsDashboardBody)
            const localVarPath = `/user/pets/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petsDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return pets belonging to all groups where the user is a member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignablePets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/assignable-pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s activities
         * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookActivitiesRequestBody' is not null or undefined
            assertParamExists('getActivities', 'healthbookActivitiesRequestBody', healthbookActivitiesRequestBody)
            const localVarPath = `/healthbook/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookActivitiesRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s healthbook records
         * @param {HealthbookPetRequest} healthbookBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecords: async (healthbookBody: HealthbookPetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookBody' is not null or undefined
            assertParamExists('getRecords', 'healthbookBody', healthbookBody)
            const localVarPath = `/healthbook/records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s sleep and rest activity
         * @param {SleepDashboardRequest} sleepDashboardResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPetSleep: async (sleepDashboardResponseBody: SleepDashboardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sleepDashboardResponseBody' is not null or undefined
            assertParamExists('getUserPetSleep', 'sleepDashboardResponseBody', sleepDashboardResponseBody)
            const localVarPath = `/user/pet/sleep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sleepDashboardResponseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivities: async (petActivitiesBody: ActivitiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petActivitiesBody' is not null or undefined
            assertParamExists('petActivities', 'petActivitiesBody', petActivitiesBody)
            const localVarPath = `/user/pet/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petActivitiesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesPercentRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivitiesWithPercent: async (petActivitiesBody: ActivitiesPercentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petActivitiesBody' is not null or undefined
            assertParamExists('petActivitiesWithPercent', 'petActivitiesBody', petActivitiesBody)
            const localVarPath = `/user/pet/activities/percent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petActivitiesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestPet} petDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petDashboard: async (petDashboardBody: ActivityRequestPet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petDashboardBody' is not null or undefined
            assertParamExists('petDashboard', 'petDashboardBody', petDashboardBody)
            const localVarPath = `/user/pet/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove healthbook item
         * @param {HealthbookRemoveItem} healthbookRemoveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove: async (healthbookRemoveBody: HealthbookRemoveItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookRemoveBody' is not null or undefined
            assertParamExists('remove', 'healthbookRemoveBody', healthbookRemoveBody)
            const localVarPath = `/healthbook/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookRemoveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update healthbook item
         * @param {HealthbookSave} healthbookSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (healthbookSaveBody: HealthbookSave, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookSaveBody' is not null or undefined
            assertParamExists('save', 'healthbookSaveBody', healthbookSaveBody)
            const localVarPath = `/healthbook/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookSaveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userBarkfies: async (userPetsBarkfieCountBody: UserBarkfiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetsBarkfieCountBody' is not null or undefined
            assertParamExists('userBarkfies', 'userPetsBarkfieCountBody', userPetsBarkfieCountBody)
            const localVarPath = `/user/pets/barkfiecount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetsBarkfieCountBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {UserPet} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPet: async (petBody: UserPet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBody' is not null or undefined
            assertParamExists('userPet', 'petBody', petBody)
            const localVarPath = `/user/pet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete pet
         * @param {UserPet} petDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetDelete: async (petDeleteBody: UserPet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petDeleteBody' is not null or undefined
            assertParamExists('userPetDelete', 'petDeleteBody', petDeleteBody)
            const localVarPath = `/user/pet/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petDeleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationAccept: async (petInvitationAcceptBody: UserPetInvitationAcceptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationAcceptBody' is not null or undefined
            assertParamExists('userPetInvitationAccept', 'petInvitationAcceptBody', petInvitationAcceptBody)
            const localVarPath = `/user/pet/invitation/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationAcceptBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationCancel: async (petInvitationCancelBody: Invitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationCancelBody' is not null or undefined
            assertParamExists('userPetInvitationCancel', 'petInvitationCancelBody', petInvitationCancelBody)
            const localVarPath = `/user/pet/invitation/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationCancelBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationSend: async (petInvitationSendBody: SendInvitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationSendBody' is not null or undefined
            assertParamExists('userPetInvitationSend', 'petInvitationSendBody', petInvitationSendBody)
            const localVarPath = `/user/pet/invitation/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationSendBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwnerDelete: async (userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetOwnerDeleteBody' is not null or undefined
            assertParamExists('userPetOwnerDelete', 'userPetOwnerDeleteBody', userPetOwnerDeleteBody)
            const localVarPath = `/user/pet/owner/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetOwnerDeleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwners: async (userPetOwnersBody: UserPetOwnersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetOwnersBody' is not null or undefined
            assertParamExists('userPetOwners', 'userPetOwnersBody', userPetOwnersBody)
            const localVarPath = `/user/pet/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetOwnersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates or saves user\'s pet
         * @param {SavePet} petSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetSave: async (petSaveBody: SavePet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petSaveBody' is not null or undefined
            assertParamExists('userPetSave', 'petSaveBody', petSaveBody)
            const localVarPath = `/user/pet/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petSaveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find user\'s pet
         * @param {UserPetsRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPets: async (petBody: UserPetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBody' is not null or undefined
            assertParamExists('userPets', 'petBody', petBody)
            const localVarPath = `/user/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetsOwners: async (userPetsOwnersBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetsOwnersBody' is not null or undefined
            assertParamExists('userPetsOwners', 'userPetsOwnersBody', userPetsOwnersBody)
            const localVarPath = `/user/pets/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetsOwnersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User\'s veterinarians
         * @param {UserId} healthbookVeterinariansBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veterinarians: async (healthbookVeterinariansBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthbookVeterinariansBody' is not null or undefined
            assertParamExists('veterinarians', 'healthbookVeterinariansBody', healthbookVeterinariansBody)
            const localVarPath = `/healthbook/veterinarians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthbookVeterinariansBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PetApi - functional programming interface
 * @export
 */
export const PetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestAll} petsDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPetsDashboard(petsDashboardBody: ActivityRequestAll, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PetsDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPetsDashboard(petsDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return pets belonging to all groups where the user is a member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAssignablePets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindAssignablePetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAssignablePets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s activities
         * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(healthbookActivitiesRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s healthbook records
         * @param {HealthbookPetRequest} healthbookBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecords(healthbookBody: HealthbookPetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecords(healthbookBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s sleep and rest activity
         * @param {SleepDashboardRequest} sleepDashboardResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPetSleep(sleepDashboardResponseBody: SleepDashboardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SleepDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPetSleep(sleepDashboardResponseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async petActivities(petActivitiesBody: ActivitiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.petActivities(petActivitiesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesPercentRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async petActivitiesWithPercent(petActivitiesBody: ActivitiesPercentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesPercentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.petActivitiesWithPercent(petActivitiesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestPet} petDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async petDashboard(petDashboardBody: ActivityRequestPet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.petDashboard(petDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove healthbook item
         * @param {HealthbookRemoveItem} healthbookRemoveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remove(healthbookRemoveBody: HealthbookRemoveItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remove(healthbookRemoveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create or update healthbook item
         * @param {HealthbookSave} healthbookSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(healthbookSaveBody: HealthbookSave, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthbookItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(healthbookSaveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userBarkfies(userPetsBarkfieCountBody: UserBarkfiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetBarkfieCount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userBarkfies(userPetsBarkfieCountBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {UserPet} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPet(petBody: UserPet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPet(petBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete pet
         * @param {UserPet} petDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetDelete(petDeleteBody: UserPet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetDelete(petDeleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationAccept(petInvitationAcceptBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationCancel(petInvitationCancelBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationSend(petInvitationSendBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetOwnerDelete(userPetOwnerDeleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owners>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetOwners(userPetOwnersBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates or saves user\'s pet
         * @param {SavePet} petSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetSave(petSaveBody: SavePet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetSave(petSaveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find user\'s pet
         * @param {UserPetsRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPets(petBody: UserPetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPets(petBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetsOwners(userPetsOwnersBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Owners>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetsOwners(userPetsOwnersBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User\'s veterinarians
         * @param {UserId} healthbookVeterinariansBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async veterinarians(healthbookVeterinariansBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Veterinarians>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.veterinarians(healthbookVeterinariansBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PetApi - factory interface
 * @export
 */
export const PetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PetApiFp(configuration)
    return {
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestAll} petsDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPetsDashboard(petsDashboardBody: ActivityRequestAll, options?: any): AxiosPromise<PetsDashboard> {
            return localVarFp.allPetsDashboard(petsDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return pets belonging to all groups where the user is a member
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAssignablePets(options?: any): AxiosPromise<FindAssignablePetsResponse> {
            return localVarFp.findAssignablePets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s activities
         * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options?: any): AxiosPromise<ActivitiesResponse> {
            return localVarFp.getActivities(healthbookActivitiesRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s healthbook records
         * @param {HealthbookPetRequest} healthbookBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecords(healthbookBody: HealthbookPetRequest, options?: any): AxiosPromise<Healthbook> {
            return localVarFp.getRecords(healthbookBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s sleep and rest activity
         * @param {SleepDashboardRequest} sleepDashboardResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPetSleep(sleepDashboardResponseBody: SleepDashboardRequest, options?: any): AxiosPromise<SleepDashboardResponse> {
            return localVarFp.getUserPetSleep(sleepDashboardResponseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivities(petActivitiesBody: ActivitiesRequest, options?: any): AxiosPromise<ActivitiesResponse> {
            return localVarFp.petActivities(petActivitiesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesPercentRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivitiesWithPercent(petActivitiesBody: ActivitiesPercentRequest, options?: any): AxiosPromise<ActivitiesPercentResponse> {
            return localVarFp.petActivitiesWithPercent(petActivitiesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestPet} petDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petDashboard(petDashboardBody: ActivityRequestPet, options?: any): AxiosPromise<Dashboard> {
            return localVarFp.petDashboard(petDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove healthbook item
         * @param {HealthbookRemoveItem} healthbookRemoveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(healthbookRemoveBody: HealthbookRemoveItem, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.remove(healthbookRemoveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update healthbook item
         * @param {HealthbookSave} healthbookSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(healthbookSaveBody: HealthbookSave, options?: any): AxiosPromise<HealthbookItem> {
            return localVarFp.save(healthbookSaveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userBarkfies(userPetsBarkfieCountBody: UserBarkfiesRequest, options?: any): AxiosPromise<Array<PetBarkfieCount>> {
            return localVarFp.userBarkfies(userPetsBarkfieCountBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {UserPet} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPet(petBody: UserPet, options?: any): AxiosPromise<Pet> {
            return localVarFp.userPet(petBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete pet
         * @param {UserPet} petDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetDelete(petDeleteBody: UserPet, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetDelete(petDeleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: any): AxiosPromise<Pet> {
            return localVarFp.userPetInvitationAccept(petInvitationAcceptBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetInvitationCancel(petInvitationCancelBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetInvitationSend(petInvitationSendBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetOwnerDelete(userPetOwnerDeleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: any): AxiosPromise<Owners> {
            return localVarFp.userPetOwners(userPetOwnersBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates or saves user\'s pet
         * @param {SavePet} petSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetSave(petSaveBody: SavePet, options?: any): AxiosPromise<Pet> {
            return localVarFp.userPetSave(petSaveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find user\'s pet
         * @param {UserPetsRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPets(petBody: UserPetsRequest, options?: any): AxiosPromise<Pets> {
            return localVarFp.userPets(petBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetsOwners(userPetsOwnersBody: UserId, options?: any): AxiosPromise<Array<Owners>> {
            return localVarFp.userPetsOwners(userPetsOwnersBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User\'s veterinarians
         * @param {UserId} healthbookVeterinariansBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veterinarians(healthbookVeterinariansBody: UserId, options?: any): AxiosPromise<Veterinarians> {
            return localVarFp.veterinarians(healthbookVeterinariansBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PetApi - object-oriented interface
 * @export
 * @class PetApi
 * @extends {BaseAPI}
 */
export class PetApi extends BaseAPI {
    /**
     * 
     * @summary Pet\'s week activities summary
     * @param {ActivityRequestAll} petsDashboardBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public allPetsDashboard(petsDashboardBody: ActivityRequestAll, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).allPetsDashboard(petsDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return pets belonging to all groups where the user is a member
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public findAssignablePets(options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).findAssignablePets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s activities
     * @param {HealthbookActivitiesRequest} healthbookActivitiesRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public getActivities(healthbookActivitiesRequestBody: HealthbookActivitiesRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).getActivities(healthbookActivitiesRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s healthbook records
     * @param {HealthbookPetRequest} healthbookBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public getRecords(healthbookBody: HealthbookPetRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).getRecords(healthbookBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s sleep and rest activity
     * @param {SleepDashboardRequest} sleepDashboardResponseBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public getUserPetSleep(sleepDashboardResponseBody: SleepDashboardRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).getUserPetSleep(sleepDashboardResponseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s day activity
     * @param {ActivitiesRequest} petActivitiesBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public petActivities(petActivitiesBody: ActivitiesRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).petActivities(petActivitiesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s day activity
     * @param {ActivitiesPercentRequest} petActivitiesBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public petActivitiesWithPercent(petActivitiesBody: ActivitiesPercentRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).petActivitiesWithPercent(petActivitiesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s week activities summary
     * @param {ActivityRequestPet} petDashboardBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public petDashboard(petDashboardBody: ActivityRequestPet, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).petDashboard(petDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove healthbook item
     * @param {HealthbookRemoveItem} healthbookRemoveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public remove(healthbookRemoveBody: HealthbookRemoveItem, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).remove(healthbookRemoveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update healthbook item
     * @param {HealthbookSave} healthbookSaveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public save(healthbookSaveBody: HealthbookSave, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).save(healthbookSaveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return count of barkfies in given interval of each pet
     * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userBarkfies(userPetsBarkfieCountBody: UserBarkfiesRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userBarkfies(userPetsBarkfieCountBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return user\' pet
     * @param {UserPet} petBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPet(petBody: UserPet, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPet(petBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete pet
     * @param {UserPet} petDeleteBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetDelete(petDeleteBody: UserPet, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetDelete(petDeleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept owner invitation
     * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetInvitationAccept(petInvitationAcceptBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel owner invitation
     * @param {Invitation} petInvitationCancelBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetInvitationCancel(petInvitationCancelBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send owner invitation
     * @param {SendInvitation} petInvitationSendBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetInvitationSend(petInvitationSendBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete owner
     * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetOwnerDelete(userPetOwnerDeleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet owners list
     * @param {UserPetOwnersRequest} userPetOwnersBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetOwners(userPetOwnersBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates or saves user\'s pet
     * @param {SavePet} petSaveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetSave(petSaveBody: SavePet, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetSave(petSaveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find user\'s pet
     * @param {UserPetsRequest} petBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPets(petBody: UserPetsRequest, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPets(petBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Owners lists of all pets
     * @param {UserId} userPetsOwnersBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public userPetsOwners(userPetsOwnersBody: UserId, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).userPetsOwners(userPetsOwnersBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User\'s veterinarians
     * @param {UserId} healthbookVeterinariansBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public veterinarians(healthbookVeterinariansBody: UserId, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).veterinarians(healthbookVeterinariansBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SdkApi - axios parameter creator
 * @export
 */
export const SdkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceStats: async (deviceStatsAddBody: DeviceStats, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceStatsAddBody' is not null or undefined
            assertParamExists('addDeviceStats', 'deviceStatsAddBody', deviceStatsAddBody)
            const localVarPath = `/internal/device/stats/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceStatsAddBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceConnectionLog: async (deviceInfo: Array<ConnectionLogItem>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceInfo' is not null or undefined
            assertParamExists('deviceConnectionLog', 'deviceInfo', deviceInfo)
            const localVarPath = `/internal/device/connectionlog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLastSync: async (deviceLastSync: DeviceId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLastSync' is not null or undefined
            assertParamExists('deviceLastSync', 'deviceLastSync', deviceLastSync)
            const localVarPath = `/internal/device/lastsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLastSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceLogRequest} deviceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLog: async (deviceLog: DeviceLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceLog' is not null or undefined
            assertParamExists('deviceLog', 'deviceLog', deviceLog)
            const localVarPath = `/internal/device/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceMetadata: async (firmwareCheckBody: DeviceMetadataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmwareCheckBody' is not null or undefined
            assertParamExists('deviceMetadata', 'firmwareCheckBody', firmwareCheckBody)
            const localVarPath = `/internal/device/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication FirmwareAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(firmwareCheckBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync: async (deviceSync: DeviceSync, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSync' is not null or undefined
            assertParamExists('deviceSync', 'deviceSync', deviceSync)
            const localVarPath = `/internal/device/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSync, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (refreshBody: RefreshBowlTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshBody' is not null or undefined
            assertParamExists('refresh', 'refreshBody', refreshBody)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SdkApi - functional programming interface
 * @export
 */
export const SdkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SdkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeviceStats(deviceStatsAddBody: DeviceStats, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDeviceStats(deviceStatsAddBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceConnectionLog(deviceInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceLastSync(deviceLastSync: DeviceId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSyncTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceLastSync(deviceLastSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceLogRequest} deviceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceLog(deviceLog: DeviceLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceLog(deviceLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceMetadata(firmwareCheckBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSync(deviceSync: DeviceSync, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSync(deviceSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(refreshBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(refreshBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SdkApi - factory interface
 * @export
 */
export const SdkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SdkApiFp(configuration)
    return {
        /**
         * 
         * @param {DeviceStats} deviceStatsAddBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeviceStats(deviceStatsAddBody: DeviceStats, options?: any): AxiosPromise<void> {
            return localVarFp.addDeviceStats(deviceStatsAddBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ConnectionLogItem>} deviceInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.deviceConnectionLog(deviceInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceId} deviceLastSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLastSync(deviceLastSync: DeviceId, options?: any): AxiosPromise<DeviceSyncTime> {
            return localVarFp.deviceLastSync(deviceLastSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceLogRequest} deviceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceLog(deviceLog: DeviceLogRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deviceLog(deviceLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Firmware check
         * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: any): AxiosPromise<DeviceMetadataResponse> {
            return localVarFp.deviceMetadata(firmwareCheckBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceSync} deviceSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync(deviceSync: DeviceSync, options?: any): AxiosPromise<void> {
            return localVarFp.deviceSync(deviceSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshBowlTokenRequest} refreshBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(refreshBody: RefreshBowlTokenRequest, options?: any): AxiosPromise<OAuthToken> {
            return localVarFp.refresh(refreshBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SdkApi - object-oriented interface
 * @export
 * @class SdkApi
 * @extends {BaseAPI}
 */
export class SdkApi extends BaseAPI {
    /**
     * 
     * @param {DeviceStats} deviceStatsAddBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdkApi
     */
    public addDeviceStats(deviceStatsAddBody: DeviceStats, options?: AxiosRequestConfig) {
        return SdkApiFp(this.configuration).addDeviceStats(deviceStatsAddBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ConnectionLogItem>} deviceInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdkApi
     */
    public deviceConnectionLog(deviceInfo: Array<ConnectionLogItem>, options?: AxiosRequestConfig) {
        return SdkApiFp(this.configuration).deviceConnectionLog(deviceInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceId} deviceLastSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdkApi
     */
    public deviceLastSync(deviceLastSync: DeviceId, options?: AxiosRequestConfig) {
        return SdkApiFp(this.configuration).deviceLastSync(deviceLastSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceLogRequest} deviceLog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdkApi
     */
    public deviceLog(deviceLog: DeviceLogRequest, options?: AxiosRequestConfig) {
        return SdkApiFp(this.configuration).deviceLog(deviceLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Firmware check
     * @param {DeviceMetadataRequest} firmwareCheckBody verze aktualniho firmware
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdkApi
     */
    public deviceMetadata(firmwareCheckBody: DeviceMetadataRequest, options?: AxiosRequestConfig) {
        return SdkApiFp(this.configuration).deviceMetadata(firmwareCheckBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceSync} deviceSync 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdkApi
     */
    public deviceSync(deviceSync: DeviceSync, options?: AxiosRequestConfig) {
        return SdkApiFp(this.configuration).deviceSync(deviceSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshBowlTokenRequest} refreshBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SdkApi
     */
    public refresh(refreshBody: RefreshBowlTokenRequest, options?: AxiosRequestConfig) {
        return SdkApiFp(this.configuration).refresh(refreshBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimezonesApi - axios parameter creator
 * @export
 */
export const TimezonesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezones: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/timezones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimezonesApi - functional programming interface
 * @export
 */
export const TimezonesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimezonesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timezones(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Timezones>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timezones(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimezonesApi - factory interface
 * @export
 */
export const TimezonesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimezonesApiFp(configuration)
    return {
        /**
         * 
         * @summary List of timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezones(options?: any): AxiosPromise<Timezones> {
            return localVarFp.timezones(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimezonesApi - object-oriented interface
 * @export
 * @class TimezonesApi
 * @extends {BaseAPI}
 */
export class TimezonesApi extends BaseAPI {
    /**
     * 
     * @summary List of timezones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimezonesApi
     */
    public timezones(options?: AxiosRequestConfig) {
        return TimezonesApiFp(this.configuration).timezones(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnitsApi - axios parameter creator
 * @export
 */
export const UnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get length units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        length: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/units/length`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get temperature units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        temperature: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/units/temperature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get volume units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        volume: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/units/volume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get weight units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weight: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/units/weight`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitsApi - functional programming interface
 * @export
 */
export const UnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get length units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async length(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Units>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.length(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get temperature units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async temperature(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Units>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.temperature(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get volume units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async volume(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Units>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.volume(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get weight units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weight(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Units>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weight(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitsApi - factory interface
 * @export
 */
export const UnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get length units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        length(options?: any): AxiosPromise<Units> {
            return localVarFp.length(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get temperature units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        temperature(options?: any): AxiosPromise<Units> {
            return localVarFp.temperature(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get volume units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        volume(options?: any): AxiosPromise<Units> {
            return localVarFp.volume(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get weight units
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weight(options?: any): AxiosPromise<Units> {
            return localVarFp.weight(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitsApi - object-oriented interface
 * @export
 * @class UnitsApi
 * @extends {BaseAPI}
 */
export class UnitsApi extends BaseAPI {
    /**
     * 
     * @summary Get length units
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public length(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).length(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get temperature units
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public temperature(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).temperature(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get volume units
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public volume(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).volume(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get weight units
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public weight(options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).weight(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestAll} petsDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPetsDashboard: async (petsDashboardBody: ActivityRequestAll, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petsDashboardBody' is not null or undefined
            assertParamExists('allPetsDashboard', 'petsDashboardBody', petsDashboardBody)
            const localVarPath = `/user/pets/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petsDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns amount of food filled to the bowl in time
         * @param {PetFeedRequest} petFeedBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPetFeed: async (petFeedBody: PetFeedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petFeedBody' is not null or undefined
            assertParamExists('getPetFeed', 'petFeedBody', petFeedBody)
            const localVarPath = `/user/pet/bowl/feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petFeedBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s sleep and rest activity
         * @param {SleepDashboardRequest} sleepDashboardResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPetSleep: async (sleepDashboardResponseBody: SleepDashboardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sleepDashboardResponseBody' is not null or undefined
            assertParamExists('getUserPetSleep', 'sleepDashboardResponseBody', sleepDashboardResponseBody)
            const localVarPath = `/user/pet/sleep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sleepDashboardResponseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivities: async (petActivitiesBody: ActivitiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petActivitiesBody' is not null or undefined
            assertParamExists('petActivities', 'petActivitiesBody', petActivitiesBody)
            const localVarPath = `/user/pet/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petActivitiesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesPercentRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivitiesWithPercent: async (petActivitiesBody: ActivitiesPercentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petActivitiesBody' is not null or undefined
            assertParamExists('petActivitiesWithPercent', 'petActivitiesBody', petActivitiesBody)
            const localVarPath = `/user/pet/activities/percent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petActivitiesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestPet} petDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petDashboard: async (petDashboardBody: ActivityRequestPet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petDashboardBody' is not null or undefined
            assertParamExists('petDashboard', 'petDashboardBody', petDashboardBody)
            const localVarPath = `/user/pet/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userBarkfies: async (userPetsBarkfieCountBody: UserBarkfiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetsBarkfieCountBody' is not null or undefined
            assertParamExists('userBarkfies', 'userPetsBarkfieCountBody', userPetsBarkfieCountBody)
            const localVarPath = `/user/pets/barkfiecount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetsBarkfieCountBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by ID
         * @param {object} [userCheckBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCheck: async (userCheckBody?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCheckBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {UserPet} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPet: async (petBody: UserPet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBody' is not null or undefined
            assertParamExists('userPet', 'petBody', petBody)
            const localVarPath = `/user/pet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete pet
         * @param {UserPet} petDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetDelete: async (petDeleteBody: UserPet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petDeleteBody' is not null or undefined
            assertParamExists('userPetDelete', 'petDeleteBody', petDeleteBody)
            const localVarPath = `/user/pet/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petDeleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationAccept: async (petInvitationAcceptBody: UserPetInvitationAcceptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationAcceptBody' is not null or undefined
            assertParamExists('userPetInvitationAccept', 'petInvitationAcceptBody', petInvitationAcceptBody)
            const localVarPath = `/user/pet/invitation/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationAcceptBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationCancel: async (petInvitationCancelBody: Invitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationCancelBody' is not null or undefined
            assertParamExists('userPetInvitationCancel', 'petInvitationCancelBody', petInvitationCancelBody)
            const localVarPath = `/user/pet/invitation/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationCancelBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationSend: async (petInvitationSendBody: SendInvitation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petInvitationSendBody' is not null or undefined
            assertParamExists('userPetInvitationSend', 'petInvitationSendBody', petInvitationSendBody)
            const localVarPath = `/user/pet/invitation/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petInvitationSendBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwnerDelete: async (userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetOwnerDeleteBody' is not null or undefined
            assertParamExists('userPetOwnerDelete', 'userPetOwnerDeleteBody', userPetOwnerDeleteBody)
            const localVarPath = `/user/pet/owner/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetOwnerDeleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwners: async (userPetOwnersBody: UserPetOwnersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetOwnersBody' is not null or undefined
            assertParamExists('userPetOwners', 'userPetOwnersBody', userPetOwnersBody)
            const localVarPath = `/user/pet/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetOwnersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates or saves user\'s pet
         * @param {SavePet} petSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetSave: async (petSaveBody: SavePet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petSaveBody' is not null or undefined
            assertParamExists('userPetSave', 'petSaveBody', petSaveBody)
            const localVarPath = `/user/pet/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petSaveBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find user\'s pet
         * @param {UserPetsRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPets: async (petBody: UserPetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBody' is not null or undefined
            assertParamExists('userPets', 'petBody', petBody)
            const localVarPath = `/user/pets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetsOwners: async (userPetsOwnersBody: UserId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPetsOwnersBody' is not null or undefined
            assertParamExists('userPetsOwners', 'userPetsOwnersBody', userPetsOwnersBody)
            const localVarPath = `/user/pets/owners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPetsOwnersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user settings
         * @param {UserSettingsUpdate} [userSettingsUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsUpdate: async (userSettingsUpdateBody?: UserSettingsUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/settings/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingsUpdateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user by ID
         * @param {UserUpdateRequest} userUpdateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (userUpdateBody: UserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdateBody' is not null or undefined
            assertParamExists('userUpdate', 'userUpdateBody', userUpdateBody)
            const localVarPath = `/user/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestAll} petsDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPetsDashboard(petsDashboardBody: ActivityRequestAll, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PetsDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPetsDashboard(petsDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns amount of food filled to the bowl in time
         * @param {PetFeedRequest} petFeedBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPetFeed(petFeedBody: PetFeedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PetFeedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPetFeed(petFeedBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s sleep and rest activity
         * @param {SleepDashboardRequest} sleepDashboardResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPetSleep(sleepDashboardResponseBody: SleepDashboardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SleepDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPetSleep(sleepDashboardResponseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async petActivities(petActivitiesBody: ActivitiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.petActivities(petActivitiesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesPercentRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async petActivitiesWithPercent(petActivitiesBody: ActivitiesPercentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesPercentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.petActivitiesWithPercent(petActivitiesBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestPet} petDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async petDashboard(petDashboardBody: ActivityRequestPet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.petDashboard(petDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userBarkfies(userPetsBarkfieCountBody: UserBarkfiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetBarkfieCount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userBarkfies(userPetsBarkfieCountBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by ID
         * @param {object} [userCheckBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCheck(userCheckBody?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCheck(userCheckBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {UserPet} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPet(petBody: UserPet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPet(petBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete pet
         * @param {UserPet} petDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetDelete(petDeleteBody: UserPet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetDelete(petDeleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationAccept(petInvitationAcceptBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationCancel(petInvitationCancelBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetInvitationSend(petInvitationSendBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetOwnerDelete(userPetOwnerDeleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Owners>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetOwners(userPetOwnersBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates or saves user\'s pet
         * @param {SavePet} petSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetSave(petSaveBody: SavePet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetSave(petSaveBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find user\'s pet
         * @param {UserPetsRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPets(petBody: UserPetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPets(petBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPetsOwners(userPetsOwnersBody: UserId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Owners>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPetsOwners(userPetsOwnersBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user settings
         * @param {UserSettingsUpdate} [userSettingsUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsUpdate(userSettingsUpdateBody?: UserSettingsUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsUpdate(userSettingsUpdateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user by ID
         * @param {UserUpdateRequest} userUpdateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(userUpdateBody: UserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(userUpdateBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestAll} petsDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPetsDashboard(petsDashboardBody: ActivityRequestAll, options?: any): AxiosPromise<PetsDashboard> {
            return localVarFp.allPetsDashboard(petsDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns amount of food filled to the bowl in time
         * @param {PetFeedRequest} petFeedBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPetFeed(petFeedBody: PetFeedRequest, options?: any): AxiosPromise<PetFeedResponse> {
            return localVarFp.getPetFeed(petFeedBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s sleep and rest activity
         * @param {SleepDashboardRequest} sleepDashboardResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPetSleep(sleepDashboardResponseBody: SleepDashboardRequest, options?: any): AxiosPromise<SleepDashboardResponse> {
            return localVarFp.getUserPetSleep(sleepDashboardResponseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivities(petActivitiesBody: ActivitiesRequest, options?: any): AxiosPromise<ActivitiesResponse> {
            return localVarFp.petActivities(petActivitiesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s day activity
         * @param {ActivitiesPercentRequest} petActivitiesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petActivitiesWithPercent(petActivitiesBody: ActivitiesPercentRequest, options?: any): AxiosPromise<ActivitiesPercentResponse> {
            return localVarFp.petActivitiesWithPercent(petActivitiesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet\'s week activities summary
         * @param {ActivityRequestPet} petDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petDashboard(petDashboardBody: ActivityRequestPet, options?: any): AxiosPromise<Dashboard> {
            return localVarFp.petDashboard(petDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return count of barkfies in given interval of each pet
         * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userBarkfies(userPetsBarkfieCountBody: UserBarkfiesRequest, options?: any): AxiosPromise<Array<PetBarkfieCount>> {
            return localVarFp.userBarkfies(userPetsBarkfieCountBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by ID
         * @param {object} [userCheckBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCheck(userCheckBody?: object, options?: any): AxiosPromise<User> {
            return localVarFp.userCheck(userCheckBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {UserPet} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPet(petBody: UserPet, options?: any): AxiosPromise<Pet> {
            return localVarFp.userPet(petBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete pet
         * @param {UserPet} petDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetDelete(petDeleteBody: UserPet, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetDelete(petDeleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept owner invitation
         * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: any): AxiosPromise<Pet> {
            return localVarFp.userPetInvitationAccept(petInvitationAcceptBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel owner invitation
         * @param {Invitation} petInvitationCancelBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetInvitationCancel(petInvitationCancelBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send owner invitation
         * @param {SendInvitation} petInvitationSendBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetInvitationSend(petInvitationSendBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete owner
         * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.userPetOwnerDelete(userPetOwnerDeleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pet owners list
         * @param {UserPetOwnersRequest} userPetOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: any): AxiosPromise<Owners> {
            return localVarFp.userPetOwners(userPetOwnersBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates or saves user\'s pet
         * @param {SavePet} petSaveBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetSave(petSaveBody: SavePet, options?: any): AxiosPromise<Pet> {
            return localVarFp.userPetSave(petSaveBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find user\'s pet
         * @param {UserPetsRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPets(petBody: UserPetsRequest, options?: any): AxiosPromise<Pets> {
            return localVarFp.userPets(petBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Owners lists of all pets
         * @param {UserId} userPetsOwnersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPetsOwners(userPetsOwnersBody: UserId, options?: any): AxiosPromise<Array<Owners>> {
            return localVarFp.userPetsOwners(userPetsOwnersBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettings(options?: any): AxiosPromise<UserSettings> {
            return localVarFp.userSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user settings
         * @param {UserSettingsUpdate} [userSettingsUpdateBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsUpdate(userSettingsUpdateBody?: UserSettingsUpdate, options?: any): AxiosPromise<UserSettings> {
            return localVarFp.userSettingsUpdate(userSettingsUpdateBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user by ID
         * @param {UserUpdateRequest} userUpdateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(userUpdateBody: UserUpdateRequest, options?: any): AxiosPromise<User> {
            return localVarFp.userUpdate(userUpdateBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Pet\'s week activities summary
     * @param {ActivityRequestAll} petsDashboardBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public allPetsDashboard(petsDashboardBody: ActivityRequestAll, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).allPetsDashboard(petsDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns amount of food filled to the bowl in time
     * @param {PetFeedRequest} petFeedBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPetFeed(petFeedBody: PetFeedRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getPetFeed(petFeedBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s sleep and rest activity
     * @param {SleepDashboardRequest} sleepDashboardResponseBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserPetSleep(sleepDashboardResponseBody: SleepDashboardRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserPetSleep(sleepDashboardResponseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s day activity
     * @param {ActivitiesRequest} petActivitiesBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public petActivities(petActivitiesBody: ActivitiesRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).petActivities(petActivitiesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s day activity
     * @param {ActivitiesPercentRequest} petActivitiesBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public petActivitiesWithPercent(petActivitiesBody: ActivitiesPercentRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).petActivitiesWithPercent(petActivitiesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet\'s week activities summary
     * @param {ActivityRequestPet} petDashboardBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public petDashboard(petDashboardBody: ActivityRequestPet, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).petDashboard(petDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return count of barkfies in given interval of each pet
     * @param {UserBarkfiesRequest} userPetsBarkfieCountBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userBarkfies(userPetsBarkfieCountBody: UserBarkfiesRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userBarkfies(userPetsBarkfieCountBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by ID
     * @param {object} [userCheckBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCheck(userCheckBody?: object, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCheck(userCheckBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return user\' pet
     * @param {UserPet} petBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPet(petBody: UserPet, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPet(petBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete pet
     * @param {UserPet} petDeleteBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetDelete(petDeleteBody: UserPet, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetDelete(petDeleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept owner invitation
     * @param {UserPetInvitationAcceptRequest} petInvitationAcceptBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetInvitationAccept(petInvitationAcceptBody: UserPetInvitationAcceptRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetInvitationAccept(petInvitationAcceptBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel owner invitation
     * @param {Invitation} petInvitationCancelBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetInvitationCancel(petInvitationCancelBody: Invitation, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetInvitationCancel(petInvitationCancelBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send owner invitation
     * @param {SendInvitation} petInvitationSendBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetInvitationSend(petInvitationSendBody: SendInvitation, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetInvitationSend(petInvitationSendBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete owner
     * @param {UserPetOwnerDeleteRequest} userPetOwnerDeleteBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetOwnerDelete(userPetOwnerDeleteBody: UserPetOwnerDeleteRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetOwnerDelete(userPetOwnerDeleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pet owners list
     * @param {UserPetOwnersRequest} userPetOwnersBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetOwners(userPetOwnersBody: UserPetOwnersRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetOwners(userPetOwnersBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates or saves user\'s pet
     * @param {SavePet} petSaveBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetSave(petSaveBody: SavePet, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetSave(petSaveBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find user\'s pet
     * @param {UserPetsRequest} petBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPets(petBody: UserPetsRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPets(petBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Owners lists of all pets
     * @param {UserId} userPetsOwnersBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPetsOwners(userPetsOwnersBody: UserId, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPetsOwners(userPetsOwnersBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSettings(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user settings
     * @param {UserSettingsUpdate} [userSettingsUpdateBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSettingsUpdate(userSettingsUpdateBody?: UserSettingsUpdate, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSettingsUpdate(userSettingsUpdateBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user by ID
     * @param {UserUpdateRequest} userUpdateBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(userUpdateBody: UserUpdateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(userUpdateBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsertrackerApi - axios parameter creator
 * @export
 */
export const UsertrackerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TrackUserRequest} usertrackerTrackBody track user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        track: async (usertrackerTrackBody: TrackUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usertrackerTrackBody' is not null or undefined
            assertParamExists('track', 'usertrackerTrackBody', usertrackerTrackBody)
            const localVarPath = `/usertracker/track`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usertrackerTrackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsertrackerApi - functional programming interface
 * @export
 */
export const UsertrackerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsertrackerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TrackUserRequest} usertrackerTrackBody track user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async track(usertrackerTrackBody: TrackUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.track(usertrackerTrackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsertrackerApi - factory interface
 * @export
 */
export const UsertrackerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsertrackerApiFp(configuration)
    return {
        /**
         * 
         * @param {TrackUserRequest} usertrackerTrackBody track user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        track(usertrackerTrackBody: TrackUserRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.track(usertrackerTrackBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsertrackerApi - object-oriented interface
 * @export
 * @class UsertrackerApi
 * @extends {BaseAPI}
 */
export class UsertrackerApi extends BaseAPI {
    /**
     * 
     * @param {TrackUserRequest} usertrackerTrackBody track user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsertrackerApi
     */
    public track(usertrackerTrackBody: TrackUserRequest, options?: AxiosRequestConfig) {
        return UsertrackerApiFp(this.configuration).track(usertrackerTrackBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WoofchallengeApi - axios parameter creator
 * @export
 */
export const WoofchallengeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User accepts team invitation
         * @param {AcceptTeamInvitationRequest} invitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTeamInvitation: async (invitationAcceptBody: AcceptTeamInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationAcceptBody' is not null or undefined
            assertParamExists('acceptTeamInvitation', 'invitationAcceptBody', invitationAcceptBody)
            const localVarPath = `/woofchallenge/invitation/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationAcceptBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns team info
         * @param {InvitationInfoRequest} invitationInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationInfo: async (invitationInfoBody: InvitationInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationInfoBody' is not null or undefined
            assertParamExists('getInvitationInfo', 'invitationInfoBody', invitationInfoBody)
            const localVarPath = `/woofchallenge/invitation/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationInfoBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {GetUserPetRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPet: async (petBody: GetUserPetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petBody' is not null or undefined
            assertParamExists('getPet', 'petBody', petBody)
            const localVarPath = `/woofchallenge/pet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(petBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns teams activity in specified period of time
         * @param {TeamActivityRequest} teamActivityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamActivity: async (teamActivityBody: TeamActivityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamActivityBody' is not null or undefined
            assertParamExists('getTeamActivity', 'teamActivityBody', teamActivityBody)
            const localVarPath = `/woofchallenge/team/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamActivityBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns teams earned charity points in specified period of time
         * @param {TeamCharityRequest} teamCharityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCharity: async (teamCharityBody: TeamCharityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamCharityBody' is not null or undefined
            assertParamExists('getTeamCharity', 'teamCharityBody', teamCharityBody)
            const localVarPath = `/woofchallenge/team/charity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamCharityBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find team pets with rankings
         * @param {TeamDashboardRequest} teamDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamDashboard: async (teamDashboardBody: TeamDashboardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamDashboardBody' is not null or undefined
            assertParamExists('getTeamDashboard', 'teamDashboardBody', teamDashboardBody)
            const localVarPath = `/woofchallenge/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find team pet dashboard with rankings
         * @param {TeamPetDashboardRequest} teamPetDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamPetDashboard: async (teamPetDashboardBody: TeamPetDashboardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamPetDashboardBody' is not null or undefined
            assertParamExists('getTeamPetDashboard', 'teamPetDashboardBody', teamPetDashboardBody)
            const localVarPath = `/woofchallenge/pet/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamPetDashboardBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by ID
         * @param {UserRequest} userTeamCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userTeamCheckBody: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userTeamCheckBody' is not null or undefined
            assertParamExists('getUser', 'userTeamCheckBody', userTeamCheckBody)
            const localVarPath = `/woofchallenge/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTeamCheckBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invites users to the team
         * @param {InviteTeamMembersRequest} invitationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteTeamMembers: async (invitationBody: InviteTeamMembersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationBody' is not null or undefined
            assertParamExists('inviteTeamMembers', 'invitationBody', invitationBody)
            const localVarPath = `/woofchallenge/invitation/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates or updates team
         * @param {UserTeamSaveRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTeam: async (teamBody: UserTeamSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamBody' is not null or undefined
            assertParamExists('saveTeam', 'teamBody', teamBody)
            const localVarPath = `/woofchallenge/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user info when onboarding
         * @param {UserTeamUserUpdateRequest} updateTeamUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamUser: async (updateTeamUserBody: UserTeamUserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTeamUserBody' is not null or undefined
            assertParamExists('updateTeamUser', 'updateTeamUserBody', updateTeamUserBody)
            const localVarPath = `/woofchallenge/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finds team with members and it\'s pets
         * @param {UserTeamRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTeam: async (teamBody: UserTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamBody' is not null or undefined
            assertParamExists('userTeam', 'teamBody', teamBody)
            const localVarPath = `/woofchallenge/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find user\'s teams
         * @param {UserRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTeams: async (teamBody: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamBody' is not null or undefined
            assertParamExists('userTeams', 'teamBody', teamBody)
            const localVarPath = `/woofchallenge/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teamBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WoofchallengeApi - functional programming interface
 * @export
 */
export const WoofchallengeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WoofchallengeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User accepts team invitation
         * @param {AcceptTeamInvitationRequest} invitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptTeamInvitation(invitationAcceptBody: AcceptTeamInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptTeamInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTeamInvitation(invitationAcceptBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns team info
         * @param {InvitationInfoRequest} invitationInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationInfo(invitationInfoBody: InvitationInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationInfo(invitationInfoBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {GetUserPetRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPet(petBody: GetUserPetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPet(petBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns teams activity in specified period of time
         * @param {TeamActivityRequest} teamActivityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamActivity(teamActivityBody: TeamActivityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamActivity(teamActivityBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns teams earned charity points in specified period of time
         * @param {TeamCharityRequest} teamCharityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamCharity(teamCharityBody: TeamCharityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamCharityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamCharity(teamCharityBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find team pets with rankings
         * @param {TeamDashboardRequest} teamDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamDashboard(teamDashboardBody: TeamDashboardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamDashboard(teamDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find team pet dashboard with rankings
         * @param {TeamPetDashboardRequest} teamPetDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamPetDashboard(teamPetDashboardBody: TeamPetDashboardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamPetDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamPetDashboard(teamPetDashboardBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by ID
         * @param {UserRequest} userTeamCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userTeamCheckBody: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userTeamCheckBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invites users to the team
         * @param {InviteTeamMembersRequest} invitationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteTeamMembers(invitationBody: InviteTeamMembersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteTeamMembers(invitationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates or updates team
         * @param {UserTeamSaveRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTeam(teamBody: UserTeamSaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTeam(teamBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user info when onboarding
         * @param {UserTeamUserUpdateRequest} updateTeamUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamUser(updateTeamUserBody: UserTeamUserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamUser(updateTeamUserBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finds team with members and it\'s pets
         * @param {UserTeamRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userTeam(teamBody: UserTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userTeam(teamBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find user\'s teams
         * @param {UserRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userTeams(teamBody: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userTeams(teamBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WoofchallengeApi - factory interface
 * @export
 */
export const WoofchallengeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WoofchallengeApiFp(configuration)
    return {
        /**
         * 
         * @summary User accepts team invitation
         * @param {AcceptTeamInvitationRequest} invitationAcceptBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTeamInvitation(invitationAcceptBody: AcceptTeamInvitationRequest, options?: any): AxiosPromise<AcceptTeamInvitationResponse> {
            return localVarFp.acceptTeamInvitation(invitationAcceptBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns team info
         * @param {InvitationInfoRequest} invitationInfoBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationInfo(invitationInfoBody: InvitationInfoRequest, options?: any): AxiosPromise<InvitationInfoResponse> {
            return localVarFp.getInvitationInfo(invitationInfoBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return user\' pet
         * @param {GetUserPetRequest} petBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPet(petBody: GetUserPetRequest, options?: any): AxiosPromise<Pet> {
            return localVarFp.getPet(petBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns teams activity in specified period of time
         * @param {TeamActivityRequest} teamActivityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamActivity(teamActivityBody: TeamActivityRequest, options?: any): AxiosPromise<TeamActivityResponse> {
            return localVarFp.getTeamActivity(teamActivityBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns teams earned charity points in specified period of time
         * @param {TeamCharityRequest} teamCharityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCharity(teamCharityBody: TeamCharityRequest, options?: any): AxiosPromise<TeamCharityResponse> {
            return localVarFp.getTeamCharity(teamCharityBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find team pets with rankings
         * @param {TeamDashboardRequest} teamDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamDashboard(teamDashboardBody: TeamDashboardRequest, options?: any): AxiosPromise<TeamDashboardResponse> {
            return localVarFp.getTeamDashboard(teamDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find team pet dashboard with rankings
         * @param {TeamPetDashboardRequest} teamPetDashboardBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamPetDashboard(teamPetDashboardBody: TeamPetDashboardRequest, options?: any): AxiosPromise<TeamPetDashboardResponse> {
            return localVarFp.getTeamPetDashboard(teamPetDashboardBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by ID
         * @param {UserRequest} userTeamCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userTeamCheckBody: UserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userTeamCheckBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invites users to the team
         * @param {InviteTeamMembersRequest} invitationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteTeamMembers(invitationBody: InviteTeamMembersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.inviteTeamMembers(invitationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates or updates team
         * @param {UserTeamSaveRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTeam(teamBody: UserTeamSaveRequest, options?: any): AxiosPromise<Team> {
            return localVarFp.saveTeam(teamBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user info when onboarding
         * @param {UserTeamUserUpdateRequest} updateTeamUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamUser(updateTeamUserBody: UserTeamUserUpdateRequest, options?: any): AxiosPromise<InfoMessage> {
            return localVarFp.updateTeamUser(updateTeamUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finds team with members and it\'s pets
         * @param {UserTeamRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTeam(teamBody: UserTeamRequest, options?: any): AxiosPromise<UserTeamResponse> {
            return localVarFp.userTeam(teamBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find user\'s teams
         * @param {UserRequest} teamBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTeams(teamBody: UserRequest, options?: any): AxiosPromise<Teams> {
            return localVarFp.userTeams(teamBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WoofchallengeApi - object-oriented interface
 * @export
 * @class WoofchallengeApi
 * @extends {BaseAPI}
 */
export class WoofchallengeApi extends BaseAPI {
    /**
     * 
     * @summary User accepts team invitation
     * @param {AcceptTeamInvitationRequest} invitationAcceptBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public acceptTeamInvitation(invitationAcceptBody: AcceptTeamInvitationRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).acceptTeamInvitation(invitationAcceptBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns team info
     * @param {InvitationInfoRequest} invitationInfoBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public getInvitationInfo(invitationInfoBody: InvitationInfoRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).getInvitationInfo(invitationInfoBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return user\' pet
     * @param {GetUserPetRequest} petBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public getPet(petBody: GetUserPetRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).getPet(petBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns teams activity in specified period of time
     * @param {TeamActivityRequest} teamActivityBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public getTeamActivity(teamActivityBody: TeamActivityRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).getTeamActivity(teamActivityBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns teams earned charity points in specified period of time
     * @param {TeamCharityRequest} teamCharityBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public getTeamCharity(teamCharityBody: TeamCharityRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).getTeamCharity(teamCharityBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find team pets with rankings
     * @param {TeamDashboardRequest} teamDashboardBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public getTeamDashboard(teamDashboardBody: TeamDashboardRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).getTeamDashboard(teamDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find team pet dashboard with rankings
     * @param {TeamPetDashboardRequest} teamPetDashboardBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public getTeamPetDashboard(teamPetDashboardBody: TeamPetDashboardRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).getTeamPetDashboard(teamPetDashboardBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by ID
     * @param {UserRequest} userTeamCheckBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public getUser(userTeamCheckBody: UserRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).getUser(userTeamCheckBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invites users to the team
     * @param {InviteTeamMembersRequest} invitationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public inviteTeamMembers(invitationBody: InviteTeamMembersRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).inviteTeamMembers(invitationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates or updates team
     * @param {UserTeamSaveRequest} teamBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public saveTeam(teamBody: UserTeamSaveRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).saveTeam(teamBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user info when onboarding
     * @param {UserTeamUserUpdateRequest} updateTeamUserBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public updateTeamUser(updateTeamUserBody: UserTeamUserUpdateRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).updateTeamUser(updateTeamUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finds team with members and it\'s pets
     * @param {UserTeamRequest} teamBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public userTeam(teamBody: UserTeamRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).userTeam(teamBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find user\'s teams
     * @param {UserRequest} teamBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WoofchallengeApi
     */
    public userTeams(teamBody: UserRequest, options?: AxiosRequestConfig) {
        return WoofchallengeApiFp(this.configuration).userTeams(teamBody, options).then((request) => request(this.axios, this.basePath));
    }
}


