import { Pet } from 'apiLegacy'
import RoundAvatarPet from 'components/roundAvatarPet'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 14px 5px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  padding-left: 54px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 6px ${(props) => props.theme.colors.black}44;
  font-weight: 700;
  position: relative;
  border-radius: 6px;
`

const Avatar = styled.div`
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);

  & > * {
    --size: 50px;
    ${(props) => props.theme.breakpoints.XXS} {
      --size: 40px;
    }
  }
`

const TruncatedText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding-right: 5px;
`

const UserGroupPetCard = (props: { pet: Pet; onClick?: () => void }) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Avatar>
        <RoundAvatarPet pet={props.pet} />
      </Avatar>

      <TruncatedText>{props.pet.name}</TruncatedText>
    </Wrapper>
  )
}

export default UserGroupPetCard
