import {
  ARCHIVE_PET_REQUEST,
  DELETE_PET_REQUEST,
  DISABLE_PET_REQUEST,
  ENABLE_PET_REQUEST,
  archivePetFailed,
  archivePetSucceed,
  disablePetFailed,
  disablePetSucceed,
  enablePetFailed,
  enablePetSucceed,
} from './../actions/petActions'
import {
  FETCH_BREEDS_REQUEST,
  FETCH_PETS_REQUEST,
  SAVE_PET_REQUEST,
  UPDATE_PET_REQUEST,
  deletePetFailed,
  deletePetSucceed,
  fetchBreedsFailed,
  fetchBreedsSucceed,
  fetchPetsFailed,
  fetchPetsSucceed,
  savePetFailed,
  savePetSucceed,
  setActivePet,
  updatePetSucceed,
} from 'store/actions'
import {
  archivePet as archivePetRequest,
  deletePet as deletePetRequest,
  disablePet as disablePetRequest,
  enablePet as enablePetRequest,
  fetchBreeds as fetchBreedsRequest,
  fetchPets as fetchPetsRequest,
  savePet as savePetRequest,
} from 'services'
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'

import { PET_TYPES } from 'constants/data'
import Router from 'next/router'
import { addAppNotification } from 'entity/appNotifications'
import { fetchGroups } from 'entity/group'
import { loadOverdueCount } from 'entity/eventOverdue'
import { storeError } from 'entity/errors'

function* fetchBreeds(action): Generator {
  try {
    const {
      payload: { language, petType },
    } = action
    const petTypesToFetch = petType ? [petType] : PET_TYPES

    for (const type of petTypesToFetch) {
      const breedsResponse: any = yield call(fetchBreedsRequest, language, type)
      const breeds = yield breedsResponse.data.items
      yield put(fetchBreedsSucceed({ type, breeds }))
    }
  } catch (e) {
    console.error(e)
    yield put(storeError({ error: e, origin: 'fetchBreeds' }))
    yield put(fetchBreedsFailed(e))
  }
}

function* savePet(action): Generator {
  try {
    const petExists = action.payload.petId

    const petResponse: any = yield call(savePetRequest, action.payload)
    const pet = yield petResponse.data

    if (petExists) {
      yield put(updatePetSucceed(pet))
    } else {
      yield delete action.payload.petId
      yield put(loadOverdueCount())
      yield put(savePetSucceed(pet))
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'savePet' }))
    console.error(e)
    yield put(savePetFailed(e))
  }
}

function* fetchPets(): Generator {
  try {
    const petsResponse: any = yield call(fetchPetsRequest)
    const pets: any = yield petsResponse.data
    yield put(fetchPetsSucceed(pets))
  } catch (e) {
    yield put(storeError({ error: e, origin: 'fetchPets' }))
    yield put(fetchPetsFailed(e))
  }
}

function* deletePet(action): Generator {
  try {
    const response: any = yield call(deletePetRequest, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(setActivePet('all'))
      yield put(fetchGroups())
      yield put(loadOverdueCount())
      yield put(deletePetSucceed(action.payload))
      yield put(
        addAppNotification({
          type: 'text',
          text: 'PET_DELETE_SUCCEED',
          actionType: 'success',
        })
      )
      yield call(Router.push, '/pets')
    } else {
      yield put(deletePetFailed())
    }
  } catch (e) {
    yield put(deletePetFailed(e))
    yield put(storeError({ error: e, origin: 'deletePet' }))
    console.error(e)
  }
}

function* disablePet(action): Generator {
  try {
    const response: any = yield call(disablePetRequest, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(setActivePet('all'))
      yield put(fetchGroups())
      yield put(loadOverdueCount())
      yield put(disablePetSucceed())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'PET_DISABLE_SUCCEED',
          actionType: 'success',
        })
      )
      //yield call(Router.back)
    } else {
      yield put(disablePetFailed())
    }
  } catch (e) {
    yield put(disablePetFailed(e))
    yield put(storeError({ error: e, origin: 'disablePet' }))
    console.error(e)
  }
}

function* enablePet(action): Generator {
  try {
    const response: any = yield call(enablePetRequest, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(setActivePet('all'))
      yield put(fetchGroups())
      yield put(loadOverdueCount())
      yield put(enablePetSucceed())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'PET_ENABLE_SUCCEED',
          actionType: 'success',
        })
      )
      //yield call(Router.back)
    } else {
      yield put(enablePetFailed())
    }
  } catch (e) {
    yield put(enablePetFailed(e))
    yield put(storeError({ error: e, origin: 'enablePet' }))
    console.error(e)
  }
}

function* archivePet(action): Generator {
  try {
    const response: any = yield call(archivePetRequest, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(setActivePet('all'))
      yield put(fetchGroups())
      yield put(loadOverdueCount())
      yield put(archivePetSucceed())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'PET_ARCHIVE_SUCCEED',
          actionType: 'success',
        })
      )
      //yield call(Router.back)
    } else {
      yield put(archivePetFailed())
    }
  } catch (e) {
    yield put(archivePetFailed(e))
    yield put(storeError({ error: e, origin: 'archivePet' }))
    console.error(e)
  }
}

function* petSaga() {
  yield takeEvery(FETCH_BREEDS_REQUEST, fetchBreeds)
  yield takeLeading(SAVE_PET_REQUEST, savePet)
  yield takeLeading(UPDATE_PET_REQUEST, savePet)
  yield takeLeading(FETCH_PETS_REQUEST, fetchPets)
  yield takeLeading(DELETE_PET_REQUEST, deletePet)
  yield takeLeading(DISABLE_PET_REQUEST, disablePet)
  yield takeLeading(ENABLE_PET_REQUEST, enablePet)
  yield takeLeading(ARCHIVE_PET_REQUEST, archivePet)
}

export default petSaga
