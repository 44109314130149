import * as Sentry from '@sentry/nextjs'

import { setLocalStorageItem } from 'utils'

const SENTRY_DSN: string = process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: process.env.NEXT_PUBLIC_ENV_NAME || 'development',
  // prevent sending errors / releases from localhost
  enabled: process.env.NODE_ENV !== 'development',
  // enabled: true,
  beforeSend: (event) => {
    event.fingerprint = [(Math.random() * 1000000).toString()]
    setLocalStorageItem('sentry_event_id', JSON.stringify(event.event_id))
    return event
  },
})
