import { Coordinates } from 'types'

export const GM_NEARBY_PLACES_REFETCH_TIMEOUT_MS = 200

export const GM_DEFAULT_ZOOM = 14

export const GM_DEFAULT_COORDINATES: Coordinates = { lat: 50.0755, lng: 14.4378 } // = Prague

export const GM_DEFAULT_DIRECTIONS_STATE = {
  directions: null,
  bounds: null,
}

export const getMapOptions = (google: any) => {
  return {
    center: null,
    zoom: 14,
    zoomControl: true,
    disableDoubleClickZoom: false,
    mapTypeControl: true,
    scaleControl: true,
    scrollwheel: true,
    panControl: true,
    streetViewControl: true,
    draggable: true,
    mapTypeId: google.maps.MapTypeId.ROADMAP,

    // https://mapstyle.withgoogle.com/ --> generate json --> copy-paste to 'styles' below
    styles: [
      {
        stylers: [
          {
            saturation: 25,
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural.landcover',
        stylers: [
          {
            saturation: -55,
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        stylers: [
          {
            saturation: -45,
          },
          {
            lightness: 25,
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.local',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        stylers: [
          {
            saturation: -40,
          },
          {
            lightness: 30,
          },
        ],
      },
    ],
  }
}
