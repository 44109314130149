export type GeolocationPermissionStatusType = 'granted' | 'prompt' | 'denied'

export const getGeolocationStatus: () => Promise<GeolocationPermissionStatusType> = async () => {
  return navigator.permissions.query({ name: 'geolocation' }).then((result) => result.state)
}

export const isGeolocationAllowed = async () => {
  const geolocationStatus = await getGeolocationStatus()

  switch (geolocationStatus) {
    case 'granted':
      return true
    case 'prompt':
      return false
    case 'denied':
      return false
    default:
      return false
  }
}
