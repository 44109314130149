import {
  AppActionsTypes,
  CommonActionType,
  GET_USER_SETTINGS_SUCCEED,
  RESET_STATE,
  SET_ACTIVE_PET,
  SET_APP_INITIALIZED,
  SET_CALENDAR_DATE,
  SET_CALENDAR_TYPE,
  SET_EVENT_FOOTER_PARAMS,
  SET_TIMEZONES,
  TOGGLE_LOADING_SCREEN,
  TOGGLE_PET_HEADER,
} from 'store/actions'
import { SET_REFRESHING_TOKEN, UPDATE_USER_SETTINGS_SUCCEED } from './../actions/appActions'
import { Timezone, UserSettings } from 'apiLegacy'

type InitialStateTypes = {
  isLoading: boolean
  activePet: string
  calendarType: 'month' | 'week' | 'day'
  footerEventParams: any
  petHeaderOpen: boolean
  appInitialized: boolean
  previousActivePet: string
  calendarDate: Date
  userSettings: UserSettings
  timezones: Timezone[]
  refreshingToken: boolean
}

export const initialState: InitialStateTypes = {
  isLoading: false,
  appInitialized: false,
  activePet: 'all',
  previousActivePet: 'all',
  calendarType: 'month',
  calendarDate: new Date(),
  footerEventParams: {},
  petHeaderOpen: false,
  userSettings: {},
  timezones: [],
  refreshingToken: false,
}

export default function appReducer(
  state = initialState,
  action: AppActionsTypes | CommonActionType
): InitialStateTypes {
  switch (action.type) {
    case RESET_STATE:
      return initialState

    case TOGGLE_LOADING_SCREEN:
      return {
        ...state,
        isLoading: action.payload,
      }
    case SET_APP_INITIALIZED:
      return {
        ...state,
        appInitialized: action.payload,
      }
    case GET_USER_SETTINGS_SUCCEED:
      return {
        ...state,
        userSettings: action.payload,
      }
    case SET_ACTIVE_PET: {
      const previousPet = state.activePet
      return {
        ...state,
        previousActivePet: previousPet,
        activePet: action.payload || 'all',
      }
    }
    case SET_CALENDAR_TYPE:
      return {
        ...state,
        calendarType: action.payload,
      }
    case SET_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.payload,
      }
    case SET_EVENT_FOOTER_PARAMS:
      return {
        ...state,
        footerEventParams: action.payload,
      }

    case TOGGLE_PET_HEADER:
      return {
        ...state,
        petHeaderOpen: action.payload as boolean,
      }
    case UPDATE_USER_SETTINGS_SUCCEED:
      return {
        ...state,
        userSettings: action.payload,
      }
    case SET_TIMEZONES:
      return {
        ...state,
        timezones: action.payload,
      }
    case SET_REFRESHING_TOKEN:
      return {
        ...state,
        refreshingToken: action.payload,
      }
    default:
      return state
  }
}
