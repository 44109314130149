import { PetDocument, PetDocumentListItem } from 'api'
import { call, put, takeEvery } from 'redux-saga/effects'

import { fetchPetDocument } from 'services/documentServices'
import { storeError } from './errors'

//
// STATE
//
type PetDocumentDetailType = PetDocument

type StateType = {
  [documentId: string]: PetDocumentDetailType
}

const initialState: StateType = {}

//
// FETCH PET DOCUMENT DETAIL
//
const FETCH_PET_DOCUMENT_DETAIL_REQUEST = 'FETCH_PET_DOCUMENT_DETAIL_REQUEST'
const FETCH_PET_DOCUMENT_DETAIL_SUCCEED = 'FETCH_PET_DOCUMENT_DETAIL_SUCCEED'
const FETCH_PET_DOCUMENT_DETAIL_FAILED = 'FETCH_PET_DOCUMENT_DETAIL_FAILED'

interface FetchPetDocumentDetailRequestType {
  type: typeof FETCH_PET_DOCUMENT_DETAIL_REQUEST
  payload: { documentId: string }
}

export const fetchPetDocumentDetailAction = (payload: {
  documentId: string
}): FetchPetDocumentDetailRequestType => {
  return {
    type: FETCH_PET_DOCUMENT_DETAIL_REQUEST,
    payload,
  }
}

interface FetchPetDocumentDetailSucceedType {
  type: typeof FETCH_PET_DOCUMENT_DETAIL_SUCCEED
  payload: {
    documentId: string
    documentBody: PetDocumentListItem[]
  }
}

export const fetchPetDocumentDetailSucceed = (payload): FetchPetDocumentDetailSucceedType => ({
  type: FETCH_PET_DOCUMENT_DETAIL_SUCCEED,
  payload,
})

interface fetchPetDocumentDetailFailedType {
  type: typeof FETCH_PET_DOCUMENT_DETAIL_FAILED
  payload: any
}

export const fetchPetDocumentDetailFailed = (payload): fetchPetDocumentDetailFailedType => ({
  type: FETCH_PET_DOCUMENT_DETAIL_FAILED,
  payload,
})

/* reducer */
const fetchPetDocumentDetailReducer = (state: StateType, action) => {
  const { documentId, documentBody } = action.payload

  const updatedState = { ...state }
  updatedState[documentId] = documentBody

  return updatedState as StateType
}

/* saga */
function* fetchPetDocumentDetailSaga(action): Generator {
  const { documentId } = action.payload
  try {
    if (documentId) {
      const response = yield call(fetchPetDocument, { documentId })
      yield put(fetchPetDocumentDetailSucceed({ documentId, documentBody: (response as any).data }))
    }
  } catch (e) {
    if (e?.response?.data?.error?.message === 'Error: Document does not exist.') {
      yield put(fetchPetDocumentDetailSucceed({ documentId, documentBody: null }))
    } else {
      yield put(fetchPetDocumentDetailFailed(e))
      yield put(storeError({ error: e, origin: 'fetchPetDocumentDetailSaga' }))
    }
    console.error(e)
  }
}

//
// REDUCER
//
export const petDocumentDetailsReducer = (state = initialState, action: any): StateType => {
  switch (action.type) {
    case FETCH_PET_DOCUMENT_DETAIL_SUCCEED:
      return fetchPetDocumentDetailReducer(state, action)
    default:
      return state
  }
}

//
// SAGA
//
export function* petDocumentDetailsSaga() {
  yield takeEvery(FETCH_PET_DOCUMENT_DETAIL_REQUEST, fetchPetDocumentDetailSaga)
}

//
// SELECTORS
//
export const getPetDocumentDetail = (documentId: string) => (state: StateType) =>
  (state.petDocumentDetails[documentId] || null) as PetDocumentDetailType
