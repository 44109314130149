import React, { useEffect, useState } from 'react'
import { isTutorialToShow, setTutorialAsSeen } from 'entity/tutorials'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import TutorialOverlay from 'components/Tutorials/TutorialOverlay'

const TutorialContent = styled.div<{ paddingTop?: string }>`
  padding: 10px 24px;
  color: ${(props) => props.theme.colors.tutorial};
  white-space: break-spaces;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  ${(props) =>
    props.paddingTop &&
    css`
      padding-top: ${props.paddingTop};
    `}
`

const TutorialFullscreen = (props: {
  tutorialId: string
  children?: any
  paddingTop?: string
  onTutorialShow?: () => void
  onTutorialClose?: () => void
  menuPosition?: 'top' | 'bottom'
}) => {
  const dispatch = useDispatch()

  const tutorial = useSelector(isTutorialToShow(props.tutorialId))
  const [showTutorialId, setShowTutorialId] = useState<string>()

  useEffect(() => {
    if (tutorial.show) {
      const timer = setTimeout(() => {
        setShowTutorialId(tutorial.id)
      }, 500)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [tutorial])

  useEffect(() => {
    if (showTutorialId && props.onTutorialShow) {
      props.onTutorialShow()
    }
  }, [showTutorialId])

  const onTutorialClose = () => {
    dispatch(setTutorialAsSeen(showTutorialId))
    setShowTutorialId(undefined)
    if (props.onTutorialClose) {
      props.onTutorialClose()
    }
  }

  //
  // RENDER
  //
  return (
    <TutorialOverlay
      isVisible={!!showTutorialId}
      isOverLayout
      onClose={onTutorialClose}
      menuPosition={props.menuPosition}
    >
      <TutorialContent paddingTop={props.paddingTop}>{props.children}</TutorialContent>
    </TutorialOverlay>
  )
}

export default TutorialFullscreen
