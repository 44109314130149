import * as Sentry from '@sentry/nextjs'

import { ErrorInfo, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import Router from 'next/router'
import SvgIcon from 'components/svgIcon'
import { actionButtonCss } from 'styles/css'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  max-width: ${(props) => props.theme?.layout?.maxWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme?.colors?.primaryBackground || 'white'};
`

const Title = styled.div`
  font-size: 100px;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
  white-space: nowrap;
`

const Description = styled.div`
  padding: 25px;
  text-align: center;
`

const Href = styled.a`
  ${actionButtonCss}
  margin: 0;
  min-width: auto;
  padding: 30px;
`

const ErrorIcon = styled.div`
  position: absolute;
  color: ${(props) => props.theme.colors?.orange || 'orange'};
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
`

const animAppear = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
  }
`
const ErrorOverlay = styled.div`
  animation: ${animAppear} 0.2s ease-out both;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  padding-top: 0px;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.orange}11;
  backdrop-filter: blur(40px);
  display: flex;
  flex-direction: column;
  font-size: 16px;
`

const ErrorTitle = styled.div`
  margin-top: 14px;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.orange};
`

const ErrorStack = styled.div`
  font-size: 12px;
  white-space: pre;
  overflow: scroll;
`

const ErrorPage = (props: { statusCode: number; error?: Error; errorInfo?: ErrorInfo }) => {
  const { t } = useTranslation('common')
  const { statusCode, error, errorInfo } = props

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  useEffect(() => {
    Sentry.captureException(
      new Error(statusCode + ': ' + Router.asPath + ', ' + (error?.message || 'No error message'))
    )
  }, [])

  return (
    <Wrapper>
      <Title>{t('SOMETHING_WRONG_TITLE')}</Title>
      <Description>{t('SOMETHING_WRONG')}</Description>
      <Href href={window?.location?.origin}>{t('HOME')}</Href>
      <Href
        style={{ background: 'none', color: 'grey', border: '1px solid grey', marginTop: 10 }}
        href={'/feedback'}
      >
        {t('notifications.error.REPORT_ERROR')}
      </Href>

      {isErrorOpen ? (
        <ErrorOverlay>
          <ErrorTitle>Name:</ErrorTitle>
          {error.name}
          <ErrorTitle>Message:</ErrorTitle>
          {error.message}
          <ErrorTitle>Stack:</ErrorTitle>
          <ErrorStack>{error.stack?.trim()}</ErrorStack>
          {errorInfo && (
            <>
              <ErrorTitle>Component Stack</ErrorTitle>
              <ErrorStack>{errorInfo.componentStack?.trim()}</ErrorStack>
            </>
          )}
        </ErrorOverlay>
      ) : null}

      {error ? (
        <ErrorIcon onClick={() => setIsErrorOpen(!isErrorOpen)}>
          <SvgIcon code={isErrorOpen ? 'icon-close' : 'icon-warning'} />
        </ErrorIcon>
      ) : null}
    </Wrapper>
  )
}

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err?.statusCode
  return { statusCode, err }
}

export default ErrorPage
