import styled, { css, keyframes } from 'styled-components'

import useTranslation from 'next-translate/useTranslation'

const LINE_HEIGHT_DEFAULT = 120
const LINE_HEIGHT_MEDIUM = 75
const LINE_HEIGHT_SHORT = 20

const ANIMATION_DELAY_STEP_SEC = 0.3

const animFlash = keyframes`
  from {
    opacity: 0.9;
  }

  to {
    opacity: 1;
  }
`

const Tutorial = styled.div<{ delayIndex?: number }>`
  pointer-events: none;
  position: absolute;
  width: 100vw;
  top: -100000px;
  bottom: -100000px;
  padding-bottom: 100070px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.tutorial};
  white-space: break-spaces;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  animation: ${animFlash} 0.7s ease infinite alternate;
  animation-delay: ${(props) => 0.3 + props.delayIndex * 0.15}s;
`

const animAppear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const TutorialText = styled.div<{ color?: string; delayIndex?: number }>`
  animation: ${animAppear} 0.4s ease-out both;
  animation-delay: ${(props) => 0.3 + props.delayIndex * ANIMATION_DELAY_STEP_SEC}s;
  filter: brightness(1.4);

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

const animLine = keyframes`
  from {
    max-height: 0;
  }

  to {
    max-height: ${LINE_HEIGHT_DEFAULT}px;
  }
`

export type TutorialLineHeight = 'short' | 'medium'

const TutorialLine = styled.div<{ lineHeight?: TutorialLineHeight; delayIndex?: number }>`
  width: 0;
  height: ${(props) =>
    props.lineHeight === 'short'
      ? LINE_HEIGHT_SHORT
      : props.lineHeight === 'medium'
      ? LINE_HEIGHT_MEDIUM
      : LINE_HEIGHT_DEFAULT}px;
  border-left: 1px dashed ${(props) => props.theme.colors.tutorial}88;
  margin: 10px 0;
  animation: ${animLine} 0.3s ease-out both;
  animation-delay: ${(props) => 0.2 + props.delayIndex * ANIMATION_DELAY_STEP_SEC}s;
`

const FooterTutorialLabel = (props: {
  showTutorial: boolean
  text?: string
  translationID?: string
  lineHeight?: TutorialLineHeight
  color?: string
  fontSize?: number
  delayIndex?: number
}) => {
  const { t } = useTranslation('common')

  if (props.showTutorial) {
    return (
      <Tutorial delayIndex={props.delayIndex}>
        <TutorialText color={props.color} delayIndex={props.delayIndex}>
          {props.text ? props.text : t('footer-tutorial.' + props.translationID)}
        </TutorialText>
        <TutorialLine lineHeight={props.lineHeight} delayIndex={props.delayIndex} />
      </Tutorial>
    )
  }
  return null
}

export default FooterTutorialLabel
