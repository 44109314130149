import { isValidNumber } from 'utils'

export const capitalizeFirstLetter = ([first, ...rest], locale = 'en') =>
  first.toLocaleUpperCase(locale) + rest.join('')

export const nameFromEmail = (email: string) =>
  typeof email === 'string' ? email.substring(0, email.lastIndexOf('@')) : email

export const isEmailString = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const formatPads = (num: number, count: number, pad = '0') =>
  isValidNumber(num) ? String(num).padStart(count, pad) : ''

export const createSearchString = (
  s: string,
  options?: {
    removeDashes?: boolean
  }
) => {
  if (!s?.length) {
    return ''
  }
  let str = s
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  if (options?.removeDashes) {
    str = str.replace(/-/g, '')
  }

  return str
}

export const compareSearchStrings = (
  searchIn: string,
  searchString: string,
  options?: {
    exactMatch?: boolean
    removeDashes?: boolean
  }
) => {
  const sIn = createSearchString(searchIn, options)
  const s = createSearchString(searchString, options)

  return options?.exactMatch ? sIn === s : sIn.includes(s)
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace)
}
