import { DevelopmentApi, FeedbackRequest, TimezonesApi } from 'apiLegacy'

import axios from 'axios'
import { createLegacyApiInstance } from 'utils'

const developmentApiInstance = () => createLegacyApiInstance(DevelopmentApi)
const timezoneApiInstance = () => createLegacyApiInstance(TimezonesApi)

export const feedback = (payload: FeedbackRequest) => {
  return developmentApiInstance().feedback(payload)
}

export const sentryFeedback = (eventId: string, email: string, comments: string, name?: string) => {
  return axios.post(
    `${process.env.NEXT_PUBLIC_SENTRY_API_URL}/projects/${process.env.NEXT_PUBLIC_SENTRY_ORG}/${process.env.NEXT_PUBLIC_SENTRY_PROJECT}/user-feedback/`,
    { event_id: eventId, name, email, comments },
    {
      headers: {
        Authorization: 'Bearer ' + process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN,
      },
    }
  )
}

export const fetchTimezones = () => {
  return timezoneApiInstance().timezones()
}
