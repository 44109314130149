import styled, { css, keyframes } from 'styled-components'

import SvgIcon from 'components/svgIcon'
import { actionButtonCss } from 'styles/css'
import { addAppNotification } from 'entity/appNotifications'
import { updateUserSettings } from 'store/actions'
import { useDispatch } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'

const animAppearTutorial = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Wrapper = styled.div<{ isOverLayout?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: ${(props) => props.theme?.layout?.maxWidth};
  margin: auto;
  background: #000c;
  animation: ${animAppearTutorial} 0.4s ease-out both;
  pointer-events: auto !important;
  cursor: pointer;

  ${(props) =>
    props.isOverLayout &&
    css`
      z-index: ${props.theme.layout.layers.tutorial};
    `}
`

const Buttons = styled.div<{ position: 'top' | 'bottom' }>`
  animation: ${animAppearTutorial} 0.4s ease-out both;
  z-index: ${(props) => props.theme.layout.layers.tutorial + 10};
  position: fixed;
  left: 0;
  right: 0;
  max-width: ${(props) => props.theme?.layout?.maxWidth};
  margin: auto;

  padding: min(100px, 10%);
  display: flex;
  align-items: center;

  pointer-events: none;

  ${(props) =>
    props.position === 'top'
      ? css`
          top: 0;
          flex-direction: column-reverse;
        `
      : css`
          bottom: 0;
          flex-direction: column;
        `}

  gap: min(30px, 2vh);
`

const Button = styled.button`
  ${actionButtonCss}
  background: ${(props) => props.theme.colors.lightGrey}DD;
  color: ${(props) => props.theme.colors.white}AA;
  gap: 2%;

  font-size: 14px;
  svg {
    height: 12px;
  }

  pointer-events: auto !important;
`

/* const ButtonClose = styled.button<{ position: 'top' | 'bottom' }>`
  cursor: pointer;
  outline: none;
  border: none;
  background: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  color: ${(props) => props.theme.colors.white}AA;
  filter: drop-shadow(0 0 10px black);

  font-size: 14px;
  font-weight: 700;

  svg {
    fill: ${(props) => props.theme.colors.white}77;
    height: 20px;
  }

  ${(props) =>
    props.position === 'top'
      ? css`
          flex-direction: column-reverse;
        `
      : css`
          flex-direction: column;
        `}
` */

const TutorialOverlay = (props: {
  isVisible: boolean
  isOverLayout?: boolean
  onClose: () => void
  children?: any
  menuPosition?: 'top' | 'bottom'
}) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  //
  // RENDER
  //
  return props.isVisible ? (
    <>
      <Wrapper onClick={props.onClose} isOverLayout={props.isOverLayout}>
        {props.children}
      </Wrapper>

      <Buttons position={props.menuPosition || 'bottom'}>
        {/* <ButtonClose position={props.menuPosition || 'bottom'} onClick={props.onClose}>
          <SvgIcon code="icon-paw-filled" />
          {t('tutorial-overlay.CLOSE')}
        </ButtonClose> */}
        <Button
          onClick={() => {
            dispatch(updateUserSettings({ showHelp: false }))
            dispatch(
              addAppNotification({
                type: 'text',
                text: 'tutorial-overlay.TURN_OFF_NOTIFICATION',
                actionType: 'success',
              })
            )
            props.onClose()
          }}
        >
          <SvgIcon code="icon-close" />
          {t('tutorial-overlay.TURN_OFF')}
        </Button>
      </Buttons>
    </>
  ) : null
}

export default TutorialOverlay
