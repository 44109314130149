import { CURRENCY_ARRAY, currencyColors } from './../../constants/currency'
import { isAfter, isSameDay, isSameMonth, isSameYear } from 'date-fns'

import { GraphTimeUnit } from 'api'
import { GraphValue } from 'components/Graph'
import { TimePeriodType } from 'components/inputs/timePeriodInput'
import { isEmptyObject } from 'utils/types'

export type ValueType = { date?: string; value: number; userId?: string }

export const parseGraphValuesFromApi: (props: {
  timeUnit: GraphTimeUnit
  values: ValueType[]
  ignoreFutureValues?: boolean
  firstValueIsOutside?: boolean
  lastValueIsOutside?: boolean
}) => {
  valuesFiltered: GraphValue[]
  valuesWithoutNulls: GraphValue[]
} = (props) => {
  if (props.values) {
    const relevantValues = []

    props.values?.forEach((obj, i) => {
      const isOutside =
        (props.firstValueIsOutside && i === 0) ||
        (props.lastValueIsOutside && i === props.values.length - 1)

      const isInFuture = isAfter(new Date(obj?.date), new Date())
      const ignoreValue = props.ignoreFutureValues && isInFuture

      if (!isOutside && !ignoreValue) {
        relevantValues.push(obj)
      }
    })

    const valuesFiltered: GraphValue[] = relevantValues.map(
      (obj) => obj?.value || null
    ) as GraphValue[]
    const valuesWithoutNulls: GraphValue[] = valuesFiltered.filter((v) => v || v === 0)

    return {
      valuesFiltered,
      valuesWithoutNulls,
    }
  } else {
    return {
      valuesFiltered: [],
      valuesWithoutNulls: [],
    }
  }
}

export type ExpenseValueType = { [currency: string]: { value: number; previous?: number }[] }

export type ExpenseValuesDataReturnType =
  | { values: number[]; color?: string; currency?: string }
  | undefined

export const parseGraphExpenseValuesFromApi = (
  values: ExpenseValueType
): {
  data: ExpenseValuesDataReturnType[]
  dataWithCurrency: ExpenseValuesDataReturnType[]
} => {
  const data = []
  const dataWithCurrency = []
  if (!values || isEmptyObject(values)) {
    return { data, dataWithCurrency }
  }

  CURRENCY_ARRAY.forEach((currency) => {
    const currencyData = []
    if (values[currency]?.length > 0) {
      values[currency].forEach((val) => {
        currencyData.push(val.value)
      })
    }
    data.push({ values: currencyData, color: currencyColors[currency] })
    dataWithCurrency.push({ values: currencyData, color: currencyColors[currency], currency })
  })

  return { data, dataWithCurrency }
}

export const getTimeUnitForPeriod = (period: TimePeriodType) => {
  return (period === 'year' ? 'month' : 'day') as GraphTimeUnit
}

export const isValueOutsideGraph = (props: {
  value: ValueType
  timeUnit: GraphTimeUnit
  selectedDate: Date
}) => {
  const valueDate = new Date(props.value?.date)

  if (props.timeUnit === 'month') {
    return !isSameYear(props.selectedDate, valueDate)
  }
  if (props.timeUnit === 'day') {
    return !isSameMonth(props.selectedDate, valueDate)
  }
  return true
}

export const getGraphFadeArea: (props: {
  values: ValueType[]
  firstValueIsOutside?: boolean
  lastValueIsOutside?: boolean
}) => { indexStart?: number; indexEnd?: number } = (props) => {
  if (props.values) {
    const today = new Date()
    const offset = props.firstValueIsOutside ? 1 : 0
    const max = props.values.length - (props.lastValueIsOutside ? 1 : 0)

    for (let i = offset; i < max; i++) {
      const obj = props.values[i]
      const valueDate = new Date(obj.date)

      if (!isSameDay(today, valueDate) && isAfter(valueDate, today)) {
        return {
          indexStart: i + 1,
          indexEnd: undefined, // without end, the area will extend to the end of the graph automatically
        }
      }
    }
  }

  return null
}
