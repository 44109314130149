import FooterTutorialLabel, { TutorialLineHeight } from './FooterTutorialLabel'
import SvgIcon, { IconName } from 'components/svgIcon'
import { addTutorialToShow, isTutorialToShow, setTutorialAsSeen } from 'entity/tutorials'
import { getFooterIsOpen, toggleFooterIsOpen } from 'entity/footer'
import styled, { css, keyframes } from 'styled-components'
import { submitButtonBoxCss, submitButtonCircularCss } from 'styles/css'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import AnimateHeight from 'react-animate-height'
import FooterButtonText from './FooterButtonText'
import FooterDetailItems from './FooterDetailItems'
import NoPetWarning from 'components/noPet'
import TutorialOverlay from 'components/Tutorials/TutorialOverlay'
import UnderConstruction from 'components/underConstruction'
import { getAvailablePets } from 'store/selectors'
import { getEventDefinitions } from 'entity/eventDefinition'
import { isFunction } from 'utils'
import { mainTheme } from 'styles/themes'
import { setCalendarType } from 'store/actions'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

const animAppear = keyframes`
  from {
    transform: translateY(200px);
  }

  to {
    transform: none;
  }
`

const Wrapper = styled.div<{ showTutorial?: boolean; animateAppearance?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => props.theme.layout.layers.footer + (props.showTutorial ? 10000 : 0)};

  ${(props) =>
    props.showTutorial &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.animateAppearance &&
    css`
      animation: ${animAppear} 0.2s ease-out both;
    `}
`

const Toolbar = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  box-shadow: 0px 0px 16px #00000033;
  background: ${(props) => props.theme.colors.white};
  border-radius: 27px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  min-height: 66px;
  ${(props) => props.theme.breakpoints.XXS} {
    min-height: 54px;
  }
`

const SubmitButtonBox = styled.div<{ isDisabled?: boolean }>`
  ${submitButtonBoxCss}
  position: relative;
  margin-top: -25px;
  ${(props) =>
    props.isDisabled &&
    css`
      button {
        background: ${(props) => props.theme.colors.lighterGrey};
      }
    `}

  ${(props) => props.theme.breakpoints.XXS} {
    margin-left: -10px;
    margin-right: -10px;
    --size: 66px;
  }
`

const animRotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SubmitButton = styled.button<{ isLoading?: boolean; color?: string; isDisabled?: boolean }>`
  ${submitButtonCircularCss}
  z-index: 2;

  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
      animation: ${animRotate} 0.8s linear infinite;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.color &&
    props.theme.colors[(props as any).color] &&
    css`
      background: ${(props) => props.theme.colors[(props as any).color]};
    `}
`

const Content = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconArray = styled.div<{ isRight?: boolean }>`
  position: relative;
  align-self: stretch;
  flex: 1;
  gap: 18%;
  max-width: ${(props) => (props.isRight ? ` min(65%, 120px)` : `min(70%, 120px)`)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Icon = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => (props.isActive ? props.theme.colors.green : props.theme.colors.black)};

  svg {
    width: 100%;
    height: 100%;
    max-height: 32px;

    ${(props) => props.theme.breakpoints.XXS} {
      width: 90%;
      height: 90%;
    }
  }
`

const DetailBox = styled.div`
  z-index: 1;
  background: ${(props) => props.theme.colors.white};

  & > * > * {
    /* AnimateHeight component*/
    overflow-y: auto;
    max-height: 70vh;
  }
`

export type FooterProps = {
  animateAppearance?: boolean
  submitButton?: {
    isHidden?: boolean
    icon?: IconName
    onClick?: () => void
    isDisabled?: boolean
    isLoading?: boolean
    color?: string
    tutorialId?: string
    tutorialOnlyForSubmitBtn?: boolean
    formId?: string
  }
  contentLeft?: {
    displayMode?: 'default' | 'empty' | 'back-button' | 'previous-button'
    onBackClick?: () => void
    noOnBackRouteChange?: boolean
    onPreviousClick?: () => void
  }
  contentRight?: {
    displayMode?:
      | 'default'
      | 'empty'
      | 'skip-button'
      | 'delete-button'
      | 'home-button'
      | 'next-button'
    onSkipClick?: () => void
    onDeleteClick?: () => void
    onNextClick?: () => void
  }
  detail?: {
    custom?: any
    usePetMenu?: boolean
    doOpen?: boolean
  }
}

const Footer = (props: FooterProps) => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const dispatch = useDispatch()
  const pets = useSelector(getAvailablePets)
  /* const petId = useSelector(getActivePet) */
  const eventDefinition = useSelector(getEventDefinitions)

  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
  const isOpen = useSelector(getFooterIsOpen)

  const isSubmitDisabled = !props.submitButton || props.submitButton?.isDisabled

  useEffect(() => {
    dispatch(toggleFooterIsOpen(isDetailOpen))
  }, [isDetailOpen])

  useEffect(() => {
    setIsDetailOpen(props.detail?.doOpen || false)
  }, [props.detail?.doOpen])

  useEffect(() => {
    if (props.submitButton?.isDisabled || !props.detail) {
      setIsDetailOpen(false)
    }
  }, [props.submitButton?.isDisabled, props.detail])

  useEffect(() => {
    setIsDetailOpen(false)
  }, [router.pathname])

  //
  // ON-BACK
  //
  const handleGoBack = () => {
    if (isFunction(props.contentLeft?.onBackClick)) {
      props.contentLeft.onBackClick()
    }
    if (!props.contentLeft?.noOnBackRouteChange) {
      if (window.history?.state?.redirectHome) {
        router.push('/')
      } else {
        router.back()
      }
    }
  }

  //
  // TUTORIAL
  //
  useEffect(() => {
    dispatch(addTutorialToShow(props.submitButton?.tutorialId))
  }, [props.submitButton?.tutorialId])

  const showTutorialSel = useSelector(isTutorialToShow(props.submitButton?.tutorialId))
  const [showTutorial, setShowTutorial] = useState(false)

  const getShowSecondaryTutorial = () => {
    return !props.submitButton?.tutorialOnlyForSubmitBtn && showTutorial
  }

  useEffect(() => {
    const timer = setTimeout(() => setShowTutorial(showTutorialSel.show), 500)
    return () => {
      clearTimeout(timer)
    }
  }, [showTutorialSel, props.submitButton?.tutorialId])

  const onTutorialClose = () => {
    dispatch(setTutorialAsSeen(props.submitButton?.tutorialId))
    setShowTutorial(false)
  }

  //
  // SUB-RENDERS
  //
  const renderIcon = (
    icon: IconName,
    path: string,
    tutorial?: { translationID: string; lineHeight?: TutorialLineHeight; delayIndex?: number },
    onClickWhenActive?: () => void
  ) => {
    const handleOnClick = () => {
      if (router.pathname === path && onClickWhenActive && isFunction(onClickWhenActive)) {
        onClickWhenActive()
      } else {
        router.push(path)
      }
    }

    return (
      <Icon isActive={router.pathname === path}>
        <SvgIcon code={icon} onClick={handleOnClick} />
        <FooterTutorialLabel showTutorial={getShowSecondaryTutorial()} {...tutorial} />
      </Icon>
    )
  }

  //
  // DISPLAY SECTIONS
  //
  const renderSubmitButton = () => {
    let icon: IconName = props.submitButton?.icon || 'icon-paw'
    let onClick =
      isSubmitDisabled || props.submitButton?.isLoading ? undefined : props.submitButton?.onClick

    if (props.detail) {
      icon = isOpen ? 'icon-arrow-down' : props.submitButton?.icon || 'icon-plus'
      onClick = () => setIsDetailOpen(!isOpen)
    }
    if (props.submitButton?.isLoading) {
      icon = 'icon-refresh'
      onClick = undefined
    }

    return (
      <>
        <SubmitButton
          onClick={(e) => {
            e.preventDefault()
            onClick && onClick()
          }}
          isLoading={props.submitButton?.isLoading}
          isDisabled={isSubmitDisabled}
          color={props.submitButton?.color}
          type={props.submitButton?.formId ? 'submit' : undefined}
          form={props.submitButton?.formId}
        >
          <SvgIcon code={icon} style={icon === 'icon-paw' ? { width: 40 } : undefined} />
        </SubmitButton>

        <FooterTutorialLabel
          showTutorial={showTutorial}
          text={props.submitButton?.tutorialId && t(`tutorials.${props.submitButton?.tutorialId}`)}
          translationID="SUBMIT"
          color={mainTheme.colors.primary}
          fontSize={20}
          delayIndex={0}
        />
      </>
    )
  }

  const renderContentLeft = () => {
    /* LEFT - DEFAULT */
    if (!props.contentLeft || props.contentLeft?.displayMode === 'default') {
      return (
        <IconArray>
          {renderIcon('icon-clock', '/', {
            translationID: 'EVENTS',
            lineHeight: 'short',
            delayIndex: 1,
          })}
          {renderIcon('icon-graph-up', '/graphs', {
            translationID: 'GRAPHS',
            lineHeight: 'medium',
            delayIndex: 2,
          })}
        </IconArray>
      )
    }

    /* LEFT - EMPTY */
    if (props.contentLeft.displayMode === 'empty') {
      return null
    }

    /* LEFT - GO-BACK BUTTON */
    if (props.contentLeft.displayMode === 'back-button') {
      return (
        <>
          <FooterButtonText
            text={t('footer.BACK_BUTTON')}
            icon="icon-arrow-left-short"
            iconColor={mainTheme.colors.blue}
            onClick={handleGoBack}
          />
          <FooterTutorialLabel
            showTutorial={getShowSecondaryTutorial()}
            translationID="GO_BACK"
            lineHeight="medium"
            delayIndex={2}
          />
        </>
      )
    }

    /* LEFT - 'PREVIOUS' BUTTON */
    if (props.contentLeft.displayMode === 'previous-button') {
      return (
        <>
          <FooterButtonText
            text={t('footer.PREVIOUS_BUTTON')}
            icon="icon-arrow-left-short"
            iconColor={mainTheme.colors.blue}
            onClick={props.contentLeft?.onPreviousClick}
          />
        </>
      )
    }
  }

  const renderContentRight = () => {
    /* RIGHT - DEFAULT */
    if (!props.contentRight || props.contentRight?.displayMode === 'default') {
      return (
        <IconArray isRight>
          {renderIcon(
            'icon-calendar',
            '/calendar',
            {
              translationID: 'CALENDAR',
              lineHeight: 'medium',
              delayIndex: 3,
            },
            () => dispatch(setCalendarType('month'))
          )}
          {renderIcon('icon-catalog', '/switcher', {
            translationID: 'SWITCHER',
            lineHeight: 'short',
            delayIndex: 4,
          })}
        </IconArray>
      )
    }

    /* RIGHT - EMPTY */
    if (props.contentRight.displayMode === 'empty') {
      return null
    }

    /* RIGHT - SKIP BUTTON */
    if (props.contentRight.displayMode === 'skip-button') {
      return (
        <>
          <FooterButtonText
            alignRight
            text={t('footer.SKIP_BUTTON')}
            iconRight="icon-arrow-right-short"
            iconColor={mainTheme.colors.blue}
            onClick={props.contentRight?.onSkipClick}
          />
          <FooterTutorialLabel
            showTutorial={getShowSecondaryTutorial()}
            translationID="SKIP"
            lineHeight="medium"
            delayIndex={2}
          />
        </>
      )
    }

    /* RIGHT - DELETE BUTTON */
    if (props.contentRight.displayMode === 'delete-button') {
      return (
        <>
          <FooterButtonText
            alignRight
            text={t('footer.DELETE_BUTTON')}
            textColor={mainTheme.colors.orange}
            icon="icon-trash-small"
            iconColor={mainTheme.colors.orange}
            onClick={() => {
              if (isFunction(props.contentRight?.onDeleteClick)) {
                props.contentRight.onDeleteClick()
              } else {
                handleGoBack()
              }
            }}
          />
          <FooterTutorialLabel
            showTutorial={getShowSecondaryTutorial()}
            translationID="DELETE"
            lineHeight="medium"
            delayIndex={2}
          />
        </>
      )
    }

    /* RIGHT - HOME BUTTON */
    if (props.contentRight.displayMode === 'home-button') {
      return (
        <FooterButtonText
          alignRight
          text={t('footer.HOME_BUTTON')}
          icon="icon-paw"
          onClick={() => {
            if (isFunction(props.contentRight?.onDeleteClick)) {
              props.contentRight.onDeleteClick()
            } else {
              router.push('/')
            }
          }}
        />
      )
    }

    /* RIGHT - 'NEXT' BUTTON */
    if (props.contentRight.displayMode === 'next-button') {
      return (
        <>
          <FooterButtonText
            alignRight
            text={t('footer.NEXT_BUTTON')}
            iconRight="icon-arrow-right-short"
            iconColor={mainTheme.colors.blue}
            onClick={props.contentRight?.onNextClick}
          />
        </>
      )
    }
  }

  const renderDetail = () => {
    /* DETAIL - CUSTOM COMPONENT */
    if (props.detail?.custom) {
      return props.detail.custom
    }

    /* DETAIL - PET MENU */
    if (props.detail?.usePetMenu) {
      if (Object.keys(pets).length === 0) {
        return <NoPetWarning />
      }
      /* if (petId === 'all') {
        return <UnderConstruction textCode="WIP_EVENT4ALL" />
      } */
      return <FooterDetailItems items={Object.values(eventDefinition)} singlePetRequired />
    }

    /* DETAIL - EMPTY */
    return <UnderConstruction textCode="WIP_EMPTY_FOOTER_DETAIL" />
  }

  //
  // RENDER
  //
  return (
    <Wrapper showTutorial={showTutorial} animateAppearance={props.animateAppearance}>
      <Toolbar>
        <Content>{renderContentLeft()}</Content>

        {!props.submitButton?.isHidden && (
          <SubmitButtonBox isDisabled={isSubmitDisabled}>{renderSubmitButton()}</SubmitButtonBox>
        )}

        <Content>{renderContentRight()}</Content>
      </Toolbar>

      <TutorialOverlay isVisible={showTutorial} onClose={onTutorialClose} menuPosition="top" />

      {props.detail && (
        <DetailBox>
          <AnimateHeight height={isOpen ? 'auto' : 0}>{renderDetail()}</AnimateHeight>
        </DetailBox>
      )}
    </Wrapper>
  )
}

export default Footer
