import { User, UserEntity } from 'apiLegacy'

import RoundAvatar from 'components/roundAvatar'

const RoundAvatarUser = (props: {
  size?: number | string
  onClick?: (e) => void
  style?: any
  iconOverlay?: Element
  user: User | UserEntity
}) => {
  const getAvatarText = () => {
    if (props.user && !props.user?.images?.thumbnail) {
      return props.user.name
        ? props.user.name
            .split(' ')
            .map((str) => str[0])
            .join('')
            .toUpperCase()
        : null
    }
  }

  return (
    <RoundAvatar
      size={props.size}
      onClick={props.onClick}
      style={props.style}
      image={props.user?.images?.thumbnail}
      iconOverlay={props.iconOverlay}
    >
      {getAvatarText()}
    </RoundAvatar>
  )
}

export default RoundAvatarUser
