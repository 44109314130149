import styled, { css } from 'styled-components'

import SvgIcon from './svgIcon'
import { isBirthday } from 'utils'

const Wrapper = styled.div<{
  size: number | string
  isInactive?: boolean
  image: any
  onClick?: any
}>`
  --size: ${(props) =>
    props.size ? (typeof props.size === 'string' ? props.size : `${props.size}px`) : '80px'};

  --font-size: min(calc(var(--size) * 0.4), 40px);
  ${(props) => props.theme.breakpoints.XXS} {
    --font-size: min(calc(var(--size) * 0.4), 20px);
  }

  position: relative;
  width: var(--size);
  min-width: var(--size);
  height: 0;
  padding-top: var(--size);
  border-radius: 50%;

  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.white};
  background-image: url(${(props) => props.image});

  box-shadow: inset 0px 0px calc(var(--size) * 0.1) #00000066;

  font-size: var(--font-size);

  transition: all 0.3s ease;

  ${(props) =>
    props.isInactive &&
    (props.image
      ? css`
          box-shadow: inset 0px 0px 1000px ${(props) => props.theme.colors.lighterGrey2}BB;
        `
      : css`
          background-color: ${(props) => props.theme.colors.primaryBackground};
          box-shadow: inset 0px 0px calc(var(--size) * 0.1) #00000033;
        `)}

  ${(props) =>
    props.isInactive &&
    css`
      & > * {
        opacity: 0.4;
      }
    `}

${(props) =>
    props.onClick &&
    css`
      & > * {
        cursor: pointer;
      }
    `}
`

const Children = styled.div<{
  color?: string
}>`
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color || props.theme.colors.text};
`

const IconBirthday = styled.div`
  position: absolute;
  right: -4px;
  bottom: 5px;
  --size: 40%;
  width: var(--size);
  height: var(--size);
`

const IconOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0x;
  left: 0px;
  right: 0px;
`
interface RoundAvatarProps {
  children?: React.ReactNode
  size?: number | string
  onClick?: (e) => void
  style?: any
  image?: string
  useBirthdayIcon?: boolean
  birthdayDate?: Date | string
  isInactive?: boolean
  iconOverlay?: Element
  color?: string
}

const RoundAvatar = ({
  size,
  onClick,
  children,
  style = {},
  image = '',
  useBirthdayIcon = false,
  birthdayDate,
  isInactive = false,
  iconOverlay = undefined,
  color = '',
}: RoundAvatarProps) => {
  return (
    <Wrapper
      size={size}
      onClick={onClick}
      style={{ ...style }}
      isInactive={isInactive}
      image={image}
    >
      <Children color={color}>{children}</Children>

      {(useBirthdayIcon ||
        (birthdayDate &&
          isBirthday(
            typeof birthdayDate === 'string' ? new Date(birthdayDate) : birthdayDate
          ))) && (
        <IconBirthday>
          <SvgIcon code="icon-cake" />
        </IconBirthday>
      )}

      {iconOverlay && <IconOverlay>{iconOverlay}</IconOverlay>}
    </Wrapper>
  )
}

export default RoundAvatar
