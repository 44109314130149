import SvgIcon, { IconName } from 'components/svgIcon'
import { inputCss, inputDisabledCss } from 'styles/css'
import styled, { css } from 'styled-components'

import { mainTheme } from 'styles/themes'

const Input = styled.input<{ required: boolean; disabled?: boolean; hasIcon?: boolean }>`
  ${inputCss};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'hand')};

  ${(props) =>
    props.hasIcon &&
    css`
      padding-left: 45px;
      ${(props) => props.theme.breakpoints.XXS} {
        padding-left: 38px;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      ${inputDisabledCss}
    `}
`

const Wrapper = styled.div<{ margin: string }>`
  position: relative;
  margin: ${(props) => props.margin};
`

const RequiredIcon = styled.span`
  display: block;
  position: absolute;
  right: 10px;
  top: 5px;
  bottom: 0px;
  color: ${(props) => props.theme.colors.red};
  font-size: 18px;
`

const Error = styled.span`
  position: absolute;
  right: 25px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: 25px;
  background: white;
  color: ${(props) => props.theme.colors.red};
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  pointer-events: none;
  padding: 0 10px;
  user-select: none;
`

const Icon = styled.div`
  position: absolute;
  top: 1px;
  padding: 11px;
  color: ${(props) => props.theme.colors.primary};
  fill: ${(props) => props.theme.colors.primary};
  width: 40px;
  ${(props) => props.theme.breakpoints.XXS} {
    top: 2px;
    left: 2px;
    width: 36px;
  }
`
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
  onChange: (e) => void
  error?: string
  required?: boolean
  disabled?: boolean
  wrapperStyles?: any
  maxLength?: number
  margin?: string
  iconCode?: IconName
  onIconClick?: () => void
}

const TextInput = ({
  value,
  onChange,
  error = '',
  required = false,
  disabled = false,
  wrapperStyles = {},
  maxLength = mainTheme.input.maxTextLength,
  iconCode,
  onIconClick,
  margin = '0',
  ...rest
}: Props) => {
  return (
    <Wrapper margin={margin} style={wrapperStyles}>
      <Input
        disabled={disabled}
        onChange={onChange}
        {...rest}
        value={value}
        required={required}
        maxLength={maxLength}
        hasIcon={!!iconCode}
      />
      {iconCode && (
        <Icon onClick={onIconClick}>
          <SvgIcon code={iconCode} />
        </Icon>
      )}
      {error && error !== '' && <Error>{error}</Error>}
      {required && <RequiredIcon>*</RequiredIcon>}
    </Wrapper>
  )
}

export default TextInput
