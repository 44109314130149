import { GraphCode, GraphTimeUnit } from 'api'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { getGraphDefinitionsService, getPetGraphData } from 'services'

import { storeError } from './errors'

//
// STATE
//
export type GraphDataSingle = { date: string; value: number }[]

export type GraphData = GraphDataSingle | { [key: string]: GraphDataSingle }

export type GraphDataResponse = {
  data: GraphData[]
}

export type GraphDefinition = {
  code: GraphCode
  name: string
  icon: string
  defaultTimePeriod: 'year' | 'month' | 'week' | 'day'
  units: string
  translateUnits?: boolean
}

type StateType = {
  graphDefinitions: {
    [key: string]: GraphDefinition
  }
  data: {
    [key: string]: any // TODO
  }
}

const initialState: StateType = {
  graphDefinitions: {},
  data: {},
}

//
// HELPERS
//
type TimeFilter = { timeUnit: GraphTimeUnit; year: number; month?: number }

const getTimeFilterFromDate = (date: Date, timeUnit: GraphTimeUnit) => {
  // month is 1-based !
  return {
    year: date?.getFullYear(),
    month: timeUnit === 'day' ? date?.getMonth() + 1 : undefined,
    timeUnit,
  } as TimeFilter
}

const getTimeFilterId = (props: {
  petId: string
  graphCode?: GraphCode
  timeFilter?: TimeFilter
}) => {
  const { petId, graphCode, timeFilter } = props

  const _timeFilter = timeFilter || getTimeFilterFromDate(new Date(), 'month')
  return `${petId || '<petId>'}_${graphCode || '<code>'}_${_timeFilter.year}${
    timeFilter.timeUnit !== 'month' ? `-${_timeFilter.month}` : ''
  }-by_${timeFilter.timeUnit}`
}

//
// FETCH GRAPH_DATA
//
const FETCH_GRAPH_DATA_REQUEST = 'FETCH_GRAPH_DATA_REQUEST'
const FETCH_GRAPH_DATA_SUCCEED = 'FETCH_GRAPH_DATA_SUCCEED'
const FETCH_GRAPH_DATA_FAILED = 'FETCH_GRAPH_DATA_FAILED'

interface FetchGraphDataRequestType {
  type: typeof FETCH_GRAPH_DATA_REQUEST
  payload: {
    petId: string
    graphCode: GraphCode
    timeUnit: GraphTimeUnit
    date: Date
  }
}

export const fetchGraphDataAction = (payload: {
  petId: string
  graphCode: GraphCode
  timeUnit: GraphTimeUnit
  date: Date
}): FetchGraphDataRequestType => {
  return {
    type: FETCH_GRAPH_DATA_REQUEST,
    payload,
  }
}

interface FetchGraphDataSucceedType {
  type: typeof FETCH_GRAPH_DATA_SUCCEED
  payload: {
    petId: string
    graphCode: GraphCode
    timeFilter: TimeFilter
    data?: GraphDataResponse
  }
}

export const fetchGraphDataSucceed = (payload): FetchGraphDataSucceedType => ({
  type: FETCH_GRAPH_DATA_SUCCEED,
  payload,
})

interface fetchGraphDataFailedType {
  type: typeof FETCH_GRAPH_DATA_FAILED
  payload: any
}

export const fetchGraphDataFailed = (payload): fetchGraphDataFailedType => ({
  type: FETCH_GRAPH_DATA_FAILED,
  payload,
})

/* reducer */
const fetchGraphDataReducer = (state: StateType, action) => {
  const { timeFilter, data, graphCode, petId } = action.payload

  const timeFilterId = getTimeFilterId({ timeFilter, graphCode, petId })
  const updatedState: StateType = { ...state }

  updatedState.data[timeFilterId] = data

  return updatedState as StateType
}

/* saga */
function* fetchGraphDataSaga(action): Generator {
  try {
    const { petId, graphCode, timeUnit, date } = action.payload

    const timeFilter = getTimeFilterFromDate(date, timeUnit)

    const response = yield call(getPetGraphData, {
      petId,
      graphCode,
      timeUnit,
      year: timeFilter.year,
      month: timeFilter.month,
    })

    yield put(fetchGraphDataSucceed({ petId, graphCode, timeFilter, data: (response as any).data }))
  } catch (e) {
    yield put(fetchGraphDataFailed(e))
    yield put(storeError({ error: e, origin: 'fetchGraphDataSaga' }))
    console.error(e)
  }
}

//
// FETCH GRAPH DEFINITIONS
//
const FETCH_GRAPHS_DEFINITIONS_REQUEST = 'FETCH_GRAPHS_DEFINITIONS_REQUEST'
const FETCH_GRAPHS_DEFINITIONS_SUCCEED = 'FETCH_GRAPHS_DEFINITIONS_SUCCEED'
const FETCH_GRAPHS_DEFINITIONS_FAILED = 'FETCH_GRAPHS_DEFINITIONS_FAILED'

type FetchGraphDefinitionsAction = {
  type: typeof FETCH_GRAPHS_DEFINITIONS_REQUEST
}

export function fetchGraphDefinitions(): FetchGraphDefinitionsAction {
  return {
    type: FETCH_GRAPHS_DEFINITIONS_REQUEST,
  }
}

interface FetchGraphDefinitionsSucceedType {
  type: typeof FETCH_GRAPHS_DEFINITIONS_SUCCEED
  payload: {
    graphDefinitions?: GraphDefinition[]
  }
}

const fetchGraphDefinitionsSucceed = (payload): FetchGraphDefinitionsSucceedType => ({
  type: FETCH_GRAPHS_DEFINITIONS_SUCCEED,
  payload,
})

interface fetchGraphDefinitionsFailedType {
  type: typeof FETCH_GRAPHS_DEFINITIONS_FAILED
  payload: any
}

const fetchGraphDefinitionsFailed = (payload): fetchGraphDefinitionsFailedType => ({
  type: FETCH_GRAPHS_DEFINITIONS_FAILED,
  payload,
})

/* reducer */
const fetchGraphDefinitionsReducer = (state: StateType, action) => {
  const { graphDefinitions } = action.payload

  const normalizedDefinitions = Object.assign(
    {},
    ...graphDefinitions.map((definition) => ({ [definition.code]: definition }))
  )

  const updatedState: StateType = { ...state, graphDefinitions: normalizedDefinitions }
  return updatedState
}

/* saga */
function* fetchGraphDefinitionsSaga(): Generator {
  try {
    const graphsDefinitionResponse: any = yield call(getGraphDefinitionsService)
    const graphDefinitions = (yield graphsDefinitionResponse?.data) as GraphDefinition[]

    yield put(fetchGraphDefinitionsSucceed({ graphDefinitions }))
  } catch (e) {
    yield put(fetchGraphDefinitionsFailed(e))
    yield put(storeError({ error: e, origin: 'fetchGraphDefinitionsSaga' }))
    console.error(e)
  }
}

//
// REDUCER
//
export function graphReducer(
  state = initialState,
  action: FetchGraphDefinitionsSucceedType | FetchGraphDataSucceedType
) {
  switch (action.type) {
    case FETCH_GRAPHS_DEFINITIONS_SUCCEED:
      return fetchGraphDefinitionsReducer(state, action)
    case FETCH_GRAPH_DATA_SUCCEED:
      return fetchGraphDataReducer(state, action)
    default:
      return state
  }
}

//
// SAGA
//
export function* graphSaga() {
  yield takeLeading(FETCH_GRAPHS_DEFINITIONS_REQUEST, fetchGraphDefinitionsSaga)
  yield takeLatest(FETCH_GRAPH_DATA_REQUEST, fetchGraphDataSaga)
}

//
// SELECTORS
//
export const getGraphDefinitions = (state) => state.graphs?.graphDefinitions
export const getGraphDefinition = (code: GraphCode) => (state) =>
  state.graphs.graphDefinitions[code] as GraphDefinition

export const getGraphData =
  (props: { date: Date; timeUnit: GraphTimeUnit; graphCode: GraphCode; petId: string }) =>
  (state) => {
    const { date, timeUnit, graphCode, petId } = props

    const _state: StateType = state.graphs
    return (_state.data[
      getTimeFilterId({ timeFilter: getTimeFilterFromDate(date, timeUnit), petId, graphCode })
    ] || null) as GraphData
  }
