import {
  OAuthToken,
  PasswordChangeRequest,
  PasswordResetCodeRequest,
  PasswordResetEmailRequest,
  User,
  UserUpdateRequest,
} from 'apiLegacy'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCEED = 'USER_REGISTER_SUCCEED'
export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED'
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCEED = 'USER_LOGIN_SUCCEED'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const SET_USER = 'SET_USER'
export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST'
export const GET_USER_DATA_SUCCEED = 'GET_USER_DATA_SUCCEED'
export const GET_USER_DATA_FAILED = 'GET_USER_DATA_FAILED'
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCEED = 'USER_LOGOUT_SUCCEED'
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED'
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCEED = 'USER_UPDATE_SUCCEED'
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCEED = 'CHANGE_PASSWORD_SUCCEED'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCEED = 'RESET_PASSWORD_SUCCEED'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
export const CONFIRM_RESET_PASSWORD_REQUEST = 'CONFIRM_RESET_PASSWORD_REQUEST'
export const CONFIRM_RESET_PASSWORD_SUCCEED = 'CONFIRM_RESET_PASSWORD_SUCCEED'
export const CONFIRM_RESET_PASSWORD_FAILED = 'CONFIRM_RESET_PASSWORD_FAILED'
export const REGISTER_FIREBASE_TOKEN_REQUEST = 'REGISTER_FIREBASE_TOKEN_REQUEST'
export const REGISTER_FIREBASE_TOKEN_SUCCEED = 'REGISTER_FIREBASE_TOKEN_SUCCEED'
export const REGISTER_FIREBASE_TOKEN_FAILED = 'REGISTER_FIREBASE_TOKEN_FAILED'
export const REGISTER_ANONYMOUS_TOKEN_REQUEST = 'REGISTER_ANONYMOUS_TOKEN_REQUEST'
export const REGISTER_ANONYMOUS_TOKEN_SUCCEED = 'REGISTER_ANONYMOUS_TOKEN_SUCCEED'
export const REGISTER_ANONYMOUS_TOKEN_FAILED = 'REGISTER_ANONYMOUS_TOKEN_FAILED'
export const INVALIDATE_FIREBASE_TOKEN_REQUEST = 'INVALIDATE_FIREBASE_TOKEN_REQUEST'
export const INVALIDATE_FIREBASE_TOKEN_FAILED = 'INVALIDATE_FIREBASE_TOKEN_FAILED'
export const INVALIDATE_FIREBASE_TOKEN_SUCCEED = 'INVALIDATE_FIREBASE_TOKEN_SUCCEED'
export const INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_REQUEST = 'INVALIDATE_FIREBASE_TOKEN_REQUEST'
export const INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_FAILED = 'INVALIDATE_FIREBASE_TOKEN_FAILED'
export const INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_SUCCEED = 'INVALIDATE_FIREBASE_TOKEN_SUCCEED'

type RegisterUserType = {
  type: typeof USER_REGISTER_REQUEST
  payload: OAuthToken
}

export const registerUser = (payload): RegisterUserType => ({
  type: USER_REGISTER_REQUEST,
  payload,
})

type RegisterUserSucceedType = {
  type: typeof USER_REGISTER_SUCCEED
  payload: User
}

export const registerUserSucceed = (payload): RegisterUserSucceedType => ({
  type: USER_REGISTER_SUCCEED,
  payload,
})

type RegisterUserFailedType = {
  type: typeof USER_REGISTER_FAILED
}

export const registerUserFailed = (): RegisterUserFailedType => ({
  type: USER_REGISTER_FAILED,
})

type LoginUserType = {
  type: typeof USER_LOGIN_REQUEST
  payload: OAuthToken
}

export const loginUser = (payload): LoginUserType => ({
  type: USER_LOGIN_REQUEST,
  payload,
})

type LoginUserFailedType = {
  type: typeof USER_LOGIN_FAILED
}

export const loginUserFailed = (): LoginUserFailedType => ({
  type: USER_LOGIN_FAILED,
})

type LoginUserSucceedType = {
  type: typeof USER_LOGIN_SUCCEED
  payload: OAuthToken
}

export const loginUserSucceed = (payload): LoginUserSucceedType => ({
  type: USER_LOGIN_SUCCEED,
  payload,
})

type SetUserType = {
  type: typeof SET_USER
  payload: OAuthToken | UserUpdateRequest | null
}

export const setUser = (payload): SetUserType => ({
  type: SET_USER,
  payload,
})

type GetUserDataType = {
  type: typeof GET_USER_DATA_REQUEST
}

export const getUserData = (): GetUserDataType => ({
  type: GET_USER_DATA_REQUEST,
})

type GetUserDataSucceedType = {
  type: typeof GET_USER_DATA_SUCCEED
  payload: UserUpdateRequest
}

export const getUserDataSucceed = (payload): GetUserDataSucceedType => ({
  type: GET_USER_DATA_SUCCEED,
  payload,
})

type GetUserDataFailedType = {
  type: typeof GET_USER_DATA_FAILED
  payload: any
}

export const getUserDataFailed = (payload): GetUserDataFailedType => ({
  type: GET_USER_DATA_FAILED,
  payload,
})

type LogoutUserType = {
  type: typeof USER_LOGOUT_REQUEST
}

export const logoutUser = (): LogoutUserType => ({
  type: USER_LOGOUT_REQUEST,
})

type LogoutUserSucceedType = {
  type: typeof USER_LOGOUT_SUCCEED
}

export const logoutUserSucceed = (): LogoutUserSucceedType => ({
  type: USER_LOGOUT_SUCCEED,
})

type LogoutUserFailedType = {
  type: typeof USER_LOGOUT_FAILED
}

export const logoutUserFailed = (): LogoutUserFailedType => ({
  type: USER_LOGOUT_FAILED,
})

type UserUpdateType = {
  type: typeof USER_UPDATE_REQUEST
  payload: UserUpdateRequest
}

export const updateUser = (payload): UserUpdateType => ({
  type: USER_UPDATE_REQUEST,
  payload,
})

type UserUpdateSucceedType = {
  type: typeof USER_UPDATE_SUCCEED
  payload: UserUpdateRequest
}

export const updateUserSucceed = (payload): UserUpdateSucceedType => ({
  type: USER_UPDATE_SUCCEED,
  payload,
})

type UserUpdateFailedType = {
  type: typeof USER_UPDATE_FAILED
}

export const updateUserFailed = () => ({
  type: USER_UPDATE_FAILED,
})

type ChangePasswordType = {
  type: typeof CHANGE_PASSWORD_REQUEST
  payload: PasswordChangeRequest
}

export const changePassword = (payload): ChangePasswordType => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
})

type ChangePasswordSucceedType = {
  type: typeof CHANGE_PASSWORD_SUCCEED
  payload: PasswordChangeRequest
}

export const changePasswordSucceed = () => ({
  type: CHANGE_PASSWORD_SUCCEED,
})

type ChangePasswordFailedType = {
  type: typeof CHANGE_PASSWORD_FAILED
}

export const changePasswordFailed = () => ({
  type: USER_UPDATE_FAILED,
})

type ResetPasswordType = {
  type: typeof RESET_PASSWORD_REQUEST
  payload: PasswordResetEmailRequest
}

export const resetPassword = (payload: PasswordResetEmailRequest): ResetPasswordType => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
})

type ResetPasswordFailedType = {
  type: typeof RESET_PASSWORD_FAILED
}

export const resetPasswordFailed = (): ResetPasswordFailedType => ({
  type: RESET_PASSWORD_FAILED,
})

type ResetPasswordSucceedType = {
  type: typeof RESET_PASSWORD_SUCCEED
}

export const resetPasswordSucceed = (): ResetPasswordSucceedType => ({
  type: RESET_PASSWORD_SUCCEED,
})

type ConfirmResetPasswordType = {
  type: typeof CONFIRM_RESET_PASSWORD_REQUEST
  payload: PasswordResetCodeRequest
}

export const confirmResetPassword = (
  payload: PasswordResetCodeRequest
): ConfirmResetPasswordType => ({
  type: CONFIRM_RESET_PASSWORD_REQUEST,
  payload,
})

type ConfirmResetPasswordFailedType = {
  type: typeof CONFIRM_RESET_PASSWORD_FAILED
}

export const confirmResetPasswordFailed = (): ConfirmResetPasswordFailedType => ({
  type: CONFIRM_RESET_PASSWORD_FAILED,
})

type ConfirmResetPasswordSucceedType = {
  type: typeof CONFIRM_RESET_PASSWORD_SUCCEED
}

export const confirmResetPasswordSucceed = (): ConfirmResetPasswordSucceedType => ({
  type: CONFIRM_RESET_PASSWORD_SUCCEED,
})

type RegisterFirebaseTokenType = {
  type: typeof REGISTER_FIREBASE_TOKEN_REQUEST
  payload: string
}

export const registerFirebaseToken = (payload: string): RegisterFirebaseTokenType => ({
  type: REGISTER_FIREBASE_TOKEN_REQUEST,
  payload,
})

type RegisterFirebaseTokenSucceedType = {
  type: typeof REGISTER_FIREBASE_TOKEN_SUCCEED
}

export const registerFirebaseTokenSucceed = (): RegisterFirebaseTokenSucceedType => ({
  type: REGISTER_FIREBASE_TOKEN_SUCCEED,
})

type RegisterFirebaseTokenFailedTpe = {
  type: typeof REGISTER_FIREBASE_TOKEN_FAILED
}

export const registerFirebaseTokenFailed = (): RegisterFirebaseTokenFailedTpe => ({
  type: REGISTER_FIREBASE_TOKEN_FAILED,
})

type RegisterAnonymousTokenType = {
  type: typeof REGISTER_ANONYMOUS_TOKEN_REQUEST
  payload: string
}

export const registerAnonymousToken = (payload: string): RegisterAnonymousTokenType => ({
  type: REGISTER_ANONYMOUS_TOKEN_REQUEST,
  payload,
})

type RegisterAnonymousTokenSucceed = {
  type: typeof REGISTER_ANONYMOUS_TOKEN_SUCCEED
}

export const registerAnonymousTokenSucceed = (): RegisterAnonymousTokenSucceed => ({
  type: REGISTER_ANONYMOUS_TOKEN_SUCCEED,
})

type RegisterAnonymousTokenFailed = {
  type: typeof REGISTER_ANONYMOUS_TOKEN_FAILED
}

export const registerAnonymousTokenFailed = (): RegisterAnonymousTokenFailed => ({
  type: REGISTER_ANONYMOUS_TOKEN_FAILED,
})

type InvalidateFirebaseTokenType = {
  type: typeof INVALIDATE_FIREBASE_TOKEN_REQUEST
  payload: string
}

export const invalidateFirebaseToken = (payload: string): InvalidateFirebaseTokenType => ({
  type: INVALIDATE_FIREBASE_TOKEN_REQUEST,
  payload,
})

type InvalidateFirebaseTokenFailedType = {
  type: typeof INVALIDATE_FIREBASE_TOKEN_FAILED
}

export const invalidateFirebaseTokenFailed = (): InvalidateFirebaseTokenFailedType => ({
  type: INVALIDATE_FIREBASE_TOKEN_FAILED,
})

type InvalidateFirebaseTokenSucceedType = {
  type: typeof INVALIDATE_FIREBASE_TOKEN_SUCCEED
}

export const invalidateFirebaseTokenSucceed = (): InvalidateFirebaseTokenSucceedType => ({
  type: INVALIDATE_FIREBASE_TOKEN_SUCCEED,
})

type InvalidateAnonymousFirebaseTokenType = {
  type: typeof INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_REQUEST
  payload: string
}

export const invalidateAnonymousFirebaseToken = (
  payload: string
): InvalidateAnonymousFirebaseTokenType => ({
  type: INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_REQUEST,
  payload,
})

type InvalidateAnonymousFirebaseTokenFailedType = {
  type: typeof INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_FAILED
}

export const invalidateAnonymousFirebaseTokenFailed =
  (): InvalidateAnonymousFirebaseTokenFailedType => ({
    type: INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_FAILED,
  })

type InvalidateAnonymousFirebaseTokenSucceedType = {
  type: typeof INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_SUCCEED
}

export const invalidateAnonymousFirebaseTokenSucceed =
  (): InvalidateAnonymousFirebaseTokenSucceedType => ({
    type: INVALIDATE_ANONYMOUS_FIREBASE_TOKEN_SUCCEED,
  })

export type UserActionTypes =
  | RegisterUserType
  | RegisterUserFailedType
  | RegisterUserSucceedType
  | LoginUserType
  | LoginUserFailedType
  | LoginUserSucceedType
  | SetUserType
  | GetUserDataType
  | GetUserDataSucceedType
  | GetUserDataFailedType
  | LogoutUserType
  | LogoutUserSucceedType
  | LogoutUserFailedType
  | UserUpdateType
  | UserUpdateSucceedType
  | UserUpdateFailedType
  | ChangePasswordType
  | ChangePasswordSucceedType
  | ChangePasswordFailedType
  | ResetPasswordType
  | ResetPasswordFailedType
  | ResetPasswordSucceedType
  | ConfirmResetPasswordFailedType
  | ConfirmResetPasswordSucceedType
  | ConfirmResetPasswordType
  | RegisterFirebaseTokenType
  | RegisterFirebaseTokenSucceedType
  | RegisterFirebaseTokenFailedTpe
  | RegisterAnonymousTokenType
  | RegisterAnonymousTokenSucceed
  | RegisterAnonymousTokenFailed
  | InvalidateFirebaseTokenType
  | InvalidateFirebaseTokenFailedType
  | InvalidateFirebaseTokenSucceedType
  | InvalidateAnonymousFirebaseTokenType
  | InvalidateAnonymousFirebaseTokenFailedType
  | InvalidateAnonymousFirebaseTokenSucceedType
