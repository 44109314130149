import { AnimatePresence, m } from 'framer-motion'
import { ReactElement, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import PopUp from 'components/modals/PopUp'
import TutorialFullscreen from 'components/Tutorials/TutorialFullscreen'
import TutorialLine from 'components/Tutorials/TutorialLine'
import { addOrUpdateUrlParam } from 'utils'
import { addTutorialToShow } from 'entity/tutorials'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

const MOTION_VARIANTS_LIST = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      when: 'afterChildren',
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      when: 'beforeChildren',
    },
  },
}

const MOTION_VARIANTS_ITEM = {
  hidden: {
    opacity: 0,
    x: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.2,
      type: 'ease-out',
    },
  },
}

const SHADOW_SIZE = '6px'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: flex-end;
  gap: 0px;
`

const LabelWrapper = styled.div<{
  isActive?: boolean
  isTutorialVisible?: boolean
  stretchLabels?: boolean
}>`
  z-index: ${(props) =>
    props.isTutorialVisible ? props.theme.layout.layers.tutorial + 1 : props.isActive ? 100 : 1};
  margin: 0 calc(${SHADOW_SIZE} / -2);
  padding: ${SHADOW_SIZE};
  padding-bottom: 0;
  overflow-x: visible;
  overflow-y: hidden; /* trims bottom part of the shadow */

  ${(props) =>
    props.isActive &&
    css`
      color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.stretchLabels &&
    css`
      flex: 1;
      text-align: center;
    `}

  ${(props) =>
    props.isTutorialVisible &&
    css`
      pointer-events: none;
    `}
`

const TabLabel = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: ${(props) => props.theme.colors.primaryBackground};
  box-shadow: 0px 0px ${SHADOW_SIZE} ${(props) => props.theme.colors.black}44;
`

const TabWrapper = styled.div<{ noShadow?: boolean }>`
  z-index: 10;
  width: 100%;
  display: flex;
  background: ${(props) => props.theme.colors.primaryBackground};

  ${(props) =>
    !props.noShadow &&
    css`
      box-shadow: 0px -3px 6px ${(props) => props.theme.colors.black}22;
    `}

  &>* {
    flex: 1;
  }
`

export const Tab = styled.div<TabProps>``

type TabProps = {
  id: string
  name: string
}

const Tabs = (props: {
  urlParamId?: string // if this prop is used, active index will be stored in URL (and can be linked to)
  stretchLabels?: boolean
  children: ReactElement<TabProps>[]
  tutorialId?: string
  onTabChange?: (activeTabId: string) => void
  tabChangeManagement?: {
    requireConfirm?: boolean
    onConfirm?: () => void
    confirmTitle?: string
    confirmDescription?: string
  }
}) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const headerRef = useRef()

  const [isTutorialVisible, setIsTutorialVisible] = useState(false)
  const childCount = props.children.filter((child) => child).length

  //
  // ACTIVE TAB MANAGEMENT
  //
  const getDefaultTabId = () => {
    if (props.urlParamId && router.query[props.urlParamId]) {
      const tabId = router.query[props.urlParamId]
      const tab = props.children.find((tab) => tab?.props?.id === tabId)
      if (tab?.props?.id) {
        return tab.props.id
      }
    }
    return props.children[0].props.id
  }

  const [activeTabId, setActiveTabId] = useState<string>(getDefaultTabId())
  const [queuedActiveTabId, setQueuedActiveTabId] = useState<string>()

  const [confirmPopUpIsOpen, setConfirmPopUpIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (childCount > 1 && props.tutorialId) {
      dispatch(addTutorialToShow(props.tutorialId))
    }
  }, [])

  useEffect(() => {
    if (props.onTabChange) {
      props.onTabChange(activeTabId)
    }
  }, [activeTabId])

  useEffect(() => {
    if (queuedActiveTabId !== undefined) {
      const confirmRequired =
        props.tabChangeManagement?.requireConfirm && queuedActiveTabId !== undefined

      if (confirmRequired) {
        setConfirmPopUpIsOpen(true)
      }
    }
  }, [queuedActiveTabId])

  //
  // HANDLERS
  //
  const handleSetActiveTabId = (tabId: string) => {
    if (props.urlParamId) {
      // store tab in url
      addOrUpdateUrlParam(props.urlParamId, tabId)
    }
    setActiveTabId(tabId)
  }

  const onTabClick = (tab: ReactElement<TabProps>) => {
    const tabId = tab?.props?.id
    if (props.tabChangeManagement?.requireConfirm && tabId !== activeTabId) {
      setQueuedActiveTabId(tabId)
    } else {
      handleSetActiveTabId(tabId)
    }
  }

  const onTabChangeConfirm = () => {
    setActiveTabId(queuedActiveTabId)
    setQueuedActiveTabId(undefined)

    if (props.tabChangeManagement?.onConfirm) {
      props.tabChangeManagement.onConfirm()
    }
    setConfirmPopUpIsOpen(false)
  }

  const onTabChangeCancel = () => {
    setQueuedActiveTabId(undefined)
    setConfirmPopUpIsOpen(false)
  }

  //
  // HELPERS
  //
  const isTabActive = (tab: ReactElement<TabProps>) => {
    return tab.props.id === activeTabId
  }

  const getHeaderOffset = () => {
    if (headerRef?.current) {
      const rect = (headerRef.current as any)?.getBoundingClientRect()
      return rect.top + rect.height
    }
    return 0
  }

  //
  // RENDER
  //
  return (
    <>
      <Wrapper>
        {childCount > 1 && (
          <Header ref={headerRef}>
            {props.children.map((tab) =>
              tab ? (
                <LabelWrapper
                  key={tab.props.id}
                  isActive={isTabActive(tab)}
                  isTutorialVisible={isTutorialVisible}
                  stretchLabels={props.stretchLabels}
                >
                  <TabLabel
                    key={tab.props.id}
                    isActive={isTabActive(tab)}
                    onClick={() => onTabClick(tab)}
                  >
                    {tab.props.name}
                  </TabLabel>
                </LabelWrapper>
              ) : null
            )}
          </Header>
        )}

        <TutorialFullscreen
          tutorialId={props.tutorialId}
          onTutorialShow={() => setIsTutorialVisible(true)}
          onTutorialClose={() => setIsTutorialVisible(false)}
          paddingTop={`${getHeaderOffset()}px`}
        >
          <TutorialLine length="100px" centered />
          <div>{props.tutorialId && t(`tutorials.${props.tutorialId}`)}</div>
        </TutorialFullscreen>

        <TabWrapper noShadow={childCount <= 1}>
          <AnimatePresence>
            <m.div initial="hidden" animate="visible" variants={MOTION_VARIANTS_LIST}>
              <m.div
                key={`${props.urlParamId || ''}_${props.tutorialId || ''}_${activeTabId}`}
                variants={MOTION_VARIANTS_ITEM}
              >
                {props.children.find((tab) => tab?.props?.id === activeTabId)}
              </m.div>
            </m.div>
          </AnimatePresence>
        </TabWrapper>
      </Wrapper>

      <PopUp
        isOpen={confirmPopUpIsOpen}
        closeOnOverlayClick
        close={onTabChangeCancel}
        submitButton={{
          onClick: onTabChangeConfirm,
        }}
        header={{
          title: props.tabChangeManagement?.confirmTitle,
          closeTitle: t('CANCEL'),
        }}
      >
        {props.tabChangeManagement?.confirmDescription}
      </PopUp>
    </>
  )
}

export default Tabs
