import { TUTORIALS_PET_HEADER, TUTORIAL_IDS, addTutorialToShow } from 'entity/tutorials'
import { clickable, mainTheme } from 'styles/themes'
import { getActivePet, getAvailablePets, isLoading, isSubHeaderVisible } from 'store/selectors'
import { getPushNotificationsHidden, showHiddenPushNotifications } from 'entity/pushNotifications'
import { setActivePet, toggleMenu, togglePetHeader, toggleSubHeader } from 'store/actions'
import styled, { css, keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import IconWithBadge from './Icons/iconWithBadge'
import LoadingIcon from 'components/svg/synchro.svg'
import LogoSvg from 'components/svg/logo-line.svg'
import SubHeader from './subHeader'
import TutorialFullscreen from './Tutorials/TutorialFullscreen'
import TutorialHighlight from './Tutorials/TutorialHighlight'
import TutorialLine from './Tutorials/TutorialLine'
import { getAppErrors } from 'entity/errors'
import { getInvitesCount } from 'entity/group'
import { getOverdueCount } from 'entity/eventOverdue'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div<{
  background?: string
  color?: string
  showTutorial?: boolean
}>`
  z-index: ${(props) => props.theme?.layout?.layers?.header || 100};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: ${(props) => props.theme?.layout?.maxWidth};
  margin: auto;
  display: flex;

  ${(props) =>
    props.showTutorial &&
    css`
      &,
      & * {
        pointer-events: none !important;
      }
      z-index: ${(props) => props.theme.layout.layers.tutorial + 1};
    `}
`

const HeaderBox = styled.div<{
  background?: string
  color?: string
}>`
  flex: 1;
  height: 46px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  background: ${(props) => (props.background ? props.background : 'white')};
  box-shadow: 0px 0px 16px #00000033;
  color: ${(props) => (props.color ? props.color : 'inherit')};
`

const LogoWrapper = styled.div<{ hideInTutorial?: boolean }>`
  ${clickable};
  position: relative;
  display: grid;
  place-items: center;
  padding: 0px 10px;

  transition: opacity 0.1s ease;
  ${(props) =>
    props.hideInTutorial &&
    css`
      opacity: 0.25;
    `}

  svg {
    height: 36px;
    ${(props) => props.theme.breakpoints.XXS} {
      height: 34px;
    }
  }
`
const Right = styled.div`
  margin: 0px 5px;
  text-align: right;
  position: relative;
  display: flex;
  display: -webkit-inline-box;
  align-items: center;
  justify-content: end;
  height: 100%;
  & > * {
    padding: 0px 4px;
  }
  & > *:last-child {
    padding-right: 0px;
  }
  & > *:not(:last-of-type) {
    margin: 0 4px;
  }
`

const Icon = styled.div`
  position: relative;
  height: 100%;
  & > * {
    height: 100%;
  }

  svg {
    height: 23px;
  }
`

const HamburgerIcon = styled(Icon)`
  svg {
    height: 30px;
  }
`

const animFlash = keyframes`
  from {
    opacity: 0.7;
  }

  to {
    opacity: 1;
  }
`

const ErrorIcon = styled(Icon)`
  svg {
    animation: ${animFlash} 0.4s ease-in infinite alternate;
    color: ${(props) => props.theme.colors.warning};
    height: 23px;
    width: 23px;
  }
`

const IconWithBadgeStyled = styled(IconWithBadge)`
  ${clickable};
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledLoadingIcon = styled(LoadingIcon)`
  height: 32px;
  & #synchro_svg__refresh {
    transform-origin: center center;
    animation: ${rotate} 2s linear infinite;
  }
`

const SubHeaderBox = styled.div<{ isVisible?: boolean; hideForTutorial?: boolean }>`
  position: absolute;
  z-index: -1;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme?.colors?.footerBackground || 'white'};
  border-radius: 0px 0px 30px 30px;
  box-shadow: 0px 0px 16px #00000033;
  overflow: hidden;

  transition: all 0.4s ease;

  ${(props) =>
    props.isVisible
      ? css`
          top: 46px;
          opacity: 1;
        `
      : css`
          opacity: 0;
          top: 0px;
        `};

  ${(props) =>
    props.hideForTutorial &&
    css`
      z-index: ${(props) => props.theme.layout.layers.tutorial - 1};
    `}
`

const TutorialWrapper = styled.div<{ isOnLeft?: boolean }>`
  position: absolute;
  top: 100%;
  width: 40vw;
  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.colors.tutorial};
  white-space: break-spaces;
  font-size: 15px;
  font-weight: bold;

  ${(props) =>
    props.isOnLeft
      ? css`
          left: 50%;
          align-items: flex-start;
          text-align: left;
        `
      : css`
          right: 25%;
          align-items: flex-end;
          text-align: right;
        `}
`

const Header = ({
  visiblePets = true,
  visiblePetsEventFilter = true,
  visiblePetsEventFilterForSelectedPet = false,
  lockedPet = false,
  petsSliderOnChange = undefined,
}) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const overdueCount = useSelector(getOverdueCount)
  const isAppLoading = useSelector(isLoading)
  const activePetId = useSelector(getActivePet)
  const pets = useSelector(getAvailablePets)

  /* FOR DEV PURPOSES (shows all header icons with different possible numbers in badges) */
  const _DEV_SHOW_ALL_ICONS = false

  const subHeaderVisible = useSelector(isSubHeaderVisible)

  const notificationsHidden = useSelector(getPushNotificationsHidden)
  const invitesCount = useSelector(getInvitesCount)
  const menuBadgeCount = invitesCount
  const appErrors = useSelector(getAppErrors)

  //
  // TUTORIALS
  //
  const [showTutorialId, setShowTutorialId] = useState<string>()

  const isTutorialSubHeader = () => {
    return !!showTutorialId && TUTORIALS_PET_HEADER.includes(showTutorialId)
  }

  const isTutorialHeader = () => {
    return !!showTutorialId && !isTutorialSubHeader()
  }

  useEffect(() => {
    if (notificationsHidden?.length) {
      dispatch(addTutorialToShow(TUTORIAL_IDS.HEADER_ICON_PUSH_NOTIFICATIONS))
    }
  }, [notificationsHidden])

  useEffect(() => {
    if (overdueCount) {
      dispatch(addTutorialToShow(TUTORIAL_IDS.HEADER_ICON_EVENTS_OVERDUE))
    }
  }, [overdueCount])

  useEffect(() => {
    if (appErrors?.length) {
      dispatch(addTutorialToShow(TUTORIAL_IDS.HEADER_ICON_ERROR))
    }
  }, [appErrors])

  useEffect(() => {
    if (subHeaderVisible && pets?.length > 1) {
      dispatch(addTutorialToShow(TUTORIAL_IDS.PET_HEADER_ARROWS))
    }
  }, [pets])

  //
  // HANDLERS
  //
  const handleNotificationIconClick = () => {
    if (notificationsHidden?.length > 0) {
      dispatch(showHiddenPushNotifications())
    }
  }

  const handleOverdueEventsClick = () => {
    dispatch(setActivePet('all'))
    router.push('/overdue')
  }

  const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>(false)
  useEffect(() => {
    let loadingTimeout

    if (isAppLoading) {
      // Delay loading icon render to avoid flickering due to fast data fetching
      loadingTimeout = setTimeout(() => {
        setShowLoadingIndicator(isAppLoading)
      }, 500)
    } else {
      setShowLoadingIndicator(false)
      clearTimeout(loadingTimeout)
    }
    return () => {
      clearTimeout(loadingTimeout)
    }
  }, [isAppLoading])

  useEffect(() => {
    dispatch(toggleSubHeader(visiblePets))
  }, [visiblePets])

  useEffect(() => {
    if (visiblePetsEventFilter || visiblePetsEventFilterForSelectedPet) {
      dispatch(togglePetHeader(visiblePetsEventFilterForSelectedPet ? activePetId !== 'all' : true))
    } else {
      dispatch(togglePetHeader(false))
    }
  }, [visiblePetsEventFilter, visiblePetsEventFilterForSelectedPet, activePetId, router.pathname])

  //
  // RENDER
  //
  return (
    <>
      <Wrapper showTutorial={isTutorialSubHeader()}>
        <SubHeaderBox isVisible={subHeaderVisible}>
          <SubHeader
            isLocked={lockedPet}
            petsSliderOnChange={petsSliderOnChange}
            showTutorialId={showTutorialId}
          />
        </SubHeaderBox>
      </Wrapper>

      <Wrapper showTutorial={isTutorialHeader()}>
        <HeaderBox>
          <LogoWrapper
            hideInTutorial={isTutorialHeader() && showTutorialId !== TUTORIAL_IDS.HEADER_INFO}
            onClick={() => router.push('/')}
          >
            <LogoSvg />
            {showTutorialId === TUTORIAL_IDS.HEADER_INFO && (
              <TutorialWrapper isOnLeft>
                <TutorialLine length="130px" />
              </TutorialWrapper>
            )}
          </LogoWrapper>

          <Right>
            {showLoadingIndicator && (
              <div>
                <StyledLoadingIcon />
              </div>
            )}

            {(_DEV_SHOW_ALL_ICONS || !!appErrors?.length) && (
              <ErrorIcon>
                <TutorialHighlight
                  hide={isTutorialHeader() && showTutorialId !== TUTORIAL_IDS.HEADER_ICON_ERROR}
                  highlight={showTutorialId === TUTORIAL_IDS.HEADER_ICON_ERROR}
                >
                  <IconWithBadgeStyled
                    code="icon-warning"
                    value={_DEV_SHOW_ALL_ICONS ? 2 : appErrors.length}
                    showBadge={_DEV_SHOW_ALL_ICONS ? true : appErrors.length > 1}
                    onClick={() => router.push('/feedback')}
                    badgeColor={mainTheme.colors.error}
                  />
                </TutorialHighlight>

                {showTutorialId === TUTORIAL_IDS.HEADER_ICON_ERROR && (
                  <TutorialWrapper>
                    <TutorialLine length="100px" />
                  </TutorialWrapper>
                )}
              </ErrorIcon>
            )}

            {(_DEV_SHOW_ALL_ICONS || !!notificationsHidden?.length) && (
              <Icon>
                <TutorialHighlight
                  hide={
                    isTutorialHeader() &&
                    showTutorialId !== TUTORIAL_IDS.HEADER_ICON_PUSH_NOTIFICATIONS
                  }
                  highlight={showTutorialId === TUTORIAL_IDS.HEADER_ICON_PUSH_NOTIFICATIONS}
                >
                  <IconWithBadgeStyled
                    code="icon-notification"
                    value={_DEV_SHOW_ALL_ICONS ? 100 : notificationsHidden?.length}
                    showBadge={_DEV_SHOW_ALL_ICONS ? true : notificationsHidden?.length > 0}
                    onClick={handleNotificationIconClick}
                  />
                </TutorialHighlight>

                {showTutorialId === TUTORIAL_IDS.HEADER_ICON_PUSH_NOTIFICATIONS && (
                  <TutorialWrapper>
                    <TutorialLine length="100px" />
                  </TutorialWrapper>
                )}
              </Icon>
            )}

            {(_DEV_SHOW_ALL_ICONS || !!overdueCount) && (
              <Icon>
                <TutorialHighlight
                  hide={
                    isTutorialHeader() && showTutorialId !== TUTORIAL_IDS.HEADER_ICON_EVENTS_OVERDUE
                  }
                  highlight={showTutorialId === TUTORIAL_IDS.HEADER_ICON_EVENTS_OVERDUE}
                >
                  <IconWithBadgeStyled
                    code="icon-clock"
                    value={_DEV_SHOW_ALL_ICONS ? 20 : overdueCount}
                    showBadge={_DEV_SHOW_ALL_ICONS ? true : overdueCount > 0}
                    iconColor={
                      router.pathname === '/overdue' ? mainTheme.colors.primary : undefined
                    }
                    badgeColor={
                      router.pathname === '/overdue' ? mainTheme.colors.primary : undefined
                    }
                    onClick={handleOverdueEventsClick}
                  />
                </TutorialHighlight>

                {showTutorialId === TUTORIAL_IDS.HEADER_ICON_EVENTS_OVERDUE && (
                  <TutorialWrapper>
                    <TutorialLine length="100px" />
                  </TutorialWrapper>
                )}
              </Icon>
            )}

            <HamburgerIcon>
              <TutorialHighlight
                hide={isTutorialHeader() && showTutorialId !== TUTORIAL_IDS.HEADER_INFO}
                highlight={showTutorialId === TUTORIAL_IDS.HEADER_INFO}
              >
                <IconWithBadgeStyled
                  code="icon-hamburger"
                  value={menuBadgeCount}
                  showBadge={menuBadgeCount > 0}
                  onClick={() => dispatch(toggleMenu())}
                />
              </TutorialHighlight>

              {showTutorialId === TUTORIAL_IDS.HEADER_INFO && (
                <TutorialWrapper>
                  <TutorialLine length="50px" />
                  {t(`tutorials.${TUTORIAL_IDS.HEADER_INFO}.MENU`)}
                </TutorialWrapper>
              )}
            </HamburgerIcon>
          </Right>
        </HeaderBox>
      </Wrapper>

      {/* TUTORIALS */}
      <TutorialFullscreen
        tutorialId={TUTORIAL_IDS.HEADER_INFO}
        onTutorialShow={() => setShowTutorialId(TUTORIAL_IDS.HEADER_INFO)}
        onTutorialClose={() => setShowTutorialId(undefined)}
        paddingTop="200px"
      >
        {t(`tutorials.${TUTORIAL_IDS.HEADER_INFO}.LOGO`)}
      </TutorialFullscreen>

      <TutorialFullscreen
        tutorialId={TUTORIAL_IDS.HEADER_ICON_ERROR}
        onTutorialShow={() => setShowTutorialId(TUTORIAL_IDS.HEADER_ICON_ERROR)}
        onTutorialClose={() => setShowTutorialId(undefined)}
        paddingTop="170px"
      >
        {t(`tutorials.${TUTORIAL_IDS.HEADER_ICON_ERROR}`)}
      </TutorialFullscreen>

      <TutorialFullscreen
        tutorialId={TUTORIAL_IDS.HEADER_ICON_EVENTS_OVERDUE}
        onTutorialShow={() => setShowTutorialId(TUTORIAL_IDS.HEADER_ICON_EVENTS_OVERDUE)}
        onTutorialClose={() => setShowTutorialId(undefined)}
        paddingTop="170px"
      >
        {t(`tutorials.${TUTORIAL_IDS.HEADER_ICON_EVENTS_OVERDUE}`)}
      </TutorialFullscreen>

      <TutorialFullscreen
        tutorialId={TUTORIAL_IDS.HEADER_ICON_PUSH_NOTIFICATIONS}
        onTutorialShow={() => setShowTutorialId(TUTORIAL_IDS.HEADER_ICON_PUSH_NOTIFICATIONS)}
        onTutorialClose={() => setShowTutorialId(undefined)}
        paddingTop="170px"
      >
        {t(`tutorials.${TUTORIAL_IDS.HEADER_ICON_PUSH_NOTIFICATIONS}`)}
      </TutorialFullscreen>

      <TutorialFullscreen
        tutorialId={TUTORIAL_IDS.PET_HEADER_ARROWS}
        onTutorialShow={() => setShowTutorialId(TUTORIAL_IDS.PET_HEADER_ARROWS)}
        onTutorialClose={() => setShowTutorialId(undefined)}
        paddingTop="160px"
      >
        <TutorialLine length="100px" centered />
        {t(`tutorials.${TUTORIAL_IDS.PET_HEADER_ARROWS}`)}
      </TutorialFullscreen>

      <TutorialFullscreen
        tutorialId={TUTORIAL_IDS.PET_HEADER_FILTERS_GRAPHS}
        onTutorialShow={() => setShowTutorialId(TUTORIAL_IDS.PET_HEADER_FILTERS_GRAPHS)}
        onTutorialClose={() => setShowTutorialId(undefined)}
        paddingTop="160px"
      >
        <TutorialLine length="100px" centered />
        {t(`tutorials.${TUTORIAL_IDS.PET_HEADER_FILTERS_GRAPHS}`)}
      </TutorialFullscreen>

      <TutorialFullscreen
        tutorialId={TUTORIAL_IDS.PET_HEADER_FILTERS_EVENTS}
        onTutorialShow={() => setShowTutorialId(TUTORIAL_IDS.PET_HEADER_FILTERS_EVENTS)}
        onTutorialClose={() => setShowTutorialId(undefined)}
        paddingTop="160px"
      >
        <TutorialLine length="100px" centered />
        {t(`tutorials.${TUTORIAL_IDS.PET_HEADER_FILTERS_EVENTS}`)}
      </TutorialFullscreen>
    </>
  )
}

export default Header
