import { AuthApi } from 'apiLegacy'
import { createLegacyApiInstance } from 'utils'
import { isServerSide } from 'utils'

const authApiInstance = () => createLegacyApiInstance(AuthApi)

export function getAuthToken() {
  if (isServerSide()) return null
  const token = localStorage.getItem('authToken')
  if (token) return JSON.parse(token)
  return undefined
}

export function setAuthToken(token) {
  if (isServerSide()) return
  let parsedTokenObject
  if (token && token.data) {
    parsedTokenObject = token.data
  } else {
    parsedTokenObject = token || undefined
  }

  localStorage.removeItem('authToken')
  if (parsedTokenObject) localStorage.setItem('authToken', JSON.stringify(parsedTokenObject))
}

export function removeAuthToken() {
  localStorage.removeItem('authToken')
}

export function refreshToken(refreshToken) {
  return authApiInstance().refresh({ refreshToken })
}

export function logoutUser() {
  return authApiInstance().logout({})
}
