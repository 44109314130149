import { getLocalStorageItem, setLocalStorageItem } from 'utils'

import { TUTORIAL_IDS } from 'entity/tutorials'

const LOCAL_STORAGE_TUTORIALS_SEEN = 'tutorialsSeen'

export const getTutorialsSeenFromLocalStorage = () => {
  return getLocalStorageItem(LOCAL_STORAGE_TUTORIALS_SEEN) || []
}

export const isInTutorialsSeenInLocalStorage = (tutorialId: string) => {
  const tutorialsSeen = getTutorialsSeenFromLocalStorage()
  return tutorialsSeen?.includes(tutorialId)
}

export const resetTutorialsSeenInLocalStorage = () => {
  setLocalStorageItem(LOCAL_STORAGE_TUTORIALS_SEEN, JSON.stringify([]))
}

export const pushTutorialSeenToLocalStorage = (tutorialId: string) => {
  const tutorialsSeen = getTutorialsSeenFromLocalStorage()
  if (!tutorialsSeen?.includes(tutorialId)) {
    setLocalStorageItem(
      LOCAL_STORAGE_TUTORIALS_SEEN,
      JSON.stringify([...tutorialsSeen, tutorialId])
    )
  }
}

export const markAllTutorialsAsSeen = () => {
  Object.values(TUTORIAL_IDS).forEach((tutorialId) => {
    pushTutorialSeenToLocalStorage(tutorialId)
  })
}
