import { clickable, styled } from 'styles/themes'

import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  min-height: 200px;
  display: grid;
  place-items: center;
  margin: 50px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${(props) => props.theme?.colors?.white || 'white'};
  border: ${(props) => props.theme?.colors.darkGrey || 'darkgray'} 1px solid;
  box-shadow: 0px 0px 5px #0000000f;
  ${clickable};
`

const UnderConstruction = ({ textCode = 'WIP' }) => {
  const { t } = useTranslation('common')

  return <Wrapper dangerouslySetInnerHTML={{ __html: t(textCode) }}></Wrapper>
}
export default UnderConstruction
