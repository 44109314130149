import { Pet } from 'apiLegacy'
import RoundAvatarPet from 'components/roundAvatarPet'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 5%;
`

const TruncatedText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding-right: 5px;
  font-weight: 500;
`

const PetCard = (props: { pet: Pet; onClick?: () => void; noRedirect?: boolean }) => {
  return (
    <Wrapper onClick={props.onClick}>
      <RoundAvatarPet size={50} pet={props.pet} noRedirect={props.noRedirect} />

      <TruncatedText>{props.pet.name}</TruncatedText>
    </Wrapper>
  )
}

export default PetCard
