import { put, takeLatest } from '@redux-saga/core/effects'

import { PushNotificationBody } from 'types'
import { storeError } from './errors'

//
// CONST
//
const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN'
const ADD_PUSH_NOTIFICATION = 'ADD_PUSH_NOTIFICATION'
const SET_VIEWED_PUSH_NOTIFICATION = 'SET_VIEWED_PUSH_NOTIFICATION'
const SET_HIDDEN_PUSH_NOTIFICATION = 'SET_HIDDEN_PUSH_NOTIFICATION'
const SHOW_HIDDEN_NOTIFICATIONS = 'SHOW_HIDDEN_NOTIFICATIONS'

//
// ACTIONS
//

/* Set Firebase Token */
interface SetFirebaseTokenActionProps {
  token: string
}
interface SetFirebaseTokenActionType {
  type: typeof SET_FIREBASE_TOKEN
  payload: SetFirebaseTokenActionProps
}

export const setFirebaseToken = ({
  token,
}: SetFirebaseTokenActionProps): SetFirebaseTokenActionType => ({
  type: SET_FIREBASE_TOKEN,
  payload: { token },
})

/* Add new push notification */
interface AddPushNotificationActionProps {
  notificationBody: PushNotificationBody
}
interface AddPushNotificationActionType {
  type: typeof ADD_PUSH_NOTIFICATION
  payload: AddPushNotificationActionProps
}

export const addPushNotification = ({
  notificationBody,
}: AddPushNotificationActionProps): AddPushNotificationActionType => ({
  type: ADD_PUSH_NOTIFICATION,
  payload: { notificationBody },
})

/* Set notification as viewed */
interface SetViewedPushNotificationActionProps {
  notificationBody: PushNotificationBody
}
interface SetViewedNotificationActionType {
  type: typeof SET_VIEWED_PUSH_NOTIFICATION
  payload: SetViewedPushNotificationActionProps
}

export const setViewedPushNotification = ({
  notificationBody,
}: SetViewedPushNotificationActionProps): SetViewedNotificationActionType => ({
  type: SET_VIEWED_PUSH_NOTIFICATION,
  payload: { notificationBody },
})

/* Set notification as hidden */
interface SetHiddenPushNotificationActionProps {
  notificationBody: PushNotificationBody
}
interface SetHiddenNotificationActionType {
  type: typeof SET_HIDDEN_PUSH_NOTIFICATION
  payload: SetHiddenPushNotificationActionProps
}

export const setHiddenPushNotification = ({
  notificationBody,
}: SetHiddenPushNotificationActionProps): SetHiddenNotificationActionType => ({
  type: SET_HIDDEN_PUSH_NOTIFICATION,
  payload: { notificationBody },
})

/* Show hidden notifications */
interface ShowHiddenNotificationsActionType {
  type: typeof SHOW_HIDDEN_NOTIFICATIONS
}

export const showHiddenPushNotifications = (): ShowHiddenNotificationsActionType => ({
  type: SHOW_HIDDEN_NOTIFICATIONS,
})

//
// HELPERS
//
const removeNotificationFromList = (
  notificationList: PushNotificationBody[],
  notificationToRemove: PushNotificationBody
) => {
  return [...notificationList].filter((n: PushNotificationBody) => n.id !== notificationToRemove.id)
}

const addNotificationToList = (
  notificationList: PushNotificationBody[],
  notificationToAdd: PushNotificationBody
) => {
  return [...notificationList, notificationToAdd]
}

//
// REDUCERS
//
const setFirebaseTokenReducer = (state, action) => {
  const { token } = action.payload
  return {
    ...state,
    firebaseToken: token,
  }
}

const addPushNotificationReducer = (state, action) => {
  const { notificationBody } = action.payload
  if (!notificationBody) {
    return state
  }
  return {
    ...state,
    notificationsToView: [...state.notificationsToView, notificationBody],
  }
}

const setViewedPushNotificationReducer = (state, action) => {
  const { notificationBody } = action.payload
  if (!notificationBody) {
    return state
  }
  return {
    ...state,
    notificationsToView: removeNotificationFromList(state.notificationsToView, notificationBody),
    notificationsViewed: addNotificationToList(state.notificationsViewed, notificationBody),
    notificationsHidden: removeNotificationFromList(state.notificationsHidden, notificationBody),
  }
}

const setHiddenPushNotificationReducer = (state, action) => {
  const { notificationBody } = action.payload
  if (!notificationBody) {
    return state
  }
  return {
    ...state,
    notificationsToView: removeNotificationFromList(state.notificationsToView, notificationBody),
    notificationsViewed: removeNotificationFromList(state.notificationsViewed, notificationBody),
    notificationsHidden: addNotificationToList(state.notificationsHidden, notificationBody),
  }
}

const showHiddenPushNotificationReducer = (state) => {
  return {
    ...state,
    notificationsToView: [...state.notificationsToView, ...state.notificationsHidden],
    notificationsHidden: [],
  }
}

type InitialStateTypes = {
  firebaseToken: string
  notificationsToView: PushNotificationBody[]
  notificationsViewed: PushNotificationBody[]
  notificationsHidden: PushNotificationBody[]
}

const initialState: InitialStateTypes = {
  firebaseToken: null,
  notificationsToView: [],
  notificationsViewed: [],
  notificationsHidden: [],
}

/* final reducer */
export const pushNotificationReducer = (state = initialState, action: any): InitialStateTypes => {
  switch (action.type) {
    case SET_FIREBASE_TOKEN:
      return setFirebaseTokenReducer(state, action)
    case ADD_PUSH_NOTIFICATION:
      return addPushNotificationReducer(state, action)
    case SET_VIEWED_PUSH_NOTIFICATION:
      return setViewedPushNotificationReducer(state, action)
    case SET_HIDDEN_PUSH_NOTIFICATION:
      return setHiddenPushNotificationReducer(state, action)
    case SHOW_HIDDEN_NOTIFICATIONS:
      return showHiddenPushNotificationReducer(state)
    default:
      return state
  }
}

//
// SAGA
//

function* addPushNotificationSaga(action): Generator {
  const { notificationBody } = action.payload
  try {
    if (notificationBody) {
      yield put(addPushNotification(notificationBody))
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'addPushNotificationSaga' }))
    console.error(e)
  }
}

function* setViewedPushNotificationSaga(action): Generator {
  const { notificationBody } = action.payload
  try {
    if (notificationBody) {
      yield put(setViewedPushNotification(notificationBody))
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'setViewedPushNotificationSaga' }))
    console.error(e)
  }
}

function* setHiddenPushNotificationSaga(action): Generator {
  const { notificationBody } = action.payload
  try {
    if (notificationBody) {
      yield put(setHiddenPushNotification(notificationBody))
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'setHiddenPushNotificationSaga' }))
    console.error(e)
  }
}

/* final saga */
export function* pushNotificationSaga() {
  yield takeLatest(ADD_PUSH_NOTIFICATION, addPushNotificationSaga)
  yield takeLatest(SET_VIEWED_PUSH_NOTIFICATION, setViewedPushNotificationSaga)
  yield takeLatest(SET_HIDDEN_PUSH_NOTIFICATION, setHiddenPushNotificationSaga)
}

//
// SELECTORS
//
export const getFirebaseToken = (state) =>
  (state?.pushNotifications as InitialStateTypes)?.firebaseToken as string

export const getPushNotificationsToView = (state) =>
  (state?.pushNotifications as InitialStateTypes)?.notificationsToView as PushNotificationBody[]

export const getPushNotificationsViewed = (state) =>
  (state?.pushNotifications as InitialStateTypes)?.notificationsViewed as PushNotificationBody[]

export const getPushNotificationsHidden = (state) =>
  (state?.pushNotifications as InitialStateTypes)?.notificationsHidden as PushNotificationBody[]
