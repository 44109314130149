import { call, put, takeEvery } from 'redux-saga/effects'

import { PetDocumentListItem } from 'api'
import { fetchPetDocuments } from 'services/documentServices'
import { storeError } from './errors'

//
// STATE
//
type PetDetailType = {
  documents: PetDocumentListItem[]
}

type StateType = {
  [petId: string]: PetDetailType
}

const initialState: StateType = {}

//
// FETCH PET DETAIL
//
const FETCH_PET_DETAIL_REQUEST = 'FETCH_PET_DETAIL_REQUEST'
const FETCH_PET_DETAIL_SUCCEED = 'FETCH_PET_DETAIL_SUCCEED'
const FETCH_PET_DETAIL_FAILED = 'FETCH_PET_DETAIL_FAILED'

interface FetchPetDetailRequestType {
  type: typeof FETCH_PET_DETAIL_REQUEST
  payload: { petId: string }
}

export const fetchPetDetailAction = (payload: { petId: string }): FetchPetDetailRequestType => {
  return {
    type: FETCH_PET_DETAIL_REQUEST,
    payload,
  }
}

interface FetchPetDetailSucceedType {
  type: typeof FETCH_PET_DETAIL_SUCCEED
  payload: {
    petId: string
    documents: PetDocumentListItem[]
  }
}

export const fetchPetDetailSucceed = (payload): FetchPetDetailSucceedType => ({
  type: FETCH_PET_DETAIL_SUCCEED,
  payload,
})

interface fetchPetDetailFailedType {
  type: typeof FETCH_PET_DETAIL_FAILED
  payload: any
}

export const fetchPetDetailFailed = (payload): fetchPetDetailFailedType => ({
  type: FETCH_PET_DETAIL_FAILED,
  payload,
})

/* reducer */
const fetchPetDetailReducer = (state: StateType, action) => {
  const { petId, documents } = action.payload

  const updatedState = { ...state }
  const petDetail = updatedState[petId]

  updatedState[petId] = { ...(petDetail || {}), documents }

  return updatedState as StateType
}

/* saga */
function* fetchPetDetailSaga(action): Generator {
  try {
    const { petId } = action.payload

    if (petId) {
      const response = yield call(fetchPetDocuments, petId)
      yield put(fetchPetDetailSucceed({ petId, documents: (response as any).data }))
    }
  } catch (e) {
    yield put(fetchPetDetailFailed(e))
    yield put(storeError({ error: e, origin: 'fetchPetDetailSaga' }))
    console.error(e)
  }
}

//
// REDUCER
//
export const petDetailsReducer = (state = initialState, action: any): StateType => {
  switch (action.type) {
    case FETCH_PET_DETAIL_SUCCEED:
      return fetchPetDetailReducer(state, action)
    default:
      return state
  }
}

//
// SAGA
//
export function* petDetailsSaga() {
  yield takeEvery(FETCH_PET_DETAIL_REQUEST, fetchPetDetailSaga)
}

//
// SELECTORS
//
export const getPetDetails = (options: { petIds?: 'all' | string[] }) => (state) => {
  const petDetails = state.petDetails as StateType
  if (!options.petIds || options.petIds === 'all') {
    return petDetails
  }

  const petDetailsFiltered = {}

  options.petIds.forEach((petId) => {
    if (petDetails[petId]) {
      petDetailsFiltered[petId] = petDetails[petId]
    }
  })

  return petDetailsFiltered as StateType
}

export const getPetDetail = (petId: string) => (state) =>
  (state.petDetails[petId] || null) as PetDetailType

export const getPetDocuments = (options: { petIds?: 'all' | string[] }) => (state) => {
  const petDetails = state.petDetails as StateType

  const documents = (
    Object.entries(petDetails).map(([petId, petDetail]) =>
      options.petIds === 'all' || options.petIds.includes(petId) ? petDetail.documents : []
    ) as any
  ).flat() as PetDocumentListItem[]

  return documents
}
