import SvgIcon, { IconName } from 'components/svgIcon'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ iconColor?: string }>`
  position: relative;
  transition: all 0.2s ease;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-height: 23px;
    ${(props) =>
      !!props.iconColor &&
      css`
        color: ${props.iconColor};
      `}
  }
`
const Badge = styled.div<{ badgeColor: string }>`
  position: absolute;
  bottom: 4px;
  left: -8px;

  --size: 16px;
  min-width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);

  padding: 0 4px;

  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.badgeColor || props.theme.colors.orange};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  font-weight: 600;
  line-height: 1em;
  transition: all 0.2s ease;
`

const IconWithBadge = (props: {
  code: IconName
  value?: number | string
  showBadge?: boolean
  iconColor?: string
  badgeColor?: string
  className?: string
  onClick?: () => void
}) => {
  return (
    <Wrapper className={props.className} onClick={props.onClick} iconColor={props.iconColor}>
      <SvgIcon code={props.code} />
      {props.showBadge && <Badge badgeColor={props.badgeColor}>{props.value}</Badge>}
    </Wrapper>
  )
}
export default IconWithBadge
