import { isValidUrl } from './url'
export const runningInWebview = () => {
  if (window && window.ReactNativeWebView) return true
  return false
}

export const changeWebviewUrl = (url: string) => {
  if (isValidUrl(url)) {
    if (runningInWebview()) {
      ;(window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'CHANGE_WEBVIEW_URL', payload: url })
      )
    } else {
      window?.alert('You are not running in Webview!')
    }
  }
}

export const changeWebviewLanguage = (language: string) => {
  if (runningInWebview()) {
    ;(window as any).ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'CHANGE_WEBVIEW_LANGUAGE', payload: language })
    )
  }
}
