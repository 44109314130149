import { Breed, Pet } from 'apiLegacy'
import {
  CommonActionType,
  FETCH_BREEDS_SUCCEED,
  FETCH_PETS_SUCCEED,
  PetActionsTypes,
  RESET_STATE,
} from 'store/actions'
import { SAVE_PETS, savePetsReducer } from 'entity/pet'
import { SAVE_PET_SUCCEED, UPDATE_PET_SUCCEED } from 'store/actions/petActions'

import { savePetReducer } from 'entity/pet'

// import { array2keyObject } from 'utils'

type InitialStateTypes = {
  pets: {
    entitiesFetched: boolean
    entities: {
      [x: string]: Pet
    }
    lists: {
      [x: string]: string[]
    }
  }
  breeds: {
    cat: Breed[]
    dog: Breed[]
  }
}

export const initialState: InitialStateTypes = {
  pets: {
    entitiesFetched: false,
    entities: {},
    lists: {},
  },
  breeds: {
    cat: [],
    dog: [],
  },
}

export default function petReducer(
  state = initialState,
  action: PetActionsTypes | CommonActionType
): InitialStateTypes {
  switch (action.type) {
    case FETCH_BREEDS_SUCCEED:
      return { ...state, breeds: { ...state.breeds, [action.payload.type]: action.payload.breeds } }
    case RESET_STATE:
      return initialState
    case FETCH_PETS_SUCCEED: {
      return {
        ...state,
        pets: {
          ...state.pets,
          entities: action.payload.entities,
          lists: action.payload.lists,
        },
      }
    }
    case SAVE_PETS:
      return savePetsReducer(state, action)
    case SAVE_PET_SUCCEED:
      return savePetReducer(state, action)
    case UPDATE_PET_SUCCEED:
      return savePetReducer(state, action)
    default:
      return state
  }
}
