import { Event, GetAssignableUsersResponse, GetEventResponse } from 'apiLegacy'
import { UserInfo, saveUsers } from './users'
import { call, put } from 'redux-saga/effects'
import { flattenArray, isEmptyObject } from 'utils'
import { getAssignableUsers as getAssignableUsersService, postAssignEvent } from 'services'

import { RootState } from 'store/reducers'
import { saveEvent } from './event'
import { savePets } from './pet'
import { storeError } from './errors'

/* CONST */
export const FETCH_ASSIGNABLE_USERS = 'FETCH_ASSIGNABLE_USERS'
export const SAVE_ASSIGNABLE_USERS = 'SAVE_ASSIGNABLE_USERS'
export const ASSIGN_EVENT = 'ASSIGN_EVENT'

/* DATA TYPES */
export type UserArrayType = UserInfo[]

/* STORE */
export type assignableUsersStoreType = {
  assignableUsers: UserArrayType
}
export const assignableUsersStoreDefault: assignableUsersStoreType = {
  assignableUsers: [],
}

/* ACTION */
export interface FetchAssignableUsersActionType {
  type: typeof FETCH_ASSIGNABLE_USERS
}
export const fetchAssignableUsers = (): FetchAssignableUsersActionType => ({
  type: FETCH_ASSIGNABLE_USERS,
})

export interface SaveAssignableUsersProps {
  users: UserArrayType
}
export interface SaveAssignableUsersActionType {
  type: typeof SAVE_ASSIGNABLE_USERS
  payload: SaveAssignableUsersProps
}
export const saveAssignableUsers = (
  payload: SaveAssignableUsersProps
): SaveAssignableUsersActionType => ({
  type: SAVE_ASSIGNABLE_USERS,
  payload,
})

export interface AssignEventProps {
  eventId: string
  userId: string
  event?: Event
}
export interface AssignEventActionType {
  type: typeof ASSIGN_EVENT
  payload: AssignEventProps
}
export const assignEvent = (payload: AssignEventProps): AssignEventActionType => ({
  type: ASSIGN_EVENT,
  payload,
})

/* REDUCER */
export const saveAssignableUsersReducer = (state, action: SaveAssignableUsersActionType) => {
  return {
    ...state,
    assignableUsers: action?.payload?.users,
  }
}

/* SAGA */
export function* fetchAssignableUsersSaga(): Generator {
  try {
    const eventResponse: any = yield call(getAssignableUsersService)
    const data = (yield eventResponse.data) as GetAssignableUsersResponse
    const users = (yield data?.users) as UserArrayType
    // TODO: check if result is ok
    yield put(saveAssignableUsers({ users }))
  } catch (e) {
    yield put(storeError({ error: e, origin: 'fetchAssignableUsersSaga' }))
    console.error(e)
  }
}

export function* assignEventSaga(action: AssignEventActionType): Generator {
  const payload: AssignEventProps = action.payload
  try {
    const { eventId, userId } = payload
    const eventResponse: any = yield call(postAssignEvent, eventId, userId)
    const data = (yield eventResponse.data) as GetEventResponse
    const {
      events,
      entities: { users, pets },
    } = data
    yield put(savePets({ entities: pets, lists: {} }))
    yield put(saveUsers({ entities: users, lists: {} }))
    yield put(saveEvent(events[0]))
  } catch (e) {
    yield put(storeError({ error: e, origin: 'assignEventSaga' }))
    console.error(e)
  }
}

/* SELECTOR */
export const getAssignableUsers = (petId: string) => (state: RootState) => {
  if (!isEmptyObject(state.groups.groups.entities) && petId) {
    const isMyPet =
      state.groups.groups.entities[state.groups.groups.lists.homeGroupId].pets.includes(petId)

    if (isMyPet) {
      const assignableUsers = []
      assignableUsers.push(
        state.groups.groups.entities[state.groups.groups.lists.homeGroupId]?.members
      )

      state.groups.groups.lists.joinedGroupIds.forEach((groupId) =>
        assignableUsers.push(state.groups.groups.entities[groupId]?.members)
      )

      const uniqueAssignableUsers = flattenArray(assignableUsers)
      return uniqueAssignableUsers.map((userId: string) => state.user.users.entities[userId])
    } else {
      const petGroup: any = Array.from(
        Object.keys(state.groups.groups.entities),
        (key) => state.groups.groups.entities[key]
      ).find((group) => group.pets.includes(petId))

      if (petGroup) {
        return state.groups.groups.entities[petGroup.id].members.map(
          (userId: string) => state.user.users.entities[userId]
        )
      }
    }
  }
  return []
}
