import baseStyled, { ThemedStyledInterface } from 'styled-components'

import breakpoints from './breakpoints'

const layout = {
  maxWidth: '500px',
  layers: {
    main: 10,
    header: 300,
    modal: 400,
    loading: 700,
    menu: 500,
    footer: 310,
    notification: 200,
    tutorial: 10000,
  },
  transition: 'all 0.5s ease',
}

const colors = {
  placeholderBackground: '#CCCCCC',
  placeholderFill: '#8E8E8E',
  text: '#525051',
  textInverted: '#FFFFFF',
  lighterGrey2: '#DDDDDD',
  lighterGrey: '#bebebe',
  lightGrey: '#525051',
  darkGrey: '#232323',
  grey: '#696969',
  orange: '#F6B626',
  darkOrange: '#D3A034',
  darkOrange2: '#b57b49',
  brown: '#B57B49',
  blue: '#81C3D7',
  red: '#F67226',
  darkRed: '#D46321',
  green: '#7CB227',
  darkGreen: '#71A232',
  black: '#525051',
  white: '#ffffff',
}
const additionalColors = {
  primary: colors.green,
  primaryDarker: colors.darkGreen,
  secondary: colors.orange,
  secondaryDarker: colors.darkOrange,
  primaryBackground: '#f6f6f6',
  lightBackground: colors.white,
  warning: colors.orange,
  error: colors.red,
  tutorial: colors.white,
  link: colors.blue,
}
const input = {
  maxTextLength: 250,
  borderRadius: 17,
  innerShadow: 'inset #0003 2px 2px 2px',
}

export const mainTheme = {
  layout,
  breakpoints,
  colors: { ...colors, ...additionalColors },
  input,
  events: {
    event: {
      color: colors.green,
      colorDarker: colors.darkGreen,
    },
    reminder: {
      color: colors.orange,
      colorDarker: colors.darkOrange,
    },
    overdue: {
      color: colors.red,
      colorDarker: colors.darkRed,
    },
  },
}
export type Theme = typeof mainTheme

export const secondTheme = {
  ...mainTheme,
  colors: {
    ...mainTheme.colors,
    secondary: 'red',
  },
}

/* MIXINS */
export const clickable = {
  cursor: 'pointer',
}
export const clickThrough = {
  'pointer-events': 'none',
}
export const animated = {
  transition: mainTheme.layout.transition,
}
export const capitalizeFirstWord = `
  &:first-letter {
    text-transform: uppercase;
  }
`
export const ellipsis = {
  'white-space': 'nowrap',
  'text-overflow': 'ellipsis',
  overflow: 'hidden',
}

export const styled = baseStyled as ThemedStyledInterface<Theme>
