import { Event, GetEventResponse } from 'apiLegacy'
import { call, put } from 'redux-saga/effects'

import { addAppNotification } from './appNotifications'
import { completeEvent } from 'services'
import { loadOverdueCount } from './eventOverdue'
import { saveEvent } from './event'
import { savePets } from './pet'
import { saveUsers } from './users'
import { storeError } from './errors'

/* CONST */
export const FULFILL_EVENT = 'FULFILL_EVENT'

/* DATA TYPES */
// export type { UserInfo } from 'api'

/* ACTION */
export interface FulfillEventProps {
  eventId: string
  isCompleted?: boolean
  event: Event
}
export interface FulfillEventActionType {
  type: typeof FULFILL_EVENT
  payload: FulfillEventProps
}
export const fulfillEvent = (payload: FulfillEventProps): FulfillEventActionType => ({
  type: FULFILL_EVENT,
  payload,
})

/* REDUCER */
// export const fulfillEventReducer = (state, action) => {
//   console.log('fulfillEventReducer', action, state)
//   return {
//     ...state,
//   }
// }

/* SAGA */
export function* fulFillEventSaga(action: FulfillEventActionType): Generator {
  const payload = action.payload
  try {
    const { event, eventId, isCompleted } = payload
    const eventResponse: any = yield call(completeEvent, eventId, isCompleted)
    const data = (yield eventResponse.data) as GetEventResponse
    const {
      events,
      entities: { users, pets },
    } = data
    yield put(savePets({ entities: pets, lists: {} }))
    yield put(saveUsers({ entities: users, lists: {} }))
    yield put(saveEvent(events[0]))
    yield put(loadOverdueCount())
    if (isCompleted) {
      yield put(
        addAppNotification({
          type: 'event',
          title: 'notifications.success.FULFILL_EVENT',
          event: event,
          isCompleted,
        })
      )
    } else {
      yield put(
        addAppNotification({
          type: 'event',
          title: 'notifications.error.FULFILL_EVENT',
          event: event,
          isCompleted,
        })
      )
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'fulFillEventSaga' }))
    console.error(e)
  }
}

/* SELECTOR */
// export const getUserById = (id: string) => (state) => state?.user?.users[id]
