export const findNearbyPlaces = async (props: {
  placesService: google.maps.places.PlacesService
  location: { lat: number; lng: number } | google.maps.LatLng
  callback: (results: google.maps.places.PlaceResult[]) => void
  filters?: {
    type?: string
  }
}) => {
  await props.placesService?.nearbySearch(
    {
      location: props.location,
      rankBy: google.maps.places.RankBy.DISTANCE,
      type: props.filters?.type, // https://developers.google.com/maps/documentation/javascript/supported_types
    },
    (results, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        props.callback(results)
      }
    }
  )
}
