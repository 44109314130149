import { DashboardApi, GraphCode, GraphTimeUnit } from 'api/api'

import { GraphDefinition } from 'entity/graphs'
import { createApiInstance } from 'utils'

const apiInstance = () => createApiInstance(DashboardApi)

export const getGraphDefinitionsService = () => {
  // TODO - connect to BE (?)
  return {
    data: [
      {
        code: 'height',
        name: 'graph-height-name',
        icon: 'icon-height',
        defaultTimePeriod: 'month',
        units: 'cm',
      },
      {
        code: 'weight',
        name: 'graph-weight-name',
        icon: 'icon-weight',
        defaultTimePeriod: 'month',
        units: 'kg',
      },
      {
        code: 'expenses',
        name: 'graph-expenses-name',
        icon: 'icon-expenses',
        defaultTimePeriod: 'month',
        units: 'CZK',
        translateUnits: true,
      },
      {
        code: 'walking',
        name: 'graph-walking-name',
        icon: 'icon-paw',
        defaultTimePeriod: 'month',
        units: ' ',
      },
    ] as GraphDefinition[],
  }
}

export const getPetGraphData = (props: {
  petId: string
  graphCode: GraphCode
  timeUnit: GraphTimeUnit
  year: number
  month?: number
}) => {
  return apiInstance().getGraphSeriesForPet(
    props.graphCode,
    props.petId,
    props.timeUnit,
    props.year,
    props.month
  )
}
