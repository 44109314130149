import {
  LanguageCode,
  PublicLinkRequest,
  SharedPageItem,
  SharedPageReponse,
  SharedPagesReponseLists,
} from 'api'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  deleteLink,
  fetchSharePet as fetchSharePetService,
  findLinks,
  generateShareToVetLink as generateShareToVetLinkService,
  updateLink,
} from 'services'

import { RootState } from 'store/reducers'
import { addAppNotification } from './appNotifications'

//
// STATE
//
type StateType = {
  links: any
  activeLink: string | undefined
  data: SharedPageReponse | undefined
  activePet: string | undefined
  dateRange: { from: string; till: string }
  isSelectable: boolean
  isPrintable: boolean
  loaded: boolean
}

const beforeYearDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(
  0,
  0,
  1
)
const now = new Date().setHours(23, 59, 59)

const initialState: StateType = {
  links: [],
  activeLink: undefined,
  data: undefined,
  activePet: undefined,
  dateRange: {
    from: new Date(beforeYearDate).toISOString(),
    till: new Date(now).toISOString(),
  },
  isSelectable: false,
  isPrintable: false,
  loaded: false,
}

// ACTIONS
export const GENERATE_SHARE_TO_VET_LINK_REQUEST = 'GENERATE_SHARE_TO_VET_LINK_REQUEST'
export const GENERATE_SHARE_TO_VET_LINK_FAILED = 'GENERATE_SHARE_TO_VET_LINK_FAILED'
export const GENERATE_SHARE_TO_VET_LINK_SUCCEED = 'GENERATE_SHARE_TO_VET_LINK_SUCCEED'
export const FETCH_SHARE_PET_REUQEST = 'FETCH_SHARE_PET_REQUEST'
export const FETCH_SHARE_PET_SUCCEED = 'FETCH_SHARE_PET_SUCCEED'
export const FETCH_SHARE_PET_FAILED = 'FETCH_SHARE_PET_FAILED'
export const FETCH_SHARE_LINKS_REQUEST = 'FETCH_SHARE_LINKS_REQUEST'
export const FETCH_SHARE_LINKS_FAILED = 'FETCH_SHARE_LINKS_FAILED'
export const FETCH_SHARE_LINKS_SUCCEED = 'FETCH_SHARE_LINKS_SUCCEED'
export const SET_ACTIVE_SHARE_PET = 'SET_ACTIVE_SHARE_PET'
export const SET_SHARE_DATE_RANGE = 'SET_SHARE_DATE_RANGE'
export const SET_IS_SELECTABLE_SHARE_PAGE = 'SET_IS_SELECTABLE_SHARE_PAGE'
export const SET_IS_PRINTABLE_SHARE_PAGE = 'SET_IS_PRINTABLE_SHARE_PAGE'
export const DELETE_SHARE_LINK_REQUEST = 'DELETE_SHARE_LINK_REQUEST'
export const DELETE_SHARE_LINK_FAILED = 'DELETE_SHARE_LINK_FAILED'
export const DELETE_SHARE_LINK_SUCCEED = 'DELETE_SHARE_LINK_SUCCEED'
export const UPDATE_SHARE_LINK_REQUEST = 'UPDATE_SHARE_LINK_REQUEST'
export const UPDATE_SHARE_LINK_FAILED = 'UPDATE_SHARE_LINK_FAILED'
export const UPDATE_SHARE_LINK_SUCCEED = 'UPDATE_SHARE_LINK_SUCCEED'
export const SET_SHARE_LINK_LOADED = 'SET_SHARE_LINK_LOADED'

type GenerateShareToVetLink = {
  type: typeof GENERATE_SHARE_TO_VET_LINK_REQUEST
  payload: PublicLinkRequest
}

export const generateShareToVetLink = (
  payload: GenerateShareToVetLink['payload']
): GenerateShareToVetLink => ({
  type: GENERATE_SHARE_TO_VET_LINK_REQUEST,
  payload,
})

type GenerateShareToVetLinkFailed = {
  type: typeof GENERATE_SHARE_TO_VET_LINK_FAILED
  payload: any
}

export const generateShareToVetLinkFailed = (payload): GenerateShareToVetLinkFailed => ({
  type: GENERATE_SHARE_TO_VET_LINK_FAILED,
  payload,
})

type GenerateShareToVetLinkSucceed = {
  type: typeof GENERATE_SHARE_TO_VET_LINK_SUCCEED
  payload: string
}

export const generateShareToVetLinkSucceed = (payload): GenerateShareToVetLinkSucceed => ({
  type: GENERATE_SHARE_TO_VET_LINK_SUCCEED,
  payload,
})

type FetchSharePet = {
  type: typeof FETCH_SHARE_PET_REUQEST
  payload: {
    from: string
    till: string
    limit: number
    token: string
    locale: LanguageCode
  }
}

export const fetchSharePet = (payload: FetchSharePet['payload']): FetchSharePet => ({
  type: FETCH_SHARE_PET_REUQEST,
  payload,
})

type FetchSharePetFailed = {
  type: typeof FETCH_SHARE_PET_FAILED
  payload: any
}

export const fetchSharePetFailed = (payload) => ({
  type: FETCH_SHARE_PET_FAILED,
  payload,
})

type FetchSharePetSucceed = {
  type: typeof FETCH_SHARE_PET_SUCCEED
  payload: SharedPageReponse
}

export const fetchSharePetSucceed = (
  payload: FetchSharePetSucceed['payload']
): FetchSharePetSucceed => ({
  type: FETCH_SHARE_PET_SUCCEED,
  payload,
})

type SetActiveSharePet = {
  type: typeof SET_ACTIVE_SHARE_PET
  payload: string | undefined
}

export const setActiveSharePet = (payload: SetActiveSharePet['payload']): SetActiveSharePet => ({
  type: SET_ACTIVE_SHARE_PET,
  payload,
})

type SetShareDateRange = {
  type: typeof SET_SHARE_DATE_RANGE
  payload: {
    from: string
    till: string
  }
}

export const setShareDateRange = (payload: SetShareDateRange['payload']): SetShareDateRange => ({
  type: SET_SHARE_DATE_RANGE,
  payload,
})

type FetchSharedLinks = {
  type: typeof FETCH_SHARE_LINKS_REQUEST
}

export const fetchSharedLinks = (): FetchSharedLinks => ({
  type: FETCH_SHARE_LINKS_REQUEST,
})

type FetchShareLinksFailed = {
  type: typeof FETCH_SHARE_LINKS_FAILED
  payload: any
}

export const fetchSharedLinksFailed = (payload): FetchShareLinksFailed => ({
  type: FETCH_SHARE_LINKS_FAILED,
  payload,
})

type FetchSharedLinksSucceed = {
  type: typeof FETCH_SHARE_LINKS_SUCCEED
  payload: SharedPagesReponseLists
}

export const fetchSharedLinksSuceed = (
  payload: FetchSharedLinksSucceed['payload']
): FetchSharedLinksSucceed => ({
  type: FETCH_SHARE_LINKS_SUCCEED,
  payload,
})

type SetIsSelectable = {
  type: typeof SET_IS_SELECTABLE_SHARE_PAGE
  payload: boolean
}

export const setIsSelectable = (payload: boolean) => ({
  type: SET_IS_SELECTABLE_SHARE_PAGE,
  payload,
})

type SetIsPrintableSharePage = {
  type: typeof SET_IS_PRINTABLE_SHARE_PAGE
  payload: boolean
}

export const setIsPrintableSharePage = (
  payload: SetIsPrintableSharePage['payload']
): SetIsPrintableSharePage => ({
  type: SET_IS_PRINTABLE_SHARE_PAGE,
  payload,
})

type DeleteShareLink = {
  type: typeof DELETE_SHARE_LINK_REQUEST
  payload: string
}

export const deleteShareLink = (payload: DeleteShareLink['payload']): DeleteShareLink => ({
  type: DELETE_SHARE_LINK_REQUEST,
  payload,
})

type DeleteShareLinkFailed = {
  type: typeof DELETE_SHARE_LINK_FAILED
  payload?: any
}

export const deleteShareLinkFailed = (payload?): DeleteShareLinkFailed => ({
  type: DELETE_SHARE_LINK_FAILED,
  payload,
})

type DeleteShareLinkSucceed = {
  type: typeof DELETE_SHARE_LINK_SUCCEED
  payload: string
}

export const deleteShareLinkSucceed = (
  payload: DeleteShareLinkSucceed['payload']
): DeleteShareLinkSucceed => ({
  type: DELETE_SHARE_LINK_SUCCEED,
  payload,
})

type UpdateShareLink = {
  type: typeof UPDATE_SHARE_LINK_REQUEST
  payload: PublicLinkRequest
}

export const updateShareLink = (payload: UpdateShareLink['payload']): UpdateShareLink => ({
  type: UPDATE_SHARE_LINK_REQUEST,
  payload,
})

type UpdateShareLinkFailed = {
  type: typeof UPDATE_SHARE_LINK_FAILED
  payload: any
}
export const updateShareLinkFailed = (payload): UpdateShareLinkFailed => ({
  type: UPDATE_SHARE_LINK_FAILED,
  payload,
})

type UpdateShareLinkSucceed = {
  type: typeof UPDATE_SHARE_LINK_SUCCEED
  payload: SharedPageItem
}

export const updateShareLinkSucceed = (
  payload: UpdateShareLinkSucceed['payload']
): UpdateShareLinkSucceed => ({
  type: UPDATE_SHARE_LINK_SUCCEED,
  payload,
})

type SetSharedLinkLoaded = {
  type: typeof SET_SHARE_LINK_LOADED
  payload: boolean
}

export const setSharedLinkLoaded = (
  payload: SetSharedLinkLoaded['payload']
): SetSharedLinkLoaded => ({
  type: SET_SHARE_LINK_LOADED,
  payload,
})

export type ShareActionTypes =
  | GenerateShareToVetLink
  | GenerateShareToVetLinkFailed
  | GenerateShareToVetLinkSucceed
  | FetchSharePet
  | FetchSharePetFailed
  | FetchSharePetSucceed
  | SetActiveSharePet
  | SetShareDateRange
  | FetchSharedLinks
  | FetchShareLinksFailed
  | FetchSharedLinksSucceed
  | SetIsSelectable
  | SetIsPrintableSharePage
  | DeleteShareLink
  | DeleteShareLinkFailed
  | DeleteShareLinkSucceed
  | UpdateShareLink
  | UpdateShareLinkFailed
  | UpdateShareLinkSucceed
  | SetSharedLinkLoaded

//
// REDUCER
//
export const shareReducer = (state = initialState, action: ShareActionTypes): StateType => {
  switch (action.type) {
    case SET_SHARE_LINK_LOADED:
      return {
        ...state,
        loaded: action.payload,
      }
    case GENERATE_SHARE_TO_VET_LINK_SUCCEED:
      return {
        ...state,
        activeLink: action.payload,
      }
    case FETCH_SHARE_PET_SUCCEED:
      return {
        ...state,
        data: action.payload,
      }
    case SET_ACTIVE_SHARE_PET:
      return {
        ...state,
        activePet: action.payload,
      }
    case SET_SHARE_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      }
    case FETCH_SHARE_LINKS_SUCCEED:
      return {
        ...state,
        links: action.payload,
      }
    case SET_IS_SELECTABLE_SHARE_PAGE:
      return {
        ...state,
        isSelectable: action.payload,
      }
    case SET_IS_PRINTABLE_SHARE_PAGE:
      return {
        ...state,
        isPrintable: action.payload,
      }
    case DELETE_SHARE_LINK_SUCCEED:
      return {
        ...state,
        links: {
          ...state.links,
          lists: {
            ...state.links.lists,
            pages: state.links.lists.pages.filter((page) => page.id !== action.payload),
          },
        },
      }
    case UPDATE_SHARE_LINK_SUCCEED: {
      return {
        ...state,
        activeLink: action.payload.url,
        links: {
          ...state.links,
          lists: {
            ...state.links.lists,
            pages: state.links.lists.pages.map((page) => {
              if (page.id === action.payload.id) {
                return action.payload.id
              }

              return page
            }),
          },
        },
      }
    }
    default:
      return state
  }
}

//
// SAGAS
//
function* generateShareVetLinkSaga(action): Generator {
  try {
    const response: any = yield call(generateShareToVetLinkService, action.payload)
    const { url } = response.data
    yield put(generateShareToVetLinkSucceed(url))
    yield put(fetchSharedLinks())
  } catch (e) {
    yield put(generateShareToVetLinkFailed(e))
  }
}

function* fetchSharePetSaga(action): Generator {
  try {
    yield put(setSharedLinkLoaded(false))
    const response: any = yield call(
      fetchSharePetService,
      action.payload.token,
      action.payload.from,
      action.payload.till,
      action.payload.limit,
      action.payload.locale
    )
    const data = response.data
    yield put(fetchSharePetSucceed(data))
    yield put(setActiveSharePet(data.lists.petIds[0]))
    yield put(setSharedLinkLoaded(true))
  } catch (e) {
    yield put(setSharedLinkLoaded(true))
    yield put(fetchSharePetFailed(e))
  }
}

function* fetchShareLinksSaga(action): Generator {
  try {
    const response: any = yield call(findLinks)
    const data = response?.data
    yield put(fetchSharedLinksSuceed(data))
  } catch (e) {
    yield put(fetchSharedLinksFailed(e))
  }
}

function* deleteShareLinkSaga(action): Generator {
  try {
    const response: any = yield call(deleteLink, action.payload)
    const status = response.status
    if (status >= 200 && status < 300) {
      yield put(deleteShareLinkSucceed(action.payload))
      yield put(
        addAppNotification({
          type: 'text',
          title: 'notifications.success.DELETE_LINK',
          actionType: 'success',
        })
      )
    } else {
      yield put(deleteShareLinkFailed())
    }
  } catch (e) {
    yield put(deleteShareLinkFailed(e))
  }
}

function* updateShareLinkSaga(action): Generator {
  try {
    // @ts-ignore
    let { id, createdAt, url, pets, ...updateObject } = action.payload
    const response: any = yield call(updateLink, id, updateObject)
    const data = response?.data
    yield put(updateShareLinkSucceed(data))
    yield put(generateShareToVetLinkSucceed(url))
    yield put(fetchSharedLinks())
    yield put(
      addAppNotification({
        type: 'text',
        title: 'notifications.success.EDIT_LINK',
        actionType: 'success',
      })
    )
  } catch (e) {
    yield put(updateShareLinkFailed(e))
  }
}

export function* shareSaga() {
  yield takeLatest(GENERATE_SHARE_TO_VET_LINK_REQUEST, generateShareVetLinkSaga)
  yield takeLeading(FETCH_SHARE_PET_REUQEST, fetchSharePetSaga)
  yield takeLeading(FETCH_SHARE_LINKS_REQUEST, fetchShareLinksSaga)
  yield takeLeading(DELETE_SHARE_LINK_REQUEST, deleteShareLinkSaga)
  yield takeLeading(UPDATE_SHARE_LINK_REQUEST, updateShareLinkSaga)
}

//
// SELECTORS
//
export const getShareActiveLink = (state: RootState) => state.share.activeLink
export const getSharedPetData = (state: RootState) => state.share.data
export const getSharedActivePetId = (state: RootState) => state.share.activePet
export const getSharedDateRange = (state: RootState) => state.share.dateRange
export const getSharedIsSelectable = (state: RootState) => state.share.isSelectable
export const getSharedIsPrintable = (state: RootState) => state.share.isPrintable
export const getSharedIsLoaded = (state: RootState) => state.share.loaded
export const getSharedActivePet = (state: RootState) =>
  // @ts-ignore
  state.share.data?.entities.pets[state.share.activePet]
export const getSharedPets = (state: RootState) =>
  // @ts-ignore
  state.share.data?.lists?.petIds?.map((id) => state.share.data?.entities.pets[id]) || []
export const getSharedActivePetDocuments = (state: RootState) =>
  // @ts-ignore
  state.share.data?.lists?.documentsIdsByPetId[state.share.activePet]?.map(
    // @ts-ignore
    (documentId) => state.share.data.entities.documents[documentId]
  ) || []
export const getSharedActivePetVetPhotos = (state: RootState) =>
  state.share.data?.lists?.eventsIdsByPetId[state.share.activePet]?.vet
    ?.map((vetEventId) =>
      state.share.data?.entities?.events[vetEventId].items?.photos?.map((photo) => photo.id)
    )
    .flat() || []
export const getSharedActivePetEvents = (state: RootState) => {
  let events = {}
  // @ts-ignore
  if (state.share?.data?.lists?.eventsIdsByPetId[state.share.activePet]) {
    const eventTypes = Object.keys(
      // @ts-ignore
      state.share?.data?.lists?.eventsIdsByPetId[state.share.activePet]
    )

    eventTypes.forEach((eventType) => {
      events = {
        ...events,
        // @ts-ignore
        [eventType]: state.share.data.lists.eventsIdsByPetId[state.share.activePet][eventType].map(
          // @ts-ignore
          (eventId) => state.share.data.entities.events[eventId]
        ),
      }
    })
  } else {
    return undefined
  }

  return events
}
export const getSharedLinks = (state: RootState) =>
  state.share.links?.lists?.pages?.map((page) => ({
    ...page,
    pets: page.petIds.map((petId) => state.share.links?.entities?.pets[petId]),
  })) || []
