import { Dictionary } from 'types'
import { isArray } from 'utils'

export const objectFromEntries = (arr) =>
  //@ts-ignore
  Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v })))

export const isObject = (a: any) => !!a && a.constructor === Object

export const isEmptyObject = (object) => {
  return Object.entries(object).length === 0
}

export const getProperty = (
  obj: Record<string, unknown>,
  path: string | string[],
  def: any = undefined
): unknown => {
  const pathArr = typeof path === 'string' ? path.split('.') : path
  let act: any = obj
  pathArr.forEach((prop) => {
    if (typeof act === 'object' && prop in act) {
      act = act[prop]
    } else {
      return def
    }
  })
  return act
}

export const setProperty = (
  obj: Record<string, unknown>,
  pathIn: string | string[],
  value: any
) => {
  const path = typeof pathIn === 'string' ? pathIn.split('.') : pathIn
  let act: any = obj
  let i = 0
  for (i = 0; i < path.length - 1; i++) {
    if (!(path[i] in act)) act[path[i]] = {}
    act = act[path[i]]
  }
  act[path[i]] = value
  return obj
}

export const array2objectMap = (
  arr: Array<any> = [],
  fce: (item: any, act: Dictionary<any> | Array<any>) => boolean | undefined | void,
  init: Dictionary<any> = {}
) => {
  const res = { ...init }
  arr.forEach((item) => {
    if (fce(item, res) === false) return res
  })
  return res
}

export const array2keyObject = (array = [{}], key = 'id', keys = []) => {
  if (!isArray(array)) {
    console.warn('[WARN] array2keyObject: 1st argument is not array', array)
    return {}
  }
  return array.reduce((acc, curr) => {
    keys.push(curr[key])
    acc[curr[key]] = curr
    return acc
  }, {})
}
