import { useEffect, useState } from 'react'

export type FirebaseToken = string

function useFirebaseToken() {
  const [firebaseToken, setFirebaseToken] = useState<FirebaseToken>()

  useEffect(() => {
    if (window) {
      // this will connect setFirebaseToken to the function accessible from the webview wrapper
      ;(window as any).setFirebaseToken = setFirebaseToken

      if ((window as any).ReactNativeWebView) {
        ;(window as any).ReactNativeWebView.postMessage(
          JSON.stringify({ type: 'REACT_APP_LOADED' })
        )
      }
    }
  }, [])

  return firebaseToken
}

export default useFirebaseToken
