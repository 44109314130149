import styled, { css, keyframes } from 'styled-components'

const animAppear = keyframes`
  from {
    transform: scaleY(0);
  }
`

const Wrapper = styled.div`
  padding: 10px;
`

const Line = styled.div<{ length: string; centered: boolean }>`
  width: 0px;
  height: ${(props) => props.length};
  border-left: 1px dashed ${(props) => props.theme.colors.tutorial}88;

  animation: ${animAppear} 0.2s ease-out both;
  animation-delay: 0.2s;
  transform-origin: top left;

  ${(props) =>
    props.centered &&
    css`
      margin: auto;
    `};
`

const TutorialLine = (props: { length: string; centered?: boolean }) => {
  //
  // RENDER
  //
  return (
    <Wrapper>
      <Line length={props.length} centered={props.centered} />
    </Wrapper>
  )
}

export default TutorialLine
