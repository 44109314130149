import { Pet, PetEntity, PetState } from 'apiLegacy'

import { RootState } from 'store/reducers'
import { isEmptyObject } from 'utils'

export const getBreeds = (type: string) => (state: RootState) => state.pets.breeds[type] || []
export const getAllBreeds = (state: RootState) => state.pets.breeds || undefined
export const getPets = (state: RootState) => Object.values(state.pets.pets?.entities || {})
export const getPet = (petId: string) => (state: RootState) =>
  (state.pets.pets?.entities[petId] || null) as PetEntity

export const getUserPets = (state: RootState): Pet[] => {
  if (isEmptyObject(state.groups.groups.lists)) {
    return []
  }

  return state.groups.groups.entities[state.groups.groups.lists.homeGroupId].pets
    .map((petId) => state.pets.pets.entities[petId])
    .filter(Boolean)
}

export const getAvailablePets = (state: RootState): Pet[] => {
  if (isEmptyObject(state.groups.groups.lists)) {
    return []
  }

  const myGroups = [
    state.groups.groups.lists.homeGroupId,
    ...state.groups.groups.lists.joinedGroupIds,
  ]
  // @ts-ignore
  // TODO: update target libs
  let petsIds = myGroups.map((groupId) => state.groups.groups.entities[groupId].pets).flat()

  petsIds = petsIds.map((id) => state.pets.pets.entities[id]).filter(Boolean)
  return petsIds.filter((pet) => pet.state === PetState.Active || pet.state === PetState.Archived)
}

export const getIsPetsFetched = (state: RootState) => state.pets.pets.entitiesFetched as boolean
export const getActivePets = (state: RootState): Pet[] => {
  const pets = getAvailablePets(state)
  // @ts-ignore
  return pets.filter((pet) => pet.state === PetState.Active)
}
