import { css } from 'styled-components'

export const inputCss = css`
  cursor: 'pointer';
  position: relative;
  width: 100%;
  height: auto;
  min-height: 42px;
  padding: 8px 14px;
  padding-right: 4px;

  border: 1px solid ${(props) => props.theme.colors.lighterGrey}55;
  border-radius: ${(props) => props.theme?.input?.borderRadius || 10}px;
  box-shadow: ${(props) => props.theme?.input?.innerShadow};

  background-color: ${(props) => props.theme?.colors?.lightBackground || 'white'};
  white-space: pre;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }
`

export const inputDisabledCss = css`
  background: ${(props) => props.theme?.colors?.text}11;
  pointer-events: none !important;
`

export const dividerCss = css`
  width: 90%;
  height: 1px;
  background: ${(props) => props.theme.colors.text}66;
  opacity: 0.5;
  margin: 10px 0;
  align-self: center;
`

export const submitButtonBoxCss = css`
  --size: 74px;
  position: absolute;
  height: var(--size);
  width: var(--size);
  min-height: var(--size);
  min-width: var(--size);
  border-radius: 100%;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const submitButtonCss = css`
  cursor: pointer;
  outline: none;
  border-radius: 14px;
  border: 2px solid ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 8px #00000033;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;

  padding: 6px 10px;

  & > svg {
    width: 26px;
    fill: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.white};
  }
`

export const submitButtonCircularCss = css`
  ${submitButtonCss}
  box-shadow: 0px 0px 16px #00000044;
  width: 88%;
  height: 88%;
  border-radius: 100%;
  padding: 4px;

  & > svg {
    width: 35%;
    height: 35%;
    fill: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.white};
  }
`

export const actionButtonCss = css`
  outline: none;
  border: none;
  cursor: pointer;

  margin: auto;
  min-width: 100%;
  height: 44px;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  font-weight: 700;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  svg {
    width: 22px;
  }

  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 13px;
  }
`

export const secondaryButtonCss = css`
  outline: none;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border-radius: 20px;
  padding: 8px 22px;
  background: ${(props) => props.theme.colors.black}0E;
  color: ${(props) => props.theme.colors.text};
  font-size: 16px;

  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 13px;
  }

  & > svg {
    height: 13px;
    fill: ${(props) => props.theme.colors.black};
  }
`

export const linkButtonCss = css`
  margin: 0;
  outline: none;
  background: ${(props) => props.theme.colors.link};
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.link};
  border-radius: 10px;
  padding: 4px 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-weight: 600;
  font-size: 15px;

  ${(props) => props.theme.breakpoints.XXS} {
    font-size: 13px;
  }

  svg {
    width: 14px;
    color: ${(props) => props.theme.colors.white};
    fill: ${(props) => props?.theme?.colors?.white};
    margin-top: 2px;
  }
`

export const inputStyledButtonCss = css`
  ${secondaryButtonCss}
  border: 1px solid ${(props) => props.theme.colors.lighterGrey}55;
  border-radius: ${(props) => props.theme?.input?.borderRadius || 10}px;
  box-shadow: ${(props) => props.theme?.input?.innerShadow};

  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.text};
  flex: 1;
  min-width: 160px;
`
