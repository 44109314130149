import { PublicApi, PublicLinkRequest, SharingApi } from 'api/api'

import { LanguageCode } from './../api/api'
import { createApiInstance } from 'utils'

const apiInstance = (): SharingApi => createApiInstance(SharingApi)
const publicApiInstance = (): PublicApi => createApiInstance(PublicApi)

export const generateShareToVetLink = (payload: PublicLinkRequest) => {
  return apiInstance().addPetPagePublicLink(payload)
}

export const fetchSharePet = (
  token: string,
  from: string,
  till: string,
  limit: number,
  locale: LanguageCode
) => {
  return publicApiInstance().getPetPageData(token, from, till, limit, locale)
}

export const findLinks = () => {
  return apiInstance().findLinks()
}

export const deleteLink = (id: string) => {
  return apiInstance().deleteLink(id)
}

export const updateLink = (id: string, payload: PublicLinkRequest) => {
  return apiInstance().updateLink(id, payload)
}
