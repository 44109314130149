import { GetEventResponse, InsertEventBody, UpdateEventBody } from 'apiLegacy'
import { call, put, select } from 'redux-saga/effects'
import {
  getEvent as getEventService,
  getEvents as getEventsService,
  postEvent,
  putEvent,
} from 'services'

import { Event } from 'types'
import Router from 'next/router'
import { addAppNotification } from './appNotifications'
import { getUser } from 'store/selectors'
import { savePets } from './pet'
import { saveUsers } from './users'
import { storeError } from './errors'

/* CONST */
const pageSize = 20

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST'
export const FETCH_EVENTS_SUCCEED = 'FETCH_EVENTS_SUCCEED'
export const FETCH_EVENTS_FAILED = 'FETCH_EVENTS_FAILED'
export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST'
export const FETCH_EVENT_SUCCEED = 'FETCH_EVENT_SUCCEED'
export const FETCH_EVENT_FAILED = 'FETCH_EVENT_FAILED'
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST'
export const UPDATE_EVENT_SUCCEED = 'UPDATE_EVENT_SUCCEED'
export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED'
export const INSERT_EVENT_REQUEST = 'INSERT_EVENT_REQUEST'
export const INSERT_EVENT_SUCCEED = 'INSERT_EVENT_SUCCEED'
export const INSERT_EVENT_FAILED = 'INSERT_EVENT_FAILED'
export const SAVE_EVENTS = 'SAVE_EVENTS'
export const SAVE_EVENT = 'SAVE_EVENT'
export const DIRECTION_FUTURE = 'FUTURE'
export const DIRECTION_PAST = 'PAST'

/* DATA TYPES */
export type EventsArrayType = Event[]

/* STORE */
export type IsNextEventsStoreType = {
  past?: boolean
  future?: boolean
}
export type EventsStoreType = {
  isEventsFetched: boolean
  events: EventsArrayType
  eventDetail: Event
  isNextEvents: IsNextEventsStoreType
}
export const eventsStoreDefault: EventsStoreType = {
  isEventsFetched: false,
  events: [],
  eventDetail: {} as Event,
  isNextEvents: {
    past: undefined,
    future: undefined,
  },
}

/* ACTION */
type DirectionType = typeof DIRECTION_FUTURE | typeof DIRECTION_PAST
type FetchEventsActionPayloadType = {
  id?: string
  petId?: string
  overdueFilter?: boolean
  direction?: DirectionType
  addToExisting?: boolean
  fromId?: string
}
export type FetchEventsActionType = {
  type: typeof FETCH_EVENTS_REQUEST
  payload?: FetchEventsActionPayloadType
}
export function fetchEvents(payload: FetchEventsActionPayloadType): FetchEventsActionType {
  return {
    type: FETCH_EVENTS_REQUEST,
    payload,
  }
}

export type FetchEventsSucceedType = {
  type: typeof FETCH_EVENTS_SUCCEED
}
export function fetchEventsSucceed(): FetchEventsSucceedType {
  return {
    type: FETCH_EVENTS_SUCCEED,
  }
}

export const fetchEventsFailed = (payload) => ({
  type: FETCH_EVENTS_FAILED,
  payload,
})

export type FetchEventActionType = {
  type: typeof FETCH_EVENT_REQUEST
  payload: string
}
export function fetchEvent(id: string): FetchEventActionType {
  return {
    type: FETCH_EVENT_REQUEST,
    payload: id,
  }
}

export const fetchEventSucceed = () => ({
  type: FETCH_EVENT_SUCCEED,
})

export const fetchEventFailed = (payload) => ({
  type: FETCH_EVENT_FAILED,
  payload,
})

export type SaveEventActionType = {
  type: typeof SAVE_EVENT
  // TODO: because we dont have schemes from api spec
  payload: Event
}
export function saveEvent(event: Event = {} as Event): SaveEventActionType {
  return {
    type: SAVE_EVENT,
    payload: event,
  }
}

export type UpdateEventActionType = {
  type: typeof UPDATE_EVENT_REQUEST
  payload: UpdateEventBody
}
export function updateEvent(event: UpdateEventBody): UpdateEventActionType {
  return {
    type: UPDATE_EVENT_REQUEST,
    payload: event,
  }
}

export const updateEventSucceed = () => ({
  type: UPDATE_EVENT_SUCCEED,
})

export const updateEventFailed = (payload) => ({
  type: UPDATE_EVENT_FAILED,
  payload,
})

export type InsertEventActionType = {
  type: typeof INSERT_EVENT_REQUEST
  payload: InsertEventBody
}
export function insertEvent(event: InsertEventBody): InsertEventActionType {
  return {
    type: INSERT_EVENT_REQUEST,
    payload: event,
  }
}

export const insertEventSucceed = () => ({
  type: INSERT_EVENT_SUCCEED,
})

export const insertEventFailed = (payload) => ({
  type: INSERT_EVENT_FAILED,
  payload,
})

export type SaveEventsPayloadType = {
  events: EventsArrayType
  isNextEvents?: IsNextEventsStoreType
  resetStore?: boolean
  direction?: DirectionType
}
export type SaveEventsActionType = {
  type: typeof SAVE_EVENTS
  payload: SaveEventsPayloadType
}
export function saveEvents(
  events: EventsArrayType,
  isNextEvents?: IsNextEventsStoreType,
  resetStore = true,
  direction = undefined
): SaveEventsActionType {
  return {
    type: SAVE_EVENTS,
    payload: { events, isNextEvents, resetStore, direction },
  }
}

/* REDUCER */
export const saveEventReducer = (state, action: SaveEventActionType) => {
  return { ...state, eventDetail: action.payload }
}
export const saveEventsReducer = (state, action: SaveEventsActionType) => {
  const { events, isNextEvents, resetStore, direction } = action.payload
  const futureEvents = direction === DIRECTION_FUTURE ? events : []
  const pastEvents = direction === DIRECTION_PAST ? events : []
  const actualEvents = resetStore ? events : state.events

  const eventsAll: EventsArrayType = [...futureEvents, ...actualEvents, ...pastEvents]
  const eventIds: string[] = []
  const eventsUnique: EventsArrayType = []
  eventsAll.forEach((event) => {
    if (!eventIds.includes(event.id)) {
      eventsUnique.push(event)
      eventIds.push(event.id)
    }
  })

  return {
    ...state,
    isEventsFetched: true,
    events: eventsUnique,
    isNextEvents: { ...state.isNextEvents, ...isNextEvents },
  }
}

/* SAGA */
export function* fetchEventsSaga(action: FetchEventsActionType): Generator {
  const user = yield select(getUser)
  if (!user) {
    return
  }

  // TODO: Default count
  const { petId, overdueFilter, addToExisting = false, direction, fromId } = action?.payload
  const count = direction === DIRECTION_PAST ? -pageSize : pageSize
  try {
    const eventsResponse: any = yield call(
      getEventsService,
      undefined,
      petId,
      overdueFilter,
      count,
      fromId,
      addToExisting
    )
    const data = (yield eventsResponse.data) as GetEventResponse

    const {
      events,
      entities: { users, pets },
    } = data
    const eventsNotEmpty = data.events.length > 0
    const isNextEvents: IsNextEventsStoreType = !addToExisting
      ? { past: true, future: true }
      : direction === DIRECTION_FUTURE
      ? { future: eventsNotEmpty }
      : { past: eventsNotEmpty }
    yield put(savePets({ entities: pets, lists: {} }))
    yield put(saveUsers({ entities: users, lists: {} }))
    yield put(saveEvents(events, isNextEvents, !addToExisting, direction))
    yield put(fetchEventsSucceed())
  } catch (e) {
    yield put(fetchEventsFailed(e))
    yield put(storeError({ error: e, origin: 'fetchEventsSaga' }))
    console.error(e)
  }
}

export function* fetchEventSaga(action: FetchEventActionType): Generator {
  const id = action.payload
  try {
    const eventResponse: any = yield call(getEventService, id)
    const data = (yield eventResponse.data) as GetEventResponse
    const {
      events,
      entities: { users, pets },
    } = data
    yield put(savePets({ entities: pets, lists: {} }))
    yield put(saveUsers({ entities: users, lists: {} }))
    yield put(saveEvent(events[0]))
    yield put(fetchEventSucceed())
  } catch (e) {
    if (e?.response?.data?.error?.status === 403) {
      yield put(
        saveEvent({
          id,
          isEmpty: true,
        } as Event)
      )
      yield put(fetchEventSucceed())
    } else {
      yield put(storeError({ error: e, origin: 'fetchEventSaga' }))
      yield put(fetchEventFailed(e))
    }
    console.error(e)
  }
}

export function* updateEventSaga(action: UpdateEventActionType): Generator {
  const event = action.payload
  try {
    const eventResponse: any = yield call(putEvent, event)
    const data = (yield eventResponse.data) as GetEventResponse
    const {
      events,
      entities: { users, pets },
    } = data
    yield put(savePets({ entities: pets, lists: {} }))
    yield put(saveUsers({ entities: users, lists: {} }))
    yield put(saveEvent(events[0]))
    yield put(updateEventSucceed())
  } catch (e) {
    yield put(storeError({ error: e, origin: 'updateEventSaga' }))
    yield put(updateEventFailed(e))
    console.error(e)
  }
}

export function* insertEventSaga(action: InsertEventActionType): Generator {
  const event = action.payload
  try {
    const eventResponse: any = yield call(postEvent, event)
    const data = (yield eventResponse.data) as GetEventResponse
    const {
      /* events, */
      entities: { users, pets },
    } = data
    yield put(savePets({ entities: pets, lists: {} }))
    yield put(saveUsers({ entities: users, lists: {} }))
    // yield put(saveEvent(events[0]))
    yield put(
      addAppNotification({
        type: 'event',
        title: 'notifications.success.EVENT',
        event: event,
      })
    )

    yield put(insertEventSucceed())
    yield call(Router.push, `/`, undefined, { shallow: true })
  } catch (e) {
    yield put(storeError({ error: e, origin: 'insertEventSaga' }))
    yield put(insertEventFailed(e))
    console.error(e)
  }
}

/* SELECTOR */
export const getIsEventsFetched = (state): boolean => state.events.isEventsFetched
export const getEvents = (state): Event[] => state.events.events
export const getEvent = (state): Event => state.events.eventDetail
export const getIsNextEvents = (state): IsNextEventsStoreType => state.events.isNextEvents
