import Link from 'next/link'
import SvgIcon from 'components/svgIcon'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 80%;
  margin: auto;
  margin-top: 10vh;

  div:first-child {
    width: 60%;
    svg {
      width: 100%;
    }
  }

  div:nth-child(2) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  a {
    flex: 1;
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    padding: 15px 30px;
    cursor: pointer;
    box-shadow: 0px 3px 6px ${(props) => props.theme.colors.black}55;
  }
`

// TODO: define events type - waiting for api definition updates
const NoPetScreen = () => {
  const { t } = useTranslation('common')

  return (
    <Wrapper>
      <div>
        <SvgIcon code="icon-no-pet" />
      </div>
      <div>{t('NO_PET_DESCRIPTION')}</div>
      <Link href="/pet">{t('ADD_PET_BUTTON')}</Link>
    </Wrapper>
  )
}

export default NoPetScreen
