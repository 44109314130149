export const routes = {
  INDEX_PAGE: '/',
  LOGIN_PAGE: '/login',
  REGISTER_PAGE: '/register',
  INTRO_PAGE: '/intro',
  EVENT_PAGE: '/event',
  FORGET_PASSWORD_PAGE: '/password-reset',
  SHARED_PAGE: '/shared/[token]',
}

export const unprotectedRoutes = [
  routes.LOGIN_PAGE,
  routes.REGISTER_PAGE,
  routes.INTRO_PAGE,
  routes.FORGET_PASSWORD_PAGE,
  routes.SHARED_PAGE,
]
export const protectedRoutes = [routes.INDEX_PAGE, routes.EVENT_PAGE]
