import Router from 'next/router'
import { getUserSettings } from 'store/selectors'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const LanguageListener = () => {
  const settingsInStore = useSelector(getUserSettings)

  useEffect(() => {
    const userLanguage = settingsInStore?.language
    const appLanguage = Router?.locale

    // Language from UserSettings is not the same as language in GUI
    if (userLanguage && userLanguage !== appLanguage) {
      // Update GUI language
      Router.replace(Router.asPath, Router.asPath, { locale: userLanguage })
    }
  }, [settingsInStore])

  return <span />
}

export default LanguageListener
