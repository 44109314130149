
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import 'styles/app.css'

import App, { AppInitialProps } from 'next/app'
import { LazyMotion, domAnimation } from 'framer-motion'
import React, { ErrorInfo } from 'react'

import AppBlocker from 'components/AppBlocker'
import AppNotifications from 'components/AppNotifications'
import ErrorPage from './_error'
import GlobalStyles from 'styles/globalStyles'
import Head from 'next/head'
import LanguageListener from 'components/LanguageListener'
import { PersistGate } from 'redux-persist/integration/react'
import PushNotificationListener from 'components/PushNotificationListener'
import { ReactReduxContext } from 'react-redux'
import RouteChangeListener from 'components/RouteChangeListener'
import Routes from 'components/Routes'
import Script from 'next/script'
import { ThemeProvider } from 'styled-components'
import { isServerSide } from 'utils'
import { mainTheme } from 'styles/themes'
import { wrapper } from 'store'

const GOOGLE_MAPS_LIBRARIES: (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[] = ['places']
class WrappedApp extends App<AppInitialProps> {
  state = { pageWidth: 200, error: undefined, errorInfo: undefined }

  public static staticProps = undefined
  public static getStaticProps = undefined

  handleResize = () => {
    const vh = window.innerHeight
    const vw = window.innerWidth

    this.setState({ pageWidth: vw })

    // @ts-ignore
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    // @ts-ignore
    document.documentElement.style.setProperty('--vw', `${vw}px`)
  }

  componentDidMount() {
    window.history.replaceState({ ...window.history.state, redirectHome: true }, null, null)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: error, errorInfo: errorInfo })
  }

  public render() {
    const { Component, pageProps, router } = this.props

    const useBlocker = process.env.NEXT_PUBLIC_USE_BLOCKER === 'true'

    // TODO: find better place to store locale (for use in utils/api.ts)
    if (!isServerSide()) {
      window.locale = router.locale
    }

    return (
      <ReactReduxContext.Consumer>
        {({ store }) => {
          return (
            <PersistGate persistor={(store as any).__persistor}>
              {/* GOOGLE MAPS API (note: language param causes multi-imports on lang change and the map won't work) */}
              <Script
                src={`https://maps.googleapis.com/maps/api/js?key=${
                  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
                }&libraries=${GOOGLE_MAPS_LIBRARIES.join(',')}`}
                strategy="afterInteractive"
                /* onLoad={() => console.info('GOOGLE MAPS LOADED')} */
              />

              <ThemeProvider theme={mainTheme}>
                <GlobalStyles />
                <PushNotificationListener />
                <LanguageListener />
                <RouteChangeListener />
                <AppNotifications />

                <Head>
                  <title>Pet LifeBook</title>
                  <link rel="manifest" href="/manifest.json" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                  />
                </Head>

                {useBlocker ? (
                  <AppBlocker />
                ) : this.state?.error ? (
                  <ErrorPage
                    statusCode={0}
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                  />
                ) : (
                  <LazyMotion features={domAnimation}>
                    <Routes key={router.asPath} router={router}>
                      <Component key={router.route} {...pageProps} />
                    </Routes>
                  </LazyMotion>
                )}
              </ThemeProvider>
            </PersistGate>
          )
        }}
      </ReactReduxContext.Consumer>
    )
  }
}

const __Page_Next_Translate__ = wrapper.withRedux(WrappedApp)


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  