import styled, { css, keyframes } from 'styled-components'

const blinkingEffect = () => {
  return keyframes`
    0% {
      opacity: 0.2
    }
    50% {
      opacity: .75
    }
    100% {
      opacity: 0.2
    }
  `
}

const Wrapper = styled.div<{ hide?: boolean; highlight?: boolean }>`
  transition: opacity 0.1s ease;

  ${(props) =>
    props.hide &&
    css`
      opacity: 0.3;
    `}

  ${(props) =>
    props.highlight &&
    css`
      position: relative;
      &:after {
        content: ' ';
        color: #0000;
        position: absolute;

        --padding: 10px;

        top: calc(-1 * var(--padding));
        left: calc(-1 * var(--padding));
        right: calc(-1 * var(--padding));
        bottom: calc(-1 * var(--padding));

        border-radius: 5px;
        background: ${(props) => props.theme?.colors?.black}44;
        animation: ${blinkingEffect} 2s linear infinite;
      }
    `}
`

const TutorialHighlight = (props: { hide?: boolean; highlight?: boolean; children?: any }) => {
  return (
    <Wrapper hide={props.hide} highlight={props.highlight}>
      {props.children}
    </Wrapper>
  )
}

export default TutorialHighlight
