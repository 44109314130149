import { WidgetExpensesType } from 'types'

export const getExpensesByCurrency = (expenses: WidgetExpensesType) => {
  if (!expenses) {
    return null
  }

  const expensesByCurrency: {
    currency: string
    value: any
  }[] = Object.keys(expenses).map((currency) => {
    return { currency, value: expenses[currency] }
  })

  return expensesByCurrency.filter((v) => v.value > 0)
}
