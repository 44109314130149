import {
  CommonActionType,
  HeaderActionsTypes,
  RESET_STATE,
  SET_SUBHEADER_EVENT_CODE,
  SET_SUBHEADER_GRAPH_CODE,
  SET_SUBHEADER_SLIDER_OFFSET_PX,
  TOGGLE_MENU,
  TOGGLE_SUBHEADER,
} from 'store/actions'

import { GraphCode } from 'api/api'

type InitialStateTypes = {
  subheader: {
    visible: boolean
    sliderOffsetPx: number
    selectedEventCode: string
    selectedGraphCode: GraphCode
  }
  menuVisible: boolean
}

export const initialState: InitialStateTypes = {
  subheader: {
    visible: false,
    sliderOffsetPx: undefined,
    selectedEventCode: undefined,
    selectedGraphCode: undefined,
  },
  menuVisible: false,
}

export default function reducer(
  state = initialState,
  action: HeaderActionsTypes | CommonActionType
): InitialStateTypes {
  switch (action.type) {
    case TOGGLE_SUBHEADER: {
      return { ...state, subheader: { ...state.subheader, visible: action.payload as boolean } }
    }

    case SET_SUBHEADER_SLIDER_OFFSET_PX: {
      return {
        ...state,
        subheader: { ...state.subheader, sliderOffsetPx: action.payload as number },
      }
    }

    case SET_SUBHEADER_EVENT_CODE: {
      return {
        ...state,
        subheader: { ...state.subheader, selectedEventCode: action.payload as string },
      }
    }

    case SET_SUBHEADER_GRAPH_CODE: {
      return {
        ...state,
        subheader: { ...state.subheader, selectedGraphCode: action.payload as GraphCode },
      }
    }

    case RESET_STATE:
      return initialState

    case TOGGLE_MENU:
      return {
        ...state,
        menuVisible: action.payload === undefined ? !state.menuVisible : action.payload,
      }

    default:
      return state
  }
}
