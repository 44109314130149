/* tslint:disable */
/* eslint-disable */
/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddImageBody
 */
export interface AddImageBody {
    /**
     * 
     * @type {string}
     * @memberof AddImageBody
     */
    'imageId': string;
}
/**
 * 
 * @export
 * @interface CreateDocumentBody
 */
export interface CreateDocumentBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDocumentBody
     */
    'imageIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentBody
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DashboardResponse
 */
export interface DashboardResponse {
    /**
     * 
     * @type {DashboardResponseTotal}
     * @memberof DashboardResponse
     */
    'total': DashboardResponseTotal;
    /**
     * 
     * @type {{ [key: string]: Widgets; }}
     * @memberof DashboardResponse
     */
    'byPetId': { [key: string]: Widgets; };
}
/**
 * 
 * @export
 * @interface DashboardResponseTotal
 */
export interface DashboardResponseTotal {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DashboardResponseTotal
     */
    'expenses': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface DateValue
 */
export interface DateValue {
    /**
     * 
     * @type {number}
     * @memberof DateValue
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof DateValue
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface DeleteImageBody
 */
export interface DeleteImageBody {
    /**
     * 
     * @type {string}
     * @memberof DeleteImageBody
     */
    'imageId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GraphCode = {
    Weight: 'weight',
    Height: 'height',
    Expenses: 'expenses',
    Walking: 'walking'
} as const;

export type GraphCode = typeof GraphCode[keyof typeof GraphCode];


/**
 * 
 * @export
 * @interface GraphReponse
 */
export interface GraphReponse {
}
/**
 * 
 * @export
 * @interface GraphSerieValue
 */
export interface GraphSerieValue {
    /**
     * 
     * @type {string}
     * @memberof GraphSerieValue
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof GraphSerieValue
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof GraphSerieValue
     */
    'date': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GraphTimeUnit = {
    Day: 'day',
    Month: 'month'
} as const;

export type GraphTimeUnit = typeof GraphTimeUnit[keyof typeof GraphTimeUnit];


/**
 * 
 * @export
 * @interface Images
 */
export interface Images {
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'original'?: string;
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'custom'?: string;
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'medium'?: string;
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'thumbnail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Images
     */
    'id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LanguageCode = {
    En: 'en',
    Cs: 'cs',
    De: 'de'
} as const;

export type LanguageCode = typeof LanguageCode[keyof typeof LanguageCode];


/**
 * 
 * @export
 * @interface PetDocument
 */
export interface PetDocument {
    /**
     * 
     * @type {string}
     * @memberof PetDocument
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetDocument
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PetDocument
     */
    'petId': string;
    /**
     * 
     * @type {string}
     * @memberof PetDocument
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PetDocument
     */
    'imageIds': Array<string>;
}
/**
 * 
 * @export
 * @interface PetDocumentListItem
 */
export interface PetDocumentListItem {
    /**
     * 
     * @type {string}
     * @memberof PetDocumentListItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PetDocumentListItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PetDocumentListItem
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface PetEntity
 */
export interface PetEntity {
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'state': string;
    /**
     * 
     * @type {Images}
     * @memberof PetEntity
     */
    'images': Images;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'breedId': string;
    /**
     * 
     * @type {Species}
     * @memberof PetEntity
     */
    'species': Species;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PetEntity
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface PublicLinkRequest
 */
export interface PublicLinkRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicLinkRequest
     */
    'documentIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicLinkRequest
     */
    'petIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicLinkRequest
     */
    'eventCodes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PublicLinkRequest
     */
    'title': string;
    /**
     * 
     * @type {PublicLinkRequestTimePeriod}
     * @memberof PublicLinkRequest
     */
    'timePeriod'?: PublicLinkRequestTimePeriod;
}
/**
 * 
 * @export
 * @interface PublicLinkRequestTimePeriod
 */
export interface PublicLinkRequestTimePeriod {
    /**
     * 
     * @type {string}
     * @memberof PublicLinkRequestTimePeriod
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof PublicLinkRequestTimePeriod
     */
    'start': string;
}
/**
 * 
 * @export
 * @interface PublicLinkResponse
 */
export interface PublicLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicLinkResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface SharedPageItem
 */
export interface SharedPageItem {
    /**
     * 
     * @type {Array<string>}
     * @memberof SharedPageItem
     */
    'eventCodes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SharedPageItem
     */
    'documentIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SharedPageItem
     */
    'petIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SharedPageItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SharedPageItem
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof SharedPageItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SharedPageItem
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SharedPageReponse
 */
export interface SharedPageReponse {
    /**
     * 
     * @type {SharedPageReponseEntities}
     * @memberof SharedPageReponse
     */
    'entities': SharedPageReponseEntities;
    /**
     * 
     * @type {SharedPageReponseLists}
     * @memberof SharedPageReponse
     */
    'lists': SharedPageReponseLists;
}
/**
 * 
 * @export
 * @interface SharedPageReponseEntities
 */
export interface SharedPageReponseEntities {
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof SharedPageReponseEntities
     */
    'documents': object;
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof SharedPageReponseEntities
     */
    'events': object;
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof SharedPageReponseEntities
     */
    'pets': object;
}
/**
 * 
 * @export
 * @interface SharedPageReponseLists
 */
export interface SharedPageReponseLists {
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof SharedPageReponseLists
     */
    'documentsIdsByPetId': object;
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof SharedPageReponseLists
     */
    'eventsIdsByPetId': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof SharedPageReponseLists
     */
    'petIds': Array<string>;
}
/**
 * 
 * @export
 * @interface SharedPagesReponse
 */
export interface SharedPagesReponse {
    /**
     * 
     * @type {SharedPagesReponseEntities}
     * @memberof SharedPagesReponse
     */
    'entities': SharedPagesReponseEntities;
    /**
     * 
     * @type {SharedPagesReponseLists}
     * @memberof SharedPagesReponse
     */
    'lists': SharedPagesReponseLists;
}
/**
 * 
 * @export
 * @interface SharedPagesReponseEntities
 */
export interface SharedPagesReponseEntities {
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof SharedPagesReponseEntities
     */
    'documents': object;
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof SharedPagesReponseEntities
     */
    'pets': object;
}
/**
 * 
 * @export
 * @interface SharedPagesReponseLists
 */
export interface SharedPagesReponseLists {
    /**
     * 
     * @type {Array<SharedPageItem>}
     * @memberof SharedPagesReponseLists
     */
    'pages': Array<SharedPageItem>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Species = {
    Dog: 'dog',
    Cat: 'cat'
} as const;

export type Species = typeof Species[keyof typeof Species];


/**
 * 
 * @export
 * @interface UpdateDocumentBody
 */
export interface UpdateDocumentBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDocumentBody
     */
    'deleteImageIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDocumentBody
     */
    'addImageIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentBody
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentBody
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Vet
 */
export interface Vet {
    /**
     * 
     * @type {string}
     * @memberof Vet
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Vet
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Vet
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface VetInfo
 */
export interface VetInfo {
    /**
     * 
     * @type {string}
     * @memberof VetInfo
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof VetInfo
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface Widgets
 */
export interface Widgets {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Widgets
     */
    'walking': { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Widgets
     */
    'expenses': { [key: string]: number; };
    /**
     * 
     * @type {WidgetsHeight}
     * @memberof Widgets
     */
    'height': WidgetsHeight | null;
    /**
     * 
     * @type {WidgetsHeight}
     * @memberof Widgets
     */
    'weight': WidgetsHeight | null;
}
/**
 * 
 * @export
 * @interface WidgetsHeight
 */
export interface WidgetsHeight {
    /**
     * 
     * @type {string}
     * @memberof WidgetsHeight
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof WidgetsHeight
     */
    'value': number;
}

/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} year 
         * @param {number} [month] 1-based index of mont
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (year: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('get', 'year', year)
            const localVarPath = `/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GraphCode} code 
         * @param {string} petId 
         * @param {GraphTimeUnit} timeUnit 
         * @param {number} year 
         * @param {number} [month] 1-based index of mont
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphSeriesForPet: async (code: GraphCode, petId: string, timeUnit: GraphTimeUnit, year: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getGraphSeriesForPet', 'code', code)
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('getGraphSeriesForPet', 'petId', petId)
            // verify required parameter 'timeUnit' is not null or undefined
            assertParamExists('getGraphSeriesForPet', 'timeUnit', timeUnit)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getGraphSeriesForPet', 'year', year)
            const localVarPath = `/dashboard/graph/{code}/pet/{petId}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)))
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timeUnit !== undefined) {
                localVarQueryParameter['timeUnit'] = timeUnit;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} year 
         * @param {number} [month] 1-based index of mont
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(year: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GraphCode} code 
         * @param {string} petId 
         * @param {GraphTimeUnit} timeUnit 
         * @param {number} year 
         * @param {number} [month] 1-based index of mont
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGraphSeriesForPet(code: GraphCode, petId: string, timeUnit: GraphTimeUnit, year: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphReponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGraphSeriesForPet(code, petId, timeUnit, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {number} year 
         * @param {number} [month] 1-based index of mont
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(year: number, month?: number, options?: any): AxiosPromise<DashboardResponse> {
            return localVarFp.get(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GraphCode} code 
         * @param {string} petId 
         * @param {GraphTimeUnit} timeUnit 
         * @param {number} year 
         * @param {number} [month] 1-based index of mont
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphSeriesForPet(code: GraphCode, petId: string, timeUnit: GraphTimeUnit, year: number, month?: number, options?: any): AxiosPromise<GraphReponse> {
            return localVarFp.getGraphSeriesForPet(code, petId, timeUnit, year, month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {number} year 
     * @param {number} [month] 1-based index of mont
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public get(year: number, month?: number, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).get(year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GraphCode} code 
     * @param {string} petId 
     * @param {GraphTimeUnit} timeUnit 
     * @param {number} year 
     * @param {number} [month] 1-based index of mont
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getGraphSeriesForPet(code: GraphCode, petId: string, timeUnit: GraphTimeUnit, year: number, month?: number, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getGraphSeriesForPet(code, petId, timeUnit, year, month, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new document for pet with images
         * @param {string} petId 
         * @param {CreateDocumentBody} createDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocument: async (petId: string, createDocumentBody: CreateDocumentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('addDocument', 'petId', petId)
            // verify required parameter 'createDocumentBody' is not null or undefined
            assertParamExists('addDocument', 'createDocumentBody', createDocumentBody)
            const localVarPath = `/document/pet/{petId}`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AddImageBody} addImageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentImage: async (id: string, addImageBody: AddImageBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addDocumentImage', 'id', id)
            // verify required parameter 'addImageBody' is not null or undefined
            assertParamExists('addDocumentImage', 'addImageBody', addImageBody)
            const localVarPath = `/document/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addImageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocument', 'id', id)
            const localVarPath = `/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteImageBody} deleteImageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentImage: async (id: string, deleteImageBody: DeleteImageBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocumentImage', 'id', id)
            // verify required parameter 'deleteImageBody' is not null or undefined
            assertParamExists('deleteDocumentImage', 'deleteImageBody', deleteImageBody)
            const localVarPath = `/document/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteImageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocument', 'id', id)
            const localVarPath = `/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPetDocuments: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('getPetDocuments', 'petId', petId)
            const localVarPath = `/document/pet/{petId}/list`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates existing document root properties. For add or delete image use extra endpoints
         * @param {string} id 
         * @param {UpdateDocumentBody} updateDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (id: string, updateDocumentBody: UpdateDocumentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDocument', 'id', id)
            // verify required parameter 'updateDocumentBody' is not null or undefined
            assertParamExists('updateDocument', 'updateDocumentBody', updateDocumentBody)
            const localVarPath = `/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new document for pet with images
         * @param {string} petId 
         * @param {CreateDocumentBody} createDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocument(petId: string, createDocumentBody: CreateDocumentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PetDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDocument(petId, createDocumentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AddImageBody} addImageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocumentImage(id: string, addImageBody: AddImageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDocumentImage(id, addImageBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteImageBody} deleteImageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentImage(id: string, deleteImageBody: DeleteImageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentImage(id, deleteImageBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PetDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPetDocuments(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetDocumentListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPetDocuments(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates existing document root properties. For add or delete image use extra endpoints
         * @param {string} id 
         * @param {UpdateDocumentBody} updateDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(id: string, updateDocumentBody: UpdateDocumentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PetDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(id, updateDocumentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * Creates new document for pet with images
         * @param {string} petId 
         * @param {CreateDocumentBody} createDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocument(petId: string, createDocumentBody: CreateDocumentBody, options?: any): AxiosPromise<PetDocument> {
            return localVarFp.addDocument(petId, createDocumentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AddImageBody} addImageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentImage(id: string, addImageBody: AddImageBody, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.addDocumentImage(id, addImageBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteImageBody} deleteImageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentImage(id: string, deleteImageBody: DeleteImageBody, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.deleteDocumentImage(id, deleteImageBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(id: string, options?: any): AxiosPromise<PetDocument> {
            return localVarFp.getDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPetDocuments(petId: string, options?: any): AxiosPromise<Array<PetDocumentListItem>> {
            return localVarFp.getPetDocuments(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates existing document root properties. For add or delete image use extra endpoints
         * @param {string} id 
         * @param {UpdateDocumentBody} updateDocumentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(id: string, updateDocumentBody: UpdateDocumentBody, options?: any): AxiosPromise<PetDocument> {
            return localVarFp.updateDocument(id, updateDocumentBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * Creates new document for pet with images
     * @param {string} petId 
     * @param {CreateDocumentBody} createDocumentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public addDocument(petId: string, createDocumentBody: CreateDocumentBody, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).addDocument(petId, createDocumentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AddImageBody} addImageBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public addDocumentImage(id: string, addImageBody: AddImageBody, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).addDocumentImage(id, addImageBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocument(id: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).deleteDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {DeleteImageBody} deleteImageBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocumentImage(id: string, deleteImageBody: DeleteImageBody, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).deleteDocumentImage(id, deleteImageBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocument(id: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} petId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getPetDocuments(petId: string, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getPetDocuments(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates existing document root properties. For add or delete image use extra endpoints
     * @param {string} id 
     * @param {UpdateDocumentBody} updateDocumentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public updateDocument(id: string, updateDocumentBody: UpdateDocumentBody, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).updateDocument(id, updateDocumentBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PetApi - axios parameter creator
 * @export
 */
export const PetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('archivePet', 'petId', petId)
            const localVarPath = `/pet/{petId}/archive`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('deletePet', 'petId', petId)
            const localVarPath = `/pet/{petId}/delete`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disablePet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('disablePet', 'petId', petId)
            const localVarPath = `/pet/{petId}/disable`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enablePet: async (petId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('enablePet', 'petId', petId)
            const localVarPath = `/pet/{petId}/enable`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PetApi - functional programming interface
 * @export
 */
export const PetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archivePet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archivePet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disablePet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disablePet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enablePet(petId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PetEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enablePet(petId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PetApi - factory interface
 * @export
 */
export const PetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PetApiFp(configuration)
    return {
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePet(petId: string, options?: any): AxiosPromise<Array<PetEntity>> {
            return localVarFp.archivePet(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePet(petId: string, options?: any): AxiosPromise<Array<PetEntity>> {
            return localVarFp.deletePet(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disablePet(petId: string, options?: any): AxiosPromise<Array<PetEntity>> {
            return localVarFp.disablePet(petId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} petId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enablePet(petId: string, options?: any): AxiosPromise<Array<PetEntity>> {
            return localVarFp.enablePet(petId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PetApi - object-oriented interface
 * @export
 * @class PetApi
 * @extends {BaseAPI}
 */
export class PetApi extends BaseAPI {
    /**
     * 
     * @param {string} petId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public archivePet(petId: string, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).archivePet(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} petId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public deletePet(petId: string, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).deletePet(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} petId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public disablePet(petId: string, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).disablePet(petId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} petId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public enablePet(petId: string, options?: AxiosRequestConfig) {
        return PetApiFp(this.configuration).enablePet(petId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} from 
         * @param {string} till 
         * @param {number} limit 
         * @param {LanguageCode} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPetPageData: async (token: string, from: string, till: string, limit: number, locale?: LanguageCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getPetPageData', 'token', token)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getPetPageData', 'from', from)
            // verify required parameter 'till' is not null or undefined
            assertParamExists('getPetPageData', 'till', till)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getPetPageData', 'limit', limit)
            const localVarPath = `/public/page/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (till !== undefined) {
                localVarQueryParameter['till'] = (till as any instanceof Date) ?
                    (till as any).toISOString() :
                    till;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} from 
         * @param {string} till 
         * @param {number} limit 
         * @param {LanguageCode} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPetPageData(token: string, from: string, till: string, limit: number, locale?: LanguageCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedPageReponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPetPageData(token, from, till, limit, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} from 
         * @param {string} till 
         * @param {number} limit 
         * @param {LanguageCode} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPetPageData(token: string, from: string, till: string, limit: number, locale?: LanguageCode, options?: any): AxiosPromise<SharedPageReponse> {
            return localVarFp.getPetPageData(token, from, till, limit, locale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {string} from 
     * @param {string} till 
     * @param {number} limit 
     * @param {LanguageCode} [locale] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPetPageData(token: string, from: string, till: string, limit: number, locale?: LanguageCode, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPetPageData(token, from, till, limit, locale, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SharingApi - axios parameter creator
 * @export
 */
export const SharingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * * Creates new link to public page with pet information
         * @param {PublicLinkRequest} publicLinkRequest Specify which time period and entities to display on the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPetPagePublicLink: async (publicLinkRequest: PublicLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicLinkRequest' is not null or undefined
            assertParamExists('addPetPagePublicLink', 'publicLinkRequest', publicLinkRequest)
            const localVarPath = `/sharing/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLink', 'id', id)
            const localVarPath = `/sharing/link/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sharing/link/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PublicLinkRequest} publicLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink: async (id: string, publicLinkRequest: PublicLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLink', 'id', id)
            // verify required parameter 'publicLinkRequest' is not null or undefined
            assertParamExists('updateLink', 'publicLinkRequest', publicLinkRequest)
            const localVarPath = `/sharing/link/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharingApi - functional programming interface
 * @export
 */
export const SharingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharingApiAxiosParamCreator(configuration)
    return {
        /**
         * * Creates new link to public page with pet information
         * @param {PublicLinkRequest} publicLinkRequest Specify which time period and entities to display on the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPetPagePublicLink(publicLinkRequest: PublicLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPetPagePublicLink(publicLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLink(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLink(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLinks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedPagesReponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLinks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PublicLinkRequest} publicLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLink(id: string, publicLinkRequest: PublicLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLink(id, publicLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SharingApi - factory interface
 * @export
 */
export const SharingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharingApiFp(configuration)
    return {
        /**
         * * Creates new link to public page with pet information
         * @param {PublicLinkRequest} publicLinkRequest Specify which time period and entities to display on the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPetPagePublicLink(publicLinkRequest: PublicLinkRequest, options?: any): AxiosPromise<PublicLinkResponse> {
            return localVarFp.addPetPagePublicLink(publicLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLinks(options?: any): AxiosPromise<SharedPagesReponse> {
            return localVarFp.findLinks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PublicLinkRequest} publicLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink(id: string, publicLinkRequest: PublicLinkRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateLink(id, publicLinkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharingApi - object-oriented interface
 * @export
 * @class SharingApi
 * @extends {BaseAPI}
 */
export class SharingApi extends BaseAPI {
    /**
     * * Creates new link to public page with pet information
     * @param {PublicLinkRequest} publicLinkRequest Specify which time period and entities to display on the page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public addPetPagePublicLink(publicLinkRequest: PublicLinkRequest, options?: AxiosRequestConfig) {
        return SharingApiFp(this.configuration).addPetPagePublicLink(publicLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public deleteLink(id: string, options?: AxiosRequestConfig) {
        return SharingApiFp(this.configuration).deleteLink(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public findLinks(options?: AxiosRequestConfig) {
        return SharingApiFp(this.configuration).findLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PublicLinkRequest} publicLinkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public updateLink(id: string, publicLinkRequest: PublicLinkRequest, options?: AxiosRequestConfig) {
        return SharingApiFp(this.configuration).updateLink(id, publicLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VetApi - axios parameter creator
 * @export
 */
export const VetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPinnedVets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vet/pinned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {VetInfo} vetInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinVet: async (id: string, vetInfo: VetInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pinVet', 'id', id)
            // verify required parameter 'vetInfo' is not null or undefined
            assertParamExists('pinVet', 'vetInfo', vetInfo)
            const localVarPath = `/vet/pinned/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vetInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinVet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unpinVet', 'id', id)
            const localVarPath = `/vet/pinned/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VetApi - functional programming interface
 * @export
 */
export const VetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPinnedVets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPinnedVets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {VetInfo} vetInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinVet(id: string, vetInfo: VetInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinVet(id, vetInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpinVet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpinVet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VetApi - factory interface
 * @export
 */
export const VetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VetApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPinnedVets(options?: any): AxiosPromise<Array<Vet>> {
            return localVarFp.findPinnedVets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {VetInfo} vetInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinVet(id: string, vetInfo: VetInfo, options?: any): AxiosPromise<Array<Vet>> {
            return localVarFp.pinVet(id, vetInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinVet(id: string, options?: any): AxiosPromise<Array<Vet>> {
            return localVarFp.unpinVet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VetApi - object-oriented interface
 * @export
 * @class VetApi
 * @extends {BaseAPI}
 */
export class VetApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VetApi
     */
    public findPinnedVets(options?: AxiosRequestConfig) {
        return VetApiFp(this.configuration).findPinnedVets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {VetInfo} vetInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VetApi
     */
    public pinVet(id: string, vetInfo: VetInfo, options?: AxiosRequestConfig) {
        return VetApiFp(this.configuration).pinVet(id, vetInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VetApi
     */
    public unpinVet(id: string, options?: AxiosRequestConfig) {
        return VetApiFp(this.configuration).unpinVet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


