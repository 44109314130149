import {
  CHANGE_PASSWORD_REQUEST,
  CONFIRM_RESET_PASSWORD_REQUEST,
  GET_USER_DATA_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_USER_SETTINGS_REQUEST,
  USER_LOGIN_REQUEST,
  USER_REGISTER_REQUEST,
  USER_UPDATE_REQUEST,
  changePasswordFailed,
  changePasswordSucceed,
  confirmResetPasswordFailed,
  confirmResetPasswordSucceed,
  getUserDataFailed,
  getUserDataSucceed,
  invalidateAnonymousFirebaseToken,
  loginUserFailed,
  loginUserSucceed,
  registerFirebaseToken,
  registerUserFailed,
  registerUserSucceed,
  resetPasswordFailed,
  resetPasswordSucceed,
  setUser,
  updateUserFailed,
  updateUserSettingsFailed,
  updateUserSettingsSucceed,
  updateUserSucceed,
} from 'store/actions'
import { call, put, select, takeLeading } from 'redux-saga/effects'
import {
  changePassword as changePasswordService,
  confirmResetPassword as confirmResetPasswordService,
  getUserData,
  loginUser as loginUserService,
  registerUser as registerUserService,
  resetPassword as resetPasswordService,
  setAuthToken,
  updateUser as updateUserService,
  updateUserSettings as updateUserSettingsService,
} from 'services'

import Router from 'next/router'
import { UserSettingsUpdate } from 'apiLegacy'
import { addAppNotification } from 'entity/appNotifications'
import { fetchGroups } from 'entity/group'
import { fetchLocalizedData } from './appSaga'
import { getFirebaseToken } from 'entity/pushNotifications'
import { setTutorialsAllowedByUser } from 'entity/tutorials'
import { storeError } from 'entity/errors'

function* registerUser(action): Generator {
  const { name, email, password, language } = action.payload
  try {
    const userResponse: ReturnType<any> = yield call(registerUserService, { name, email, password })
    const user: ReturnType<any> = yield userResponse?.data
    yield put(
      addAppNotification({
        type: 'text',
        text: 'notifications.success.REGISTER',
        actionType: 'success',
      })
    )
    yield put(registerUserSucceed(user))

    // auto-login user after registration - maybe disable depends-on ...
    yield put(setUser(user))
    yield call(setAuthToken, user)

    const firebaseToken = yield select(getFirebaseToken)
    if (firebaseToken && typeof firebaseToken === 'string') {
      yield put(invalidateAnonymousFirebaseToken(firebaseToken))
      yield put(registerFirebaseToken(firebaseToken))
    }

    // init user settings (BEFORE FETCHING USER INFO!)
    yield call(updateUserSettings, {
      payload: {
        language: language,
      } as UserSettingsUpdate,
    })

    yield call(getUserInfo)
    yield call(Router.push, { pathname: '/pet', query: { fromRegistration: true } }, '/pet')
  } catch (e) {
    const errorCode = yield e.response.status
    if (errorCode === 409) {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.REGISTER_USER_EXISTS',
          actionType: 'warning',
        })
      )
    } else {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.REGISTER',
          actionType: 'error',
        })
      )
    }
    yield put(registerUserFailed())
  }
}

function* loginUser(action): Generator {
  const { email, password } = action.payload
  try {
    const loggedUser: ReturnType<any> = yield call(loginUserService, { email, password })
    const user: ReturnType<any> = yield loggedUser.data
    yield call(setAuthToken, user)

    const firebaseToken = yield select(getFirebaseToken)
    if (firebaseToken && typeof firebaseToken === 'string') {
      yield put(invalidateAnonymousFirebaseToken(firebaseToken))
      yield put(registerFirebaseToken(firebaseToken))
    }

    yield put(setUser(user))
    yield call(getUserInfo)
    yield call(Router.push, '/')
    yield put(loginUserSucceed(user))
  } catch (e) {
    const errorCode = yield e.response.status
    if (errorCode === 401) {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.LOGIN',
          actionType: 'error',
        })
      )
      yield put(loginUserFailed())
    } else {
      yield put(storeError({ error: e, origin: 'loginUser' }))
    }
    console.error(e)
  }
}

function* getUserInfo(): Generator {
  try {
    const userDataResponse: ReturnType<any> = yield call(getUserData)
    const userData = yield userDataResponse.data
    yield put(getUserDataSucceed(userData))
  } catch (e) {
    yield put(storeError({ error: e, origin: 'getUserInfo' }))
    yield put(getUserDataFailed(e))
    console.error(e)
  }
}

function* updateUser(action): Generator {
  try {
    const updateUserResponse: ReturnType<any> = yield call(updateUserService, action.payload)
    const updatedUser = yield updateUserResponse.data
    yield put(updateUserSucceed(updatedUser))
    yield call(Router.push, '/')
    yield put(
      addAppNotification({
        type: 'text',
        text: 'notifications.success.UPDATE_USER',
        actionType: 'success',
      })
    )
    yield put(fetchGroups())
  } catch (e) {
    yield put(storeError({ error: e, origin: 'updateUser' }))
    yield put(updateUserFailed())
    console.error(e)
  }
}

function* changePassword(action): Generator {
  try {
    const loggedUser: ReturnType<any> = yield call(changePasswordService, action.payload)
    const user: ReturnType<any> = yield loggedUser.data
    yield put(setUser(user))
    yield call(setAuthToken, user)
    yield put(changePasswordSucceed())
    yield put(
      addAppNotification({
        type: 'text',
        text: 'notifications.success.CHANGE_PASSWORD',
        actionType: 'success',
      })
    )
  } catch (e) {
    yield put(
      addAppNotification({
        type: 'text',
        text: 'notifications.error.CHANGE_PASSWORD',
        actionType: 'warning',
      })
    )
    yield put(changePasswordFailed())
    console.error(e)
  }
}

function* updateUserSettings(action): Generator {
  try {
    const response: ReturnType<any> = yield call(updateUserSettingsService, action.payload)
    const data = yield response?.data
    if (data) {
      yield put(updateUserSettingsSucceed(data))
      yield put(setTutorialsAllowedByUser((data as UserSettingsUpdate).showHelp))

      // Refetch localized data
      if ((action.payload as UserSettingsUpdate)?.language) {
        const language = (action.payload as UserSettingsUpdate)?.language
        yield fetchLocalizedData(language)
      }
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'updateUserSettings' }))
    yield put(updateUserSettingsFailed(e))
    console.error(e)
  }
}

function* resetPassword(action): Generator {
  try {
    const response: ReturnType<any> = yield call(resetPasswordService, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(resetPasswordSucceed())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.RESET_PASSWORD',
          actionType: 'success',
        })
      )
      yield call(Router.push, '/login')
    }
  } catch (e) {
    if (e.response.status >= 400 && e.response.status < 500) {
      const { email } = action.payload
      yield put(resetPasswordFailed())
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.RESET_PASSWORD_USER_NOT_FOUND',
          button: {
            text: 'notifications.error.RESET_PASSWORD_USER_NOT_FOUND_BUTTON',
            onClick: () =>
              Router.push({
                pathname: '/register',
                query: { email, fromPasswordReset: true },
              }),
          },
          actionType: 'warning',
        })
      )
    } else {
      yield put(storeError({ error: e, origin: 'resetPassword' }))
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.RESET_PASSWORD',
          actionType: 'error',
        })
      )
      yield put(resetPasswordFailed())
    }
  }
}

function* confirmResetPassword(action): Generator {
  try {
    const response: ReturnType<any> = yield call(confirmResetPasswordService, action.payload)
    if (response.status >= 200 && response.status < 300) {
      yield put(confirmResetPasswordSucceed())
      yield call(Router.push, '/login')
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.success.RESET_PASSWORD_SUCCESS',
          actionType: 'success',
        })
      )
    } else {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.RESET_PASSWORD',
          actionType: 'error',
        })
      )
    }
  } catch (e) {
    const errorData = e?.response?.data?.error

    if (errorData?.code === 'RESET_LINK_EXPIRED') {
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.RESET_PASSWORD_EXPIRED',
          actionType: 'error',
        })
      )
    } else {
      yield put(storeError({ error: e, origin: 'confirmResetPassword' }))
      yield put(
        addAppNotification({
          type: 'text',
          text: 'notifications.error.RESET_PASSWORD',
          actionType: 'error',
        })
      )
    }

    yield put(confirmResetPasswordFailed())
  }
}

function* userSaga() {
  yield takeLeading(USER_REGISTER_REQUEST, registerUser)
  yield takeLeading(USER_LOGIN_REQUEST, loginUser)
  yield takeLeading(GET_USER_DATA_REQUEST, getUserInfo)
  yield takeLeading(USER_UPDATE_REQUEST, updateUser)
  yield takeLeading(CHANGE_PASSWORD_REQUEST, changePassword)
  yield takeLeading(UPDATE_USER_SETTINGS_REQUEST, updateUserSettings)
  yield takeLeading(RESET_PASSWORD_REQUEST, resetPassword)
  yield takeLeading(CONFIRM_RESET_PASSWORD_REQUEST, confirmResetPassword)
}

export default userSaga
