import { GraphCode } from 'api/api'
import { RootState } from 'store/reducers'

export const isSubHeaderVisible = (state: RootState) => state.header.subheader.visible

export const getSubHeaderSliderOffsetPx = (state: RootState) =>
  state.header.subheader.sliderOffsetPx

export const getSubHeaderSelectedEventCode = (state: RootState) =>
  state.header.subheader.selectedEventCode

export const getSubHeaderSelectedGraphCode = (state: RootState) =>
  state.header.subheader.selectedGraphCode as GraphCode

export const isMenuVisible = (state: RootState) => state.header.menuVisible
