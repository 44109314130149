import { getNearestMultiple, getXPositionFromEvent } from 'utils'
import styled, { css } from 'styled-components'
import { useEffect, useRef, useState } from 'react'

import ArrowIcon from 'components/svg/arrow.svg'
import TutorialHighlight from 'components/Tutorials/TutorialHighlight'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`
export const Item = styled.div`
  width: 100%;
  display: inline-flex;
  flex: 1 0 auto;
  place-items: center;
  cursor: pointer;
  overflow: hidden;
`
const Arrow = styled.div`
  flex: 1;
  height: 100%;
  cursor: pointer;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 100;
  position: relative;

  svg {
    fill: ${(props) => props.theme.colors.text};
    color: ${(props) => props.theme.colors.text};
    height: 15px;
  }
`

const LeftArrow = styled(Arrow)`
  transform: rotate(180deg);
`
const RightArrow = styled(Arrow)``

const Content = styled.div<{ showTutorialArrows?: boolean }>`
  width: calc(100% - 80px);
  display: flex;
  transition: linear;

  transition: opacity 0.1s ease;
  ${(props) =>
    props.showTutorialArrows &&
    css`
      opacity: 0.3;
    `}
`

const Slider = ({
  children,
  itemsCount,
  onChange,
  activeItemIndex = 0,
  isLocked = false,
  showTutorialArrows = false,
  showTutorialContent = false,
  externalSliderOffsetPx = undefined,
  onSliderOffsetPxChange = undefined,
}) => {
  const [sliderOffsetIndex, setSliderOffsetIndex] = useState(activeItemIndex)
  const [internalSliderOffsetPx, setInternalSliderOffsetPx] = useState(externalSliderOffsetPx || 0)
  const [baseTouchPosition, setBaseTouchPosition] = useState(0)
  const [sliderWidth, setSliderWidth] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const sliderRef = useRef(null)

  //
  // SLIDER OFFSET MANAGEMENT (External vs Internal)
  //
  const getSliderOffsetPx = () => {
    if (externalSliderOffsetPx !== undefined) {
      return externalSliderOffsetPx
    }
    return internalSliderOffsetPx
  }

  const setSliderOffsetPx = (val: number) => {
    if (onSliderOffsetPxChange) {
      return onSliderOffsetPxChange(val)
    } else {
      setInternalSliderOffsetPx(val)
    }
  }

  //
  // FUNC
  //
  const handleChangeSlider = (direction: string) => {
    if (sliderRef.current) {
      sliderRef.current.style.transition = '0.25s all ease-in-out'
    }

    let nextIndex
    if (direction === 'right') {
      nextIndex = sliderOffsetIndex < itemsCount ? sliderOffsetIndex + 1 : 0
    } else {
      nextIndex = sliderOffsetIndex === 0 ? itemsCount : sliderOffsetIndex - 1
    }

    setSliderOffsetIndex(nextIndex)
    setSliderOffsetPx(-nextIndex * sliderWidth)
    handleOnChange(nextIndex)
  }

  const handleOnChange = (index) => {
    if (index !== sliderOffsetIndex) onChange(index)
    setSliderOffsetIndex(index)
  }

  const touchStart = (e) => {
    setIsDragging(true)
    setBaseTouchPosition(getXPositionFromEvent(e))
    if (sliderRef.current) {
      sliderRef.current.style.transition = 'none'
      sliderRef.current.style.cursor = 'grabbing'
    }
  }

  const touchEnd = () => {
    setIsDragging(false)
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'pointer'
    }
    const nearestOffset = getNearestMultiple(getSliderOffsetPx(), sliderWidth)
    const newIndex = Math.abs(nearestOffset / sliderWidth)
    if (sliderRef.current) {
      sliderRef.current.style.transition = '0.2s all linear'
    }
    setSliderOffsetPx(nearestOffset)
    handleOnChange(newIndex)
  }

  const dragging = (e) => {
    if (!isDragging) return
    setBaseTouchPosition(getXPositionFromEvent(e))
    const nextMove = getXPositionFromEvent(e) - baseTouchPosition
    if (
      Math.abs(getSliderOffsetPx() + nextMove) <= sliderWidth * itemsCount &&
      getSliderOffsetPx() + nextMove <= 0
    ) {
      setSliderOffsetPx(getSliderOffsetPx() + nextMove)
    }

    if (getSliderOffsetPx() + nextMove > 0) {
      setSliderOffsetPx(0)
    }

    if (Math.abs(getSliderOffsetPx() + nextMove) >= sliderWidth * itemsCount) {
      setSliderOffsetPx(-(sliderWidth * itemsCount))
    }
  }

  const handleResize = () => {
    if (sliderRef?.current) {
      setSliderWidth(sliderRef?.current?.offsetWidth)
    }
  }

  useEffect(() => {
    handleResize()
  }, [sliderRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setSliderOffsetIndex(activeItemIndex)
    setSliderOffsetPx(-activeItemIndex * sliderWidth)
  }, [activeItemIndex, sliderWidth, sliderOffsetIndex])

  //
  // RENDER
  //
  return (
    <Wrapper>
      <LeftArrow onClick={() => handleChangeSlider('left')}>
        <TutorialHighlight hide={showTutorialContent} highlight={showTutorialArrows}>
          {!isLocked && <ArrowIcon />}
        </TutorialHighlight>
      </LeftArrow>

      <Content
        ref={sliderRef}
        onTouchStart={touchStart}
        onTouchMove={dragging}
        onTouchEnd={touchEnd}
        onMouseDown={touchStart}
        onMouseMove={dragging}
        onMouseUp={touchEnd}
        onMouseOver={touchEnd}
        style={{ transform: `translateX(${getSliderOffsetPx()}px)` }}
        showTutorialArrows={showTutorialArrows}
      >
        {children}
      </Content>

      <RightArrow onClick={() => handleChangeSlider('right')}>
        <TutorialHighlight hide={showTutorialContent} highlight={showTutorialArrows}>
          {!isLocked && <ArrowIcon />}
        </TutorialHighlight>
      </RightArrow>
    </Wrapper>
  )
}

export default Slider
