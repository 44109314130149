import { clearLocalStorage } from './localStorage'
import { resetErrors } from 'entity/errors'

export const delay = async (time = 1000) => {
  await new Promise((resolve) => setTimeout(resolve, time))
}

export const generateId = () => {
  return `${new Date().toISOString()}_${Math.random() * 1000000000000}`
}

export const restartApp = (dispatch) => {
  dispatch(resetErrors())
  clearLocalStorage()
  window.location.reload()
}

export const refreshApp = () => {
  window.location.reload()
}
