import { IconName, isExistingIcon } from 'components/svgIcon'

import { Event } from 'types'
import { EventDefinition } from 'apiLegacy'
import { KeyboardEvent } from 'react'

interface keymapItem {
  [key: string]: () => void
}

/**
 * Trigger action on key
 *
 * @param event keyboard event, onKeyDown
 * @param keymap object {<keyboard key>: <function to be called if pressed>}
 */
export const keyAction = (event: KeyboardEvent, keymap: keymapItem) => {
  const func: () => void = keymap[event.key] || keymap[event.key.toLowerCase()]
  if (func) {
    func()
  }
}

export const getXPositionFromEvent = (event) => {
  return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX
}

export const getYPositionFromEvent = (event) => {
  return event.type.includes('mouse') ? event.pageY : event.touches[0].clientY
}

export const getEventName = (event: Event, eventDefinition: EventDefinition) => {
  if (eventDefinition?.code === 'task' && event?.items?.name) {
    return event?.items?.name
  }
  return eventDefinition?.name
}

export const getEventIcon = (event: Event, eventDefinition: EventDefinition) => {
  const customIcon = eventDefinition?.items?.find((item) => item.code === 'icon')
    ? event?.items['icon']
    : null

  if (customIcon && isExistingIcon(customIcon)) {
    return customIcon as IconName
  }

  return eventDefinition?.icon as IconName
}
