import { Dictionary } from 'types'
import i18nConfig from 'i18n.js'

export const allowedTags: Dictionary<Element> = {
  b: <b />,
  i: <i />,
  u: <u />,
  br: <br />,
}

export type LanguageOption = { id: string; label: string }

export const getLanguageOptions = () => {
  const languageOptions: LanguageOption[] = i18nConfig?.locales?.map((id) => {
    return { id, label: `locales.${id}` }
  })
  return languageOptions
}
