export type GeolocationPosition = {
  coords: {
    accuracy: number
    altitude: number
    altitudeAccuracy: number
    heading: number
    latitude: number
    longitude: number
    speed: number
  }
  timestamp: number
}

export const getPosition = (options?: {
  enableHighAccuracy?: boolean
  maximumAge?: number
  timeout?: number
}): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  )
}

export const getDeviceCoordinates = async () => {
  if (navigator?.geolocation) {
    try {
      const position: GeolocationPosition = await getPosition({ enableHighAccuracy: true })
      const deviceCoordinates = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      return deviceCoordinates
    } catch (e) {
      console.warn('Geolocation failed due to: ' + e?.message)
    }
  } else {
    // Browser doesn't support Geolocation
    window.alert('Geolocation is not supported.')
  }
}

export const getCoordinatesByPlaceId = async (placeId: string) => {
  try {
    const geocoder = new google.maps.Geocoder()
    const { results } = await geocoder.geocode({ placeId })
    return results[0].geometry.location
  } catch (e) {
    window.alert('Geocoder failed due to: ' + e)
  }
}
