import {
  CANCEL_INVITE_REQUEST,
  FETCH_GROUPS_REQUEST,
  FetchGroupsPayloadType,
  GROUP_ACCEPT_REQUEST,
  GROUP_DECLINE_REQUEST,
  GROUP_LEAVE_REQUEST,
  GROUP_REMOVE_USER_REQUEST,
  INVITE_USER_REQUEST,
  cancelInviteSaga,
  fetchGroupsFailed,
  fetchGroupsSucceed,
  groupAcceptSaga,
  groupDeclineSaga,
  inviteUserSaga,
  leaveGroupSaga,
  removeUserFromGroupSaga,
} from 'entity/group'
import { all, call, put, takeLeading } from 'redux-saga/effects'

import { addAppNotification } from 'entity/appNotifications'
import { getUserGroups } from 'services'
import { savePets } from 'entity/pet'
import { saveUsers } from 'entity/users'
import { storeError } from 'entity/errors'

function* fetchUserGroups(action): Generator {
  try {
    const payload: FetchGroupsPayloadType = yield action.payload
    const groupsResponse: ReturnType<any> = yield call(getUserGroups)
    const groups: ReturnType<any> = yield groupsResponse.data
    yield all([
      put(saveUsers({ entities: groups.entities.users, lists: {} })),
      put(savePets({ entities: groups.entities.pets, lists: {} })),
      put(
        fetchGroupsSucceed({
          entities: { groups: groups?.entities.groups, invitations: groups?.entities.invitations },
          lists: groups.lists,
        })
      ),
    ])

    if (payload?.successNotification) {
      yield put(
        addAppNotification({
          type: 'text',
          text: payload.successNotification,
          actionType: 'success',
        })
      )
    }
  } catch (e) {
    yield put(storeError({ error: e, origin: 'fetchUserGroups' }))
    console.error(e)
    yield put(fetchGroupsFailed(e))
  }
}

function* userSaga() {
  yield takeLeading(FETCH_GROUPS_REQUEST, fetchUserGroups)
  yield takeLeading(INVITE_USER_REQUEST, inviteUserSaga)
  yield takeLeading(CANCEL_INVITE_REQUEST, cancelInviteSaga)
  yield takeLeading(GROUP_LEAVE_REQUEST, leaveGroupSaga)
  yield takeLeading(GROUP_REMOVE_USER_REQUEST, removeUserFromGroupSaga)
  yield takeLeading(GROUP_ACCEPT_REQUEST, groupAcceptSaga)
  yield takeLeading(GROUP_DECLINE_REQUEST, groupDeclineSaga)
}

export default userSaga
