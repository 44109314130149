export const TOGGLE_SUBHEADER = 'TOGGLE_SUBHEADER'
export const SET_SUBHEADER_SLIDER_OFFSET_PX = 'SET_SUBHEADER_SLIDER_OFFSET_PX'
export const SET_SUBHEADER_EVENT_CODE = 'SET_SUBHEADER_EVENT_CODE'
export const SET_SUBHEADER_GRAPH_CODE = 'SET_SUBHEADER_GRAPH_CODE'
export const TOGGLE_MENU = 'TOGGLE_MENU'

export type ToggleSubHeaderType = {
  type: typeof TOGGLE_SUBHEADER
  payload: boolean
}

export const toggleSubHeader = (visible: boolean = undefined): ToggleSubHeaderType => ({
  type: TOGGLE_SUBHEADER,
  payload: visible,
})

export type SetSubHeaderEventCodeType = {
  type: typeof SET_SUBHEADER_EVENT_CODE
  payload: string
}

export const setSubHeaderSliderOffsetPx = (
  sliderOffsetPx: number = undefined
): SetSubHeaderSliderOffsetPxType => ({
  type: SET_SUBHEADER_SLIDER_OFFSET_PX,
  payload: sliderOffsetPx,
})

export type SetSubHeaderSliderOffsetPxType = {
  type: typeof SET_SUBHEADER_SLIDER_OFFSET_PX
  payload: number
}

export const setSubHeaderEventCode = (
  eventCode: string = undefined
): SetSubHeaderEventCodeType => ({
  type: SET_SUBHEADER_EVENT_CODE,
  payload: eventCode,
})

export type SetSubHeaderGraphCodeType = {
  type: typeof SET_SUBHEADER_GRAPH_CODE
  payload: string
}

export const setSubHeaderGraphCode = (
  graphCode: string = undefined
): SetSubHeaderGraphCodeType => ({
  type: SET_SUBHEADER_GRAPH_CODE,
  payload: graphCode,
})

type ToggleMenuType = {
  type: typeof TOGGLE_MENU
  payload: boolean
}

export const toggleMenu = (open: boolean = undefined): ToggleMenuType => ({
  type: TOGGLE_MENU,
  payload: open,
})

export type HeaderActionsTypes =
  | ToggleSubHeaderType
  | ToggleMenuType
  | SetSubHeaderSliderOffsetPxType
  | SetSubHeaderEventCodeType
  | SetSubHeaderGraphCodeType
