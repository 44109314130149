import {
  ASSIGN_EVENT,
  FETCH_ASSIGNABLE_USERS,
  assignEventSaga,
  fetchAssignableUsersSaga,
} from 'entity/eventAssign'
import {
  FETCH_EVENTS_DEFINITION_REQUEST,
  FETCH_EVENT_LIST_REQUEST,
  fetchEventListsSaga,
  fetchEventsDefinitionSaga,
} from 'entity/eventDefinition'
import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENT_REQUEST,
  INSERT_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST,
  fetchEventSaga,
  fetchEventsSaga,
  insertEventSaga,
  updateEventSaga,
} from 'entity/event'
import { FETCH_FOLLOWUP, loadFollowupSaga } from 'entity/eventFollowup'
import { FULFILL_EVENT, fulFillEventSaga } from 'entity/eventFulfill'
import { LOAD_OVERDUE_COUNT_REQUEST, overdueCountSaga } from 'entity/eventOverdue'
import { takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'

function* eventSaga() {
  yield takeEvery(FETCH_EVENTS_REQUEST, fetchEventsSaga)
  yield takeLatest(FETCH_EVENT_REQUEST, fetchEventSaga)
  yield takeEvery(UPDATE_EVENT_REQUEST, updateEventSaga)
  yield takeLeading(INSERT_EVENT_REQUEST, insertEventSaga)
  yield takeLeading(FETCH_EVENTS_DEFINITION_REQUEST, fetchEventsDefinitionSaga)
  yield takeLeading(FETCH_EVENT_LIST_REQUEST, fetchEventListsSaga)
  yield takeLeading(FULFILL_EVENT, fulFillEventSaga)
  yield takeLeading(FETCH_ASSIGNABLE_USERS, fetchAssignableUsersSaga)
  yield takeLeading(ASSIGN_EVENT, assignEventSaga)
  yield takeLeading(FETCH_FOLLOWUP, loadFollowupSaga)
  yield takeLeading(LOAD_OVERDUE_COUNT_REQUEST, overdueCountSaga)
}

export default eventSaga
