import { EventDefinition, EventList } from 'apiLegacy'
import { call, put } from 'redux-saga/effects'
import { getEventLists, getEventsDefinition } from 'services'

import { array2keyObject } from 'utils'
import { storeError } from './errors'

/* CONST */
export const FETCH_EVENTS_DEFINITION_REQUEST = 'FETCH_EVENTS_DEFINITION_REQUEST'
export const FETCH_EVENTS_DEFINITION_SUCCEED = 'FETCH_EVENTS_DEFINITION_SUCCEED'
export const FETCH_EVENTS_DEFINITION_FAILED = 'FETCH_EVENTS_DEFINITION_FAILED'
export const FETCH_EVENT_LIST_REQUEST = 'FETCH_EVENT_LIST_REQUEST'
export const FETCH_EVENT_LIST_SUCCEED = 'FETCH_EVENT_LIST_SUCCEED'
export const FETCH_EVENT_LIST_FAILED = 'FETCH_EVENT_LIST_FAILED'
export const SAVE_EVENTS_DEFINITION = 'SAVE_EVENTS_DEFINITION'
export const SAVE_EVENT_LISTS = 'SAVE_EVENT_LISTS'

/* DATA TYPES */

/* STORE */
export type EventsDefinitionStoreType = {
  eventsDefinition: EventDefinition[]
  eventListsByCode: Record<string, unknown>
}
export const eventDefinitionStoreDefault: EventsDefinitionStoreType = {
  eventsDefinition: [],
  eventListsByCode: {},
}

/* ACTION */
export type FetchEventsDefinitionActionType = {
  type: typeof FETCH_EVENTS_DEFINITION_REQUEST
}
export function fetchEventsDefinition(): FetchEventsDefinitionActionType {
  return {
    type: FETCH_EVENTS_DEFINITION_REQUEST,
  }
}

type FetchEventsDefinitionSucceedType = {
  type: typeof FETCH_EVENTS_DEFINITION_SUCCEED
}

export const fetchEventsDefinitionSucceed = (): FetchEventsDefinitionSucceedType => ({
  type: FETCH_EVENTS_DEFINITION_SUCCEED,
})

type FetchEventsDefinitionFailedType = {
  type: typeof FETCH_EVENTS_DEFINITION_FAILED
  payload: any
}

export const fetchEventsDefinitionFailed = (payload): FetchEventsDefinitionFailedType => ({
  type: FETCH_EVENTS_DEFINITION_FAILED,
  payload,
})

export type SaveEventsDefinitionActionType = {
  type: typeof SAVE_EVENTS_DEFINITION
  payload: EventDefinition[]
}
export function saveEventsDefinition(definition): SaveEventsDefinitionActionType {
  return {
    type: SAVE_EVENTS_DEFINITION,
    payload: definition,
  }
}

export type FetchEventListsActionType = {
  type: typeof FETCH_EVENT_LIST_REQUEST
}
export function fetchEventLists(): FetchEventListsActionType {
  return {
    type: FETCH_EVENT_LIST_REQUEST,
  }
}

export const fetchEventListsSucceed = () => ({
  type: FETCH_EVENT_LIST_SUCCEED,
})

export const fetchEventListFailed = (payload) => ({
  type: FETCH_EVENT_LIST_FAILED,
  payload,
})

export type SaveEventListsActionType = {
  type: typeof SAVE_EVENT_LISTS
  payload: EventDefinition[]
}
export function saveEventLists(lists): SaveEventListsActionType {
  return {
    type: SAVE_EVENT_LISTS,
    payload: lists,
  }
}

/* REDUCER */
export const saveEventListsReducer = (state, action: SaveEventListsActionType) => {
  return {
    ...state,
    eventListsByCode: { ...state.eventListsByCode, ...array2keyObject(action.payload, 'code') },
  }
}
export const saveEventsDefinitionReducer = (state, action: SaveEventsDefinitionActionType) => {
  return { ...state, eventsDefinition: action.payload }
}

/* SAGA */
export function* fetchEventsDefinitionSaga(): Generator {
  try {
    const eventsDefinitionResponse: any = yield call(getEventsDefinition)
    const data = (yield eventsDefinitionResponse?.data) as EventDefinition[]
    const normalizedDefinitions = Object.assign(
      {},
      ...data.map((definition) => ({ [definition.code]: definition }))
    )
    yield put(saveEventsDefinition(normalizedDefinitions))
    yield put(fetchEventsDefinitionSucceed())
  } catch (e) {
    yield put(storeError({ error: e, origin: 'fetchEventsDefinitionSaga' }))
    yield put(fetchEventsDefinitionFailed(e))
    console.error(e)
  }
}

// TODO: Typescript types
export function* fetchEventListsSaga(): Generator {
  try {
    const eventsListsResponse: any = yield call(getEventLists)
    const data = (yield eventsListsResponse?.data) as EventList
    yield put(saveEventLists(data))
    yield put(fetchEventListsSucceed())
  } catch (e) {
    yield put(storeError({ error: e, origin: 'fetchEventListsSaga' }))
    yield put(fetchEventListFailed(e))
    console.error(e)
  }
}

/* SELECTOR */
export const getEventDefinitions = (state) =>
  state.events.eventsDefinition as Record<string, EventDefinition>
export const getEventDefinition = (code: string) => (state) =>
  state.events.eventsDefinition[code] as EventDefinition
export const getEventListByCode = (code: string) => (state) =>
  state?.events?.eventListsByCode[code]?.values
