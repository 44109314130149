import {
  GraphData,
  GraphDataset,
  GraphDatasetValue,
  GraphLabelsType,
  GraphValue,
} from 'components/Graph'

export const MAX_LOOP_COUNT = 100000

export const checkMaxLoopCount = (i: number) => {
  if (i > MAX_LOOP_COUNT) {
    throw { message: `getMaxGraphValue max loop count exceeded` }
  }
}

/**
 * Detect upper graph limit based on values and y-axis step
 *
 * @param values
 * @param step
 * @returns graph upper limit value
 */

export const getStepGraphValue = (values: number[]) => {
  if (!values.length) {
    return 1
  }
  const min = Math.min(...values)
  const max = Math.max(...values)
  const diff = max - min
  const numberOfSteps = 4
  const base = diff / numberOfSteps

  const step = diff < 10 ? base : Math.ceil(base / 10) * 10

  const roundBase = 1
  const stepRounded = Math.round(step * roundBase) / roundBase

  // Warning - returning 0 step will freeze the app (it is later used as an increment in a loop)
  return stepRounded === 0 ? 1 : stepRounded
}

export const getMinGraphValue = (values: number[], step: number) => {
  if (!values?.length) {
    return 0
  }
  if (values.length === 1) {
    return values[0]
  }
  const min = Math.min(...values)
  let val = 0
  if (min < 0) {
    val = step * Math.round(min / step)
  } else if (step > 0) {
    for (
      let i = 0, indexNormalized = 0;
      i <= min;
      i = i + step, indexNormalized = indexNormalized + 1
    ) {
      val = i
      checkMaxLoopCount(indexNormalized)
    }
  }
  return val
}

export const getMaxGraphValue = (values: number[], step: number) => {
  if (!values.length) {
    return 1
  }
  if (values.length === 1) {
    return values[0]
  }
  const max = Math.max(...values)
  let val = 0
  if (step > 0) {
    for (
      let i = 0, indexNormalized = 0;
      i <= max;
      i = i + step, indexNormalized = indexNormalized + 1
    ) {
      val = i
      checkMaxLoopCount(indexNormalized)
    }
  }
  return val
}
/**
 * Calculate value height in graph
 *
 * @param options
 * @returns value between 0-1, determining y placement of a value in graph
 */
export const getValueHeight = (options: {
  value: number
  min: number
  step: number
  steps: number[]
}) => {
  const { value, min, step, steps } = options

  if (!value) {
    return 0
  }

  const stepHeight = 1 / steps.length
  const valueInSteps = value / step - min / step
  const height = stepHeight * valueInSteps

  return Math.min(1, Math.max(0, height))
}

/**
 * Gives each set of values additional values that are outside the displayed values (if they are not defined)
 * @param values
 * @returns
 */
export const preProcessGraphData = (data: GraphData[]) => {
  const processedData: GraphData[] = []

  data.forEach((vObj) => {
    if (vObj) {
      const updatedObj = { ...vObj, values: [] }

      // make sure that values are between outside-of-graph values from left and right
      if (!vObj.firstValueIsOutside) {
        updatedObj.values.push(null)
      }

      updatedObj.values.push(...vObj.values)

      if (!vObj.lastValueIsOutside) {
        updatedObj.values.push(null)
      }

      processedData.push(updatedObj)
    }
  })

  return processedData
}

/**
 * Interpolate given values (when a value is null or undefined, calculate it based on the closest values on the left and right, relative to null/undefined neighbors)
 *
 * @param originalValues
 * @returns array of {value, interpolated: boolean}
 */
export const interpolateGraphValues = (originalValues: GraphValue[]) => {
  const values: GraphValue[] = [...originalValues]
  const interpolated: boolean[] = originalValues.map(() => false)

  let prev = null

  for (let i = 1; i < values.length; i++) {
    let curr = values[i]
    if (!curr && curr !== 0) {
      // find next non-null value
      let next = null
      let j = 0
      for (j; i + j < values.length; j++) {
        next = values[i + j]
        if (next || next === 0) {
          break
        }
      }
      if (next && next !== 0) {
        interpolated[i] = true
        curr = prev - (prev - next) / (i === 0 ? 1 : j + 1)
        curr = Math.round(curr * 100) / 100
        values[i] = curr
      }
    }

    prev = curr
  }

  const interpolatedValues: GraphDatasetValue[] = values.map((value, i) => {
    return { value, interpolated: interpolated[i] }
  })

  return interpolatedValues
}

/**
 * Accumulate values
 *
 */
export const accumulateDatasets = (originalDatasets: GraphDataset[]) => {
  const accumulatedDatasets: GraphDataset[] = []

  originalDatasets.forEach((dataset, datasetIndex) => {
    if (datasetIndex === 0) {
      // First dataset has no previous values to accumulate
      return accumulatedDatasets.push({
        ...dataset,
        values: dataset.values.map((v) => {
          return { ...v, originalValue: v.value }
        }),
      })
    }

    const newDataset: GraphDataset = {
      ...dataset,
      values: [...dataset.values].map((valueObj, valueIndex) => {
        const prevNeighborValue: GraphDatasetValue =
          accumulatedDatasets[datasetIndex - 1]?.values[valueIndex]
        /* if (
            !prevNeighborValue?.value ||
            (!props.displaySettings?.interpolateValues && prevNeighborValue?.interpolated)
          ) {
            return valueObj
          } */
        return {
          ...valueObj,
          value: valueObj.value + prevNeighborValue.value,
          originalValue: valueObj.value,
        }
      }),
    }

    return accumulatedDatasets.push(newDataset)
  })

  return accumulatedDatasets
}

/**
 * Order values in datasets (to determine which value is the lowest, second lowest etc.)
 *
 */
const getOrderIndexOfValue = (values: number[], value: number) => {
  if (!values) {
    return 0
  }
  const index = [...values].sort((a, b) => a - b).indexOf(value)
  return index
}

export const orderDatasetValues = (originalDatasets: GraphDataset[]) => {
  if (!originalDatasets?.length) {
    return []
  }

  const updatedDatasets: GraphDataset[] = []

  // 1) Get array of arrays of values (values that are at the same X coordinates in the graph)
  //
  //     = [[dataset1.value1, dataset2.value1], [dataset1.value2, dataset2.value2], ...]
  //
  const joinedDatasetValues = [...originalDatasets[0].values.map((v) => [v.value])]

  originalDatasets.forEach((dataset, i) => {
    if (i > 0) {
      dataset.values.forEach((v, valueIndex) => joinedDatasetValues[valueIndex]?.push(v.value))
    }
  })

  // 2) Get order index of each value in the dataset based on the joined values above
  originalDatasets.forEach((dataset) => {
    let newDataset: GraphDataset = { ...dataset }

    newDataset = {
      ...newDataset,
      values: [...dataset.values].map((valueObj, valueIndex) => {
        return {
          ...valueObj,
          orderIndex: getOrderIndexOfValue(joinedDatasetValues[valueIndex], valueObj.value),
        }
      }),
    }

    updatedDatasets.push(newDataset)
  })

  return updatedDatasets
}

export const getIsValueEmptyWithNoNextValues = (options: {
  value: number
  index: number
  labels: GraphLabelsType
  dataset: GraphDataset
}) => {
  let isEmptyWithNoNextValues = false
  if (!options.value && options.value !== 0) {
    isEmptyWithNoNextValues = true
    for (let i = options.index; i < options.labels.length; i++) {
      const nextData = options.dataset.values[i]
      if (nextData?.value || nextData?.value === 0) {
        isEmptyWithNoNextValues = false
        break
      }
    }
  }
  return isEmptyWithNoNextValues
}
