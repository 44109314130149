export const setLocalStorageItem = (key: string, item: string) => {
  localStorage.setItem(key, item)
}

export const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
}

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
  localStorage.clear()
}
