import { Event, PushNotificationDataEvent } from 'types'
import PopUp, { PopUpDivider } from 'components/modals/PopUp'
import {
  TrackClosePushNotificationType,
  getScreenNameFromRoute,
  trackPushNotificationClose,
} from 'services'
import { getAvailablePets, getUser } from 'store/selectors'
import { setHiddenPushNotification, setViewedPushNotification } from 'entity/pushNotifications'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { PushNotificationBody } from 'types'
import RoundAvatarPet from 'components/roundAvatarPet'
import TimelineItem from 'components/timelineItem'
import { useRouter } from 'next/router'
import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

const Content = styled.div<{ isCentered?: boolean }>`
  --padding: 17px;
  ${(props) => props.theme.breakpoints.XXS} {
    --padding: 14px;
  }
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: calc(-1 * var(--padding));
  padding-right: var(--padding);
  min-height: 50px;

  ${(props) =>
    props.isCentered &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`

const Description = styled.div<{ isLarge?: boolean }>`
  ${(props) =>
    props.isLarge &&
    css`
      font-size: 20px;
      font-weight: 600;
    `}
`

const PetImage = styled.div`
  position: relative;
  margin-bottom: 20px;
`

const EventList = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`

const EventComponent = styled.div`
  * {
    pointer-events: none;
  }
`

const ButtonList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8%;
  row-gap: 10px;
  margin-top: 0px;
  margin-bottom: 10px;
`

const Link = styled.div`
  flex: 1;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  border-radius: 16px;
  min-width: 140px;
  cursor: pointer;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.black}55;
`

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  row-gap: 10px;
`

const FooterOption = styled.div<{ highlight?: boolean }>`
  margin: 5px 16px;
  cursor: pointer;
  color: ${(props) => (props.highlight ? props.theme.colors.green : props.theme.colors.black)};
  font-size: 16px;
`

const Spacer = styled.div`
  flex: 1;
`

const PopUpNotification = (props: {
  body: PushNotificationBody
  onClose?: () => void
  onLinkClick?: () => void
  noOverlay?: boolean
  index?: number
  height?: number
  innerRef?: any
}) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const user = useSelector(getUser)
  const pets = useSelector(getAvailablePets)

  const isBirthday = props.body.type === 'petBirthdayToday'

  //
  // STATE
  //
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const closePopUp = () => {
    setIsOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  //
  // FUNCTIONS
  //
  const trackClose = (type: TrackClosePushNotificationType, params?: Record<string, unknown>) => {
    trackPushNotificationClose({
      userId: user.id,
      type,
      params: {
        'Push Notification ID': props.body?.id?.toString(),
        'Push Notification Title': props.body?.title,
        ...(params || {}),
      },
    })
  }

  const handleHideNotification = (body: PushNotificationBody) => {
    dispatch(setHiddenPushNotification({ notificationBody: body }))
  }

  const handleViewedNotification = (body: PushNotificationBody) => {
    dispatch(setViewedPushNotification({ notificationBody: body }))
  }

  const onCloseClick = () => {
    handleViewedNotification(props.body)
    closePopUp()
    trackClose('close')
  }

  const onHideClick = () => {
    handleHideNotification(props.body)
    closePopUp()
    trackClose('minimize')
  }

  const onLinkClick = (link: string | { pathname: string; query?: any }) => {
    props.onLinkClick && props.onLinkClick()
    handleViewedNotification(props.body)
    router.push(link)
    closePopUp()

    const route = (link as any).pathname || (link as string)
    const toScreenName = getScreenNameFromRoute(route)

    trackClose('redirect', { 'To Screen': toScreenName })
  }

  //
  // EVENT (Optional)
  //
  const renderEvent = (eventData: PushNotificationDataEvent) => {
    if (eventData) {
      const isCompleted = eventData.eventCompletedAt?.length > 0

      const event: Event = {
        id: eventData.eventId,
        code: eventData.eventCode,
        name: eventData.eventName,
        assignedUserId: null,
        createdByUserId: null,
        createdAt: null,
        timelineAt: eventData.eventTimelineAt,
        completedAt: isCompleted ? eventData.eventCompletedAt : null,
        isCompleted: isCompleted,
        items: null,
        petId: null,
        petName: eventData.petName,
      }

      return (
        <EventComponent
          key={event.id}
          onClick={() =>
            onLinkClick({ pathname: `/event/[eventId]`, query: { eventId: event.id } })
          }
        >
          <TimelineItem event={event} showPetName />
        </EventComponent>
      )
    }
    return null
  }

  const renderEvents = () => {
    if (props.body?.eventDataArray?.length) {
      return (
        <EventList>
          {props.body.eventDataArray.map((eventData) => renderEvent(eventData))}
        </EventList>
      )
    }
    return null
  }

  //
  // RENDER
  //
  return (
    <PopUp
      isOpen={isOpen}
      close={onCloseClick}
      header={{
        /* icon: <InfoIcon />, */
        title: props.body.title,
        closeTitle: t('push_notification.CLOSE'),
      }}
      noOverlay={props.noOverlay}
      index={props.index}
      height={props.height}
      innerRef={props.innerRef}
      closeOnOverlayClick={false}
    >
      <Content isCentered={isBirthday}>
        {isBirthday && (
          <PetImage>
            <RoundAvatarPet
              pet={pets.find((p) => p.id === props.body.petId)}
              size={110}
              useBirthdayIcon={isBirthday}
            />
          </PetImage>
        )}

        <Description isLarge={isBirthday}>{props.body.description}</Description>
        {renderEvents()}
      </Content>

      {props.body.links?.length > 0 && (
        <ButtonList>
          {props.body.links?.map((link, i) => (
            <Link key={i} onClick={() => onLinkClick(link.slug)}>
              {t(link.labelTranslationKey)}
            </Link>
          ))}
        </ButtonList>
      )}

      <PopUpDivider />

      <Footer>
        {/* <FooterOption onClick={() => null}>Již neukazovat</FooterOption> */}

        <Spacer />

        <FooterOption onClick={onHideClick} highlight={true}>
          {t('push_notification.HIDE')}
        </FooterOption>
      </Footer>
    </PopUp>
  )
}

export default PopUpNotification
