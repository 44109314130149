import { createGlobalStyle } from 'styled-components'
import { defaultFont } from './fonts'
import { mainTheme } from './themes'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px;
    margin: 0px;
    font-family: ${defaultFont.family}, sans-serif;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
  }
  body{
    color: ${mainTheme.colors.text};
  }
  @media print {
    html, body {
        height: 99%;    
    }
}

  h1 {
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    ${mainTheme.breakpoints.XXS} {
      font-size: 18px;
      line-height: 18px;
    }

    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 1.1em;
    ${mainTheme.breakpoints.XXS} {
      font-size: 1em;
    }

    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    font-family: ${defaultFont.family};
    -webkit-overflow-scrolling: touch;
  }

  html {
    overscroll-behavior-x: none;
  }

  ::-webkit-scrollbar {
    width: 0; 
    background: transparent; 
  }

  select {
    font-family: ${defaultFont.family};
  }

  input {
    border-color: transparent;
    outline: none;
  }

  /* react-modal */
  .ReactModal__Overlay {
    opacity: 0;
    transition: all 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

`

export default GlobalStyle
